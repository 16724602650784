import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild, Optional } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordDialogComponent } from 'src/app/shared/component/change-password-dialog/change-password-dialog.component';
import { AuthenticateService } from '../../services/authenticate.service';
import { CommonService } from '../../services/common.service';
import { HeaderService } from '../header.service';
import { ApplicationConstant } from '../../shared/constant/app.constant';
import { SocialAuthService } from 'angularx-social-login';
import { MsalService } from '@azure/msal-angular';
import { Observable, Subject, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlarmSetupConfirmDialogComponent } from '../../shared/component/alarm-setup-confirm-dialog/alarm-setup-confirm-dialog.component';
import { CookieService } from 'ngx-cookie-service';
import { RoutingConstant } from '../../shared/constant/routing.constant';
import {EndpointConstant} from "../../shared/constant/endpoint.constant";
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { EventLogsEnum } from '../../student-dashboard/enum/event-logs-enum';
import { StudentProfileConstant } from '../../student-profile/student-profile-constant';
import { StudentDashboardService } from '../../student-dashboard/student-dashboard.service';
import { StudentProgressState } from '../../state/student-dashboard.state';
import { StudentProgress } from 'src/app/model/student-progress.model';
import { BaseService } from 'src/app/services/base.service';
import { ApplicationNotifierService } from 'src/app/shared/component/application-notifier/application-notifier.service';
import { GetUser } from 'src/app/model/get-user.model';
import { GetUserState } from 'src/app/state/get-user.state';
import { APP_URL } from 'src/environments/environment';

@Component({
  selector: 'app-academic-header',
  templateUrl: './academic-header.component.html',
  styleUrls: ['./academic-header.component.scss']
})

export class AcademicHeaderComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('popOver') public popover: NgbPopover;
  @ViewChild('popOverMob') public popoverMob: NgbPopover;
  @ViewChild('popOverPushNotification') public popoverPushNotification: NgbPopover;
  @ViewChild('popOverMobPushNotification') public popoverMobPushNotification: NgbPopover;
  @Input() currentUrl: string;
  eligibleCourseInfo: any;
  destroySubscription: Subject<void> = new Subject<void>();
  eventLogsEnum = EventLogsEnum;
  firstName: string = 'Student';
  courseDetails: any;
  courseEligibleDetails: any;
  mobileViewMenu = false;
  routingConstant = RoutingConstant;
  dropdownContentHeight: string;
  genericDynamicForm = null;
  genericDynamicFormUrlSlug = '';
  profileDropDownLinks = [
    {
      url: RoutingConstant.STUDENT_PROFILE,
      label: StudentProfileConstant.myProfileLabel,
      imageUrl: 'assets/profile-new/user.svg'
    },
    {
      url: RoutingConstant.CONTACT_US,
      label: StudentProfileConstant.contactUsLabel,
      imageUrl: 'assets/profile-new/phone.svg'
    },
    {
      url: RoutingConstant.FAQ,
      label: StudentProfileConstant.faqLabel,
      imageUrl: 'assets/profile-new/help-circle.svg'
    },
    {
      url: RoutingConstant.STUDENT_DASHBOARD_CERTIFICATE_ISSUED,
      label: StudentProfileConstant.certificateIssuedLabel,
      imageUrl: 'assets/profile-new/certificate-issue.svg',
      isCertIssuedCount: false,
      isClaimCertCount: false
    },
    {
      url: RoutingConstant.ELIGIBLE_FOR_CLAIM_CERTIFICATE,
      label: StudentProfileConstant.claimYourCertificateLabel,
      imageUrl: 'assets/profile-new/claim-certificate.svg',
      isCertIssuedCount: false,
      isClaimCertCount: false
    },
    {
      url: RoutingConstant.REWARD_POINTS_CURRENT,
      label: StudentProfileConstant.myRewardsLabel,
      imageUrl: 'assets/profile-new/my-reward.svg'
    },
    {
      label: StudentProfileConstant.logoutLabel,
      imageUrl: 'assets/profile-new/logout-union.svg'
    }
  ];
  showNavigation = false;
  userDetails: any;
  userProfileImage: any;
  eligibleCertCount = 0;
  showNotificationBell = false;
  showProfileBadge = false;
  @Select(StudentProgressState.getProgressInfo) progressInfo: Observable<StudentProgress>;
  @Select(GetUserState.getUserInfo) studentDetailsInfo: Observable<GetUser>;
  isLoggenIn = false;

  constructor(private authenticateService: AuthenticateService,
              private cookieService: CookieService,
              public commonService: CommonService,
              private headerService: HeaderService,
              @Optional() private msalService: MsalService,
              private baseService: BaseService,
              private dialog: MatDialog,
              public matDialog: MatDialog,
              public router: Router,
              @Optional() private socialAuthService: SocialAuthService,
              private studentDashboardService: StudentDashboardService,
              private activatedRoute: ActivatedRoute,
              private applicationNotifierService: ApplicationNotifierService) {
  }

  ngOnChanges(): void {
    this.setHeaderContent();
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (window.innerWidth < ApplicationConstant.DEVICE_SIZE.maxIpad) {
        this.mobileViewMenu = true;
        this.showNavigation = false;
      }
    });

    //to update the user details on page load.
    if (this.authenticateService.isTokenAvailable()) {
      this.headerService.dispatchProgress();
      this.headerService.dispatchGetUserDetails();
    }
    this.headerService.dispatchSubscriptionPlans();
    this.subscriptionDetails();
    this.getStudentProgress();
    this.closePopoverOnNavigation();
    this.checkStudentActivity();
    this.getUserDetailsInfo();
    this.getUserProfileBadgeInfo();
    this.removeGenericFormObject();
  }

  /**
   * Subscribe user and alarm details
   */
  subscriptionDetails() {
    this.commonService.profileImageLink
      .pipe(takeUntil(this.destroySubscription))
      .subscribe(profilePic => {
        this.userProfileImage = profilePic;
      }, error => {
        console.error('error', error);
      });
    this.commonService.alarmDetails
      .pipe(takeUntil(this.destroySubscription))
      .subscribe((item: any) => {
        this.courseDetails = item?.courseDetails;
        this.courseEligibleDetails = item?.courseEligibleInfo;
      }, error => {
        console.error('error', error);
      });
    this.commonService.userFirstName
      .pipe(takeUntil(this.destroySubscription))
      .subscribe((userFirstName: string) => {
        this.firstName = userFirstName;
      }, error => {
        console.error('error', error);
      });
  }

  /**
   * Toggle Push Notification Popup
   * @isDesktop - checking desktop view
   */
  togglePushNotificationPopup(isDesktopView = true) {
    isDesktopView ? this.popoverPushNotification?.open() : this.popoverMobPushNotification?.open();
  }

  /**
   * Get the eligible for claim certificate.
   * @isDesktop - checking desktop view
   */
  getEligibleForClaimCertificates(isDesktopView = true) {
    this.commonService.saveStudentClickEventLog(this.eventLogsEnum.cartIcon, null);
    this.studentDashboardService.getDetails(EndpointConstant.ELIGIBLE_FOR_CLAIM_CERTIFICATE)
      .pipe(takeUntil(this.destroySubscription))
      .subscribe(eligibleCourseresp => {
        this.eligibleCourseInfo = eligibleCourseresp?.data;
        this.checkAndShowOfferDetails();
        if(isDesktopView) {
          this.popover.open();
        } else {
          this.popoverMob.open();
        }
      }, error => {
        console.error('Error', error);
      });
  }

  /**
   * Close popover
   */
  private closePopoverOnNavigation() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.popover && this.popover.isOpen()) {
          this.popover.close();
        }
        if (this.popoverPushNotification && this.popoverPushNotification?.isOpen()) {
          this.popoverPushNotification?.close();
        }
        if (this.popoverMob && this.popoverMob.isOpen()) {
          this.popoverMob.close();
        }
        if (this.popoverMobPushNotification && this.popoverMobPushNotification?.isOpen()) {
          this.popoverMobPushNotification?.close();
        }
      }
    });
  }

  /**
   * Check for offer details
   */
  checkAndShowOfferDetails() {
    this.eligibleCourseInfo.forEach(courseInfo => {
      if (courseInfo && courseInfo?.offerDetails?.length > 0 &&
        courseInfo?.offerDetails[0]?.discount_percentage &&
        courseInfo?.is_paid === 0) {
        courseInfo.showOfferDetails = true;
      } else {
        courseInfo.showOfferDetails = false;
      }
    });
  }

  /**
   * Toggle the menu for show a/hide.
   *
   * @param toggleState true/false.
   */
  toggleMenu(toggleState: boolean) {
    this.showNavigation = toggleState;
  }

  /**
   * Set the header content.
   */
  setHeaderContent() {
    this.currentUrl = this.commonService.currentUrl;
    this.getStudentProgress();
    if (this.authenticateService.isTokenAvailable()) {
      this.getAnyUnreadNotifications();
      this.checkUnreadNotifications();
    }
    this.verifyEmail();
    setTimeout(() => {
      this.attachELibrary(this.userDetails);
    }, 1000);
    // if (this.currentUrl) {
    //   this.showNavigation = this.currentUrl.indexOf(ApplicationConstant.appRouting.DISCUSSION_BOARD) > -1;
    // }
  }

  /**
    * Check student activity every two minutes
  */
  checkUnreadNotifications() {
    interval(ApplicationConstant.pushNotificationTimeIntervalActivityConfig)
    .pipe(takeUntil(this.destroySubscription))
    .subscribe(() => {
      if (this.authenticateService.isTokenAvailable()) {
        this.getAnyUnreadNotifications();
      }
    });
  }

  /**
   * Get User Profile Badge Info
  */
  private getUserProfileBadgeInfo() {
    this.headerService.profileBadge
    .pipe(takeUntil(this.destroySubscription))
    .subscribe((res: boolean) => {
      if (res) {
        this.showProfileBadge = res;
      }
    }, error => {
      console.error('Error', error);
    });
  }

  /**
   * Get user information.
   * Get the student details.
  */
  private getUserDetailsInfo() {
    this.studentDetailsInfo
    .pipe(takeUntil(this.destroySubscription))
    .subscribe((userDetails: any) => {
      this.userDetails = userDetails;
      this.firstName = userDetails && userDetails?.first_name ? userDetails?.first_name : 'Student';
      if (userDetails?.first_name) {
        this.getProfileImage();
        this.attachELibrary(userDetails);
        this.isLoggenIn = this.authenticateService.isTokenAvailable();
      }
    }, error => {
      console.error('Error', error);
    });
  }

  /**
   * Include e-library option
   */
  private attachELibrary(data: any) {
    const eLibraryObj = {
      label: StudentProfileConstant.eLibrary,
      imageUrl: 'assets/profile-new/library.png'
    };
    const checkAlreadyInclude = this.profileDropDownLinks.findIndex(e => e.label === eLibraryObj.label) > -1;
    if (data) {
      if (data?.plan_details && data?.plan_details?.ends_in > 0 && !checkAlreadyInclude) {
        this.profileDropDownLinks.splice(5, 0, eLibraryObj);
      }

      if (window.innerWidth < ApplicationConstant.DEVICE_SIZE.maxIpad) {
        this.setDynamicHeight(!this.userDetails?.plan_details || (data?.plan_details && data?.ends_in === 0));
      } else {
        this.dropdownContentHeight = 'auto';
      }
    }
  }

  /**
   * User profile.
   */
  profileNavigationLinks(item: any) {
   this.captureEventLogForProfileMenu(item);
   if (item.label === StudentProfileConstant.eLibrary ) {
      window.open(ApplicationConstant.E_LIBRARY_LINK, '__blank');
      return;
   }
   const certificateIssueDisabled = item?.isCertIssuedCount && item?.url === RoutingConstant.STUDENT_DASHBOARD_CERTIFICATE_ISSUED;
   const certificateClaimDisabled = item?.isClaimCertCount  && item?.url === RoutingConstant.ELIGIBLE_FOR_CLAIM_CERTIFICATE;
   const otherRoutes = item?.url !== RoutingConstant.ELIGIBLE_FOR_CLAIM_CERTIFICATE && item?.url !== RoutingConstant.STUDENT_DASHBOARD_CERTIFICATE_ISSUED;
    if (window.innerWidth < ApplicationConstant.DEVICE_SIZE.maxIpad) {
      if(item && !(certificateIssueDisabled || certificateClaimDisabled || otherRoutes)) {
        this.toggleMenu(true);
      } else {
        this.toggleMenu(false);
      }
    }
    if (item && item?.label === StudentProfileConstant.logoutLabel) {
       this.logout();
    } else if (item && (certificateIssueDisabled || certificateClaimDisabled || otherRoutes)) {
      this.router.navigateByUrl(item.url);
    }
  }

  /**
   * Redirect to the home.
   */
  redirectToHome() {
    if (window.innerWidth < ApplicationConstant.DEVICE_SIZE.maxIpad) {
      this.toggleMenu(false);
    }
    if (this.currentUrl.indexOf(RoutingConstant.VIEW_AS_LEARNER_PAGE) > -1) {
       this.checkForActiveAlarm();
    } else {
      this.router.navigateByUrl(RoutingConstant.STUDENT_DASHBOARD);
    }
    this.studentDashboardService.backToHomeCaptureEvent(this.currentUrl);
  }

  /**
   * Check for active alarm
   */
  checkForActiveAlarm() {
    // Block alarm reminder popup when the course is not enrolled
    const enableAlarmPopup = this.courseEligibleDetails?.isEligibleForCourse && this.courseEligibleDetails?.isEnrolledForCourse; 
    if (enableAlarmPopup && !this.courseDetails?.isActiveAlarm && !this.courseDetails?.isAlarmStatus &&
      !(this.cookieService.get(ApplicationConstant.storageKeys.ALARM_KEY + '_' + this.courseDetails.courseId + this.commonService.getUId()))) {
      this.setupAlarmOnNavigation(false, 'dashboard');
     } else {
      this.router.navigateByUrl(RoutingConstant.STUDENT_DASHBOARD);
     }
  }


  /**
   * set up alarm
   */
  setupAlarmOnNavigation(stayInLesson: boolean, navigateEnd: string) {
   this.matDialog.open(AlarmSetupConfirmDialogComponent, {
      width: '458px',
      height: '273px',
      panelClass: 'ath-panel-alarm-confirm-setup',
      disableClose: true,
      data: {
        courseId: this.courseDetails?.courseId,
        lessonId: this.courseDetails?.lessonId,
        stayInLesson: stayInLesson,
        navigateEnd: navigateEnd,
        slug: this.courseDetails?.slug
      }
     });
  }

  /**
   * Change password for the user.
   */
  changePassword() {
    this.matDialog.open(ChangePasswordDialogComponent, {
      width: '633px',
      height: 'auto',
      panelClass: 'ath-panel-dialog',
      autoFocus: false
    });
  }

  /**
   * Get the profile image.
   */
  getProfileImage() {
    const uid = this.commonService.getUId();
    this.headerService.getProfileImage(uid).then((res: any = {}) => {
      this.userProfileImage = res?.data;
    }, err => {
      console.error('Error Registering: ', err);
    });
  }

  /**
   * Logout from the application.
   */
  logout() {
    if (this.socialAuthService && (this.commonService.loginProvider === ApplicationConstant.LOGIN_PROVIDERS.GOOGLE
      || this.commonService.loginProvider === ApplicationConstant.LOGIN_PROVIDERS.FACEBOOK)) {
      this.socialAuthService?.signOut();
    }
    if (this.msalService && this.commonService.loginProvider === ApplicationConstant.LOGIN_PROVIDERS.MICROSOFT) {
      this.msalService.logoutPopup();
    }
    this.studentDashboardService.saveStudentClickEventLog(this.eventLogsEnum.studentLogoutTime, null);
    this.studentDashboardService.saveDetails(EndpointConstant.LOGOUT, null)
        .pipe(takeUntil(this.destroySubscription))
        .subscribe(data =>{
            this.commonService.navigateWebsiteLink(ApplicationConstant.externalPages.SHORT_COURSE);
            this.commonService.clearLocalStorageOnLogout();
        }, error => {
          this.commonService.navigateWebsiteLink(ApplicationConstant.externalPages.SHORT_COURSE);
          this.commonService.clearLocalStorageOnLogout();
          console.error('error', error);
        });
  }

  /**
   * Go to profile pic.
   * @param url
   */
  getProfilePic(url: string) {
    if (url) { return url; }
    return 'assets/user-img-pic-latest.svg';
  }

  /**
   * Get any Unread Notifications
   */
  private getAnyUnreadNotifications() {
    this.baseService.getMethod(APP_URL.API_NEW, EndpointConstant.PUSH_NOTIFICATION_CHECK_IF_ANY_UNREAD)
    .pipe(takeUntil(this.destroySubscription))
    .subscribe(res => {
      if(res) {
        this.showNotificationBell = res?.notification_count;
        this.headerService.updatePushNotificationBadgeValue(res?.unread_notification_count);
      }}, error => {
        console.error('error', error);
    });
  }

  /**
   * Get the student progress.
   */
  private getStudentProgress() {
    this.progressInfo
    .pipe(takeUntil(this.destroySubscription))
    .subscribe(res => {
      this.headerService.studentProgressDetails = res;
      this.eligibleCertCount = res?.eligible_certificate_count;
      this.isLoggenIn = this.authenticateService.isTokenAvailable();
      this.disableProfileDropDownItem(res);
      this.addOrRemoveDropDownItem(res);
      if (res?.generic_dynamic_form && res?.generic_dynamic_form?.url_slug) {
        this.genericDynamicForm = res?.generic_dynamic_form;
        this.genericDynamicFormUrlSlug = res?.generic_dynamic_form?.url_slug;
        this.attachOrRemoveGenericForm(true);
      } else if(!res?.generic_dynamic_form) {
        this.attachOrRemoveGenericForm(false);
      } 
    });
  }

  /**
 * remove Generic Form Object
 */
  private removeGenericFormObject() {
    this.headerService.removeGenericFormObject
    .subscribe(res => {
      if (res) {
        this.attachOrRemoveGenericForm(false);          
      }},error => {
        console.error('error', error);
    });
  }

  /**
   * Attach or Remove Generic Form option
   * @param attachForm - true to attach and false to remove the genericFormObj from the existing array
  */
  private attachOrRemoveGenericForm(attachForm: boolean) {
    const genericFormObj = { 
      url: RoutingConstant.GENERIC_FORM_WITH_SLUG(this.genericDynamicFormUrlSlug),
      label: StudentProfileConstant.genericFormLabel,
      imageUrl: 'assets/profile-new/generic-form.svg'
    };
    const genericFormObjIndex = this.profileDropDownLinks.findIndex(e => e.label === genericFormObj.label);
    if (genericFormObjIndex < 0 && attachForm) {
      this.profileDropDownLinks.splice(this.profileDropDownLinks.length - 1, 0, genericFormObj);
    } else if (genericFormObjIndex > -1 && !attachForm) {
      this.profileDropDownLinks.splice(genericFormObjIndex, 1);
    }
  }

  /**
   * Disable click access
   * @param studentProgress 
   */
  disableProfileDropDownItem(studentProgress: any) {
    this.profileDropDownLinks.forEach((item) => {
      if (studentProgress && studentProgress?.certificate_issued_count === 0 &&
        item && item?.label === StudentProfileConstant.certificateIssuedLabel) {
        item['isCertIssuedCount'] = false;
      } else {
        item['isCertIssuedCount'] = true;
      }

      if (studentProgress && studentProgress?.eligible_certificate_count === 0 &&
        item && item?.label === StudentProfileConstant.claimYourCertificateLabel) {
        item['isClaimCertCount'] = false;
      } else {
        item['isClaimCertCount'] = true;
      }
    });
  }

  /**
   * capture event logs for menu items
   * @param item
   */
  private captureEventLogForProfileMenu(item: any) {
    if(!item) {
      return;
    }
    switch(item?.label) {
      case StudentProfileConstant.myProfileLabel:
      this.commonService.saveStudentClickEventLog(this.eventLogsEnum.myProfile);
      break;
      case StudentProfileConstant.contactUsLabel:
      this.commonService.saveStudentClickEventLog(this.eventLogsEnum.contactUs);
      break;
      case StudentProfileConstant.faqLabel:
      this.commonService.saveStudentClickEventLog(this.eventLogsEnum.FAQ);
      break;
      case StudentProfileConstant.certificateIssuedLabel:
      this.commonService.saveStudentClickEventLog(this.eventLogsEnum.certificateIssued);
      break;
      case StudentProfileConstant.claimYourCertificateLabel: 
      this.commonService.saveStudentClickEventLog(this.eventLogsEnum.claimYourCertificates);
      break;
      case StudentProfileConstant.eLibrary:
      this.commonService.saveStudentClickEventLog(this.eventLogsEnum.eLibrary);
      break;
      case StudentProfileConstant.myRewardsLabel:
      this.commonService.saveStudentClickEventLog(this.eventLogsEnum.myRewards);
      break;
      case StudentProfileConstant.logoutLabel:
      this.commonService.saveStudentClickEventLog(this.eventLogsEnum.logout);
      break;
      case StudentProfileConstant.mySubscriptions:
      this.commonService.saveStudentClickEventLog(this.eventLogsEnum.mySubscriptionProfile); 
      break;
    }
  }

  /**
   * Check student activity every four and half minutes
   */
  checkStudentActivity() {
    interval(ApplicationConstant.timeIntervalActivityConfig)
          .pipe(takeUntil(this.destroySubscription))
          .subscribe(() => {
            if (this.authenticateService.isTokenAvailable()) {
              this.studentDashboardService
                .saveDetails(EndpointConstant.HOURS, null)
                .pipe(takeUntil(this.destroySubscription))
                .subscribe(() => {},
                (error) => {
                  if(error && error?.status === 401) {
                    this.commonService.clearLocalStorageOnLogout();
                    window.location.reload();
                  }
                  console.error('error', error);
                });
            }
          });
  }

  /**
   * Set dynamic height for menu dropdown
   * @param hideELibrary - boolean value for hide and show e-library link
   */
  private setDynamicHeight(hideELibrary: boolean) {
    // For mobile view only - reduce height of header and profile part
    const reduceHeight = hideELibrary ? 274 : 219;
    if (window.innerHeight < 740) {
      this.dropdownContentHeight = (window.innerHeight - reduceHeight)+'px';
    }
  }

  /**
   * add/remove my subscription item on profile dropdown menu.
   * @param itemData 
   */
  private addOrRemoveDropDownItem(itemData: any) {
    const mySubObj = {
      url: RoutingConstant.SUBSCRIPTIONS,
      label: StudentProfileConstant.mySubscriptions,
      imageUrl: 'assets/profile-new/my-subscription.svg'
    }
    const checkAlreadyInclude = this.profileDropDownLinks.findIndex(item => item.label === mySubObj.label) > -1;
    if(itemData && itemData?.my_subscription_count > 0 && !checkAlreadyInclude) {
      this.profileDropDownLinks.splice(6, 0, mySubObj);
    }
  }

  /*
   * Get the Query parameters.
  */
  private verifyEmail() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params?.email_verification_token) {
        // User mail verification
        const payload = {
          email_verification_token: params?.email_verification_token
        };
        this.studentDashboardService.saveDetails(EndpointConstant.VERIFY_USER_EMAIL, payload)
            .pipe(takeUntil(this.destroySubscription))
            .subscribe((result) => {
              this.headerService.dispatchProgress();
              const msg = {
                icon: 'done',
                message: result?.message
              }
              this.applicationNotifierService.getNotifier(msg);
              this.router.navigate([], 
              {
                  queryParams: {
                    email_verification_token: null
                  },
                  queryParamsHandling: 'merge'
              });
            } ,(error) => {
              console.error('error', error);
            });
      }
    });
  }

  /**
   * Destroy the component.
   */
  ngOnDestroy(): void {
    this.destroySubscription.next();
    this.destroySubscription.complete();
  }

}