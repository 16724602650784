<h2 mat-dialog-title
    *ngIf="data?.isTitle">
  {{data.title}}
</h2>
<mat-dialog-content class="mat-typography mat-content"
                    *ngIf="data">
  <div *ngIf="data.isHtmlContent"
       [innerHTML]="data.content">
  </div>
  <div class="text-center head-content"
       *ngIf="!data.isHtmlContent">
    <h3>
      {{data.content}}
    </h3>
  </div>
  <div class="text-center btns">
    <button mat-button
            class="ok-button m-2 text-center"
            *ngIf="data.isOkButton"
            [mat-dialog-close]="true">
      {{data.okText}}
    </button>
    <button mat-button
            class="isClaimButton mb-3 text-center"
            *ngIf="data.isClaimButton"
            [mat-dialog-close]="true">
      {{data.claimText}}
    </button>
    <button mat-stroked-button
            class="reject-button m-2 text-center"
            *ngIf="data.isCancelButton"
            [mat-dialog-close]="false">
      No
    </button>
  </div>
</mat-dialog-content>
<mat-dialog-actions
  *ngIf="data?.isCloseButton">
  <button mat-button
          class="ok-button text-center"
          [mat-dialog-close]="true">
    Close
  </button>
</mat-dialog-actions>
