<div class="container-fluid p-0 shortcourse-login-bg">
  <div class="container login-wrapper">
    <form [formGroup]="signInForm" (ngSubmit)="loginUser()">
      <div class="row">
        <div class="col-12 col-md-8 col-lg-6 p-0">
          <h1 class="register-now">Welcome
            <span>Back</span>
          </h1>
          <h4 class="learning-quote">Learning is never too late. The whole world is in front of you -- Forward to the dream
          </h4>
          <div class="register-wrapper">
            <ul class="nav nav-pills">
              <li class="active">
                <a class="nav-link show active"
                   (click)="commonService.navigateOLDLMSLink(applicationConstant.externalPages.LOGIN)"
                   data-toggle="pill">
                  Academic Courses</a>
              </li>
              <li>
                <a class="nav-link"
                   data-toggle="pill">
                  Short Courses
                </a>
              </li>
            </ul>
            <div class="mob-bg">
              <div class="social-login">
                <p class="log-in-parent">
                  <a class="login-with">Login  with</a>
                </p>
                <a (click)="formService.signInWithGoogle(locationDetails, null, null, returnUrl)" class="social-icon">
                  <img src="assets/google-login.svg"
                       width="30"/>
                </a>
                <!-- <a (click)="formService.loginWithFacebook(locationDetails, null, null, null, returnUrl)" class="social-icon">
                  <img src="assets/facebook-login.svg"
                       width="16"/>
                </a> -->
                <!--<a (click)="formService.loginWithMicrosoft(locationDetails, null)">
                  <img src="assets/linkedin-login.svg"
                       width="38"/>
                </a>
                <a (click)="formService.loginWithMicrosoft(locationDetails, null, null, null, returnUrl)">
                  <img src="assets/microsoft-login.svg"
                       width="30"/>
                </a>-->
              </div>
            </div>
              <div class="continue-with">
                <span class="continue-text"><span class="mob">or </span> <span class="desk">continue with</span></span>
              </div>
            <div class="continue-with-mob">
              <span>Continue with</span>
            </div>
            <div class="tab-content">
              <div class="tab-pane fade in active show">
                <div class="login-details ac-form">
                  <div class="login-item">
                    <p>Email address</p>
                    <input type="email"
                           id="reg_email"
                           [formControlName]="appConfig.formDetails.emailId.key"
                           [placeholder]="appConfig.formDetails.emailId.placeholder"
                           (input)="updateFields()"/>
                    <br/>
                    <mat-error *ngIf="formControl[appConfig.formDetails.emailId.key].invalid
                        && formControl[appConfig.formDetails.emailId.key].touched">
                      <mat-error *ngIf="formControl[appConfig.formDetails.emailId.key].errors.required">
                        {{ appConfig.formDetails.emailId.errorMessage.required }}
                      </mat-error>
                      <mat-error *ngIf="!formControl[appConfig.formDetails.emailId.key].errors.required">
                        {{ appConfig.formDetails.emailId.errorMessage.invalid }}
                      </mat-error>
                    </mat-error>
                  </div>
                  <div class="login-item">
                    <p>Password</p>
                    <input [type]="togglePasswordIcon ? 'text' : 'password'"
                           class="password"
                           id="password"
                           [formControlName]="appConfig.formDetails.password.key"
                           placeholder="Enter Password"
                           (input)="updateFields()"/>
                           <div [ngClass]="{ 'off': togglePasswordIcon, 'on': !togglePasswordIcon }"
                                (click)="toggleIcon()"></div>
                    <br/>
                    <mat-error *ngIf="formControl[appConfig.formDetails.password.key].invalid
                        && formControl[appConfig.formDetails.password.key].touched">
                      <mat-error *ngIf="formControl[appConfig.formDetails.password.key].errors.required">
                        {{ appConfig.formDetails.password.errorMessage.required }}
                      </mat-error>
                      <mat-error *ngIf="!formControl[appConfig.formDetails.password.key].errors.required">
                        {{ appConfig.formDetails.password.errorMessage.invalid }}
                      </mat-error>
                    </mat-error>
                  </div>
                  <div class="button">
                    <p class="text-right m-0">
                      <a class="text-pink"
                         (click)="forgetPasswordDialog()"
                         class="forgot-password pointer">
                        Forgot password?
                      </a>
                    </p>
                  </div>
                  <div class="button">
                    <button id="registration_form">Login</button>

                    <mat-error *ngIf="serverError !== ''" class="row" >
                      <div class="col-12 text-center pt-3">
                        {{ serverError }}
                      </div>
                    </mat-error>

                  </div>
                  <div class="button p-3">
                    <p class="text-center">
                      If you are a new user please
                      <a class="text-pink"
                         [routerLink]="routingConstant.SHORT_COURSE_REGISTER_URL">
                        Sign Up
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
