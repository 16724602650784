
 <div class="alarm-main">
    <div class="alarm-header"> 
            <img src="assets/webinar/close-btn.png" (click)="closeButton()" class="close-btn pointer">
        <span>Do you want to set a reminder for your session?</span>
    </div>
    <div class="alram-btns">
        <button (click)="setUpNowAlarm()" class="setup-now-btn mr-3">Set up Now</button>
        <button (click)="mayBeLater()" class="maybe-later-btn">Maybe Later</button>
    </div>
    <div class="dont-show-check-box">
        <input type="checkbox" 
            [checked]="enableDoNotShowMeCheckBox"
            (change)="openDoNotShowMeAgainPopup()"
            [(ngModel)]="enableDoNotShowMeCheckBox"
            class="pointer" />
        <label for="dontShowMeAgain" class="pointer">
            Don’t show me again</label><br />
      </div>
 </div>
