import { Action, Selector, State, StateContext } from "@ngxs/store";
import { GetUser } from "../model/get-user.model";
import { Injectable } from "@angular/core";
import { GetUserDetails } from "../actions/student-dashboard.action";
import { EndpointConstant } from "../shared/constant/endpoint.constant";
import { tap } from "rxjs/operators";
import { BaseService } from "../services/base.service";
import { CommonService } from "../services/common.service";

export class GetUserModel {
    data: GetUser;
}

@State<GetUserModel>({
    name: 'getUser',
    defaults: <GetUserModel>{}
})

@Injectable()
export class GetUserState {
    constructor(private baseService: BaseService,
                private commonService: CommonService) {}
    
    @Selector()
    static getUserInfo(state: GetUser) {
        return state;
    }
    
    @Action(GetUserDetails)
    getUserDetailsInfo({getState, setState}: StateContext<GetUserModel>) {
        return this.baseService.get(EndpointConstant.USER_DETAILS(this.commonService.getUId().toString()))
                   .pipe(tap((result) => {
                        this.commonService.setLoggedUser(result);
                        this.commonService.userDetails = {...result};
                        const state = getState();
                        setState({
                            ...state, ...result
                        });
        }));
    }
}
