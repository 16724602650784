<div class="not-found-content">
  <span class="close" mat-dialog-close>&times;</span>
  <h4>No results found</h4>
  <img src="assets/faq/no-found.png"
       alt="not-found-pic">
  <p>Mail us your query at
    <a href="mailto:support@uniathena.com">support@uniathena.com</a>
  </p>
</div>

