import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { EndpointConstant } from '../shared/constant/endpoint.constant';
import { FormService } from './form.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthenticateService } from './authenticate.service';

@Injectable()
export class PushNotificationService {
  
  constructor(private afMessaging: AngularFireMessaging,
              private authenticateService: AuthenticateService,
              private formService: FormService) { }

  /**
   * Requesting push notification permission
   */
  requestPushNotificationPermission() {
    this.afMessaging.requestPermission
      .subscribe(() => {
          this.afMessaging.getToken.subscribe((token: any) => {
            this.getPushToken(token);
          });
        },
        (error: any) => { console.error(error); }
      );
  }

  /**
   * Get the token details and device type
   * @param token - push notification token
   */
  private getPushToken(token: any) {
    const userAgent: string = navigator.userAgent;
    const payload = {
      device_token: token,
      device_type: 'others'
    };

    const isMobileDevice = (): boolean => {
        const regexs = [/(Android)(.+)(Mobile)/i, /BlackBerry/i, /iPhone|iPod/i, /Opera Mini/i, /IEMobile/i]
        return regexs.some((b) => userAgent.match(b))
    }

    const isTabletDevice = (): boolean => {
        const regex = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/
        return regex.test(userAgent.toLowerCase());
    }

    const isDesktopDevice = (): boolean => { return (!isMobileDevice() && !isTabletDevice()) };

    if (isMobileDevice()) {
      payload.device_type = 'mobile';
    } else if (isTabletDevice()) {
      payload.device_type = 'tablet';
    } else if (isDesktopDevice()) {
      payload.device_type = 'desktop';
    }

    if (this.authenticateService.isTokenAvailable()) {
      this.formService.saveDetails(EndpointConstant.MANAGE_USER_PUSH_TOKENS, payload)
                    .pipe(catchError(err => of(console.error(err))))
                    .subscribe();
    }
  }

}