import { Component, OnInit } from '@angular/core';
import { ApplicationConstant } from '../../shared/constant/app.constant';
import { CommonService } from '../../services/common.service';
import { RoutingConstant } from '../../shared/constant/routing.constant';

@Component({
  selector: 'app-short-course-header',
  templateUrl: './short-course-header.component.html',
  styleUrls: ['./short-course-header.component.scss']
})
export class ShortCourseHeaderComponent implements OnInit {

  applicationConstant = ApplicationConstant;
  mobileViewMenu = false;
  routingConstant = RoutingConstant;
  showNavigation = true;

  constructor(public commonService: CommonService) { }

  ngOnInit(): void {
    setTimeout(() => {
      if (window.innerWidth <= 767) {
        this.mobileViewMenu = true;
        this.showNavigation = false;
      }
    });
  }

  /**
   * Toggle the menu for show a/hide.
   *
   * @param toggleState true/false.
   */
  toggleMenu(toggleState: boolean) {
    this.showNavigation = toggleState;
  }

}
