<div mat-dialog-container class="forgot-password-body">
  <div class="header">
    <div *ngIf="data?.isLoginPage" mat-dialog-actions>
      <button mat-button mat-dialog-close class="close-btn strong">
        <span class="float-left pr-2">X</span><span>Cancel</span>
      </button>
    </div>
    <div class="forgor-password-text pt-4">
      <span>Forgot Password</span>
    </div>
    <div class="forgot-descrp">
      <span>Please enter you email address and we will mail you a link to reset your password.</span>
    </div>
  </div>
  <form [formGroup]="forgotPasswordForm" (ngSubmit)="saveDetails(forgotPasswordForm.value)">
    <div class="email-addrs">
      <label class="email-lable">Email Address</label>
      <input placeholder="Enter Email Address"
             [formControlName]="appConfig.formDetails.recoveryEmailId.key"
             type="text"
             class="email-input" />
          <mat-error *ngIf="formControl[appConfig.formDetails.recoveryEmailId.key].invalid
                        && formControl[appConfig.formDetails.recoveryEmailId.key].touched">
            <mat-error *ngIf="formControl[appConfig.formDetails.recoveryEmailId.key].errors.required">
                  {{ appConfig.formDetails.recoveryEmailId.errorMessage.required }}
            </mat-error>
              <mat-error *ngIf="!formControl[appConfig.formDetails.recoveryEmailId.key].errors.required">
                  {{ appConfig.formDetails.recoveryEmailId.errorMessage.invalid }}
              </mat-error>
          </mat-error>
    </div>
    <div class="reset-btn-div">
      <button class="reset-my-password">Reset my password</button>
    </div>
    <div class="back-to-login">
      <span *ngIf="!data?.isProfilePage else backToProf"  (click)="backToLogin()">
        << Back to Login</span>
        <ng-template #backToProf>
          <span (click)="backToProfile()">
            << Back to Profile</span>
        </ng-template>
    </div>
  </form>
</div>
