import { ChangeDetectorRef, Component } from '@angular/core';
import { LoaderService } from '../../../services/loader.service';
import { HeaderService } from 'src/app/header/header.service';
import { ApplicationConstant } from '../../constant/app.constant';
import { AppUtil } from '../../utils/app.util';
import { throttleTime } from 'rxjs/operators';

const THROTTLE_TIME = 5000;

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  isLoading = false;
  quoteContent = {
    id: 1,
    author: "Malcolm Forbes",
    quote: "Education's purpose is to replace an empty mind with an open one."
  };
  isSmallSizeDevice = window.screen.width < ApplicationConstant.DEVICE_SIZE.maxIpad;

  constructor(private loaderService: LoaderService,
              private headerService: HeaderService,
              private ref: ChangeDetectorRef
            ) {
    this.loaderService.isLoading
        .subscribe((res) => {
          this.isLoading = res;
          this.ref.detectChanges();
        });

    /**
     * Throttle fixed to 5 seconds so that a same quote is visible
     */
    this.loaderService.isLoading
        .pipe(throttleTime(THROTTLE_TIME))
        .subscribe((res) => {
          if (res && this.headerService.quotesList.length > 0) {
            const len = this.headerService.quotesList.length;
            const randomIndex = AppUtil.getRandomInt(len)
            this.quoteContent = this.headerService.quotesList[randomIndex];
          }
        });
  }

}
