import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { EducationDetailsInDownloadComponent } from '../education-details-in-download/education-details-in-download.component';
import { Router } from '@angular/router';
import { RoutingConstant } from '../../constant/routing.constant';
import { CourseFeeTypes } from 'src/app/student-dashboard/enum/course-fee-types';
import { EventLogsEnum } from 'src/app/student-dashboard/enum/event-logs-enum';
import { Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { GetUser } from 'src/app/model/get-user.model';
import { GetUserState } from 'src/app/state/get-user.state';
import { takeUntil } from 'rxjs/operators';
import { EndpointConstant } from '../../constant/endpoint.constant';
import { FormService } from 'src/app/services/form.service';

@Component({
  selector: 'app-personalized-offer-popup',
  templateUrl: './personalized-offer-popup.component.html',
  styleUrls: ['./personalized-offer-popup.component.scss']
})
export class PersonalizedOfferPopupComponent implements OnInit, OnDestroy {

  destroySubscription: Subject<void> = new Subject<void>();
  userData: any;
  @Select(GetUserState.getUserInfo) studentDetailsInfo: Observable<GetUser>;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
              @Optional() private dialogRef: MatDialogRef<PersonalizedOfferPopupComponent>,
              private matDialog: MatDialog,
              private commonService: CommonService,
              private router: Router,
              private formService: FormService) { }

  ngOnInit(): void {
    this.getUserData();
  }

  /**
   * Get the student details.
   */
  private getUserData() {
    this.studentDetailsInfo
        .pipe(takeUntil(this.destroySubscription))
        .subscribe(userDetails => {
          this.userData = userDetails;
      }, error => {
        console.error('Error', error);
    });
  }

  /**
   * claim with offer 
   */
  async claimWithOfferButton() {
    this.commonService.saveStudentClickEventLog(EventLogsEnum.claimWithOfferFromPopup, this.dialogData?.offerDetails?.course_id);
    if(this.commonService.showProfileUpdateDialog(this.userData)) {
      this.dialogRef.close();
      const dialogRef = this.matDialog.open(EducationDetailsInDownloadComponent, {
        panelClass: 'offline-panel-dialog',
        disableClose: true,
        data: {
          downloadLink: null,
          courseId: this.dialogData?.offerDetails?.course_id,
          lessonId: null,
          resourceType: null,
          showPopupCloseIcon: false,
          isPersonalizedOffer: true
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if(result) {
          this.updateOfferStatus(1);
        }
    });
   } else {
    this.updateOfferStatus(1);
   }
  }

  /**
   * claim without offer
   */
  claimWithoutOfferButton() {
    this.commonService.saveStudentClickEventLog(EventLogsEnum.claimWithoutOfferFromPopup, this.dialogData?.offerDetails?.course_id);
    this.updateOfferStatus(0);
  }

  /**|
   * navigate to spin wheel page.
   */
  private navigateToSpinWheelPage() {
    const url = `${RoutingConstant.PERSONALIZED_OFFERS}?courseId=${this.dialogData?.offerDetails?.course_id}`;
    return url;
  }

  /**
   * navigate to payment page on click of claim without offer.
   */
  private navigateToPaymentPage() {
    const url = `${RoutingConstant.PAYMENT_DETAILS(this.dialogData?.offerDetails?.course_id, CourseFeeTypes.DIGITAL_CERT_FEE)}`
    return url;
  }

  /**
   * update offer status.
   * @param status 
   */
  private updateOfferStatus(status: number) {
    const payload = {
      cid: this.dialogData?.offerDetails?.course_id,
      status: status
    }
    const endpoint = EndpointConstant.OFFER_STATUS;
    this.formService.saveDetails(endpoint, payload)
    .pipe(takeUntil(this.destroySubscription))
    .subscribe((resp) => {
      if(resp) {
        this.dialogRef.close();
        const url = status === 1 ? this.navigateToSpinWheelPage() : this.navigateToPaymentPage();
        this.router.navigateByUrl(url);
      }
    }, err => {
      console.error('err', err);
    });
  }

  ngOnDestroy(): void {
    this.destroySubscription.next();
    this.destroySubscription.complete();
  }

}
