<div class="container-fluid p-0 athena-header" style="position: fixed;top: 0;width: 100%;z-index: 10;">
    <div class="container bg-white">
        <div class="row ">
            <div class="col-4 logo"
                 (click)="commonService.navigateWebsiteLink('');">
                <img src="assets/uniathena-logo-without-txt.svg" />
            </div>
            <div class="col-8 sc-menu sc-navigation">
              <div class="sc-mobile-menu-icon float-right d-block d-md-none"
                   (click)="toggleMenu(true)">
                <span></span>
              </div>
              <div *ngIf="showNavigation" class="menu-list">
                <img  class="link-close"
                      (click)="toggleMenu(false)"
                      src="assets/close.svg"
                      style="opacity: 0.5; width: 35px; height: auto;position: absolute; right: 19px;top:19px;">
                  <ul class="list-inline">
                    <li class="list-inline-item"
                        (click)="commonService.navigateWebsiteLink(applicationConstant.externalPages.COURSES)">
                      Courses
                    </li>
                    <li class="list-inline-item"
                        (click)="commonService.navigateWebsiteLink(applicationConstant.externalPages.INSIGHT)">
                      Insights
                    </li>
                    <li class="list-inline-item stronglogin"
                        [routerLink]="routingConstant.SHORT_COURSE_LOGIN_URL">
                      Log in
                    </li>
                    <li class="list-inline-item get-started"
                        [routerLink]="routingConstant.SHORT_COURSE_REGISTER_URL">
                      <span>Get Started</span>
                    </li>
                  </ul>
              </div>
          </div>
      </div>
    </div>
</div>
