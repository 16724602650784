import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { AuthenticateService } from '../services/authenticate.service';
import { APP_URL } from '../../environments/environment';
import { LoaderService } from '../services/loader.service';
import { EndpointConstant, ExcludingLoaderForEndpoints } from '../shared/constant/endpoint.constant';
import { NavigationStart, Router } from '@angular/router';
import { RoutingConstant } from '../shared/constant/routing.constant';
import { ApplicationConstant } from '../shared/constant/app.constant';
import { CommonService } from '../services/common.service';

@Injectable()

export class NetworkInterceptor implements HttpInterceptor {

  private httpRequestsCount = 0;

  constructor(private authenticateService: AuthenticateService,
              private loaderService: LoaderService,
              private commonService: CommonService,
              private router: Router) {
              router.events.subscribe(( event: NavigationStart ) => {
                    if ( event.restoredState || event.url === RoutingConstant.STUDENT_DASHBOARD_CERTIFICATE_ISSUED ) {
                      this.httpRequestsCount = 0;
                    }
              });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!request.url.includes(APP_URL.CLAIM_CERTIFICATE_CC)) {

          if (!request.url.includes('login') || !request.url.includes('register')) {
              // Adding authentication and removing caching from header in request.
              request = request.clone({
                headers: request.headers.set('Cache-Control', 'no-cache, no-store, must-revalidate')
                  .set('Pragma', 'no-cache')
                  .set('If-Modified-Since', '0'),
                setHeaders: {
                  Authorization: this.authenticateService.getToken()
                }
              });
          }
      }

    /**
     * Triggering loader spinner
     */
    if (this.matchedEndpointIndex(request) == -1) {
      this.httpRequestsCount++;
      this.loaderService.show();
    }

    if (this.httpRequestsCount === 1 && !request.url.includes('saveNewsLetter')) {
      this.loaderService.toggleFooterVisibility(false);
    }

    // Sending user to login page if response's status is 401.
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // response for the request.
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401 && !request.url.includes(EndpointConstant.LOGIN)) {
          this.commonService.clearLocalStorageOnLogout();
          window.location.reload();
          this.delete_cookie('hideOnBoarding');
          // this.router.navigateByUrl(ApplicationConstant.appRouting.LOGIN);
        //  window.location.href = 'https://ulearn.athena.edu/Login';
        }
      }
    })).pipe(finalize(() => {
      if (this.matchedEndpointIndex(request) == -1) {
        this.httpRequestsCount--;
        if (this.httpRequestsCount <= 0 && this.loaderService.isPageRendered) {
          this.loaderService.toggleFooterVisibility(true);
          this.loaderService.hide();
        }
      }
    }));
  }

  delete_cookie(name: string) {
    document.cookie = name + '=; domain=.athena.edu;Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  /**
   * Find the index of the matched url to exclude for spinner
   * @param request - http request
   * @returns - index of the matched url
   */
  private matchedEndpointIndex(request) {
    return ExcludingLoaderForEndpoints.findIndex(url => request.url.match(url));
  }

}
