export const ApplicationNotifierConstant = {

  notifierConfig: {
    duration: 3000,
    horizontalPosition: 'right',
    verticalPosition: 'top',
  },
  auto_saved: {
    icon: 'done',
    message: 'Auto Saved'
  },
  approved_application_error: {
    icon: 'error_outline',
    message: 'Selected course have already been approved. Please apply for a different course!'
  },
  code_error: {
    icon: 'error_outline',
    message: 'Please add the Code',
  },
  course_fee_message: {
    icon: 'error_outline',
    message: 'Please select at least one course fee details'
  },
  course_code_message: {
    icon: 'error_outline',
    message: 'Please add module code'
  },
  error_message: {
    icon: 'error_outline',
    message: 'Something went wrong'
  },
  file_extension_error: {
    icon: 'error_outline',
    message: 'Wrong format. Please upload file with correct format '
  },
  file_limit_error: {
    icon: 'error_outline',
    message: 'Max no of files '
  },
  module_fee_message: {
    icon: 'error_outline',
    message: 'Please select at least one module fee details'
  },
  no_document_error: {
    icon: 'error_outline',
    message: 'No documents added. Please add a document to upload.'
  },
  quiz_question_count_error: {
    icon: 'error_outline',
    message: 'Please add correct number of questions'
  },
  single_file_limit_error: {
    icon: 'error_outline',
    message: 'Multiple files not allowed'
  },
  file_size_error: {
    icon: 'error_outline',
    message: 'File size should be less than 5MB'
  },
  file_upload: {
    icon: 'done',
    message: 'File uploaded successfully'
  },
  photo_upload: {
    icon: 'done',
    message: 'Photo upload successfully'
  },
  form_saved: {
    icon: 'done',
    message: 'Details Saved Successfully'
  },
  contact_form_saved: {
    icon: 'done',
    message: 'Your Message submitted Successfully!! Will Contact you soon!'
  },
  field_empty: {
    icon: 'error_outline',
    message: 'Field empty'
  },
  no_certificated_added: {
    icon: 'error_outline',
    message: 'No Certificate added'
  },
  module_selection_error: {
    icon: 'error_outline',
    message: 'Please select at least one Unit'
  },
  password_updated: {
    icon: 'done',
    message: 'Password updated successfully'
  },
  reset_link: {
    icon: 'done',
    message: 'Reset password link sent to your mail'
  },
  link_copied: {
    icon: 'done',
    message: 'Copied'
  },
  password_not_matched: {
    icon: 'error_outline',
    message: 'Confirm Password does not match with password'
  },
  invalid_email: {
    icon: 'error_outline',
    message: 'Please enter your registered email'
  },
  invalid_dob: {
    icon: 'error_outline',
    message: 'Please enter DOB'
  },
  not_valid_dob: {
    icon: 'error_outline',
    message: 'Invalid DOB'
  },
  no_certificatetype_selected: {
    icon: 'error_outline',
    message: 'No certificate type selected'
  },
  send_mail_now: {
    icon: 'email',
    message: 'Mail has been sent to the registered email'
  },
  no_select_module : {
    icon: 'error_outline',
    message: 'Please select atleast one module to Publish'
  },
  certificate_already_claimed: {
    icon: 'error_outline',
    message: 'You have already claimed certificate for this course'
  },
  course_fees_not_added : {
    icon: 'error_outline',
    message: 'Please select atleast one Course fee to Publish'
  },
  course_code_not_saved: {
    icon: 'error_outline',
    message: 'Please save course code to Publish'
  },
  discussion_error: {
    icon: 'error_outline',
    message: 'Please Enter Discussion Title'
  },
  fact_sheet_error: {
    icon: 'error_outline',
    message: 'Fact sheet url required'
  },
  ExternalId_error: {
    icon: 'error_outline',
    message: 'External ID required'
  },
  post_comment_admin: {
    icon: 'done',
    message: 'Your comment will be posted after the admins approval'
  },
  post_comment: {
    icon: 'done',
    message: 'Posted comment successfully'
  },
  reply_comment_admin: {
    icon: 'done',
    message: 'Your comment will be posted after the admins approval'
  },
  reply_comment: {
    icon: 'done',
    message: 'Reply comment successfully'
  },
  user_review_save : {
    icon: 'done',
    message: 'Review Submitted successfully!'
  },
  clipboard_url_copy: {
    icon: 'done',
    message: 'Share link is copied'
  },
  not_eligible_another_plan: {
    icon: 'error_outline',
    message: 'You are not eligible to upgrade to another plan.'
  },
  premium_limit_reached: {
    icon: 'error_outline',
    message: 'Premium Course Enrollment Limit Reached'
  },
  premium_course_add: {
    icon: 'done',
    message: 'Course added to the current plan'
  },
  feedback_form_mandatory_msg: {
    icon: 'error_outline',
    message: 'This field is required!'
  },
  upload_proof_doc: {
    icon: 'error_outline',
    message: 'Please upload your photo id proof'
  },
  proof_type: {
    icon: 'error_outline',
    message: 'Please select proof type'
  },
  push_notification_mark_all_as_read: {
    icon: 'done',
    message: 'All Records Marked as Read Successfully'
  },
  form_already_submitted: {
    icon: 'error_outline',
    message: 'Form is Already Submitted'
  },
  earn_credits_user_not_enrolled: {
    icon: 'error_outline',
    message: 'User not enrolled for this Course'
  },
  earn_credits_user_plan_expired: {
    icon: 'error_outline',
    message: 'This access has expired'
  },
  poll_question_empty: {
    icon: 'error_outline',
    message: 'No Active Poll Questions'
  },
  profile_proof_type: {
    icon: 'error_outline',
    message: 'Please select Photo ID type'
  }
};
