import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'negToZeroPipe'
})
export class NegativeToZeroPipe implements PipeTransform {

  transform(value: string | number): number {
    const convertedValue = +value;
    return convertedValue <= 0 ? 0 : convertedValue;
  }

}