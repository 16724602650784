<ng-container *ngIf="domainPollNotVoted &&
                     pollDomainService.isActivePoll &&
                     !headerService.togglePollSection &&
                     !isPollDetailsSaved &&
                     !isLessonPage">
    <img (click)="togglePollSection()" src="assets/poll/poll_live.gif"  class="poll-icon poll-desktop">
    <img (click)="togglePollSection()" src="assets/poll/poll_live_mob.gif"  class="poll-icon poll-mob">
</ng-container>

<app-poll-domain *ngIf="domainPollNotVoted"
                 (emitVotedInfo)="receiveVotedInfo()">
</app-poll-domain>