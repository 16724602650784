import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StudentProgress } from 'src/app/model/student-progress.model';
import { StudentProgressState } from 'src/app/state/student-dashboard.state';
import { HeaderService } from '../../header.service';
import { NavigationEnd, Router } from '@angular/router';
import { RoutingConstant } from 'src/app/shared/constant/routing.constant';
import { ApplicationConstant } from 'src/app/shared/constant/app.constant';
import { PollDomainService } from 'src/app/shared/component/poll-domain/poll-domain.service';

@Component({
  selector: 'app-academic-header-poll',
  templateUrl: './academic-header-poll.component.html',
  styleUrls: ['./academic-header-poll.component.scss']
})
export class AcademicHeaderPollComponent implements OnInit {

  @Select(StudentProgressState.getProgressInfo) progressInfo: Observable<StudentProgress>;
  destroySubscription: Subject<void> = new Subject<void>();
  domainPollNotVoted = true;
  isPollDetailsSaved = false;
  isLessonPage = false;
  isMediumSizeDevice = false;

  constructor(public headerService: HeaderService,
              public pollDomainService: PollDomainService,
              private router: Router) {
                this.checkLessonPage();
              }

  ngOnInit(): void {
    this.isMediumSizeDevice = window.screen.width < ApplicationConstant.DEVICE_SIZE.maxIpad;
    this.isLessonPage = this.router.url.includes(RoutingConstant.VIEW_AS_LEARNER_PAGE) && this.isMediumSizeDevice;
    this.getStudentProgress();
  }

  /**
   * Get the student progress.
   */
  private getStudentProgress() {
    this.progressInfo
        .pipe(takeUntil(this.destroySubscription))
        .subscribe(res => {
          this.domainPollNotVoted = res?.student_poll_status === 0;
        });
  }

  /**
   * Open the poll domain dialog.
   */
  togglePollSection() {
    this.pollDomainService.getPollList();
  }

  /**
   * Receiving info to hide poll icon
   */
  receiveVotedInfo() {
    this.isPollDetailsSaved = true;
  }

  /**
   * Check lesson page or not
   */
  private checkLessonPage() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isLessonPage = this.isMediumSizeDevice && event.url.includes(RoutingConstant.VIEW_AS_LEARNER_PAGE);
      }
    });
  }

  /**
   * Destroy the component.
   */
  ngOnDestroy(): void {
    this.destroySubscription.next();
    this.destroySubscription.complete();
  }

}
