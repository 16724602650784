import { Component, Input, OnChanges, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { APP_URL } from '../../environments/environment';
import { LoaderService } from '../services/loader.service';
import { ApplicationConstant } from '../shared/constant/app.constant';
import { RoutingConstant } from '../shared/constant/routing.constant';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EndpointConstant } from '../shared/constant/endpoint.constant';
import { ApplicationNotifierService } from '../shared/component/application-notifier/application-notifier.service';
import { FormService } from '../services/form.service';
import { NavigationStart, Router } from '@angular/router';
import { AuthenticateService } from '../services/authenticate.service';
import { FooterConstant } from './footer.constant';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input() currentURL: string;
  currentYear: number;
  destroySubscription: Subject<void> = new Subject<void>();
  footerConfig: any;
  isPageLoaded = false;
  showCourses = false;
  websiteURL = APP_URL.WEBSITE;
  acadsURL = APP_URL.OLD_LMS;
  zohoDeskURL = APP_URL.ZOHO_DESK_URL;
  applicationConstant = ApplicationConstant;
  subscribeEmail = '';
  emailValidation = {
    invalid: false,
    isSubmitted: false,
    message: ''
  };
  routingConstant = RoutingConstant;
  popularCourses = FooterConstant.POPULAR_COURSES;
  trendingCourses = FooterConstant.TRENDING_COURSES;
  topUniversitiesCourses = FooterConstant.TOP_UNIVERSITY_COURSES;
  degreeProgramCourses = FooterConstant.DEGRESS_PROGRAM_COURSES;

  constructor(private formService: FormService,
              private loaderService: LoaderService,
              private applicationNotifierService: ApplicationNotifierService,
              private authenticateService: AuthenticateService,
              private router: Router) {
    this.footerConfig = {
      shortCourseHeader: [
        RoutingConstant.SHORT_COURSE_LOGIN_URL,
        RoutingConstant.SHORT_COURSE_REGISTER_URL
      ]
    }
    this.loaderService.isNavigationComplete
        .pipe(takeUntil(this.destroySubscription))
        .subscribe((res) => {
          this.isPageLoaded = res;
          if (this.isPageLoaded) {
            setTimeout(() => {
              const chatElement = document.querySelector<HTMLElement>('.chat-bot-text')[0];
              chatElement.style.bottom = '80px';
              const askSamElem = document.getElementById('chatBotAskSam');
              console.log(askSamElem);
              askSamElem.style.bottom = '50px';
            }, 1000);
          }
        });
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if(event.url.indexOf(RoutingConstant.SHORT_COURSE_LOGIN_URL) > -1 ||
           event.url.indexOf(RoutingConstant.SHORT_COURSE_REGISTER_URL) > -1) {
          this.loaderService.isPageRendered = true;
          this.isPageLoaded = true;
        } else if((event.url.includes(RoutingConstant.COURSE_DETAILS) ||
                  event.url.includes(RoutingConstant.VIEW_AS_LEARNER_PAGE) ||
                  event.url.includes(RoutingConstant.EARN_CREDITS) ||
                  event.url.includes(RoutingConstant.PAYMENT)) &&
                  // To avoid loader trigger while deactivating in quiz page
                  !this.router.routerState.snapshot.url?.includes(RoutingConstant.QUIZ_ASSESSMENT_QUESTION)) {
          this.loaderService.isPageRendered = false;
          this.loaderService.show();
        } else {
          this.loaderService.isPageRendered = true; 
        }
      }
    });
  }

  ngAfterViewInit() {
    // const chatElement = document.querySelector<HTMLElement>('.chat360__btn-container')[0];
    // console.log(chatElement.style);
    // chatElement.style.bottom = '80px';

    // const askSamElem = document.getElementById('chatBotAskSam');
    // console.log(askSamElem);
    // askSamElem.style.bottom = '50px !important';
  }

  /**
   * Subscribing newsletter
   */
  subscribeNewsletter() {
    this.emailValidation.isSubmitted = true;
    this.updatingEmail();
    if (this.emailValidation.invalid) {
      return;
    }
    const payload = {
      mail: this.subscribeEmail
    };
    this.formService.saveDetails(EndpointConstant.SUBSCRIBE_NEWSLETTER, payload)
        .pipe(takeUntil(this.destroySubscription))
        .subscribe(res => {
          this.applicationNotifierService.getNotifier({
            icon: 'done',
            message: res['message']
          });
        }, error => {
          console.error('Error', error);
        });
  }

  /**
   * Reset error message
   */
  updatingEmail() {
    const pattern = new RegExp(ApplicationConstant.validatorPatterns.email);
    if (!this.subscribeEmail.trim()) {
      this.emailValidation.message = 'Email is required';
      this.emailValidation.invalid = true;
    } else if (this.emailValidation.isSubmitted) {
      this.emailValidation.message = 'Please enter a valid email';
      this.emailValidation.invalid = !pattern.test(this.subscribeEmail);
    }
  }

  ngOnChanges() {
    if (this.currentURL) {
      this.showCourses = this.footerConfig.shortCourseHeader.some(value => this.currentURL.indexOf(value) > -1);
    }
  }

  /**
   * Redirection to acads based on token
   */
  redirectToAcadPrograms() {
    if (this.authenticateService.isTokenAvailable()) {
      this.router.navigate([RoutingConstant.STUDENT_DASHBOARD], {
        queryParams: { 
          academicCourse: 1,
          shortCourse: null,
          category: null
        },
        queryParamsHandling: 'merge'
      });
    } else {
      window.open(`${this.websiteURL}course-home`, '_blank');
    }
  }

  /**
   * Destroy the component.
   */
  ngOnDestroy(): void {
    this.destroySubscription.next();
    this.destroySubscription.complete();
  }

}
