import { Injectable } from '@angular/core';
import { BaseService } from '../services/base.service';
import { CookieService } from 'ngx-cookie-service';
import { ApplicationConstant } from '../shared/constant/app.constant';
import { EventLogsEnum } from './enum/event-logs-enum';
import { EndpointConstant } from '../shared/constant/endpoint.constant';
import { map, mergeMap } from 'rxjs/operators';
import { RewardPointsService } from '../reward-points/reward-points.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { HeaderService } from '../header/header.service';
import { CommonService } from '../services/common.service';
@Injectable({
  providedIn: 'root'
})
export class StudentDashboardService {

  isCoachMarkShown = new BehaviorSubject<boolean>(false);
  updatePlanCard = new BehaviorSubject<boolean>(false);
  triggerCoachmarkApi = new Subject<boolean>();
  isPremiumTabTriggered = new BehaviorSubject<boolean>(false);
  triggerAcademicProgramsTab = new Subject<boolean>();
  eventLogsEnum = EventLogsEnum;
  constructor(private baseService: BaseService,
              private rewardPointsService: RewardPointsService,
              private cookieService: CookieService,
              private headerService: HeaderService,
              private commonService: CommonService) {
  }

  /**
   * Get the details for the parent module
   *
   * @param endpoint api
   */
  getDetails(endpoint: string) {
    return this.baseService.get(endpoint);
  }

  /**
   * Save the details for the parent module
   *
   * @param endpoint api
   * @param data parent module
   */
  saveDetails(endpoint: string, data: any) {
    return this.baseService.post(endpoint, data);
  }

  /**
   * Save the details for the parent module
   *
   * @param endpoint api
   * @param data parent module
   */
  updateDetails(endpoint: string, data: any) {
    return this.baseService.put(endpoint, data);
  }

  /**
   * Delete the details for the parent module.
   *
   * @param endpoint api
   */
  deleteDetails(endpoint: string) {
    return this.baseService.delete(endpoint);
  }

  /**
   * Get all the discussion.
   *
   * @param payload request consists of course id, title, description, category id, lesson id.
   * @param isViewAsLearnerPage view as leaner page.
   */
  getAllDiscussions(payload: any, isViewAsLearnerPage: boolean) {
    let params = `course_id=${payload.course_id}&category_id=${payload.category_id}`;
    if (isViewAsLearnerPage) {
      params = `${params}&child_module_item_id=${payload.child_module_item_id}`;
    }
    const url = `${EndpointConstant.DISCUSSION}?${params}`;

    return this.baseService.get(url)
      .toPromise()
      .then(response => response)
      .catch();
  }

  getPaginationPost(id: number, pageNo: number) {
    return this.baseService.get(`discussion/post/${id}?page_limit=5&page=${pageNo}`);
  }

  getPaginationReply(id: number, pageNo: number) {
    return this.baseService.get(`discussion/reply/${id}?page_limit=5&page=${pageNo}`);
  }

  createNewReply(data: any) {
    return this.baseService.post(EndpointConstant.DISCUSSION_REPLY, data)
      .toPromise()
      .then(response => response)
      .catch();
  }

  createNewPost(data: any) {
    return this.baseService.post(EndpointConstant.DISCUSSION_POST, data)
      .toPromise()
      .then(response => response)
      .catch();
  }

  getCategory() {
    return this.baseService.get(EndpointConstant.DISCUSSION_CATEGORY)
      .toPromise()
      .then(response => response)
      .catch();
  }

  likeContentInDiscussion(data: any) {
    return this.baseService.post(EndpointConstant.DISCUSSION_RATING, data)
      .toPromise()
      .then(response => response)
      .catch();
  }

  getMyDiscussions(uid: any) {
    return this.baseService.get(EndpointConstant.DISCUSSION_LOGGED_IN_USER(uid))
      .toPromise()
      .then(response => response)
      .catch();
  }

  moderateThread(data: any) {
    return this.baseService.post(EndpointConstant.DISCUSSION_MODERATION, data)
      .toPromise()
      .then(response => response)
      .catch();
  }

  registeredCourses(courseId: any) {
    return this.baseService.get(EndpointConstant.REGISTERED_COURSE(courseId))
      .toPromise()
      .then(response => response)
      .catch();
  }

  /**
   * Configure the quiz result in report card.
   *
   * @param moduleList is the information for the module.
   * @param quizScoresDetails is the details for the quiz.
   */
  configureQuizDetails(moduleList: any, quizScoresDetails?: any) {

    moduleList.forEach(module => {

      const moduleQuizScores = quizScoresDetails && quizScoresDetails.find(moduleInfo => moduleInfo.id === module.child_module_id);
      let unitScore = 0;
      let unitTotalScore = 0;
      let allUnitPassed = true;
      let isUnitQuizExist = false;
      let unitQuizAttempt = false;
      let unitPosition = 0;
      let lessonPositionInsideModule = 0;

      module.moduleItems.forEach(unit => {

        if (unit.type === 'quiz') {
          this.calculateScoreDetails(moduleQuizScores, unit);
        } else if (unit.type === 'unit') {
          unit.position = unitPosition++;
        } else {
          unit.position = lessonPositionInsideModule++;
        }

        let isSubModuleQuizExist = false;
        let subModuleScore = 0;
        let subModuleTotalScore = 0;
        let allSubModulePassed = true;
        let subModuleQuizAttempt = false;

        if (unit.subModuleItems && unit.subModuleItems.length) {

          let chapterPosition = 0;
          let lessonPosition = 0;
          unit.subModuleItems.forEach(chapterOrLesson => {

            let isLessonQuizExist = false;
            let lessonScore = 0;
            let lessonTotalScore = 0;
            let allLessonPassed = true;
            let lessonQuizAttempt = false;

            if (chapterOrLesson.type === 'chapter') {

              if (chapterOrLesson.type === 'quiz') {
                this.calculateScoreDetails(moduleQuizScores, chapterOrLesson);
              } else {
                chapterOrLesson.position = chapterPosition++;
              }

              if (chapterOrLesson.subModuleItems && chapterOrLesson.subModuleItems.length) {

                let lessonPositionInsideChapter = 0;

                chapterOrLesson.subModuleItems.forEach(lesson => {

                  if (lesson.type === 'quiz') {
                    this.calculateScoreDetails(moduleQuizScores, lesson);
                  } else {
                    lesson.position = lessonPositionInsideChapter++;
                  }

                  if (lesson.reportCard) {
                    lessonScore = lessonScore + lesson.reportCard.score;
                    lessonTotalScore = lessonTotalScore + lesson.reportCard.totalScore;
                    allLessonPassed = allLessonPassed && lesson.reportCard.isPassed;
                    lessonQuizAttempt = lessonQuizAttempt || lesson.reportCard.quizAttempt;
                    isLessonQuizExist = true;
                  }
                });
              }

              if (isLessonQuizExist) {
                chapterOrLesson.reportCardFinalScore = {
                  score: lessonScore,
                  totalScore: lessonTotalScore,
                  isPassed: allLessonPassed,
                  quizAttempt: lessonQuizAttempt
                };
                subModuleScore = subModuleScore + lessonScore;
                subModuleTotalScore = subModuleTotalScore + lessonTotalScore;
                allSubModulePassed = allSubModulePassed && allLessonPassed;
                subModuleQuizAttempt = subModuleQuizAttempt || lessonQuizAttempt;
                isSubModuleQuizExist = true;
              }
              if (chapterOrLesson.reportCard) {
                subModuleScore = subModuleScore + chapterOrLesson.reportCard.score;
                subModuleTotalScore = subModuleTotalScore + chapterOrLesson.reportCard.totalScore;
                allSubModulePassed = allSubModulePassed && chapterOrLesson.reportCard.isPassed;
                subModuleQuizAttempt = subModuleQuizAttempt || chapterOrLesson.reportCard.quizAttempt;
                isSubModuleQuizExist = true;
              }
            } else {

              if (chapterOrLesson.type === 'quiz') {
                this.calculateScoreDetails(moduleQuizScores, chapterOrLesson);
              } else {
                chapterOrLesson.position = lessonPosition++;
              }

              if (chapterOrLesson.reportCard) {
                subModuleScore = subModuleScore + chapterOrLesson.reportCard.score;
                subModuleTotalScore = subModuleTotalScore + chapterOrLesson.reportCard.totalScore;
                allSubModulePassed = allSubModulePassed && chapterOrLesson.reportCard.isPassed;
                subModuleQuizAttempt = subModuleQuizAttempt || chapterOrLesson.reportCard.quizAttempt;
                isSubModuleQuizExist = true;
              }
            }
          });
        }

        if (isSubModuleQuizExist) {
          unit.reportCardFinalScore = {
            score: subModuleScore,
            totalScore: subModuleTotalScore,
            isPassed: allSubModulePassed,
            quizAttempt: subModuleQuizAttempt
          };
          unitScore = unitScore + subModuleScore;
          unitTotalScore = unitTotalScore + subModuleTotalScore;
          allUnitPassed = allUnitPassed && allSubModulePassed;
          unitQuizAttempt = unitQuizAttempt || subModuleQuizAttempt;
          isUnitQuizExist = true;
        }

        if (unit.reportCard) {
          unitScore = unitScore + unit.reportCard.score;
          unitTotalScore = unitTotalScore + unit.reportCard.totalScore;
          allUnitPassed = allUnitPassed && unit.reportCard.isPassed;
          unitQuizAttempt = unitQuizAttempt || unit.reportCard.quizAttempt;
          isUnitQuizExist = true;
        }
      });

      if (isUnitQuizExist) {
        module.reportCardFinalScore = {
          score: unitScore,
          totalScore: unitTotalScore,
          isPassed: allUnitPassed,
          quizAttempt: unitQuizAttempt
        };
      }
    });
  }

  /**
   * Calculate the quiz scores for the module.
   *
   * @param moduleQuizScores is the score for the quiz in module.
   * @param moduleDetails is the details of the module in the lesson.
   */
  calculateScoreDetails(moduleQuizScores: any, moduleDetails: any) {

    if (moduleDetails.assessment && moduleDetails.assessment.quiz_details) {
      const quizInfo = moduleQuizScores && moduleQuizScores.quiz.find(value => value.module_item_id === moduleDetails.id);
      moduleDetails.reportCard = {
        totalScore: moduleDetails.assessment.quiz_details.marks_per_questions * moduleDetails.assessment.quiz_details.max_question_per_test,
        quizAttempt: quizInfo && quizInfo.examresult !== null,
        score: quizInfo ? quizInfo.correctAnswers * quizInfo.marks_per_question : 0,
        isRetake: moduleDetails.assessment.quiz_details.is_retakable === 1,
        isPassed: quizInfo && quizInfo.examresult === 1,
        isResumable: quizInfo && quizInfo?.is_resumable === 1,
        isSubmitted: quizInfo && quizInfo?.is_submitted === 1
      };
    }
  }

  /**
   * Student click event logs.
   * @param eventTypeNumber event type number
   * @param courseId course Id
   * @param lessonId lesson Id
   */
  saveStudentClickEventLog(eventTypeNumber: number, id?: number, lessonId?: number) {
    let payload: any = {};
    if (eventTypeNumber === this.eventLogsEnum.courseCategoryClickInLms) {
        payload = { student_activity_type: eventTypeNumber, subject_area_id: id };
    } else if (eventTypeNumber === this.eventLogsEnum.certificationTypeClickInLms) {
        payload = { student_activity_type: eventTypeNumber, certificate_type_id: id };
    } else {
        payload = { student_activity_type: eventTypeNumber, course_id: id, child_module_item_id: lessonId };
    }
    this.baseService.post(EndpointConstant.STUDENT_LOG_SAVE, payload).subscribe(response => {
      }, error => {
        console.error('Error', error);
      });
  }

  getCertificationTypes() {
    return this.baseService.get(EndpointConstant.CERTIFICATION_TYPES)
      .toPromise()
      .then(response => response)
      .catch();
  }

  /**
   * Get the categories.
   */
  getCategories() {
    return this.baseService.get(EndpointConstant.COURSE_CATEGORIES)
      .toPromise()
      .then(response => response)
      .catch();
  }

  /**
    * @param currentUrl Current Url
    */
  backToHomeCaptureEvent(currentUrl){
    if(currentUrl.indexOf('view-as-learner')>-1){
      this.saveStudentClickEventLog(this.eventLogsEnum.lessonEndTime, null);
    }
  }

  /**
   * get claim floating cookie
   */
   get isClaimFloatClose() {
    return !this.cookieService.get(ApplicationConstant.storageKeys.IS_CLAIM_FLOAT_CLOSE);
  }

  /**
   * Set claim floating cookie
   */
  set isClaimFloatClose(value: boolean) {
    this.cookieService.set(ApplicationConstant.storageKeys.IS_CLAIM_FLOAT_CLOSE, JSON.stringify(value), 1);
  }

  /**
    * Get the UTM details for the course id
   */
  getUTMDetails(cid: any, medium: string, campaign: string) {
    let utmDetails = {};
    // Getting UTM details
    this.getDetails(EndpointConstant.REWARD_COURSE_UTM(cid))
      .pipe(map((res) => {
        if (res?.data) {
          utmDetails = res?.data;
        }
        return res;
      }),
      // Taking the campaign from the UTM details for the sharecourselink api
      mergeMap(() => this.rewardPointsService.saveDetails(EndpointConstant.REWARD_COURSE_LINK(cid, campaign), true)))
      .subscribe(() => {
        this.headerService.dispatchProgress();
        this.rewardPointsService.generateShareURL(medium, utmDetails, cid);
      }, error => {
        console.error('error', error);
      });
  }

  /**
  * To communicate coachmark shown detail to registered courses component
  */
  updateCoachMarkStatus(value: boolean) {
    this.isCoachMarkShown.next(value);
  }

  /**
  * Trigger coach mark api from service
  */
   startCoachMarkAfterEnrolled() {
    this.triggerCoachmarkApi.next(true);
  }

  /**
  * Update user details in plan card
  */
  updateUserCardDetails() {
    this.updatePlanCard.next(true);
  }

  /**
  * Trigger academic programs tab
  */
  triggerScrollToAcademicProgramsSection() {
    this.triggerAcademicProgramsTab.next(true);
  }

  /**
   * get startup popup cookie
   */
  get isStartUpAvailable() {
    return !this.cookieService.get(ApplicationConstant.storageKeys.START_UP_POPUP + '_' + this.commonService.getUId());
  }

  /**
   * Set startup popup cookie
   */
  set isStartUpAvailable(value: boolean) {
    this.cookieService.set(ApplicationConstant.storageKeys.START_UP_POPUP + '_' + this.commonService.getUId(), JSON.stringify(value), 0.5);
  }

}
