import { Injectable } from '@angular/core';
import { APP_URL } from '../../environments/environment';
import { BaseService } from '../services/base.service';
import { CommonService } from '../services/common.service';
import { EventLogsEnum } from '../student-dashboard/enum/event-logs-enum';
import { AuthenticateService } from '../services/authenticate.service';
import { RedemptionDetails } from '../student-dashboard/dashboard-stats/dashboard-stats.model';

@Injectable({
  providedIn: 'root'
})
export class RewardPointsService {

  rewardPointsBaseUrl = APP_URL.WEBSITE+'short-courses/';
  redemptionDetails: RedemptionDetails;

  constructor(private authenticateService: AuthenticateService,
              private baseService: BaseService,
              private commonService: CommonService) {
  }

  /**
   * Get the details
   *
   * @param endpoint api
   */
  getDetails(endpoint: string) {
    return this.baseService.get(endpoint);
  }

  /**
   * Save the details
   *
   * @param endpoint api
   * @param data parameters (course id, utm source)
   */
  saveDetails(endpoint: string, data: any) {
    return this.baseService.post(endpoint, data);
  }

  /**
   * Generate the URL based on utm parameters
   * @param utmMedium - selected medium
   */
   generateShareURL(utmMedium: string, allUtmDetails: any, cid: number) {
    // Replacing - with space and capitalize first character of every word
    const courseName = allUtmDetails['course_name'].replace(/-/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase());
    const courseText = `Hey, Check out this Course: ${courseName}. The course is Free to Learn and 100% online !!`;
    const campaign = allUtmDetails.mediums.find(u => u.utm_medium === utmMedium).utm_campaign;
    const mediumBaseUrls = {
      Facebook: {
        url: `https://www.facebook.com/sharer/sharer.php?u=`,
        log: EventLogsEnum.shareCourseFacebook
      },
      LinkedIn: {
        url: `https://www.linkedin.com/sharing/share-offsite?&url=`,
        log: EventLogsEnum.shareCourseLinkedin
      },
      Twitter: {
        url: `http://twitter.com/share?text=${encodeURIComponent(courseText)}&url=`,
        log: EventLogsEnum.shareCourseTwitter
      },
      Whatsapp: {
        url: `https://api.whatsapp.com/send?text=${courseText} - `,
        log: EventLogsEnum.shareCourseWhatsApp
      },
      Mail: {
        url: `mailto:?subject=Uniathena Short Course&body=${courseText} - `,
        log: EventLogsEnum.shareCourseMail
      }
    };
    const getMedium = this.getMediumDetails(utmMedium, allUtmDetails);
    if (!getMedium) {
      return;
    }
    let genUrl;
    if (this.authenticateService.isTokenAvailable()) {
      this.commonService.saveStudentClickEventLog(mediumBaseUrls[utmMedium].log, cid.toString());
      genUrl = `${this.rewardPointsBaseUrl}${allUtmDetails['course_name']}?utm_source=${allUtmDetails['utm_source']}_${allUtmDetails['encrypted_uid']}&utm_medium=${utmMedium}&utm_campaign=${campaign}-${allUtmDetails['course_short_name']}-share-${allUtmDetails['encrypted_uid']}&shared_uid=${allUtmDetails['encrypted_uid']}&utm_page=Login`;
    } else {
      genUrl = `${this.rewardPointsBaseUrl}${allUtmDetails['course_name']}?utm_source=${allUtmDetails['utm_source']}&utm_medium=${utmMedium}&utm_campaign=${campaign}-${allUtmDetails['course_short_name']}&shared_uid=${allUtmDetails['encrypted_uid']}&utm_page=Login`;
    }
    genUrl = mediumBaseUrls[utmMedium].url + encodeURIComponent(genUrl);
    this.openUrlNewTab(genUrl);
  }

  /**
   * open the URL in new tab
   * @param copiedUrl - Share URL
   */
  openUrlNewTab(copiedUrl: any) {
    window.open(copiedUrl, "_blank");
  }  

  /**
   * Get the matched medium details
   * @param medium - medium type
   * @param mediumList - medium list
   */
  getMediumDetails(medium: string, mediumList: any) {
    return mediumList['mediums'].find(item => item['utm_medium'].toLowerCase() === medium.toLowerCase());
  }

}