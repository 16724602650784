/**
 * Endpoint details for the application.
 */
export const EndpointConstant = {

  ACADEMIC_COURSE_LIST_GET: `GetCourseDetails/0`,
  ACADEMIC_ELIGIBILITY_CHECK: `academicEligibilityForm/checkAlreadySubmitted`,
  ACADEMIC_ELIGIBILITY_SAVE: `academicEligibilityForm/recordResponse`,
  ACCESS_LOG: 'log/pageentry',
  ALARM_CONFIGURATION: (alarmTypeName: string) => `getAlarmConfiguration?name=${alarmTypeName}`,
  ALARM_SET_SAVE: `setUserAlarm`,
  ALARM_ACTIVE: (courseId: string, lessonId: string) => `getUserAlarm?cid=${courseId}&lesson_id=${lessonId}`,
  ALARM_STATUS_SET: `setPopupStatus`,
  ASSESSMENT_INSTRUCTIONS: (quizAssessmentId: string) => `assessments/${quizAssessmentId}/introduction`,
  ASSESSMENT_QUESTION_OPTION: (quizAssessmentId: string, quizExamId: string) =>
    `assessments/${quizAssessmentId}/examId/${quizExamId}/quizQuestions`,
  ASSESSMENT_TYPE: 'assesmenttypes',
  ASSESSMENT_START_QUIZ: 'startquiz',
  ASSESSMENT_RESULT: (quizAssessmentId: string, quizExamId: string) =>
    `assessments/${quizAssessmentId}/examId/${quizExamId}/results`,
  ASSESSMENT_RESULT_DETAIL: (quizExamId: string) => `exam/${quizExamId}/quizresultdetails`,
  ASSESSMENT_USER_ANSWER: 'userAnswer',
  AUTO_REPLY_QUESTIONS_ANSWERS: (value: string) =>
      `getAutoReplyQuestions?page=1&page_limit=10&keyword=${value}`,

  BIRTHDAY_OFFER_ELIGIBILITY_CHECK: `checkBirthdayOfferEligibilityOnLogin`,
  BIRTHDAY_OFFER_SEND_EMAIL: `sendBirthdayOfferActivatedEmail`,

  CERTIFICATE_AND_TRANSCRIPT_BY_BLOCKCHAIN_ID: (blockchainId: string) =>
    `getClaimedCertificateByBlockchainId/${blockchainId}`,
  CERTIFICATE_CLAIM: `createClaimCertificateByOrderId`,
  CERTIFICATION_TYPES: `getCertTypeByQualId/1`,
  CERTIFICATION_TYPES_ACADEMIC: `getCertTypeByQualId/3?request_from=admin`,
  CERTIFICATION_TYPES_MICRO_CREDITS: `getCertTypeByQualId/2`,
  CLAIMED_CERTIFICATE: `getClaimedCertificateByStudentId`,

  COACH_MARKS_LIST: `getCoachMarksList`,
  COACH_MARK_SEEN_UPDATE: (coachMarkId: number) => `updateCoachMark/${coachMarkId}`,

  CONTACT_US_DETAILS_SAVE: 'saveStudentContactUsData',
  COUNTRY: 'countries',
  COUNTRY_INFO: (countryCode: string) => `countryDetails/${countryCode}`,

  COURSE_ACADEMIC: 'courses',
  COURSE_CATEGORIES: 'get_master_table_data?table=subject_area&course_available=true',
  COURSE_CATEGORIES_ALL: 'get_master_table_data?table=subject_area',
  COURSE_ENROLL: 'enrollCourse',
  COURSE_FEE_TYPES: (courseId: string, countryCode?: string) => `courses/${courseId}/courseFees?country_code=${countryCode}`,
  COURSE_INFO_LOGGED_IN_USER: (courseId: string, moduleInfo?: number) => moduleInfo ? 
   `student/course/${courseId}?module_list_info=${moduleInfo}` :
   `student/course/${courseId}`,
  COURSE_LAST_COMPLETED: 'getLastCompletedCourse',
  COURSE_LIST: (courseId: string, countryCode:string, addtionalCoureInfo?: any,) => addtionalCoureInfo ? 
  `courselist/${courseId}?additional_course_info=${addtionalCoureInfo}&country_code=${countryCode}` : 
  `courselist/${courseId}?country_code=${countryCode}`,
  COURSE_LIST_CATEGORY_WISE: (certificationId: string) =>
    `courselist?status=1&page=1&page_limit=0&fk_certificate_type_id=${certificationId}`,  // page limit set 0 for all courses
  COURSE_LIST_PAGINATION: (userId: string, subjectId: string, certificationId: string, courseName: string,
                           countryCode: string, pageNo: string, sortOrderType: string = null, pageLimit = 20) =>
    `courselist?uid=${userId}&subject_area_id=${subjectId}&fk_type_of_qualification_id=1&fk_certificate_type_id=` +
    `${certificationId}&course_name=${courseName}&country_code=${countryCode}&status=1&page=${pageNo}&page_limit=${pageLimit}` +
    `&course_order=${sortOrderType}`,
  COURSE_RECOMMENDATION: (countryCode: string) => `courseRecommendation?country_code=${countryCode}`,
  COURSE_RECOMMENDATION_ACADEMIC: 'academicCourseRecommendation',
  COURSE_RECOMMENDATION_PERSONALIZED: (countryCode: string, pageNo = 1, pageLimit = 20) => 
    `course-recommendation/personalized?country_code=${countryCode}&page=${pageNo}&limit=${pageLimit}`,

  DISCUSSION_CATEGORY: 'category',
  DISCUSSION_MODERATION: 'moderation',
  DISCUSSION_LOGGED_IN_USER: (userId: string) => `mydiscussions?uid=${userId}`,
  DISCUSSION_POST: 'post',
  DISCUSSION_RATING: 'dfrating',
  DISCUSSION_REPLY: 'reply',
  DISCUSSION: 'discussion',
  DOCUMENT_PROOF: 'getprooftypes/admin',
  DROP_COURSE_REASONS_GET: 'dropCourseReasons',
  DROP_COURSE_REASONS_SAVE: (courseId: string) => `dropCourse/${courseId}`,

  EARN_CREDIT_DETAILS: (courseId: string) => `getEarnCreditDetails/${courseId}`,
  ELIGIBLE_FOR_ENROLL: (courseId: number) => `isEligibleForCourse/${courseId}`,
  ELIGIBLE_FOR_CLAIM_CERTIFICATE: 'eligibleForClaimCertificate',
  EMAIL_SUBSCRIBE:(userId: string) => `subscribeEmail/${userId}`,
  EMAIL_UNSUBSCRIBE:(userId: string) => `unsubscribeEmail/${userId}`,
  EMAIL_UNSUBSCRIBE_REASONS: `getUnsubscriptionReasons`,
  EMPLOYMENT_LVL: 'grades',
  ENROLLED_COURSE_PAGINATION: (pageNumber: number, pageLimit: number, country_code?: string, is_premium?: any) =>
  `getStudentRegisteredCoursesById?page=${pageNumber}&page_limit=${pageLimit}&country_code=${country_code}&is_premium=${is_premium}`,
  ENROLLED_COURSE_PAGINATION_WITH_SEARCH: (pageNumber: number, pageLimit: number, searchText: string, country_code?: string, is_premium?: number) =>
  `getStudentRegisteredCoursesById?page=${pageNumber}&page_limit=${pageLimit}&search=${searchText}&country_code=${country_code}&is_premium=${is_premium}`,
  EVENTS_UPCOMING: `getEvents?flag=Upcoming Events`,

  FAQ_CATEGORIES_GET: `getFAQCategories`,
  FAQ_GET_DETAILS:'getAllGeneralFAQ',
  FAQ_GET_ALL_FAQ: (courseId: number) => `getAllGeneralFAQ?cid=${courseId}`,
  FAQ_GET_DETAILS_SEARCH_TEXT: (searchBy: string) => `getAllGeneralFAQ?keyword=${searchBy}`,
  FEEDBACK_FORMS_GET: `feedback/getAllForms?page=1&page_limit=0&status=1`,
  FEEDBACK_QUESTION_SET_BY_ID_GET: (formId: number) => `feedback/${formId}/view`,
  FEEDBACK_QUESTION_SET_GET: (slug: string) => `feedback/${slug}/view`,
  FEEDBACK_QUESTION_SET_SAVE: `feedback/recordUserResponse`,
  FORGOT_PASSWORD: 'forgotpassword',
  
  HARD_COPY_GET: `getShippingDetails`,
  HARD_COPY_SAVE: `saveShippingDetails`,
  HOURS: 'hours',

  LAST_ACCESSED_STUDENT_COURSE: `studentLastAccessed`,
  LESSON_CONTENT_GET: (courseId: string, lessonId: string) =>
  `course/${courseId}/lesson/${lessonId}/viewSingleLearnerElement`,
  LESSON_MARK_AS_COMPLETED: (cid: string, lessonId: string) =>
    `student/getmarkcompletestatus/cid/${cid}/lesson_id/${lessonId}`,
  LESSON_NOTE_DELETE: (courseId: string, noteId: string) =>
    `delete_note/${noteId}?course_id=${courseId}`,
  LESSON_NOTE_GET: (courseId: string, moduleId: string, lessonId: string) => 
  `student_note?course_id=${courseId}&module_id=${moduleId}&module_item_id=${lessonId}`,
  LESSON_NOTE_SAVE: `student_note`,
  LESSON_MARK_AS_COMPLETED_SAVE: 'student/markcomplete',
  LEVEL_AND_CERTIFICATE_DETAILS: 'getLevelCertiFicateDesc?get=both',
  LOGIN: 'login',
  LOGOUT: 'logout',

  INDUSTRIES: 'industries',

  MANAGE_USER_PUSH_TOKENS: 'manageUserPushTokens',
  MOBILE_NO_VALIDATE: 'validatemobile',

  OFFER_COUPONS_GET: (courseId: string, countryCode: string, isEarnCreditsRoute: number) => 
    `offers/coupons/${courseId}?country_code=${countryCode}&is_earn_credit=${isEarnCreditsRoute}`,
  OFFER_STATUS: `offers/status`,
  OFFLINE_READING_MAIL: (courseId: string, lessonId: string) =>
    `course/${courseId}/lesson/${lessonId}/offlineReadingMail`,

  PENDING_FEES_TO_BE_PAID: (courseId: string) => `getPendingFeesToBePaidByStudentForCourse/${courseId}`,
  PASSWORD_CHANGE: 'changePassword',
  PAYMENT_DETAILS_SAVE: `savePaymentDetails`,
  PAYMENT_FAILED_LOGS: `failed-payment-logs`,
  PAYMENT_REMINDER_SAVE: `savePaymentReminder`,
  PAYMENT_STATUS: (userId: string, courseId: string) =>
    `getCertificationPaymentDetailsByUserAndCourse/${userId}/course/${courseId}`,
  PAYMENT_STATUS_VERIFY: `verifyPaymentStatusByOrderId`,
  PAYMENT_UPDATE_STATUS_BY_ORDER_ID: (orderId: string, countryCode: string) => `updatePaymentStatusByOrderId/${orderId}?country_code=${countryCode} `,
  POLLS_LIST: 'polls',
  POLLS_CREATE: 'polls/create',
  PRACTICE_QUIZ_ASSESSMENT: (courseId: string, lessonId: string) => `practiceQuiz/course/${courseId}/lesson/${lessonId}`,
  PREMIUM_CHECK: 'check-premium-courses',

  PUSH_NOTIFICATION_CHECK_IF_ANY_UNREAD: `notifications/check`,
  PUSH_NOTIFICATION_GET_MASTER: `get_master_table_data?table=notification_master`,
  PUSH_NOTIFICATION_LIST: (isGetAll: number,pageNumber: number, pageLimit: number) => 
  `notifications?getAll=${isGetAll}&page=${pageNumber}&page_limit=${pageLimit}`,
  PUSH_NOTIFICATION_MARK_ALL_AS_READ: `notifications/mark-all-read`,

  QUALIFICATION: 'highestqualifications',
  QUIZ_REPORT_MAIL_GET: (examId: string) => `sendQuizResultMail/${examId}`,
  QUOTES_LIST: `getLoaderQuotes?page=1&page_limit=20&rand=1`,

  REDEMPTION_DETAILS: (countryCode: string) => `reward/master-details?country_code=${countryCode}`,
  REGISTER: 'register',
  REGISTER_SELECTION_LOG: (sectorIds: string, categoryIds: string) => 
    `registerSelectionLog?subject_area_id=${sectorIds}&certificate_type_id=${categoryIds}`,
  REGISTER_VIA_SOCIAL_LOGIN: 'registerviasociallogin',
  REGISTERED_COURSE: (courseId: string) => `getEnrolledCoursesByCourseId/${courseId}`,
  REPORT_CARD: (courseId: string) => `course/${courseId}/viewReportcart`,
  REWARD_COURSE_LINK: (courseId: number, utmSource) => `shareCourseLink/${courseId}/${utmSource}`,
  REWARD_COURSE_UTM: (courseId: number) => `utmCourseDetails/${courseId}`,
  REWARD_MINI_STATEMENT: `getStudentMiniStatement`,
  REWARD_SHARED_COURSES: `getStudentSharedCourses`,
  REWARD_SHARED_REPORT: (countryCode: string) => `get-banner-report?country_code=${countryCode}`,
  RESET_PASSWORD: 'updatepassword',
  RESET_PASSWORD_EMAIL_AUTO_FETCH: (keyToken: string) => `getResetPasswordEmail/${keyToken}`,

  SPIN_WHEEL_CREATE_POST: `offers/spin-wheel/create`,
  SPIN_WHEEL_OFFER_CLAIMED: (courseId: string) => `offers/spin-wheel/claimed/${courseId}`,
  SEND_RETURNING_USER_MAIL: `send-returning-user-mail`,
  SEND_VERIFICATION_MAIL: 'send-verification-mail',
  STATES_BY_COUNTRY: (countryId: number) => `statesByCountry/${countryId}`,
  STUDENT_LOG_SAVE: 'saveStudentLog',
  STUDENT_PROGRESS: `studentProgress`,
  SUBSCRIBE_MULTI_PLANS: (countryCode: string) => `subscription/planList?country_code=${countryCode}`,
  SUBSCRIBE_NEWSLETTER: 'saveNewsLetter',
  SUBSCRIBE_PLANS: `subscription/plans`,
  SUBSCRIPTION_ENROLLED_COURSES_BY_SUB_ID: (subscriptionId: number) => `subscription/getCoursesEnrolledinPlan/${subscriptionId}`,
  SUBSCRIBE_PLAN_PAYMENT: 'subscription/payment',

  TESTIMONIAL: `getTestimonials?published=true&page_limit=30`,
  TOKEN_OLD_LMS: 'session',
  TOKEN_SESSION: 'sessionToken',
  TOKEN_EMAIL: 'sessionmail',

  UPLOAD_AND_SAVE_CERTIFICATE: (certificateId: string) => `certificateUpload/${certificateId}`,
  UPLOAD_IMAGE: 'bimage',
  UPLOAD_USER_FILE: 'upload/user',
  UPLOAD_FILE: 'fileupload',
  UPLOAD_FILE_REMOVE: 'upload/delete',
  USER_DETAILS: (userId: string) => `getuser/${userId}`,
  USER_DETAILS_SAVE: (userId: string) =>  `updateuserdetails/${userId}`,
  USER_PERMISSIONS: `permission`,
  USER_QUALIFICATION_UPDATE: `update_user_qualification`,
  USER_PROFILE_IMAGE: (userId: string) => `getuserprofileimage?uid=${userId}`,
  USER_REVIEW_EXIST_OR_NOT: (courseId: number) => `checkuserReviewExistence/${courseId}`,
  USER_REVIEWS_BY_COURSE_ID_GET: (courseId: number) => `getUserReviews/${courseId}`,
  USER_REVIEW_LIKES_SAVE: (courseId: number, reviewId: number) => `userLikes/${courseId}/${reviewId}`,
  USER_REVIEW_LIKES_COUNT: (reviewId: number) => `countOfReviewLikes/${reviewId}`,
  USER_REVIEW_SAVE: `saveUserReview`,
  USER_EDUCATION_DETAILS: (userId: string) => `getuserqualification_details/${userId}?uid=${userId}`,
  USER_WORK_DETAILS: (userId: string) => `getuserwork_details/${userId}?uid=${userId}`,
  USER_WORK_DETAILS_UPDATE: `update_user_work`,
  USER_DOCUMENT_PROORF_TYPES: `getprooftypes/admin`,
  UTM_DETAILS_SAVE: `addUTMParameters`,

  VERIFY_USER_EMAIL: 'verify-user-email'
 };

 export const ExcludingLoaderForEndpoints = [
    new RegExp(EndpointConstant.BIRTHDAY_OFFER_ELIGIBILITY_CHECK, 'g'),
    new RegExp(/getEvents/, 'g'),
    new RegExp(/getAutoReplyQuestions/, 'g'),
    new RegExp(EndpointConstant.DISCUSSION, 'g'),
    new RegExp(/getuserprofileimage/, 'g'),
    new RegExp(/getCoachMarksList/, 'g'),
    new RegExp(/saveStudentLog/, 'g'),
    new RegExp(/createClaimCertificateByOrderId/, 'g'),
    new RegExp(/verifyPaymentStatusByOrderId/, 'g'),
    new RegExp(/getLoaderQuotes/, 'g'),
    new RegExp(EndpointConstant.SUBSCRIBE_PLANS, 'g'),
    new RegExp(EndpointConstant.HOURS, 'g'),
    new RegExp(/registerSelectionLog/, 'g'),
    new RegExp(EndpointConstant.SPIN_WHEEL_CREATE_POST, 'g'),
    new RegExp(EndpointConstant.POLLS_CREATE, 'g'),
    new RegExp(EndpointConstant.PUSH_NOTIFICATION_CHECK_IF_ANY_UNREAD, 'g'),
    new RegExp(/checkuserReviewExistence/, 'g')
 ];
