import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthGuard } from './guard/auth.guard';
import { PageNotFoundComponent } from './shared/component/page-not-found/page-not-found.component'
import { ShortCourseSignUpComponent } from './short-course-sign-up/short-course-sign-up.component';
import { ShortCourseSignInComponent } from './short-course-sign-in/short-course-sign-in.component';
import { ResetPasswordComponent } from './forgot-password/reset-password/reset-password.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FaqComponent } from './faq/faq.component';
import { CustomPreloadingStrategyService } from './services/custom-preload.service';
import { DownloadPdfPageComponent } from './download-pdf-page/download-pdf-page.component';

const routes: Routes = [
  { path: '', redirectTo: '/short-course-sign-in', pathMatch: 'full' },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'download-pdf-page/:quizId', component: DownloadPdfPageComponent },
  {
    path: 'email-preference/:uid',
    loadChildren: () => import('./email-preference/email-preference.module')
      .then(m => m.EmailPreferenceModule)
  },
  { path: 'faq', component: FaqComponent, canActivate: [AuthGuard] },
  {
    path: 'payment',
    data: { preload: true, loadAfterSeconds: 30 },
    canActivate: [AuthGuard],
    loadChildren: () => import('./payment/payment.module')
      .then(m => m.PaymentModule)
  },
  {
    path: 'quiz-assessment',
    canActivate: [AuthGuard],
    loadChildren: () => import('./assessment/quiz-assessment/quiz-assessment.module')
      .then(m => m.QuizAssessmentModule)
  },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  { path: 'short-course-sign-up', component: ShortCourseSignUpComponent },
  { path: 'short-course-sign-in', component: ShortCourseSignInComponent },
  {
    path: 'student-dashboard/course/:courseId/:courseName',
    data: { preload: true, loadAfterSeconds: 10 },
    canActivate: [AuthGuard],
    loadChildren: () => import('./course-details/course-details.module')
      .then(m => m.CourseDetailsModule)
  },
  {
    path: 'student-dashboard/course-landing/:courseId/:courseName',
    data: { preload: true, loadAfterSeconds: 20 },
    loadChildren: () => import('./course-landing/course-landing.module')
      .then(m => m.CourseLandingModule)
  },
  {
    path: 'student-dashboard/view-as-learner/:courseId/:lessonId',
    data: { preload: true, loadAfterSeconds: 10 },
    canActivate: [AuthGuard],
    loadChildren: () => import('./view-as-learner/view-as-learner.module')
      .then(m => m.ViewAsLearnerModule)
  },
  {
    path: 'student-dashboard/report-card/:courseId',
    data: { preload: true, loadAfterSeconds: 20 },
    canActivate: [AuthGuard],
    loadChildren: () => import('./report-card/report-card.module')
      .then(m => m.ReportCardModule)
  },
  {
    path: 'student-dashboard',
    data: { preload: true, loadAfterSeconds: 10 },
    canActivate: [AuthGuard],
    loadChildren: () => import('./student-dashboard/student-dashboard.module')
      .then(m => m.StudentDashboardModule)
  },
  {
    path: 'student-profile',
    data: { preload: true, loadAfterSeconds: 20 },
    canActivate: [AuthGuard],
    loadChildren: () => import('./student-profile/student-profile.module')
      .then(m => m.StudentProfileModule)
  },
  {
    path: 'reward-points',
    data: { preload: true, loadAfterSeconds: 20 },
    canActivate: [AuthGuard],
    loadChildren: () => import('./reward-points/reward-points.module')
      .then(m => m.RewardPointsModule)
  },
  { 
    path: 'feedback',
    canActivate: [AuthGuard],
    loadChildren: () => import('./feedback-form/feedback-form.module')
      .then(m => m.FeedbackFormModule)
  },
  { 
    path: 'form',
    loadChildren: () => import('./generic-form/generic-form.module')
      .then(m => m.GenericFormModule)
  },
  {
    path: 'course-recommendation',
    canActivate: [AuthGuard],
    loadChildren: () => import('./course-recommendation-signup/course-recommendation-signup.module')
      .then(m => m.CourseRecommendationSignUpModule)
  },
  { 
    path: 'subscriptions',
    canActivate: [AuthGuard],
    loadChildren: () => import('./subscriptions/subscriptions.module')
      .then(m => m.SubscriptionsModule)
  },
  {
    path: 'personalized-offers',
    canActivate: [AuthGuard],
    loadChildren: () => import('./personalized-offers/personalized-offers.module')
      .then(m => m.PersonalizedOffersModule)
  },
  {
    path: 'push-notification',
    canActivate: [AuthGuard],
    loadChildren: () => import('./push-notification/push-notification.module')
      .then(m => m.PushNotificationModule)
  },
  {
    path: 'earn-credits/:courseId',
    canActivate: [AuthGuard],
    loadChildren: () => import('./earn-credits/earn-credits.module')
      .then(m => m.EarnCreditsModule)
  },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'top',
    preloadingStrategy:  CustomPreloadingStrategyService
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
