<div *ngIf="isFloatEnable && studentProgress && 
    studentProgress[appConfig.formDetails.eligibleCertificateCount.key] > 0 &&
    !studentProgress[appConfig.formDetails.isOnlyCMICoursesAvailable.key]" @fadeIn @fadeOut>
    <div class="parent-claim">
        <div class="container-claim">
            <p *ngIf="studentProgress[appConfig.formDetails.maxOfferPercentage.key]">You are eligible to claim 
                <span class="pointer" (click)="eligibleForCertification()">
                    {{studentProgress[appConfig.formDetails.eligibleCertificateCount.key]}} 
                    {{studentProgress[appConfig.formDetails.eligibleCertificateCount.key] > 1 ? 'certificates' : 'certificate'}}
                </span> 
                @ {{ studentProgress[appConfig.formDetails.maxOfferPercentage.key] }} % Discount*!
            </p>
            <p *ngIf="!studentProgress[appConfig.formDetails.maxOfferPercentage.key]">
                Your {{studentProgress[appConfig.formDetails.eligibleCertificateCount.key] > 1 ? 'certificates are' : 'certificate is'}} ready to be claimed !
                <span class="pointer" (click)="eligibleForCertification()">
                    Download Now!
                </span>
            </p>
            <img class="pointer" (click)="closeClaimFloat()" src="assets/claim-float/close-btn.svg">
        </div>
    </div>
</div>


