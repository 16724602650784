import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseService } from 'src/app/services/base.service';
import { ApplicationNotifierService } from '../application-notifier/application-notifier.service';
import { EndpointConstant } from '../../constant/endpoint.constant';
import { ApplicationNotifierConstant } from '../../constant/notifier.constant';
import { RoutingConstant } from '../../constant/routing.constant';
import { Router } from '@angular/router';
import { PushNotificationListModel, PushNotificationMasterDataModel } from 'src/app/push-notification/model/push-notification.model';
import { PushNotificationConstant } from 'src/app/push-notification/push-notification.constant';
import { HeaderService } from 'src/app/header/header.service';
import { APP_URL } from 'src/environments/environment';

@Component({
  selector: 'app-push-notification-popup',
  templateUrl: './push-notification-popup.component.html',
  styleUrls: ['./push-notification-popup.component.scss']
})
export class PushNotificationPopupComponent implements OnInit {

  pushNotificationMasterData: PushNotificationMasterDataModel[] = [];
  pushNotificationList: PushNotificationListModel[] = [];
  isGetAll = 1;
  routingConstant = RoutingConstant;
  destroySubscription: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private baseService: BaseService,
    private applicationNotifierService: ApplicationNotifierService,
    private headerService: HeaderService
  ){}

  ngOnInit(): void {
    this.getPushNotificationMaster();
    this.getPushNotificationList(1);
  }
  
  /**
   * Get the Push Notification Master Data
  */
  private getPushNotificationMaster() {
    this.baseService.get(EndpointConstant.PUSH_NOTIFICATION_GET_MASTER)
    .pipe(takeUntil(this.destroySubscription))
    .subscribe(res => {
      if(res) {
        this.pushNotificationMasterData = res;
      }}, error => {
        console.error('error', error);
      });
    }

  /**
   * Get the Image Icon based on the Notification Type from the Master Table
  */
  getIconUrl(notificationType: number): string {
    return this.pushNotificationMasterData?.find(obj => obj?.id === notificationType)?.icon_url;
  }

  /**
   * Get the Push Notification List Data
  */
  fetchPushNotificationList(isGetAll: number, pageNumber: number) {
    let endpoint = EndpointConstant.PUSH_NOTIFICATION_LIST(isGetAll, pageNumber, PushNotificationConstant?.PUSH_NOTIFICATION_PAGE_LIMIT);
    this.baseService.getMethod(APP_URL.API_NEW, endpoint)
    .pipe(takeUntil(this.destroySubscription))
    .subscribe(res => {
      if(res) {
        this.pushNotificationList = res?.data?.data;
        this.getAnyUnreadNotifications();
      }}, error => {
        console.error('error', error);
    });
  }

  /**
   * Show the Push Notification List Data based on Unread Notification Count
  */
  private getPushNotificationList(pageNumber = 1) {
    if (this.headerService.pushNotificationUnreadGlobalValue > 0) {
      this.fetchPushNotificationList(PushNotificationConstant?.PUSH_NOTIFICATION_GET_UNREAD, pageNumber);
    } else {
      this.fetchPushNotificationList(PushNotificationConstant?.PUSH_NOTIFICATION_GET_ALL, pageNumber);
    }
  }
  
  /**
   * Get any Unread Notifications
  */
  private getAnyUnreadNotifications() {
    this.baseService.getMethod(APP_URL.API_NEW, EndpointConstant.PUSH_NOTIFICATION_CHECK_IF_ANY_UNREAD)
    .pipe(takeUntil(this.destroySubscription))
    .subscribe(res => {
      if(res) {
        this.headerService.updatePushNotificationBadgeValue(res?.unread_notification_count);
      }}, error => {
        console.error('error', error);
    });
  }

  /**
   * Call Mark All As Read
  */
  markAllAsRead() {
    this.baseService.post(EndpointConstant.PUSH_NOTIFICATION_MARK_ALL_AS_READ, null)
    .pipe(takeUntil(this.destroySubscription))
    .subscribe(res => {
      if(res?.data) {
        this.getPushNotificationList(1);
        this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.push_notification_mark_all_as_read);
      } else {
        this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.error_message);
      }
      }, error => {
        console.error('error', error);
    });
  }

  /**
   * Navigate to Push Notification page.
  */
  navigateToPushNotificationPage() {
    this.headerService.updatePushNotificationGetAllTab(true);
    this.router.navigateByUrl(this.routingConstant.PUSH_NOTIFICATION);
  }

    /**
   * Destroys All Subscriptions
  */
  ngOnDestroy(): void {
    this.destroySubscription.next();
    this.destroySubscription.complete();
  }
}