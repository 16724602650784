<div #pollSectionRef *ngIf="domainPollDetails?.questionWithOptions || domainPollDetailsAfterVote?.options_with_active_status"
     class="poll-container"
     [ngClass]="updatePollFeatureSection ? 'place-visible' : 'place-hidden'">
    <button (click)="closePollDialog()"
            [ngClass]="{ 'poll-blur': voteDetails.voteInProgress }"
            class="close-button">
            <img src="assets/webinar/close-btn.png">
    </button>
    <div class="poll-section" [ngClass]="{ 'poll-blur': voteDetails.voteInProgress }">
        <div class="poll-header">
            <img src="assets/poll/bar.svg">
            <div class="poll-title">
                <h2>{{ voteDetails.voteRegistered ? 'Poll Results' : 'UniAthena Polls' }}</h2>
                <p>{{ voteDetails.voteRegistered ? 'Thank you, we appreciate it' : 'Helps us to serve you better !' }}</p>
            </div>
        </div>
        <hr>

        <div class="poll-body">
            <h3>
                {{ domainPollDetails?.questionWithOptions[currentIndex]?.question }}
            </h3>
            <ng-container *ngIf="domainPollDetails?.questionWithOptions &&
                                 !voteDetails.voteRegistered">
                <ul class="domain-vote athena-scroll options-parent"
                    [ngStyle]="{ 'height': isScreenHeightSmall && isDesktopSize ? dynamicHeight+'px' : 'auto' }">
                    <li *ngFor="let item of domainPollDetails?.questionWithOptions[currentIndex]?.options_with_active_status; let i = index">
                        <mat-checkbox 
                            [checked]="item?.isChecked" 
                            (change)="onOptionSelection($event, i, domainPollDetails?.questionWithOptions[currentIndex]?.selection_mode)">
                            {{ item?.options }}
                        </mat-checkbox>
                    </li>
                </ul>
                <button (click)="registerVoteForDomains()" type="button" class="poll-vote">
                    Vote
                </button>
                <mat-error *ngIf="voteDetails.voteRequired">
                    Please select a domain to vote
                </mat-error>
            </ng-container>
            <ng-container *ngIf="domainPollDetailsAfterVote?.options_with_active_status?.length > 0 &&
                                 voteDetails.voteRegistered &&
                                 !voteDetails.voteInProgress">
                <ul class="domain-voted athena-scroll options-parent"
                    [ngStyle]="{ 'height': isScreenHeightSmall && isDesktopSize ? dynamicHeight+'px' : 'auto' }">
                    <li *ngFor="let item of domainPollDetailsAfterVote?.options_with_active_status">
                        <div>
                            <h3>{{ item?.percentage }}%</h3>
                                <div>
                                    <label>{{ item?.options }}</label>
                                    <mat-checkbox *ngIf="item?.selected"
                                                [checked]="item?.selected"
                                                [disableRipple]="true">
                                    </mat-checkbox>
                                </div>
                                </div>
                                <label style="width: 100%">
                                    <span [ngClass]="item?.selected ? 'registered' : 'un-registered'"
                                        [style.width.%]="item?.percentage"
                                        class="percent-appear">
                                    </span>
                                </label>
                    </li>
                </ul>
            </ng-container>
        </div>

        <div *ngIf="totalVoteCount > 0" 
              class="poll-footer">
            <ul *ngIf="imageUrlsAvailable"
                       class="pt-2">
                <li *ngFor="let item of imageUrlArr(); let i = index" [style.left.px]="i * 15">
                    <img [src]="getProfilePic(item?.image)">
                </li>
            </ul>
            <h3 class="pt-2">
                Total Votes: {{ totalVoteCount }}
            </h3>
        </div>
        <button *ngIf="isDisplayNext" (click)="nextQuestionForVote($event)" type="button" class="poll-next-btn">
            Next
        </button>
    </div>

    <div *ngIf="voteDetails.voteInProgress" class="poll-thank-you">
        <img src="assets/poll/tick.svg">
        <h3 class="mt-3">Thank You!</h3>
        <p>Showing results...</p>
    </div>
        
</div>