<ng-template [ngIf]="isLoading">
    <div class="ath-backdrop-overlay">
        <div class="ath-loader">
            <div class="quote-content">
                <p>“{{ quoteContent?.quote }}”</p>
                <h3>
                    <span *ngIf="isSmallSizeDevice">-</span>
                    <mat-icon *ngIf="!isSmallSizeDevice" class="author-name">remove</mat-icon>
                    {{ quoteContent?.author }}
                </h3>
            </div>
        </div>
    </div>
</ng-template>