<div class="bg-grey">
  <img src="assets/faq/bg-ash.png" alt="" />
</div>
<div class="container">
  <div class="heading text-center">
    <h3><span>Hello,</span>how can we help?</h3>
  </div>
  <div class="search">
  <form>
    <img src="assets/faq/frame-search.png" alt="Search" />
    <input #faqTextbox
           type="text"
           name="search"
           id="search"
           [(ngModel)]="searchText"
           placeholder="Ask a Question" />
    <button type="submit" (click)="searchFAQQuestion()">Search</button>
    <a *ngIf="searchText"
    (click)="clearSearch()">
      <mat-icon>close</mat-icon>
    </a>
  </form>
  <p>Or choose a category to quickly find the help you need</p>
</div>

  <div class="top-card">
    <div class="card-group">
      <div class="card m-2"
           [ngClass]="{ 'show': selectedFAQ?.title === 'Certification' }"
           (click)="toggleFAQType('Certification')">
        <svg width="35"
             height="35"
             viewBox="0 0 35 35"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.4987 21.6748C22.9755 21.6748 27.4154 17.2349 27.4154 11.7581C27.4154 6.28127 22.9755 1.84143 17.4987 1.84143C12.0219 1.84143 7.58203 6.28127 7.58203 11.7581C7.58203 17.2349 12.0219 21.6748 17.4987 21.6748Z"
            stroke-opacity="0.6"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"/>
          <path d="M12.1321 20.1023L10.418 33.0081L17.5013 28.7581L24.5846 33.0081L22.8705 20.0881"
                stroke-opacity="0.6"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
        <div class="card-body">
          <h4 class="card-title">Certification</h4>
        </div>
      </div>

      <div class="card m-2"
           [ngClass]="{ 'show': selectedFAQ?.title === 'Learning' }"
           (click)="toggleFAQType('Learning')">
        <svg width="37"
             class="text"
             height="34"
             viewBox="0 0 37 35"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M34.89 2.63887C34.551 2.55287 27.814 -1.90113 19.87 2.06987L18.162 2.92387L16.454 2.06787C8.65801 -1.82413 1.65101 2.58787 1.43401 2.63887C0.554012 3.07587 -0.00298794 3.97587 1.20581e-05 4.95887V31.2859C1.20581e-05 32.0289 0.358012 32.7269 0.961012 33.1619C1.56501 33.5969 2.34001 33.7149 3.04501 33.4809C10.952 30.8459 16.899 33.9289 17.889 34.1169C18.066 34.1759 18.258 34.1759 18.435 34.1169C19.613 33.8929 25.268 30.8089 33.279 33.4809C33.985 33.7149 34.759 33.5969 35.363 33.1619C35.966 32.7269 36.323 32.0289 36.324 31.2859V4.95887C36.327 3.97587 35.771 3.07587 34.89 2.63887ZM28.54 2.30087V9.19387L27.29 8.35987C27 8.16587 26.621 8.16587 26.33 8.35987L25.081 9.19387V2.30087C26.23 2.15987 27.391 2.15987 28.541 2.30087H28.54ZM20.643 3.61687C21.508 3.19187 22.416 2.86087 23.351 2.62987V10.8099C23.351 11.1279 23.527 11.4219 23.808 11.5719C24.089 11.7219 24.431 11.7059 24.696 11.5289L26.811 10.1189L28.925 11.5289C29.191 11.7059 29.532 11.7229 29.813 11.5719C30.095 11.4219 30.27 11.1279 30.27 10.8089V2.62987C31.612 2.98687 32.904 3.50987 34.116 4.18687C34.409 4.33287 34.594 4.63187 34.595 4.95887V27.7719L33.825 27.5119C29.001 25.9519 23.795 26.0439 19.027 27.7719V4.42587L20.643 3.61687ZM1.73001 4.95887C1.73001 4.63187 1.91501 4.33287 2.20801 4.18587C2.56501 4.09487 8.57501 0.0638688 15.681 3.61687L17.297 4.42587V27.7719C12.537 26.0099 7.31801 25.9179 2.49901 27.5119L1.72901 27.7719V4.95887H1.73001ZM33.826 31.8389C25.663 29.1209 18.71 32.2829 18.162 32.3839C13.165 30.3779 7.62201 30.1859 2.49901 31.8399C2.32001 31.8999 2.12401 31.8699 1.97201 31.7599C1.82001 31.6499 1.73001 31.4739 1.73001 31.2859V29.5959L3.04501 29.1559C10.951 26.5209 16.888 29.6019 17.889 29.7919C18.065 29.8519 18.256 29.8519 18.432 29.7919C19.61 29.5679 25.264 26.4849 33.276 29.1569L34.595 29.5949V31.2849C34.595 31.4739 34.505 31.6499 34.352 31.7599C34.2 31.8699 34.004 31.8999 33.826 31.8399V31.8389Z"
            stroke-opacity="0.6"
            stroke-width="0"/>
        </svg>
        <div class="card-body">
          <h4 class="card-title">Learning</h4>
        </div>
      </div>

      <div class="card m-2"
           [ngClass]="{ 'show': selectedFAQ?.title === 'Courses & Enrollment' }"
           (click)="toggleFAQType('Courses & Enrollment')">
        <svg width="34"
             height="34"
             viewBox="0 0 34 35"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19.8346 3.25818H8.5013C7.74986 3.25818 7.02919 3.55669 6.49783 4.08804C5.96648 4.6194 5.66797 5.34007 5.66797 6.09151V28.7582C5.66797 29.5096 5.96648 30.2303 6.49783 30.7616C7.02919 31.293 7.74986 31.5915 8.5013 31.5915H25.5013C26.2527 31.5915 26.9734 31.293 27.5048 30.7616C28.0361 30.2303 28.3346 29.5096 28.3346 28.7582V11.7582L19.8346 3.25818Z"
            stroke-opacity="0.6"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"/>
          <path d="M19.832 3.25818V11.7582H28.332"
                stroke-opacity="0.6"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"/>
          <path d="M22.6654 18.8414H11.332"
                stroke-opacity="0.6"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"/>
          <path d="M22.6654 24.5082H11.332"
                stroke-opacity="0.6"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"/>
          <path d="M14.1654 13.1748H12.7487H11.332"
                stroke-opacity="0.6"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
        <div class="card-body">
          <h4 class="card-title">Courses & Enrollment</h4>
        </div>
      </div>
    </div>
  </div>
  <div class="change-content">
    <div class="content-heading">
      <h4>{{ this.selectedFAQ?.title }}</h4>
      <p>{{ this.selectedFAQ?.description }}</p>
    </div>
    <div class="accordion-content" >
      <div *ngFor="let faq of faqDetails[this.selectedFAQ?.title]"
           class="accordion"
           (click)="toggleFAQDetails(faq)"
           [ngClass]="{ 'active': faq?.isShow }">
        <h4 [innerHTML]="faq?.question"></h4>
        <span></span>
        <div class="panel">
          <p [innerHTML]="faq?.answer"></p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bottom-bg">
  <img src="assets/faq/bottom-bg.png" alt="bottom-bg">
  <div class="content">
    <p>It's Time to Start<br>
      <span>Investing</span> In <span>Yourself</span></p>
      <a [routerLink]="routingConstant.STUDENT_DASHBOARD">Learn Now</a>
  </div>
</div>
