<div class="reset-body">
  <div class="name">
    <div mat-dialog-close class="close-btn"><span class="material-icons">
      highlight_off
      </span></div>
  <div class="heading">
    <div class="qualification"><img src="assets/profile-new/account-settings-icon.png"> Change Password</div>
</div>
</div>
<div class="form-scroll athena-scroll">
  <form [formGroup]="changePasswordForm"
        (ngSubmit)="updatePassword()">
        <div class="row">
          <div class="col-12 col-md-12 fields">
            <label>Current Password</label>
            <input matInput
                   [formControlName]="appConfig.formDetails.oldPassword.key"
                   [placeholder]="appConfig.formDetails.oldPassword.placeholder"
                   type="text"
                   required/>
            <mat-error *ngIf="formControl[appConfig.formDetails.oldPassword.key].invalid && 
              formControl[appConfig.formDetails.oldPassword.key].touched">
              <mat-error *ngIf="formControl[appConfig.formDetails.oldPassword.key].errors.required">
                {{ appConfig.formDetails.oldPassword.errorMessage.required }}
              </mat-error>
            </mat-error>
          </div>
          <div class="col-12 col-md-12 fields">
            <label>New Password</label>
            <input matInput
                   [formControlName]="appConfig.formDetails.newPassword.key"
                   [placeholder]="appConfig.formDetails.newPassword.placeholder"
                   type="text"
                   required/>
            <mat-error *ngIf="formControl[appConfig.formDetails.newPassword.key].invalid &&
              formControl[appConfig.formDetails.newPassword.key].touched">
              <mat-error *ngIf="formControl[appConfig.formDetails.newPassword.key].errors.required">
                {{ appConfig.formDetails.newPassword.errorMessage.required }}
              </mat-error>
              <mat-error *ngIf="formControl[appConfig.formDetails.newPassword.key].errors.newPasswordValidator">
                {{ appConfig.formDetails.newPassword.errorMessage.isExist }}
              </mat-error>
            </mat-error>
          </div>
          <div class="col-12 col-md-12 fields">
            <label>Re-enter Password</label>
            <input matInput
                   [formControlName]="appConfig.formDetails.confirmPassword.key"
                   [placeholder]="appConfig.formDetails.confirmPassword.placeholder"
                   type="text"
                   required/>
            <mat-error *ngIf="formControl[appConfig.formDetails.confirmPassword.key].invalid &&
              formControl[appConfig.formDetails.confirmPassword.key].touched ">
              <mat-error *ngIf="formControl[appConfig.formDetails.confirmPassword.key].errors.required">
                {{ appConfig.formDetails.confirmPassword.errorMessage.required }}
              </mat-error>
              <mat-error *ngIf="!formControl[appConfig.formDetails.confirmPassword.key].errors.required">
                {{ appConfig.formDetails.confirmPassword.errorMessage.invalid }}
              </mat-error>
            </mat-error>
          </div>
            <div class="col-12 col-md-12 text-center mt-4">
              <button class="save-btn">Save Password</button><br />
              <span (click)="forgetPasswordDialog()" class="d-block frgt-pswd-span">Forgot Password</span>
            </div>
        </div>
  </form>
  </div>
</div>
