import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApplicationConstant } from '../../constant/app.constant';
import { RoutingConstant } from '../../constant/routing.constant';

@Component({
  selector: 'app-alarm-confirmation',
  templateUrl: './alarm-confirmation.component.html',
  styleUrls: ['./alarm-confirmation.component.scss']
})
export class AlarmConfirmationComponent {

  applicationConstant = ApplicationConstant;
  
  constructor(@Optional() private matDialogRef: MatDialogRef<AlarmConfirmationComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
              private router: Router) { }

  /**
   * Close button
   */
  close() {
   if(this.dialogData && !this.dialogData?.stayInLesson) {
     const url = this.dialogData?.navigateEnd === 'courseDetails' ? RoutingConstant.COURSE_DETAILS_PAGE(this.dialogData.courseId, this.dialogData.slug) : RoutingConstant.STUDENT_DASHBOARD;
     this.router.navigateByUrl(url);
    }
    this.matDialogRef.close();
  }

}
