import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseService } from 'src/app/services/base.service';
import { ApplicationConstant } from 'src/app/shared/constant/app.constant';
import { EndpointConstant } from 'src/app/shared/constant/endpoint.constant';
import { DomainInfoBeforeVote, DomainList, PollDomainOptionsAfterVote } from './model/poll-domain.interface';
import { APP_URL } from 'src/environments/environment';
import { HeaderService } from 'src/app/header/header.service';
import { ApplicationNotifierService } from '../application-notifier/application-notifier.service';
import { ApplicationNotifierConstant } from '../../constant/notifier.constant';

const SINGLE_SELECTION_MODE  = 'Single';

@Component({
  selector: 'app-poll-domain',
  templateUrl: './poll-domain.component.html',
  styleUrls: ['./poll-domain.component.scss']
})
export class PollDomainComponent implements OnInit, OnDestroy {

  @ViewChild('pollSectionRef') pollSectionRef: ElementRef;
  @Output() emitVotedInfo: EventEmitter<any> = new EventEmitter<any>();
  destroySubscription: Subject<void> = new Subject<void>();
  voteDetails = {
    voteRegistered: false,
    voteInProgress: false,
    voteRequired: false
  };
  dynamicHeight = 0;
  currentIndex = 0;
  isDesktopSize = window.screen.width >= ApplicationConstant.DEVICE_SIZE.maxIpad;
  isScreenHeightSmall = window.screen.height < 860;
  domainPollDetails = {} as DomainList;
  domainPollDetailsAfterVote: PollDomainOptionsAfterVote;
  selectedPoll: DomainInfoBeforeVote[] = [];
  totalVoteCount = 0;

  constructor(private baseService: BaseService,
              private headerService: HeaderService,
              private applicationNotifierService: ApplicationNotifierService,
              private renderer: Renderer2) {
                this.renderer.listen('window', 'click', (e:Event) => {
                  if (this.pollSectionRef && !this.pollSectionRef?.nativeElement.contains(e.target) && 
                      (this.headerService.togglePollSection)) {
                          this.headerService.togglePollSection = false;
                          this.closePollDialog();
                  }
                });
              }

  ngOnInit(): void {
    this.getPollList();
    if (this.isDesktopSize && this.isScreenHeightSmall) {
      this.dynamicHeight = window.screen.height - 450;
    }
  }

  /**
   * Get domain list
   */
  private getPollList() {
    this.baseService.getMethod(APP_URL.API_NEW, EndpointConstant.POLLS_LIST)
        .pipe(takeUntil(this.destroySubscription))
        .subscribe((res) => {
          if (res?.data) {
            this.domainPollDetails = res?.data;
            const index = this.domainPollDetails?.questionWithOptions?.findIndex(poll => poll?.is_voted === 0);
            if (index !== -1) {
              this.currentIndex = index;
              this.totalVoteCount = this.domainPollDetails?.questionWithOptions[this.currentIndex]?.totalCount;
            } else {
              this.headerService.togglePollSection = false;
              this.emitVotedInfo.next();
            }
            this.uncheckDomainSelections();
          }
        }, error => {
          console.error('Error', error);
    });
  }

  /**
   * Uncheck all checkboxes
   */
  private uncheckDomainSelections() {
    this.domainPollDetails?.questionWithOptions[this.currentIndex]?.options_with_active_status.forEach(d => {
      d.isChecked = false;
    });
  }

  /**
   * domain selection on change
   * @param event 
   * @param index - index of the selection
   */
  onOptionSelection(event: any, index: number, selectionMode: string) {
    this.selectedPoll = [];
    if (selectionMode === SINGLE_SELECTION_MODE && event?.checked) {
      let domainsArr = this.domainPollDetails?.questionWithOptions[this.currentIndex]?.options_with_active_status;
      for (let i = 0; i < domainsArr.length; i++) {
        domainsArr[i].isChecked = i === index;
        if (i === index) {
          this.selectedPoll.push(domainsArr[i]);
        }
      }
    } else {
      const domainsArr = this.domainPollDetails?.questionWithOptions[this.currentIndex]?.options_with_active_status;
      domainsArr[index].isChecked = event?.checked;
      domainsArr.forEach(d => {
        if (d.isChecked) {
          this.selectedPoll.push(d);
        }
      });
    }
    this.voteDetails.voteRequired = this.selectedPoll.length === 0;
  }

  /**
   * Display Next Button
  */
  get isDisplayNext() {
    return this.voteDetails.voteRegistered &&
           this.currentIndex !== this.domainPollDetails?.questionWithOptions?.length - 1 &&
           !this.voteDetails.voteInProgress;
  }

  /**
   * Poll domain Next
   */
  nextQuestionForVote(event: any) {
    event.stopPropagation();
    this.voteDetails.voteRegistered = false;
    this.getPollList();
  }

  /**
   * Poll domain register
   */
  registerVoteForDomains() {
    if (this.selectedPoll.length === 0) {
      this.voteDetails.voteRequired = true;
      return;
    }
    this.voteDetails.voteInProgress = true;
    let payload = [];
    this.selectedPoll.forEach(s => {
      payload.push({
        question_id: s?.question_id,
        option_id: s?.id
      });
    });
    this.baseService.postMethod(APP_URL.API_NEW, EndpointConstant.POLLS_CREATE, payload)
    .pipe(takeUntil(this.destroySubscription))
    .subscribe((res) => {
      if (res?.data) {
        this.domainPollDetailsAfterVote = res?.data;
        this.totalVoteCount = this.domainPollDetailsAfterVote?.totalCount;
      }
      this.voteDetails.voteRegistered = true;
      this.voteDetails.voteInProgress = false;
      this.selectedPoll = [];
    }, error => {
      if (error && error?.status === 400) {
        const poll_question_inactive = {
          icon: 'error_outline',
          message: error?.error?.message
        }
        this.applicationNotifierService.getNotifier(poll_question_inactive);
        if ((this.domainPollDetails?.questionWithOptions?.length - 1) === this.currentIndex) {
          this.headerService.togglePollSection = false;
          this.emitVotedInfo.next();
        } else {
          this.voteDetails.voteRegistered = false;
          this.voteDetails.voteInProgress = false;
          this.selectedPoll = [];
          this.getPollList();
        }
      }
      console.error('Error', error);
    });
  }

  /**
   * Close dialog
   */
  closePollDialog() {
    this.headerService.togglePollSection = false;
    if (this.voteDetails.voteRegistered && (this.domainPollDetails?.questionWithOptions?.length - 1) === this.currentIndex) {
        this.emitVotedInfo.next();
    }
  }

  /**
   *
   * @param url profile pic url
   * @returns set default image
   */
  getProfilePic(url: string) {
    if (url) {
      return url;
    }
    return 'assets/avatar.png';
  }

  /**
   * check image urls are there
   */
  get imageUrlsAvailable() {
    return (this.domainPollDetails?.questionWithOptions[this.currentIndex]?.imageUrls?.length > 0 && this.domainPollDetails?.questionWithOptions[this.currentIndex]?.imageUrls?.length <= 3) ||
           (this.domainPollDetailsAfterVote?.imageUrls?.length > 0 && this.domainPollDetailsAfterVote?.imageUrls?.length <= 3);
  }

  /**
   * imageurls array pointing based on register
   */
  imageUrlArr() {
    return this.voteDetails.voteRegistered ? this.domainPollDetailsAfterVote?.imageUrls : this.domainPollDetails?.questionWithOptions[this.currentIndex]?.imageUrls;
  }

  get updatePollFeatureSection() {
    return this.headerService.togglePollSection;
  }

  /**
   * Destroy for the component.
   */
  ngOnDestroy(): void {
    this.destroySubscription.next();
    this.destroySubscription.complete();
  }

}
