import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { PageNotFoundComponent } from './shared/component/page-not-found/page-not-found.component';
import { SharedModule } from './shared/modules/shared/shared.module';
import { MSAL_INSTANCE, MsalModule, MsalService } from '@azure/msal-angular';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { ShortCourseSignUpComponent } from './short-course-sign-up/short-course-sign-up.component';
import { ShortCourseSignInComponent } from './short-course-sign-in/short-course-sign-in.component';
import { ShortCourseHeaderComponent } from './header/short-course-header/short-course-header.component';
import { BrowserCacheLocation, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { AcademicHeaderComponent } from './header/academic-header/academic-header.component';
import { PlainHeaderComponent } from './header/plain-header/plain-header.component';
import { ForgotPasswordDialogComponent } from './forgot-password/dialog/forgot-password-dialog/forgot-password-dialog.component';
import { ResetPasswordComponent } from './forgot-password/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { environment, SOCIAL_LOGIN } from '../environments/environment';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FaqComponent } from './faq/faq.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { LoaderComponent } from './shared/component/loader/loader.component';
import { RoutingConstant } from './shared/constant/routing.constant';
import { NgxsModule } from '@ngxs/store';
import { StudentProgressState, SubscriptionPlansState } from './state/student-dashboard.state';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { PushNotificationService } from './services/push-notification.service';
import { FormsModule } from '@angular/forms';
import { DownloadPdfPageComponent } from './download-pdf-page/download-pdf-page.component';
import { AcademicHeaderPollComponent } from './header/academic-header/academic-header-poll/academic-header-poll.component';
import { AcademicHeaderNotifierComponent } from './header/academic-header-notifier/academic-header-notifier.component';
import { GetUserState } from './state/get-user.state';
import { NgDialogAnimationService } from 'ng-dialog-animation';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: SOCIAL_LOGIN.MICROSOFT_CLIENT_ID,
      redirectUri: window.location.origin + RoutingConstant.SHORT_COURSE_LOGIN_URL
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
  });
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    PageNotFoundComponent,
    ShortCourseSignUpComponent,
    ShortCourseSignInComponent,
    ShortCourseHeaderComponent,
    AcademicHeaderComponent,
    PlainHeaderComponent,
    ForgotPasswordDialogComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    ContactUsComponent,
    FaqComponent,
    LoaderComponent,
    DownloadPdfPageComponent,
    AcademicHeaderPollComponent,
    AcademicHeaderNotifierComponent
  ],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    SharedModule,
    MsalModule,
    NgxPermissionsModule.forRoot(),
    NgxsModule.forRoot([
      StudentProgressState,
      SubscriptionPlansState,
      GetUserState
    ]),
    environment.plugins,
    SocialLoginModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase)
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(SOCIAL_LOGIN.GOOGLE_PROVIDER_ID),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(SOCIAL_LOGIN.FACEBOOK_PROVIDER_ID),
          }
        ],
      } as SocialAuthServiceConfig,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    MsalService,
    PushNotificationService,
    NgDialogAnimationService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ForgotPasswordDialogComponent
  ]
})

export class AppModule { }