<div class="main">
  <button *ngIf="dialogData?.showPopupCloseIcon" (click)="closePopup()" class="close-btn pointer">
    <span class="material-icons">
        close
      </span>
    </button>
  <div class="popup-body">
    <div class="popup-header">
      <div class="header-content">
        <div>
          <p class="update">Update <span class="profile">Your Profile!</span></p>
        </div>
        <span class="one-step">Just One Step Away.</span>
      </div>
    </div>
    <form [formGroup]="educationAndExperienceDetailForm" (ngSubmit)="saveDetails(educationAndExperienceDetailForm.value)">
      <div class="scrollbar athena-scroll" [ngStyle]="{ 'height': dynamicHeight > 0 ? dynamicHeight+'px' : 'auto' }">
        <div class="education-detail-form athena-scroll">
          <div class="row form-inputs">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <mat-form-field appearance="blank">
                <label>Highest Qualification</label>
                <mat-select [placeholder]="appConfig.formDetails.qualificationLevel.placeholder"
                  [formControlName]="appConfig.formDetails.qualificationLevel.key" ngDefaultControl required>
                  <mat-option *ngFor="let qulData of highestQualification" [value]="qulData.high_qal_id">
                    {{ qulData['qualification_name'] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-error
                  *ngIf="formControl[appConfig.formDetails.qualificationLevel.key].invalid&& formControl[appConfig.formDetails.qualificationLevel.key].touched">
                  <mat-error *ngIf="formControl[appConfig.formDetails.qualificationLevel.key].errors.required">
                    {{ appConfig.formDetails.qualificationLevel.errorMessage.required }}
                  </mat-error>
                </mat-error>
            </div>
            <!-- toggle starts -->
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 toggle-head">
              <label class="togg-label">Do you have Work Experience ?</label>
              <label class="ath-switch">
                <span class="status status-yes"><img src="assets/update-profile/toggle-tick.svg" alt="yes"></span>
                <input type="checkbox"
                       class="switch-input"
                       [formControlName]="appConfig.formDetails.workExp.key"
                       (change)="workExperienceChanged($event.target.checked)">
                <span class="switch-label" data-on="On" data-off="Off"></span>
                <span class="switch-handle"></span>
            </label>
            </div>
            <div *ngIf="showExperienceDetails" class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <mat-form-field appearance="blank">
                <label>Employment Level</label>
                <mat-select [placeholder]="appConfig.formDetails.employment_level.placeholder"
                  [formControlName]="appConfig.formDetails.employment_level.key" required>
                  <mat-option *ngFor="let empLevel of employmentLevels" [value]="empLevel['grade_id']">
                    {{empLevel['grade']}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-error
                *ngIf="formControl[appConfig.formDetails.employment_level.key].invalid&& formControl[appConfig.formDetails.employment_level.key].touched">
                <mat-error *ngIf="formControl[appConfig.formDetails.employment_level.key].errors.required">
                  {{ appConfig.formDetails.employment_level.errorMessage.required }}
                </mat-error>
              </mat-error>
            </div>
            <div *ngIf="showExperienceDetails" class="col-12">
              <label class="years-label">Years of Experience</label>
            </div>
            <div *ngIf="showExperienceDetails" class="col-6">
              <mat-form-field appearance="blank">
                <mat-select [placeholder]="appConfig.formDetails.work_experience.placeholder"
                  [formControlName]="appConfig.formDetails.work_experience.key" required>
                  <mat-option *ngFor="let option of yearExperience; let year = index" [value]="year.toString()">
                    {{year}} Years
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-error
                *ngIf="formControl[appConfig.formDetails.work_experience.key].invalid&& formControl[appConfig.formDetails.work_experience.key].touched">
                <mat-error *ngIf="formControl[appConfig.formDetails.work_experience.key].errors.required">
                  {{ appConfig.formDetails.work_experience.errorMessage.required }}
                </mat-error>
              </mat-error>
            </div>
            <div *ngIf="showExperienceDetails" class="col-6">
              <mat-form-field appearance="blank">
                <mat-select [placeholder]="appConfig.formDetails.monthsExperience.placeholder"
                  [formControlName]="appConfig.formDetails.monthsExperience.key" required>
                  <mat-option *ngFor="let option of monthExperience; let month = index" [value]="month.toString()">
                    {{month}} Months
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-error
                *ngIf="formControl[appConfig.formDetails.monthsExperience.key].invalid&& formControl[appConfig.formDetails.monthsExperience.key].touched">
                <mat-error *ngIf="formControl[appConfig.formDetails.monthsExperience.key].errors.required">
                  {{ appConfig.formDetails.monthsExperience.errorMessage.required }}
                </mat-error>
              </mat-error>
            </div>
            <div *ngIf="showExperienceDetails" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 industry-inp">
              <mat-form-field appearance="blank">
                <label>Industry</label>
                <mat-select [placeholder]="appConfig.formDetails.industry.placeholder"
                  [formControlName]="appConfig.formDetails.industry.key" required>
                  <mat-option *ngFor="let industry of industryData" [value]="industry['industry_id']">
                    {{industry['industry']}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-error
                *ngIf="formControl[appConfig.formDetails.industry.key].invalid&& formControl[appConfig.formDetails.industry.key].touched">
                <mat-error *ngIf="formControl[appConfig.formDetails.industry.key].errors.required">
                  {{ appConfig.formDetails.industry.errorMessage.required }}
                </mat-error>
              </mat-error>
            </div>
  
            <div *ngIf="showExperienceDetails" class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <mat-form-field appearance="blank">
                <label>Designation</label>
                <input matInput [formControlName]="appConfig.formDetails.designation.key"
                  [placeholder]="appConfig.formDetails.designation.placeholder" required />
              </mat-form-field>
              <mat-error *ngIf="formControl[appConfig.formDetails.designation.key].invalid && 
              formControl[appConfig.formDetails.designation.key].touched">
               <mat-error *ngIf="formControl[appConfig.formDetails.designation.key].errors.required">
                 {{ appConfig.formDetails.designation.errorMessage.required }}
               </mat-error>
             </mat-error>
            </div>
  
          </div>
        </div>
        <div class="item-editor">
          <div class="ath-button" [ngSwitch]="dialogData?.resourceType">
            <button *ngSwitchCase="applicationConstant.downloadResourseType.referenceMaterial">
              Download Reference Material</button>
            <button *ngSwitchCase="applicationConstant.downloadResourseType.offlineReadingDownloads">
              Download Offline Reading</button>
            <button *ngSwitchCase="applicationConstant.downloadResourseType.caseStudyDownloads">
              Download Case Study</button>
            <button *ngSwitchCase="applicationConstant.downloadResourseType.offlineReadingMails">
              Mail Now</button>
          </div>
          <div *ngIf="!dialogData?.resourceType" class="ath-button">
            <button>Update</button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="bg-img"><img src="assets/update-profile/update-profile.png" alt="update"> </div>
</div>