import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApplicationConstant } from '../shared/constant/app.constant';
import { FormService } from '../services/form.service';
import { AppConfig } from '../shared/config/app.config';
import { APP_URL } from '../../environments/environment';
import { CommonService } from '../services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { ForgotPasswordDialogComponent } from '../forgot-password/dialog/forgot-password-dialog/forgot-password-dialog.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RoutingConstant } from '../shared/constant/routing.constant';
import {EndpointConstant} from "../shared/constant/endpoint.constant";

@Component({
  selector: 'app-short-course-sign-in',
  templateUrl: './short-course-sign-in.component.html',
  styleUrls: ['./short-course-sign-in.component.scss']
})
export class ShortCourseSignInComponent implements OnInit, AfterViewInit, OnDestroy {

  appConfig = AppConfig;
  applicationConstant = ApplicationConstant;
  destroySubscription: Subject<void> = new Subject<void>();
  locationDetails = ApplicationConstant.DEFAULT_COUNTRY;
  queryParamData: Params;
  returnUrl: string;
  routingConstant = RoutingConstant;
  serverError = '';
  signInForm: FormGroup = this.formBuilder.group({});
  togglePasswordIcon = false;

  constructor(private activatedRoute: ActivatedRoute,
              public commonService: CommonService,
              private formBuilder: FormBuilder,
              public formService: FormService,
              public matDialog: MatDialog,
              private router:Router) { }

  ngOnInit(): void {
    this.initializeForm();
    this.getUTMParameters();
    this.getCountryCode();
    this.commonService.clearLocalStorageOnLogout();
    this.returnUrl = this.activatedRoute.snapshot.queryParams?.returnUrl
      || RoutingConstant.STUDENT_DASHBOARD;
    this.loadTrackScript();
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  /**
   * Loading track script for sign in page
   */
  private loadTrackScript() {
    let chatScript = document.createElement('script');
    chatScript.type = 'text/javascript';
    chatScript.async = true;
    chatScript.src = './assets/js/track.js';
    document.body.appendChild(chatScript);
  }

  /**
   * Initialize the form data.
   */
  initializeForm() {
    this.signInForm = this.formBuilder.group({
      [this.appConfig.formDetails.emailId.key]: ['', this.appConfig.formDetails.emailId.validation],
      [this.appConfig.formDetails.password.key]: ['', this.appConfig.formDetails.password.validation]
    });
  }

  /**
   * Get the UTM parameters for the registration page.
   */
  getUTMParameters() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.queryParamData = params;
      if(this.queryParamData?.email){
        this.signInForm.patchValue({
          [this.appConfig.formDetails.emailId.key]: this.queryParamData?.email
        });
      }
    });
  }

  /**
   * Get all the form control
   */
  get formControl() {
    return this.signInForm.controls;
  }

  /**
   * Get the country code.
   */
   getCountryCode() {
    if (this.commonService.countryCode) {
      this.getCountryInfo(this.commonService.countryCode);
    } else {
      this.getThirdPartyApiDetails();
    }
  }

  /**
   * get third party api details
   */
  getThirdPartyApiDetails() {
    this.formService.getDetailsFromThirdParty(`${APP_URL.IP_GEOLOCATION}`, '').then((data: any) => {
      this.getCountryInfo(data.countryCode);
      this.commonService.setCountryCode(data.countryCode);
    }, error => {
       console.error(error);
   });
  }

  /**
   *
   * @param countryCode
   */
  getCountryInfo(countryCode: string) {
    if (this.commonService.locationDetails) {
      this.locationDetails = this.commonService.locationDetails;
    } else {
      this.formService.getDetails(EndpointConstant.COUNTRY_INFO(countryCode))
      .pipe(takeUntil(this.destroySubscription))
        .subscribe(res => {
          if (res) {
              this.locationDetails = {
                  countryId : res?.country_id,
                  countryCode : res?.iso3,
                  countryContactCode : res?.phonecode ? '+' + res?.phonecode : '',
                  countryIsq : res?.isq
                };
                this.commonService.setLocationDetails(this.locationDetails);
              }
          }, error => {
         console.error(error);
      });
    }
  }

  /**
   * Login user details.
   */
  loginUser() {
    this.formService.loginUser(this.signInForm).then(value => {
      this.commonService.getAndSaveUTMDetails(this.queryParamData, null);
      this.router.navigateByUrl(this.returnUrl);
    }).catch(reason => {
      this.serverError = reason;
    });
  }

  /**
   * Forget Password Dialog.
   */
  forgetPasswordDialog() {
    this.matDialog.open(ForgotPasswordDialogComponent,
      {
        panelClass: 'myapp-no-padding-dialog',
        disableClose: true,
        data: {
        isLoginPage: true,
        mail: this.signInForm.controls[this.appConfig.formDetails.emailId.key].value }
      });
  }

  /**
   * Toggle password icon.
   */
  toggleIcon() {
    this.togglePasswordIcon = !this.togglePasswordIcon;
  }

  /**
   * Reset the error status
   */
  updateFields() {
    this.serverError = '';
  }

  ngOnDestroy(): void {
    this.destroySubscription.next();
    this.destroySubscription.complete();
  }

}
