import { Injectable } from '@angular/core';
import { BaseService } from '../../services/base.service';
import { ApplicationConstant } from '../../shared/constant/app.constant';
import { QuizIntroduction } from './model/quiz-introduction.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApplicationNotifierService } from 'src/app/shared/component/application-notifier/application-notifier.service';
import { Router } from '@angular/router';
import { APP_URL } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuizAssessmentService {

  instruction: BehaviorSubject<QuizIntroduction> = new BehaviorSubject<QuizIntroduction>(new QuizIntroduction());
  quizIntroductionTrigger: Subject<any> = new Subject<any>();
  quizQuestionSetSubject: Subject<any> = new Subject<any>();
  quizQuestionTimeUpSubject: Subject<any> = new Subject<any>();
  instructions: QuizIntroduction;

  showQuizQuestionCount = true;
  isRetakeTest = false;
  isResumeTest = false;
  questionsList = [];

  constructor(private baseService: BaseService,
              private applicationNotifierService: ApplicationNotifierService,
              private router: Router) {
  }

  /**
   * Get the quiz assessment details.
   */
  get quizAssessmentDetails() {
    return JSON.parse(sessionStorage.getItem(ApplicationConstant.storageKeys.QUIZ_ASSESSMENT_DETAILS));
  }

  /**
   * Set the quiz assessment details.
   *
   * @param assessmentDetails details for the assessment.
   */
  set quizAssessmentDetails(assessmentDetails: any) {
    sessionStorage.setItem(ApplicationConstant.storageKeys.QUIZ_ASSESSMENT_DETAILS, JSON.stringify(assessmentDetails));
  }

  /**
   * Get the assessmentId for the quiz.
   */
  get quizAssessmentId() {
    return Number(sessionStorage.getItem(ApplicationConstant.storageKeys.QUIZ_ASSESSMENT_ID));
  }

  /**
   * Set the assessmentId for the quiz.
   *
   * @param assessmentId assessment Id for the quiz.
   */
  set quizAssessmentId(assessmentId: number) {
    sessionStorage.setItem(ApplicationConstant.storageKeys.QUIZ_ASSESSMENT_ID, assessmentId.toString());
  }

  /**
   * Get the examId for the quiz.
   */
  get quizExamId() {
    return Number(sessionStorage.getItem(ApplicationConstant.storageKeys.QUIZ_EXAM_ID));
  }

  /**
   * Set the examId for the quiz.
   *
   * @param examId exam Id for the quiz.
   */
  set quizExamId(examId: number) {
    sessionStorage.setItem(ApplicationConstant.storageKeys.QUIZ_EXAM_ID, examId.toString());
  }

  /**
   * Get the details for the quiz.
   *
   * @param endpoint api
   */
  getDetails(endpoint: string) {
    return this.baseService.get(endpoint);
  }

  /**
   * Save the details for the quiz.
   *
   * @param endpoint api
   * @param data parent module
   */
  saveDetails(endpoint: string, data: any) {
    return this.baseService.post(endpoint, data);
  }

  /**
  * Send question set options
  */
  sendQuestionSet(data: any) {
    this.quizQuestionSetSubject.next(data);
  }

  /**
  * Time up call to section component
  */
  sendQuizTimeUp(value: boolean) {
    this.quizQuestionTimeUpSubject.next(value);
  }

  /**
   * Trigger quiz introduction
  */
  triggerQuizIntroduction() {
    this.quizIntroductionTrigger.next();
  }
  
  /**
   * redirect to dashboard if current plan is expired.
   * @param errorInfo error information
   * @param url navigation url
   */
  navigateToDashboardIfPlanExpired(errorInfo: any, url: string) {
    if (errorInfo?.error && errorInfo?.error?.status === 400 &&
      errorInfo?.error?.message === 'Subscribed plan for the course has expired') {
      const msg = {
        icon: 'error_outline',
        message: errorInfo?.error?.message
      }
      this.applicationNotifierService.getNotifierManual(msg);
      this.router.navigateByUrl(url);
    }
  }

  /**
   * Save the details
   * @param endpoint api
   * @param data payload
   */
  postMethod(endpoint: string, data: any) {
    return this.baseService.postMethod(APP_URL.API_NEW, endpoint, data);
  }

}
