export class GetStudentProgress {
    static readonly type = '[PROGRESS] student';
}

export class GetSubscriptionPlans {
    static readonly type = '[PLANS] subscription';
}

export class GetUserDetails {
    static readonly type = '[DETAILS] user';
}