<div *ngIf="showCourses">
  <div class="all-courses">
    <div class="sections container">
      <div class="section-item">
        <h1 class="ht-4">Most Popular Online Specialisations</h1>
        <ul>
          <li *ngFor="let course of popularCourses;">
            <a [href]="websiteURL + course.path">{{ course.name }}</a>
          </li>
        </ul>
      </div>

      <div class="section-item">
        <h1 class="ht-4">Trending Online</h1>
        <ul>
          <li *ngFor="let course of trendingCourses;">
            <a [href]="websiteURL + course.path">{{ course.name }}</a>
          </li>
        </ul>
      </div>

      <div class="section-item">
        <h1 class="ht-4">Top Universities Online Certificates</h1>
        <ul>
          <li *ngFor="let course of topUniversitiesCourses;">
            <a [href]="websiteURL + course.path">{{ course.name }}</a>
          </li>
        </ul>
      </div>

      <div class="section-item">
        <h1 class="ht-4">Accredited Online Degree Program</h1>
        <ul>
          <li *ngFor="let course of degreeProgramCourses;">
            <a [href]="websiteURL + course.path">{{ course.name }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- NEW FOOTER -->
<footer *ngIf="isPageLoaded" class="footer container-fluid px-0">
  <div class="container">
    <div class="new-footer col-md-12 row p-0">
      <div class="col-md-3 p-0">
        <img src="assets/footer/footer-logo.webp" alt="footer-logo" class="footer-logo">
        <ul class="footer-social-icons">
          <li><a [href]="applicationConstant.socialIconRedirection.facebookLink" target="_blank"><img src="assets/footer/footer-facebook.webp" alt="fb"></a></li>
          <li><a [href]="applicationConstant.socialIconRedirection.instagramLink" target="_blank"><img src="assets/footer/footer-insta.webp" alt="insta"></a></li>
          <li><a [href]="applicationConstant.socialIconRedirection.linkedinLink" target="_blank"><img src="assets/footer/footer-linkedin.webp" alt="linkedin"></a></li>
          <li><a [href]="applicationConstant.socialIconRedirection.youtubeLink" target="_blank"><img src="assets/footer/footer-youtube.webp" alt="youtube"></a></li>
          <li><a [href]="applicationConstant.socialIconRedirection.twitterLink" target="_blank"><img src="assets/footer/footer-x.webp" alt="twitter"></a></li>
          </ul>
          <div class="footer-faq-section">
            <p class="faqs"><a href="{{ websiteURL }}faq-glossory" target="_blank"><img src="assets/footer/footer-faq.webp" alt="faq"> FAQs</a></p>
            <p> <a href="mailto:support@uniathena.com"><img src="assets/footer/footer-faq.webp" alt="customer-support"> Customer Support</a></p>
          </div>
      </div>
      <div class="col-md-9 mb-4">
        <div class="row">
          <div class="only-mobile-footer">
            <ul class="only-mob-left">
              <li><a href="{{ websiteURL }}insights/all" target="_blank">Insights</a></li>
              <li><a href="{{ websiteURL }}webinars" target="_blank">Webinar</a></li>
              <li><a href="{{ websiteURL }}about-us/our-background" target="_blank">About Us</a></li>
              <li><a [routerLink]="routingConstant.CONTACT_US">Contact Us</a></li>
              <li><a href="{{ websiteURL }}refer-earn" target="_blank">Refer & Earn</a></li>
              <li><a href="{{ websiteURL }}privacy-policy" target="_blank">Privacy Policy</a></li>
            </ul>
            <div class="mob-divider"></div>
            <ul class="mob-second-child">
              <li><a (click)="redirectToAcadPrograms()">Academic Courses</a></li>
              <!-- <li><a>Certification & Charges</a></li> hidden as per requirement -->
              <li><a href="{{ websiteURL }}uni-short-courses/free-certificate" target="_blank">Free Certification Courses</a></li>
              <li><a href="{{ websiteURL }}terms" target="_blank">Terms & Conditions</a></li>
              <li><a href="{{ zohoDeskURL }}portal/en/home" target="_blank">Knowledge Base</a></li>
            </ul>
          </div>
          <div class="col-md-3 only-desktop-footer">
            <ul>
              <li><a href="{{ websiteURL }}webinars" target="_blank">Webinar</a></li>
              <li><a (click)="redirectToAcadPrograms()">Academic Courses</a></li>
              <!-- <li><a>Certification & Charges</a></li> hidden as per requirement -->
              <li><a href="{{ websiteURL }}uni-short-courses/free-certificate" target="_blank">Free Certification Courses</a></li>
              <li><a href="{{ websiteURL }}insights/all" target="_blank">Insights</a></li>
            </ul>
          </div>
          <div class="col-md-3 pr-0 only-desktop-footer">
            <ul>
              <li><a href="{{ websiteURL }}refer-earn" target="_blank">Refer & Earn</a></li>
              <li><a href="{{ zohoDeskURL }}portal/en/home" target="_blank">Knowledge Base</a></li>
              <li><a href="{{ websiteURL }}terms" target="_blank">Terms & Conditions</a></li>
              <li><a href="{{ websiteURL }}about-us/our-background" target="_blank">About Us</a></li>
            </ul>
          </div>
          <div class="col-md-3 only-desktop-footer">
            <ul>
              <li><a [routerLink]="routingConstant.CONTACT_US">Contact Us</a></li>
              <li><a href="{{ websiteURL }}privacy-policy" target="_blank">Privacy Policy</a></li>
            </ul>
          </div>
          <div class="col-md-3 pl-0">
            <div class="subscription-head">
              <p class="subscription-letter">Subscribe to our Newsletter</p>
              <input type="email" type="email"
              aria-label="email id"
              id="newsletter-email-input"
              class="form-control footer-mail"
              [(ngModel)]="subscribeEmail"
              (input)="updatingEmail()"
              placeholder="Email">
              <span *ngIf="emailValidation.invalid" class="ath-error mt-1">{{ emailValidation.message }}</span>
              <button class="footer-subscribe" type="button"
              (click)="subscribeNewsletter()"
              id="news-letter-subscribe">Subscribe</button>
            </div>
          </div>
        </div>
        <hr class="only-desktop-footer desktop-divider">
        <div class="coming-soon desktop-divider">
          <p><label for="coming soon" class="soon-label">Coming Soon..</label><img src="assets/footer/footer-google-play.webp" alt="g-play" class="g-play-img"></p>
          <p><img src="assets/footer/footer-app-store.webp" alt=""></p>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    All Copyrights reserved @ Athena Global Education 2021-{{ this.currentYear }}
  </div>
  <div id="chatBotAskSam" class="chat-bot-text">
    <p>Ask Sam</p>
  </div>
</footer>
