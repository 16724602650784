import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { ApplicationConstant } from '../../constant/app.constant';
import { AppConfig } from '../../config/app.config';
import { ApplicationNotifierConstant } from '../../constant/notifier.constant';
import { EventLogsEnum } from 'src/app/student-dashboard/enum/event-logs-enum';
import { CommonService } from 'src/app/services/common.service';
import { ApplicationNotifierService } from '../application-notifier/application-notifier.service';
import { FormService } from 'src/app/services/form.service';
import { EndpointConstant } from '../../constant/endpoint.constant';
import { AppUtil } from '../../utils/app.util';
import { Router } from '@angular/router';
import { RoutingConstant } from '../../constant/routing.constant';
import { GetUser } from 'src/app/model/get-user.model';
import { GetUserState } from 'src/app/state/get-user.state';
import { Select } from '@ngxs/store';
import { HeaderService } from 'src/app/header/header.service';

@Component({
  selector: 'app-education-details-in-download',
  templateUrl: './education-details-in-download.component.html',
  styleUrls: ['./education-details-in-download.component.scss']
})
export class EducationDetailsInDownloadComponent implements OnInit, OnDestroy {

  applicationConstant = ApplicationConstant;
  appConfig = AppConfig;
  applicationNotifierConstant = ApplicationNotifierConstant;
  educationAndExperienceDetailForm: FormGroup = this.formBuilder.group({});
  highestQualification: any;
  employmentLevels: any;
  industryData: any;
  eventLogsEnum = EventLogsEnum;
  userData: GetUser;
  yearExperience = new Array(this.applicationConstant.maxYearExperience);
  monthExperience = new Array(12);
  showExperienceDetails: boolean;
  destroySubscription: Subject<void> = new Subject<void>();
  dynamicHeight = 0;
  isDesktopSize = window.screen.width > ApplicationConstant.DEVICE_SIZE.maxIpad;
  @Select(GetUserState.getUserInfo) studentDetailsInfo: Observable<GetUser>;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
              private formService: FormService,
              private headerService: HeaderService,
              private formBuilder: FormBuilder,
              private commonService: CommonService,
              private applicationNotifierService: ApplicationNotifierService,
              @Optional() private dialogRef: MatDialogRef<EducationDetailsInDownloadComponent>,
              private router: Router) {
                if (this.isDesktopSize && window.screen.height < 699) {
                  this.dynamicHeight = window.screen.height - 250;
                }
               }

  ngOnInit(): void {
    this.initializeForm();
    this.getUserData();
    this.getEducationDetails();
  }

  initializeForm() {
    this.educationAndExperienceDetailForm = this.formBuilder.group({
      [this.appConfig.formDetails.qualificationLevel.key]: ['',
      this.appConfig.formDetails.qualificationLevel.validation],
      [this.appConfig.formDetails.employment_level.key]: ['',
      this.appConfig.formDetails.employment_level.validation],
      [this.appConfig.formDetails.industry.key]: ['',
      this.appConfig.formDetails.industry.validation],
      [this.appConfig.formDetails.work_experience.key]: ['',
      this.appConfig.formDetails.work_experience.validation],
      [this.appConfig.formDetails.monthsExperience.key]: ['',
      this.appConfig.formDetails.monthsExperience.validation],
      [this.appConfig.formDetails.designation.key]: ['',
      this.appConfig.formDetails.designation.validation],
      [this.appConfig.formDetails.workExp.key]: [false, 
      this.appConfig.formDetails.workExp.validation]
    });
  }

  get formControl() {
    return this.educationAndExperienceDetailForm.controls;
  }

  /**
    * Get the highest qualification, industry, employment level list
    */
  getEducationDetails() {
    const highestQualificationUrl = this.formService.getDetails(EndpointConstant.QUALIFICATION);
    const industryUrl = this.formService.getDetails(EndpointConstant.INDUSTRIES);
    const employmentUrl = this.formService.getDetails(EndpointConstant.EMPLOYMENT_LVL);

    forkJoin([
      highestQualificationUrl.pipe(catchError(err => of(console.error(err)))),
      industryUrl.pipe(catchError(err => of(console.error(err)))),
      employmentUrl.pipe(catchError(err => of(console.error(err)))),
    ]).pipe(takeUntil(this.destroySubscription))
      .subscribe(([highestQualificationResponse, industryResponse, employmentResponse]) => {
        this.highestQualification = highestQualificationResponse;
        this.industryData = industryResponse;
        this.employmentLevels = employmentResponse;
      });
  }

  /**
   * Get the student details.
   */
  private getUserData() {
    this.studentDetailsInfo
        .pipe(takeUntil(this.destroySubscription))
        .subscribe(userDetails => {
          this.userData = userDetails;
          if(this.userData) {
            this.educationAndExperienceDetailForm.patchValue(this.userData);
            if(this.userData[this.appConfig.formDetails.workExp.key] !== this.applicationConstant.currentWorking[0].value) {
              this.educationAndExperienceDetailForm.controls[this.appConfig.formDetails.workExp.key].setValue(false);
              this.workExperienceChanged(false);
            } else {
              this.educationAndExperienceDetailForm.controls[this.appConfig.formDetails.workExp.key].setValue(true);
              this.workExperienceChanged(true);
            }
          }
        }, error => {
          console.error('Error', error);
        });
  }


/**
 * Save form detaiils.
 * @param data form data
 */
 saveDetails(data: any) {
  this.commonService.saveStudentClickEventLog(EventLogsEnum.updateProfileButtonPopup, this.dialogData.courseId);
  this.educationAndExperienceDetailForm.markAllAsTouched();
  
   if (this.educationAndExperienceDetailForm.valid) {
        const formData = Object.assign({}, data);
        formData[ this.applicationConstant.formMetaFields.uId]
              = this.commonService.getUId();
        formData[ this.applicationConstant.formMetaFields.courseId]
              = this.dialogData.courseId;
        formData[this.appConfig.formDetails.workExp.key] = this.showExperienceDetails ? 'y' : 'n'
          this.formService.saveDetails(EndpointConstant.USER_DETAILS_SAVE(
            this.commonService.getUId().toString()), formData).subscribe(async(data) => {
              if (!this.dialogData?.isPersonalizedOffer) {
                await this.headerService.dispatchGetUserDetails();
              }
             this.dialogRef.close(this.userData);
             if(!this.dialogData?.resourceType) {
                this.applicationNotifierService.getNotifier(this.applicationNotifierConstant.form_saved);
              }
          }, error => {
            console.error('error', error);
        });
     }
  }

  /**
   * @param isWorkExperience true/false
   */
  workExperienceChanged(isWorkExperience: boolean) {
    this.showExperienceDetails = isWorkExperience;
    const workExperienceFieldList = [] as any;
    for (const [fieldName, fieldObject] of Object.entries(this.appConfig.formDetails)) {
      if (fieldObject[`type`] === 'experience' && fieldName !== 'workExp') {
        workExperienceFieldList.push(fieldObject);
      }
    }
    AppUtil.enableOrDisableFormFields(this.educationAndExperienceDetailForm, workExperienceFieldList, this.showExperienceDetails);
  }

  /**
   * close popup
   */
  closePopup() {
    this.dialogRef.close(this.dialogData?.nextUncompleteLesson);
  }

  ngOnDestroy(): void {
    this.destroySubscription.next();
    this.destroySubscription.complete();
  }

}
