import { Injectable } from '@angular/core';
import { ApplicationConstant } from '../shared/constant/app.constant';
import { BaseService } from './base.service';
import { APP_URL } from '../../environments/environment';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';
import { AppUtil } from '../shared/utils/app.util';
import { MatDialog } from '@angular/material/dialog';
import { EndpointConstant } from '../shared/constant/endpoint.constant';
import { CookieService } from 'ngx-cookie-service';
import { BirthdayOfferComponent } from '../shared/component/birthday-offer/birthday-offer.component';
import { ApplicationDialogConstant } from '../shared/constant/dialog.constant';
import { ApplicationDialogService } from '../shared/component/application-dialog/application-dialog.service';
import { Params, Router } from '@angular/router';
import { EventLogsEnum } from '../student-dashboard/enum/event-logs-enum';
import { PersonalizedOfferPopupComponent } from '../shared/component/personalized-offer-popup/personalized-offer-popup.component';
import { GetUser } from '../model/get-user.model';
import { CountDownDialogComponent } from '../shared/component/count-down-dialog/count-down-dialog.component';
import { RoutingConstant } from '../shared/constant/routing.constant';


@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private appliedCourseId: number;
  currentUrl: string;
  claimedCertificateData: any;
  countryCode: string;
  firstName: string;
  private loginProviderData: string;
  uid: number;
  userDetails: GetUser;
  userLogged: any;
  locationDetails: any;
  profileImageLink:  Subject<String> = new Subject<String>();
  alarmDetails: Subject<any> = new Subject<any>();
  userFirstName: Subject<String> = new Subject<String>();
  eventLogsEnum = EventLogsEnum;
  isLessonPopupShowedTwice = false;

  constructor(private applicationDialogService: ApplicationDialogService,
              private baseService: BaseService,
              private cookieService: CookieService,
              public matDialog: MatDialog,
              private router: Router) {
    this.getLoggedUser();
    this.getLocationDetails();
  }

  /**
   * Get the course information.
   */
  get course() {
    if (this.appliedCourseId) {
      return this.appliedCourseId;
    } else {
      this.appliedCourseId = Number(localStorage.getItem(ApplicationConstant.storageKeys.COURSE_ID));
      return this.appliedCourseId;
    }
  }

  /**
   * Set the course information.
   *
   * @param courseId course id for applied application
   */
  set course(courseId: number) {
    localStorage.setItem(ApplicationConstant.storageKeys.COURSE_ID, courseId.toString());
    this.appliedCourseId = courseId;
  }

  /**
   * Get the login provider data.
   */
  get loginProvider() {
    if (this.loginProviderData) {
      return this.loginProviderData;
    } else {
      this.loginProviderData = localStorage.getItem(ApplicationConstant.storageKeys.LOGIN_PROVIDER);
      return this.loginProviderData;
    }
  }

  /**
   * Set the login provider data.
   *
   * @param provider for login
   */
  set loginProvider(provider: string) {
    localStorage.setItem(ApplicationConstant.storageKeys.LOGIN_PROVIDER, provider);
    this.loginProviderData = provider;
  }

  /**
   * Get uid.
   */
  getUId() {
    if (this.uid) {
      return this.uid;
    } else {
      this.uid = Number(localStorage.getItem(ApplicationConstant.storageKeys.U_ID));
      return this.uid;
    }
  }

  /**
   * Set uid.
   * @param uid unique id for user.
   */
  setUId(uid: number) {
    localStorage.setItem(ApplicationConstant.storageKeys.U_ID, uid.toString());
    this.uid = uid;
  }

  /**
   * Set the first name.
   * @param firstName name of the user.
   */
  setFirstName(firstName: string) {
    localStorage.setItem(ApplicationConstant.storageKeys.FIRST_NAME, firstName);
    this.firstName = firstName;
  }

  setLoggedUser(data: any) {
    localStorage.setItem(ApplicationConstant.storageKeys.USER, JSON.stringify(data));
    this.userLogged = data;
  }

  setLocationDetails(data: any) {
    localStorage.setItem(ApplicationConstant.storageKeys.LOCATION_DETAILS, JSON.stringify(data));
    this.locationDetails = data;
  }

  getLocationDetails() {
    if (this.locationDetails) {
      return this.locationDetails;
    } else {
      this.locationDetails = JSON.parse(localStorage.getItem(ApplicationConstant.storageKeys.LOCATION_DETAILS));
      return this.locationDetails;
    }
  }

  setCountryCode(data: any) {
    localStorage.setItem(ApplicationConstant.storageKeys.COUNTRY_CODE, JSON.stringify(data));
    this.countryCode = data;
  }

  getCountryCode() {
    if (this.countryCode) {
      return this.countryCode;
    } else {
      this.countryCode = JSON.parse(localStorage.getItem(ApplicationConstant.storageKeys.COUNTRY_CODE));
      return this.countryCode;
    }
  }

  /**
   * Get the course categories.
   */
  getCourseCategories() {
    const data = localStorage.getItem(ApplicationConstant.storageKeys.COURSE_CATEGORIES);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  }

  /**
   * Set the course categories.
   *
   * @param courseCategories is the course category
   */
  setCourseCategories(courseCategories: any) {
    localStorage.setItem(ApplicationConstant.storageKeys.COURSE_CATEGORIES, JSON.stringify(courseCategories));
  }

  getLoggedUser() {
    if (this.userLogged) {
      return this.userLogged;
    } else {
      this.userLogged = JSON.parse(localStorage.getItem(ApplicationConstant.storageKeys.USER));
      return this.userLogged;
    }
  }

   /**
    *
    *
    * @param {number} uId
    * @memberof CommonService
    */
   clearUserInfo(uId: number) {
    localStorage.removeItem(`${ApplicationConstant.storageKeys.REGISTERED_COURSES}_${uId}`);
   }

  /**
   * Navigate to External link.
   *
   * @param url of the page.
   */
  navigateWebsiteLink(url) {
    window.location.href = `${APP_URL.WEBSITE}${url}`;
  }

  /**
   * Navigate to External link.
   *
   * @param url of the page.
   */
  navigateOLDLMSLink(url) {
    window.location.href = `${APP_URL.OLD_LMS}${url}`;
  }

  /**
   * Download certificate or transcript.
   *
   * @param certificateDetails is the details for the certificate.
   * @param courseCode of the course
   * @param blockchainId uniques id for the certificate and transcript
   */
  downloadFiles(certificateDetails: any, courseCode: string, blockchainId: string) {
    const userInfo = this.userDetails;
    this.baseService.get(EndpointConstant.CERTIFICATE_AND_TRANSCRIPT_BY_BLOCKCHAIN_ID(blockchainId))
      .subscribe(res => {
        certificateDetails.certificate_pdf_path = res?.certificate_pdf_path;
        certificateDetails.transcript_pdf_path = res?.transcript?.transcript_pdf_path;
        if (AppUtil.detectBrowserName() === 'firefox') {
          window.open(res?.certificate_pdf_path, '_blank');
          window.open(res?.transcript?.transcript_pdf_path, '_blank');
        } else {
          saveAs(res?.certificate_pdf_path, `${userInfo?.first_name}_${courseCode}_certificate.pdf`);
          saveAs(res?.transcript?.transcript_pdf_path, `${userInfo?.first_name}_${courseCode}_transcript.pdf`);
        }
      }, (error) => {
        console.error('Error', error);
      });
  }

  /**
   * Student click event logs.
   * @param eventTypeNumber event type number
   * @param courseId course Id
   * @param lessonId lesson Id
   */
  saveStudentClickEventLog(eventTypeNumber: number, courseId?: string, lessonId?: number) {
    const payload = {
      student_activity_type: eventTypeNumber,
      course_id: courseId,
      child_module_item_id: lessonId
    }
    this.baseService.post(EndpointConstant.STUDENT_LOG_SAVE, payload).subscribe(() => {
      }, error => {
          console.error('Error', error);
      });
  }

  /**
   * Check if the user is eligible for birthday offer.
   *
   * @param isEligibleForCertificateRoute
   */
  checkForBirthdayOfferEligibility(isEligibleForCertificateRoute: boolean = false): any {
    this.baseService.get(EndpointConstant.BIRTHDAY_OFFER_ELIGIBILITY_CHECK).subscribe(response => {
      this.openBirthDayOfferPopup(response?.data, isEligibleForCertificateRoute);
    }, err => {
      console.error('Error Registering: ', err);
    });
  }

  /**
   * Check and open the birthday dialog.
   *
   * @param birthdayOfferDetails details for birthday offer
   * @param isEligibleForCertificateRoute when user navigate from eligible certificate page
   */
  openBirthDayOfferPopup(birthdayOfferDetails: any, isEligibleForCertificateRoute: boolean = false) {
    return new Promise(resolve => {
      if (isEligibleForCertificateRoute && birthdayOfferDetails
        && birthdayOfferDetails?.is_eligible_for_birth_day_offer === 0
        && birthdayOfferDetails?.isBirthdayOfferClaimed === 1) {
        this.applicationDialogService.alertDialog(
          ApplicationDialogConstant.modalMessage.birthdayOfferAlreadyClaimedDailog, ApplicationDialogConstant.alertPopupSize.MEDIUM);
      } else if (isEligibleForCertificateRoute && birthdayOfferDetails
        && birthdayOfferDetails?.is_eligible_for_birth_day_offer === 0
        && birthdayOfferDetails?.isBirthdayOfferExpired === 1) {
        this.applicationDialogService.alertDialog(
          ApplicationDialogConstant.modalMessage.birthdayOfferExpiredDailog, ApplicationDialogConstant.alertPopupSize.MEDIUM);
      }
      if (!isEligibleForCertificateRoute
        && !this.cookieService.get(ApplicationConstant.storageKeys.BIRTHDAY_OFFER_KEY + '_' + this.uid)
        && birthdayOfferDetails && birthdayOfferDetails?.is_eligible_for_birth_day_offer 
        && !birthdayOfferDetails?.is_only_cmi_courses_available) {
         if((birthdayOfferDetails?.offer_details?.valid_days - birthdayOfferDetails?.offer_details?.days_left) <= ApplicationConstant.BIRTHDAY_POPUP_LIMIT) {
          this.cookieService.set(ApplicationConstant.storageKeys.BIRTHDAY_OFFER_KEY + '_' + this.uid,
          'shown', 1);
         } else {
          this.cookieService.set(ApplicationConstant.storageKeys.BIRTHDAY_OFFER_KEY + '_' + this.uid,
          'shown', birthdayOfferDetails?.offer_details?.days_left);
         }
        
        const matDialogRef = this.matDialog.open(BirthdayOfferComponent, {
          width: '700px',
          height: '500px',
          panelClass: 'ath-panel-birthday',
          data: {
            ...birthdayOfferDetails,
          }
        });

        matDialogRef.afterClosed().subscribe(value => {
          resolve(!!value);
        });
      }
    });
  }

  /**
   * Get Image Path
   * @param url Values
   */
  getImagePath(url: string) {
    return url ? url : 'assets/course-default.png';
  }

  /**
   * Save the UTM details from the url.
   *
   * @param queryParam is the param data from the url.
   * @param courseId is the id for the course.
   */
  getAndSaveUTMDetails(queryParam: Params, courseId: number) {

    const payload = {
      [ApplicationConstant.formMetaFields.utmCampaign]: queryParam?.[ApplicationConstant.formMetaFields.utmCampaign],
      [ApplicationConstant.formMetaFields.utmMedium]: queryParam?.[ApplicationConstant.formMetaFields.utmMedium],
      [ApplicationConstant.formMetaFields.utmSource]: queryParam?.[ApplicationConstant.formMetaFields.utmSource],
      [ApplicationConstant.formMetaFields.utmPage]: queryParam?.[ApplicationConstant.formMetaFields.utmPage],
      [ApplicationConstant.formMetaFields.utmPosition]: queryParam?.[ApplicationConstant.formMetaFields.utmPosition],
      [ApplicationConstant.formMetaFields.utmBusinessUnit]: queryParam?.[ApplicationConstant.formMetaFields.utmBusinessUnit],
      [ApplicationConstant.formMetaFields.sharedUId]: queryParam?.[ApplicationConstant.formMetaFields.sharedUId],
      [ApplicationConstant.formMetaFields.courseDetailId]: courseId,
      [ApplicationConstant.formMetaFields.paymentUTM]: queryParam?.[ApplicationConstant.formMetaFields.paymentUTM]
    };

    if (queryParam?.[ApplicationConstant.formMetaFields.utmCampaign] &&
      queryParam?.[ApplicationConstant.formMetaFields.utmMedium] &&
      queryParam?.[ApplicationConstant.formMetaFields.utmSource] &&
      queryParam?.[ApplicationConstant.formMetaFields.utmPage]) {
      this.baseService.post(EndpointConstant.UTM_DETAILS_SAVE, payload).subscribe(() => {
      }, error => {
        console.error('Error', error);
      })
    }
  }

  /**
   * Trim empty spaces in a string or numbers
   */
  trimEmptySpaces(value: any) {
    return value.replace(/\s/g, '');
  }

  /**
   * capture logs for FAQ label
   * @param faqType 
   * @param courseId 
   * @returns 
   */
  captureFaqEventLogs(faqType: any, courseId?: string) {
    if(!faqType) {
      return;
    }
    switch(faqType) {
      case 'Certification': this.saveStudentClickEventLog(this.eventLogsEnum.faqCertification, courseId);
      break;
      case 'Courses & Enrollment': this.saveStudentClickEventLog(this.eventLogsEnum.faqCoursesEnrollment, courseId);
      break;
      case 'Learning': this.saveStudentClickEventLog(this.eventLogsEnum.faqLearning, courseId);
      break;
    }
   }

  /**
   * Check for Popup condition
   * @param userData 
   */
  showProfileUpdateDialog(userData: any) {
    if(userData && (userData?.work_experience === 'y')) {
      if(userData?.high_qal_id === null ||
        userData?.grade_id === null || userData?.industry_id === null ||
        userData?.months_exp === null || userData?.years_exp === null || userData?.current_designation === null) {
         return true;
       }
    } else if(userData && (userData?.work_experience === 'n')) {
      if(userData?.high_qal_id === null) {
         return true;
       }
    }
    return false;
  }

  getCertificationTypes() {
    return this.baseService.get(EndpointConstant.CERTIFICATION_TYPES)
      .toPromise()
      .then(response => response)
      .catch();
  }

   /**
   * Checking if image exist in the content and also content length
   * @param content - html content as string
   */
  checkForMoreContent(content: string) {
    const regex = new RegExp('(<figure)[^/>]*(/>|>)');
    const imgRegex = new RegExp(/<img/g);
    return regex.test(content) || content.match(imgRegex);
  }

  /**
   * Round off course rating, ex - 4.345 -> 4.3
   * @param rating - course rating
   */
  roundOffCourseRating(rating: number) {
    if (rating) {
      rating = Math.round(+rating * 10) / 10;
      return rating;
    }
  }
  
   /**
   * course info
   * @param courseData 
   */
  offerClaimOrWithoutClaimPopup(offerDetails: any) {
    this.matDialog.open(PersonalizedOfferPopupComponent, {
      width: '493px',
      panelClass: 'personalized-offer-panel-dialog',
      data: {
        offerDetails: offerDetails
      }
    });
  }
  
  /**
   * Open Countdown Dialog Component
   * @param cid course id
   * @param lessonId lesson ID
   */
  showCountdownDialog(cid: string, lessonId?: string, isCMICourse?: boolean) { 
   const dialogRef = this.matDialog.open(CountDownDialogComponent, {
      disableClose: true,
      width: '615px',
      maxHeight: '1290px',
      panelClass: 'ath-panel-count-down-dialog',
      data: {
        courseId: cid,
        lessonId: lessonId,
        isCMICourse: isCMICourse
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result && lessonId && !this.isLessonPopupShowedTwice) {
        this.isLessonPopupShowedTwice = true;
         setTimeout(() => {
          if(this.currentUrl && this.currentUrl.indexOf(RoutingConstant.COURSE_DETAILS) > -1) {
            this.showCountdownDialog(cid, lessonId);
          }
        }, 30000);
      }
    });
  }

  /**
   * Clear local storage on logout
   */
  clearLocalStorageOnLogout() {
    const keysObject = ApplicationConstant.storageKeys;

    for (const storedKey in keysObject) {
      localStorage.removeItem(keysObject[storedKey]);
    }
  }

  /**
   * Check whether User id is even or odd (hiding coachmark for userids with odd number)
   */
  isUserIdEven() {
    return !(this.getUId() % 2);
  }

}
