import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

export const ApplicationConstant = {

  urlMapping: [
    { url: [ '/', '/short-course-sign-in' ], metaTagKey: 'login'}
  ],

  metaTags: {
    login: {
      title: 'Short Courses, Free Learning',
      description: 'Ranging from 5 hours to 90 hours, a plethora of short courses to help you acquire specialised '
        + 'knowledge and skills. Enroll to the course, free of cost and complete at your own pace. Once completed, '
        + 'claim a blockchain verified certificate at the end of the course'
    }
  },

  HARD_COPY_CERTIFICATE: 'Certification Fee - Hard Copy',

  maxFileSize: 5, // MB

  maxPassingYear: 45,

  maxFile: 5,

  maxYearExperience: 45,

  currentWorking: [{ name: 'Yes', value: 'y' }, { name: 'No', value: 'n' }],
  alarmValuesTypes : [
    {name: 'Hours', value: 'Hours'},
    {name: 'Days', value: 'Days'},
    {name: 'Week', value: 'Week'}
   ],

  months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],

  studentAutoLoginUrls: [
    'student-dashboard',
    'student-dashboard/course/:courseId/:courseName',
    'student-dashboard/view-as-learner/:courseId/:lessonId',
    'student-dashboard/eligible-for-certification',
    'payment',
    'feedback/:slugName',
    'student-profile'
  ],

  studentLoginPopupUrls: [
    'student-dashboard/course/:courseId/:courseName',
    'student-dashboard/view-as-learner/:courseId/:lessonId'
  ],

  externalPages: {
    COURSES: 'course-home',
    INSIGHT: 'insights/all',
    LOGIN: 'login',
    REGISTRATION: 'registration',
    SHORT_COURSE: 'short-courses',
    VERIFY: 'verify/certificate?certID=',
    TERMS: 'terms',
    TERMS_AND_CONDITIONS: 'terms-and-conditions',
  },

  source: {
    BURSARY: 'BURSARY',
    EXEMPTION: 'EXEMPTION',
    QUICK: 'QUICK_APP',
    COURSE: 'COURSE',
    QUICK_APP_FIRST: 'QUICK_APP_FIRST'
  },

  /**
   * Local/Session storage keys.
   */
  storageKeys: {
    ALARM_KEY: 'alarm_id',
    ASSESSMENT_ID: 'assessment_id',
    AUTH_TOKEN: 'auth_token',
    BIRTHDAY_OFFER_KEY: 'BirthdayOffer',
    CATEGORY_NAMES: 'category_names',
    CHILD_MODULE_ID: 'child_module_id',
    COUNTRY_CODE: 'country_code',
    COURSE_CATEGORIES: 'course_categories',
    COURSE_ID: 'c_id',
    COURSE_MODULE_ID: 'course_module_id',
    DASHBOARD_BANNER_STATS: 'dashboard_banner_stats',
    DISCUSSIONS: 'discussions',
    EARN_CREDIT_REDIRECT_URL: 'earn_credit_redirect_url',
    FIRST_NAME: 'user_name',
    IS_CLAIM_FLOAT_CLOSE: 'is_claim_float_close',
    START_UP_POPUP: 'start_up_popup',
    LOCATION_DETAILS: 'locationDetails',
    LOGIN_PROVIDER: 'login_provider',
    QUIZ_ASSESSMENT_ID: 'quiz_assessment_id',
    QUIZ_ASSESSMENT_DETAILS: 'quiz_assessment_details',
    QUIZ_EXAM_ID: 'quiz_exam_id',
    REGISTERED_COURSES: 'registered_courses',
    U_ID: 'user_id',
    USER: 'user'
  },

  socialLoginProviderId: {
    GOOGLE_PROVIDER_ID: '276645629606-l0lte3d7aag43kmsm8g339rhhrhaev2o.apps.googleusercontent.com',
    FB_PROVIDER_ID: '207887187554142',
    CLIENT_ID: '5a8660a7-2f19-4f72-96a5-9e1980b57add'
  },

  formMetaFields: {
    applicationSource: 'source',
    contactNumber: 'contact_number',
    contactCountryCode: 'country_contact_code',
    courseDetailId: 'course_id',
    courseId: 'cid',
    currentWorking: 'currently_working',
    email: 'mail',
    isBirthdayCouponApplied: 'isBirthdayCouponApplied',
    isRewardPointsApplied: 'is_reward_points_applied',
    isIIPPTOfferApplied: 'is_iippt_offer_applied',
    isShortCourse: 'is_short_course',
    leadSource: 'lead_source',
    offerId: 'offer_id',
    parentId: 'parent_id',
    paymentUTM: 'paymentUTM',
    reVerify: 're_verify',
    sharedUId: 'shared_uid',
    tag: 'tag',
    temp_file: 'is_temp',
    type: 'type',
    uId: 'uid',
    userId: 'user_id', //for auto login email because uid key is used for external uid
    userQualificationDetailId: 'user_qualification_details_id',
    userWorkDetailId: 'user_work_details_id',
    utmCampaign: 'utm_campaign',
    utmMedium: 'utm_medium',
    utmPage: 'utm_page',
    utmPosition: 'utm_pos',
    utmBusinessUnit: 'utm_business_unit',
    utmSource: 'utm_source',
    verifyUser: 'verify_user',
  },

  gender: [{ name: 'Male', value: 'Male' }, { name: 'Female', value: 'Female' }, { name: 'Others', value: 'Others' }],

  phoneNumberConfig: {
    preferredCountries: [],
    enableAutoCountrySelect: false,
    enablePlaceholder: false,
    searchCountryFlag: true,
    searchCountryField: [SearchCountryField.Iso2, SearchCountryField.Name],
    selectFirstCountry: true,
    selectedCountryISO: CountryISO.India,
    tooltipField: TooltipLabel.Name,
    phoneValidation: true,
    separateDialCode: true,
    maxlength: '15'
  },

  progressCircleConfig: {
    radius: 55,
    outerStrokeWidth: 7,
    innerStrokeWidth: 7,
    space: -8,
    showSubtitle: true,
    subtitle: 'completed',
    outerStrokeColor: '#ff006e',
    innerStrokeColor: '#ffa2ca',
    titleFontSize: '28',
    unitsFontSize: '28',
    subtitleFontSize: '14',
    titleFontWeight: '600',
    subtitleFontWeight: '600',
    animationDuration: 300,
    lazy: false
  },

  spinWheelConfig: {
    spinWheelWidthAndHeight: 0,
    innerRadius: 0,
    spinDuration: 8,
    spinAmount: 10,
    pointerStrokeColor: 'transparent',
    pointerFillColor: 'transparent',
    innerStokeFillColor: 'transparent',
    disableSpinOnClick: true,
    spinWheelColors: ['#7BB342', '#D71B5F', '#8E23AA', '#00BEFA', '#FA8B00', '#3948AB'],
    textFillStyle: '#ffff',
    textFontSize: 0,
    strokeStyle: '#ffff'
  },

  uploadFileKeys: {
    photoUpload: {
      key: 'image',
      deleteKey: 'id',
      type: 'user_photo',
      name: 'user_photo',
      defaultTextValue: 'photo',
      extension: ['.jpg', '.jpeg', '.png']
    },
    photoIdUpload: {
      key: 'image',
      deleteKey: 'id',
      type: 'user_passport',
      name: 'user_passport',
      defaultTextValue: 'passport_Id',
      extension: ['.jpg', '.jpeg', '.docx', '.pdf', '.png', '.doc']
    },
    photoVerificationUpload: {
      key: 'image',
      deleteKey: 'id',
      type: 'user_photo',
      name: 'user_photo_verification',
      defaultTextValue: 'photo_verification',
      extension: ['.jpg', '.jpeg', '.png', '.svg']
    },
    certificateUpload: {
      key: 'image',
      deleteKey: 'id',
      type: 'user_edu_certificate',
      text: 'certificate_upload_text',
      name: 'education_certificate',
      defaultTextValue: 'Certificate',
      extension: ['.jpg', '.jpeg', '.docx', '.pdf', '.png', '.doc']
    },
    additionalDocUpload: {
      key: 'image',
      deleteKey: 'id',
      type: 'user_additional_doc',
      text: 'additional_doc_upload_text',
      name: 'additional_certificate',
      defaultTextValue: 'Additional_certificate',
      extension: ['.jpg', '.jpeg', '.docx', '.pdf', '.png', '.doc']
    },
    experienceUpload: {
      key: 'image',
      deleteKey: 'id',
      type: 'user_work',
      text: 'experience_upload_text',
      name: 'work_experience',
      defaultTextValue: 'Experience Certificate',
      extension: ['.jpg', '.jpeg', '.docx', '.pdf', '.png', '.doc']
    }
  },

  validatorPatterns: {
    email: '[a-zA-Z0-9-_.]{1,}@(?!.*--)[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}'
  },

  starRatingConfig: {
    max: 5,
    min: 1,
    default: 4,
    readonly: true
  },
  socialIconRedirection: {
    facebookLink: 'https://www.facebook.com/uniathena',
    instagramLink: 'https://www.instagram.com/uni_athena/',
    linkedinLink: 'https://www.linkedin.com/school/athenaglobaleducation/',
    twitterLink: 'https://twitter.com/AthenaGlobalEdu/',
    youtubeLink: 'https://www.youtube.com/channel/UCMNksRt7eJOypN3qWaNwL_g/featured'
  },

  NONE_PARTNER_BODY_ID: 6,
  DIGITAL_CERTIFICATE_TYPE_ID: 6,
  HARD_COPY_TYPE_ID: 14,
  REGISTRATION_FEE_TYPE_ID: 1,
  CMI_CERTIFICATION_FEE_TYPE_ID: 8,
  CMI_MODULE_FEE_TYPE_ID: 15,
  ASSESSMENT_FEE_TYPE_ID: 4,

  DEFAULT_COUNTRY: {
    countryId : '99',
    countryCode : 'IND',
    countryContactCode : '+91',
    countryIsq: 'IN'
  },

  LOGIN_PROVIDERS: {
    PASSWORD: 'password',
    GOOGLE: 'google',
    MICROSOFT: 'microsoft',
    FACEBOOK: 'facebook'
  },

  downloadResourseType: {
    referenceMaterial :'referenceMaterial',
    offlineReadingDownloads :'offlineReadingDownloads',
    caseStudyDownloads: 'caseStudyDownloads',
    offlineReadingMails : 'offlineReadingMails'
  },

  userReviewYesNo: [{ name: 'Yes', value: 'yes' }, { name: 'No', value: 'no' }],

  COUPON_CODE: {
    BIRTHDAY80: 'Birthday80',
    BIRTHDAY50: 'Birthday50',
    UA_REWARDS: 'UA Rewards'
  },

  permissionName: {
    SCDiscussionBoardModerator: 'SCDiscusionBoard.SCModerator'
  },

  COACH_MARK_IDS: {
    STUDENT_DASHBOARD: 1,
    COURSE_DETAIL: 2,
    LESSON_DETAIL: 3
  },

  COACH_MARK_STEPS: {
    STUDENT_DASHBOARD: ['studentDashboardOne', 'studentDashboardTwo', 'studentDashboardThree'],
    COURSE_DETAIL: ['courseDetailOne', 'courseDetailTwo', 'courseDetailFour', 'courseDetailFive', 'courseDetailSix'],
    LESSON_DETAIL: ['lessonDetailOne', 'lessonDetailTwo', 'lessonDetailThree', 'lessonDetailFour', 'lessonDetailFive', 'lessonDetailSix'],
  },

  DEVICE_SIZE: {
    minMedium: 481,
    maxMedium: 768,
    maxIpad: 993,
    minDesktop: 1025
  },
  COURSE_LISTING_UTM_DETAILS: {
    utm_source: 'LMS',
    utm_medium: 'LMS',
    utm_campaign: 'listing',
    utm_page: 'NA',
    utm_pos: 'NA'
  },
  COURSE_RECOMM_UTM_DETAILS: {
    utm_source: 'LMS',
    utm_medium: 'LMS',
    utm_campaign: 'COURSERECOMM',
    utm_page: 'NA',
    utm_pos: 'NA'
  },
  COURSE_RECOMM_CDP_UTM_DETAILS: {
    utm_source: 'LMS',
    utm_medium: 'LMS',
    utm_campaign: 'COURSERECOMM-CDP',
    utm_page: 'NA',
    utm_pos: 'NA'
  },
  COURSE_RECOMM_TOP_RECOMM_UTM_DETAILS: {
    utm_source: 'LMS',
    utm_medium: 'LMS',
    utm_campaign: 'COURSERECOMM-DSS',
    utm_page: 'NA',
    utm_pos: 'NA'
  },
  ACADEMIC_LEARN_MORE_UTM_DETAILS: {
    utm_source: 'SC',
    utm_medium: 'LMS',
    utm_campaign: 'ListingSCLMS',
    utm_page: 'login',
    utm_pos: 'NA'
  },
  COURSE_RECOMM_REGISTER_UTM_DETAILS: {
    utm_source: 'LMS', 
    utm_medium: 'LMS', 
    utm_campaign: 'REGISTERLIST', 
    utm_page: 'NA', 
    utm_pos: 'NA'
  },
  COURSE_RECOMM_ECP_UTM_DETAILS: {
    utm_source: 'LMS',
    utm_medium: 'LMS',
    utm_campaign: 'COURSERECOMM-ECP',
    utm_page: 'NA',
    utm_pos: 'NA'
  },
  COURSE_RECOMM_CIP_UTM_DETAILS: {
    utm_source: 'LMS',
    utm_medium: 'LMS',
    utm_campaign: 'COURSERECOMM-CIP',
    utm_page: 'NA',
    utm_pos: 'NA'
  },
  COURSE_RECOMM_WHAT_NEXT_UTM_DETAILS: {
    utm_source: 'LMS',
    utm_medium: 'LMS',
    utm_campaign: 'COURSERECOMM-CHP',
    utm_page: 'NA',
    utm_pos: 'NA'
  },
  COURSE_RECOMM_INTERMEDIATE_UTM_DETAILS: {
    utm_source: 'LMS',
    utm_medium: 'LMS',
    utm_campaign: 'COURSERECOMM-IM',
    utm_page: 'NA',
    utm_pos: 'NA'
  },

  CERT_TYPE_NEWLY_RELEASED: 'newly_released',
  CERT_TYPE_TRENDING: 'popular',
  CERT_TYPE_PREMIUM: 'premium',

  BIRTHDAY_POPUP_LIMIT: 7,
  UA_REWARD_POINT : 10,
  UA_REWARD_NAME: 'UA Rewards',
  UA_SPECIAL_OFFER: 'UA_SPECIAL_OFFER',
  IIPPT_OFFER_COUPON_CODE: 'IIPPT100',

  DEFAULT_USER_CLAIMED_PERCENTAGE: 80,
  DEFAULT_USER_RECOMMEND_PERCENTAGE: 90,

  // milliseconds for 4 and half minutes
  timeIntervalActivityConfig: 270000,

  // milliseconds for 2 minutes
  pushNotificationTimeIntervalActivityConfig: 120000,

  E_LIBRARY_LINK: 'https://wferp.westfordlms.com/proquest.html',

  META_TITLE: 'Short Courses | UniAthena',

  MAX_CARD_TO_DISPLAY: 6,

  INPUT_TYPES: {
    radio: 'radio',
    textarea: 'textarea'
  },

  CERTIFICATION_TYPE_NEWLY_RELEASED: 'Newly Released',
  CERTIFICATION_TYPE_NEWLY_RELEASED_FIELD: 'newly_released',
  CERTIFICATION_TYPE_POPULAR: 'popular',
  CERTIFICATION_TYPE_RECOMMENDATION: 'Recommended',
  CERTIFICATION_TYPE_RECOMMENDATION_FIELD: 'recommended',

  OFFER_TYPES: {
    OFFER_BIRTHDAY: 'birthday',
    OFFER_IIPPT: 'iippt',
    OFFER_COURSE: 'course'
  },

  SET_CSS_PROPERTY: {
    MAX_WIDTH: 460,
    MIN_WIDTH: 272,
    MAX_RADIUS: 40,
    MIN_RADIUS: 20,
    MAX_FONT_SIZE: 22,
    MIN_FONT_SIZE: 10
  },
  
  RE_MARKETING: '-rm-',

  CERT_CLAIM_OR_LESSON_PAGE_DELAY: 15000,
  
  CERTIFICATION_PAYMENT_CMI: 'CMI',


  CURRENCY_IDS: {
    US_CURRENCY_ID: 1,
    INR_CURRENCY_ID: 2
  },

  CURRENCY_CODE: {
    US: 'USD',
    INR: 'INR'
  },
  degreeCoursesCertificateTypeName: 'Degree Courses'
};
