<div *ngIf="eligibleCourseInfo && eligibleCourseInfo?.length > 0" class="cart-certificate">
    <div *ngFor="let courseInfo of eligibleCourseInfo" class="cert-flex">
        <div *ngIf="courseInfo && courseInfo?.sample_certificate_path">
            <img [src]="courseInfo?.sample_certificate_path"/>
        </div>
        <div>
            <p class="c-name">{{courseInfo?.course_name}}</p>
            <ng-container *ngIf="courseInfo && courseInfo?.showOfferDetails">
                <p class="c-offer">{{ courseInfo?.is_premium === 1 && !courseInfo?.free_trial ? '' : 'Upto' }} 
                    {{courseInfo?.offerDetails[0]?.discount_percentage}}% Discount</p>
            </ng-container>
        </div>
        <div>
            <p class="off-price"><span [innerHTML]="courseInfo?.currency_symbol"></span>{{ courseInfo?.is_paid === 0 ? courseInfo?.courseFee : '0' }}</p>
        </div>
        <div class="mob-align">
            <button (click)="navigateToCertGenOrPaymentPage(courseInfo)" class="claim-btn">Claim Now</button>
        </div>
    </div>
</div>