import { Component, OnDestroy, OnInit } from '@angular/core';
import { StudentDashboardService } from 'src/app/student-dashboard/student-dashboard.service';
import { AppConfig } from '../../config/app.config';
import { EventLogsEnum } from 'src/app/student-dashboard/enum/event-logs-enum';
import { Router } from '@angular/router';
import { RoutingConstant } from '../../constant/routing.constant';
import { fadeIn, fadeOut } from '../../utils/animations';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { StudentProgress } from 'src/app/model/student-progress.model';
import { StudentProgressState } from 'src/app/state/student-dashboard.state';
import { Select } from '@ngxs/store';

@Component({
  selector: 'app-claim-certificate-float',
  templateUrl: './claim-certificate-float.component.html',
  styleUrls: ['./claim-certificate-float.component.scss'],
  animations: [
    fadeIn,
    fadeOut
  ]
})
export class ClaimCertificateFloatComponent implements OnInit, OnDestroy {

  appConfig = AppConfig;
  isFloatEnable = false;
  destroySubscription: Subject<void> = new Subject<void>();
  studentProgress: any;
  @Select(StudentProgressState.getProgressInfo) progressInfo: Observable<StudentProgress>;

  constructor(private commonService: CommonService,
              private studentDashboardService: StudentDashboardService,
              private router: Router) {}

  ngOnInit(): void {
    this.isFloatEnable = this.studentDashboardService.isClaimFloatClose;
    if (this.isFloatEnable) {
      this.getStudentProgress();
    }
  }

  /**
   * Get the student progress.
   */
  getStudentProgress() {
    this.progressInfo
        .pipe(takeUntil(this.destroySubscription))
        .subscribe(res => {
          this.studentProgress = {...res};
          this.studentProgress[this.appConfig.formDetails.rewardPointsCount.key] = Math.floor(this.studentProgress[this.appConfig.formDetails.rewardPointsCount.key]);
        });
  }

  /**
   * Navigate to eligible for certificate page.
   */
   eligibleForCertification() {
    this.studentDashboardService.saveStudentClickEventLog(EventLogsEnum.dashboardClaimNow);
    this.router.navigateByUrl(RoutingConstant.ELIGIBLE_FOR_CLAIM_CERTIFICATE);
  }

  /**
   * Close webinar popup
   */
   closeClaimFloat() {
    this.commonService.saveStudentClickEventLog(EventLogsEnum.notificationCloseButton);
    this.isFloatEnable = false;
    this.studentDashboardService.isClaimFloatClose = true;
  }

  ngOnDestroy(): void {
    this.destroySubscription.next();
    this.destroySubscription.complete();
  }

}
