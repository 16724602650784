import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormService } from 'src/app/services/form.service';
import { ApplicationNotifierService } from 'src/app/shared/component/application-notifier/application-notifier.service';
import { ApplicationNotifierConstant } from 'src/app/shared/constant/notifier.constant';
import { ForgotPasswordDialogComponent } from 'src/app/forgot-password/dialog/forgot-password-dialog/forgot-password-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EndpointConstant } from '../../constant/endpoint.constant';
import { ConfirmedValidator, NewPasswordValidator } from '../../validator/confirmed.validator';
import { AppConfig } from '../../config/app.config';
import { ApplicationConstant } from '../../constant/app.constant';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
})
export class ChangePasswordDialogComponent implements OnInit, OnDestroy {

  appConfig = AppConfig;
  changePasswordForm: FormGroup;
  userDetails: any;
  destroySubscription: Subject<void> = new Subject<void>();

  constructor(private applicationNotifierService: ApplicationNotifierService,
              private formBuilder: FormBuilder,
              private formService: FormService,
              private matDialog: MatDialog,
              @Optional() private matDialogRef: MatDialogRef<ChangePasswordDialogComponent>) {
  }

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      [this.appConfig.formDetails.oldPassword.key]: ['', this.appConfig.formDetails.oldPassword.validation],
      [this.appConfig.formDetails.newPassword.key]: ['', this.appConfig.formDetails.newPassword.validation],
      [this.appConfig.formDetails.confirmPassword.key]: ['', this.appConfig.formDetails.confirmPassword.validation],
    }, {
      validator: [ConfirmedValidator(this.appConfig.formDetails.newPassword.key,
        this.appConfig.formDetails.confirmPassword.key),
      NewPasswordValidator(this.appConfig.formDetails.oldPassword.key,
          this.appConfig.formDetails.newPassword.key)]
    });
    this.userDetails = JSON.parse(localStorage.getItem(ApplicationConstant.storageKeys.USER));
  }

  /**
   * Getter for form control
   */
  get formControl() {
    return this.changePasswordForm.controls;
  }

  /**
   * Update password of the user.
   */
  updatePassword() {
    this.changePasswordForm.markAllAsTouched();
    if (this.changePasswordForm.valid) {
      this.formService.saveDetails(EndpointConstant.PASSWORD_CHANGE, this.changePasswordForm.value)
          .pipe(takeUntil(this.destroySubscription))
          .subscribe(() => {
              this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.password_updated);
              this.matDialogRef.close();
            }, (error) => {
              console.error('Error', error);
              if (error?.error?.message) {
                this.applicationNotifierService.getErrorNotifier(error?.error?.message);
              } else {
                this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.error_message);
              }
            });
    }
  }

  /**
   * Forget Password Dialog.
   */
   forgetPasswordDialog() {
    this.matDialogRef.close();
    this.matDialog.open(ForgotPasswordDialogComponent,
      {
        panelClass: 'myapp-no-padding-dialog',
        disableClose: true,
        data: {
        isProfilePage: true,
        mail: this.userDetails && this.userDetails?.mail
       }
      });
  }

  ngOnDestroy(): void {
    this.destroySubscription.next();
    this.destroySubscription.complete();
  }
}
