import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ApplicationConstant } from '../shared/constant/app.constant';
import { RoutingConstant } from '../shared/constant/routing.constant';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { HeaderService } from './header.service';
import { AuthenticateService } from '../services/authenticate.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, OnChanges {

  @Input() currentURL: string;
  applicationConstant = ApplicationConstant;
  showPlainHeader = false;
  showAcademicHeader = false;
  showShortCourseHeader = false;
  isCoachMarkAvailable = false;
  headerHidden = false;
  headerConfig;

  constructor(private headerService: HeaderService,
              private commonService: CommonService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private authenticateService: AuthenticateService) {
    this.headerConfig = this.setHeaderConfig();
  }

  ngOnInit(): void {
    this.getQuotesList();
    this.updateHeaderSection();
    this.getQueryParameters();
    this.hideHeaderSection();
  }

  ngOnChanges() {
    if (this.currentURL) {
      this.headerConfig = this.setHeaderConfig();
      this.setHeaderContent(this.currentURL);
    }
  }

  /**
   * Get the Query parameters.
   */
  private getQueryParameters() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params?.push_notification == 1) {
        this.headerService.savePushNotifyLog().subscribe(() => {
          this.router.navigate([], 
            {
              queryParams: { push_notification: null },
              queryParamsHandling: 'merge'
            });
        }, error => {
            console.error('Error', error);
        });
      }
    });
  }

  /**
   * Set the header configuration.
   */
  setHeaderConfig() {
    const headerConfigDetails = {
      academicHeader: [
        RoutingConstant.FAQ,
        RoutingConstant.PAYMENT,
        RoutingConstant.QUIZ_ASSESSMENT,
        RoutingConstant.STUDENT_DASHBOARD,
        RoutingConstant.STUDENT_PROFILE,
        RoutingConstant.COURSE_DETAILS,
        RoutingConstant.REWARD_POINTS,
        RoutingConstant.FEEDBACK_FORM,
        RoutingConstant.GENERIC_FORM,
        RoutingConstant.COURSE_RECOMMENDATION,
        RoutingConstant.SUBSCRIPTIONS,
        RoutingConstant.PUSH_NOTIFICATION,
        RoutingConstant.EARN_CREDITS,
        RoutingConstant.PERSONALIZED_OFFERS
      ],
      shortCourseHeader: [
        RoutingConstant.DEFAULT,
        RoutingConstant.DOWNLOAD_PDF,
        RoutingConstant.SHORT_COURSE_LOGIN_URL,
        RoutingConstant.SHORT_COURSE_REGISTER_URL
      ]
    };
    if (this.authenticateService.isTokenAvailable()) {
      headerConfigDetails.academicHeader.push(RoutingConstant.CONTACT_US);
    } else {
      headerConfigDetails.shortCourseHeader.push(RoutingConstant.CONTACT_US);
    }
    return headerConfigDetails;
  }

  /**
   * Show header for the application.
   *
   * @param showPlainHeader true/false
   * @param showAcademicHeader true/false
   * @param showShortCourseHeader true/false
   */
  setHeaders(showPlainHeader: boolean, showAcademicHeader: boolean, showShortCourseHeader: boolean) {
    this.showPlainHeader = showPlainHeader;
    this.showAcademicHeader = showAcademicHeader;
    this.showShortCourseHeader = showShortCourseHeader;
  }

  /**
   * Set the header content.
   *
   * @param url request url
   */
  setHeaderContent(url: string) {
    this.currentURL = url;
    this.commonService.currentUrl = url;
    if (this.headerConfig.academicHeader.some(value => url.indexOf(value) > -1)) {
      this.setHeaders(false, true, false);
    } else if (this.headerConfig.shortCourseHeader.some(value => url.indexOf(value) > -1)) {
      this.setHeaders(false, false, true);
    } else {
      this.setHeaders(true, false, false);
    }

    this.hideHeaderSection();
  }

  /**
   * Get quotes list
   */
  getQuotesList() {
    this.headerService.getLoaderQuotes()
        .subscribe(response => {
          this.headerService.quotesList = [...response?.data];
        })
  }

  /**
   * update position dynamically for header
   */
  private updateHeaderSection() {
    this.headerService.toggleHeaderPosition
        .subscribe(res => {
          this.isCoachMarkAvailable = res;
        },error => {
          console.error('error', error);
        });
  }

  /**
   * Hide header section for lesson page for mobile and tab view
   */
  private hideHeaderSection() {
    const isMediumSizeDevice = window.screen.width < ApplicationConstant.DEVICE_SIZE.maxIpad;
    this.headerHidden = this.router.url.includes(RoutingConstant.VIEW_AS_LEARNER_PAGE) && isMediumSizeDevice;
  }

}
