/**
 * Enum for course fee types.
 */
export enum CourseFeeTypes { 

    SUBSCRIPTION_FEE = 'subscription',
    DIGITAL_CERT_FEE = 'digital',
    HARD_COPY_FEE = 'hardcopy',
    REGISTRATION_FEE = 'register',
    MODULE_FEE = 'module',
    CMI_CERTIFICATION_FEE = 'cmiCertificate',
    ASSESSMENT_FEE = 'assessment',
}