import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss']
})
export class ForgotPasswordDialogComponent {

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
              @Optional() public dialogRef: MatDialogRef<ForgotPasswordDialogComponent>) {
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
