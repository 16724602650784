import { Component, Input, OnInit } from '@angular/core';
import { ApplicationConstant } from '../../shared/constant/app.constant';
import { Router } from '@angular/router';
import { AuthenticateService } from '../../services/authenticate.service';
import { RoutingConstant } from '../../shared/constant/routing.constant';

@Component({
  selector: 'app-plain-header',
  templateUrl: './plain-header.component.html',
  styleUrls: ['./plain-header.component.scss']
})
export class PlainHeaderComponent implements OnInit {

  @Input() currentUrl: string;
  applicationConstant = ApplicationConstant;
  mobileViewMenu = false;

  constructor(private authenticateService: AuthenticateService,
              private router: Router) {
  }

  ngOnInit(): void {
  }


  /**
   * Redirect to the home.
   */
  redirectToHome() {
    if (window.innerWidth <= 768) {
      this.toggleMenu(true);
    }
    if (this.authenticateService.isTokenAvailable()) {
      this.router.navigateByUrl(RoutingConstant.STUDENT_DASHBOARD);
    }
  }

  /**
   * Toggle the menu for show a/hide.
   *
   * @param toggleState true/false.
   */
  toggleMenu(toggleState: boolean) {
    this.mobileViewMenu = toggleState;
  }
}
