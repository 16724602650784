import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from '../services/common.service';
import { ApplicationNotifierService } from '../shared/component/application-notifier/application-notifier.service';
import { AppConfig } from '../shared/config/app.config';
import { ApplicationConstant } from '../shared/constant/app.constant';
import { ApplicationNotifierConstant } from '../shared/constant/notifier.constant';
import { AppUtil } from '../shared/utils/app.util';
import { StudentDashboardService } from '../student-dashboard/student-dashboard.service';
import {EndpointConstant} from "../shared/constant/endpoint.constant";
import { Title } from '@angular/platform-browser';
import { AuthenticateService } from '../services/authenticate.service';
import { HeaderService } from '../header/header.service';
import { RoutingConstant } from '../shared/constant/routing.constant';
import { Router } from '@angular/router';
import { APP_URL } from '../../environments/environment';

const FORM_PARTNERSHIP_TEMPLATE_ID = 2;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit, OnDestroy {

  appConfig = AppConfig;
  applicationNotifierConstant = ApplicationNotifierConstant;
  applicationConstant = ApplicationConstant;
  countryList: any;
  selectedCountry: any;
  userData: any;
  isPhoneValid = false;
  controlEnabled: boolean = true;
  contactUsForm: FormGroup = this.formBuilder.group({});
  phoneNumberConfig = Object.assign({}, ApplicationConstant.phoneNumberConfig);
  destroySubscription: Subject<void> = new Subject<void>();
  isLoggedIn = false;
  genericDynamicFormUrlSlug: string;
  websiteURL = APP_URL.WEBSITE;

  constructor(private applicationNotifierService: ApplicationNotifierService,
              private commonService: CommonService,
              private formBuilder: FormBuilder,
              private studentDashboardService: StudentDashboardService,
              private authenticateService: AuthenticateService,
              private title: Title,
              private headerService: HeaderService,
              private router: Router) { }

  ngOnInit(): void {
    this.initializeForm();
    this.isLoggedIn = this.authenticateService.isTokenAvailable();
    if (this.authenticateService.isTokenAvailable()) {
      this.getuserData();
    }
    this.getCountryList();
    this.title.setTitle(this.applicationConstant.META_TITLE);
    this.getFormDetails();
  }

  /**
   * Initialize the form data.
  */
  initializeForm() {
      this.contactUsForm = this.formBuilder.group({
          [this.appConfig.formDetails.firstName.key]: ['', this.appConfig.formDetails.firstName.validation],
          [this.appConfig.formDetails.lastName.key]: ['', this.appConfig.formDetails.lastName.validation],
          [this.appConfig.formDetails.emailId.key]: ['', this.appConfig.formDetails.emailId.validation],
          [this.appConfig.formDetails.residenceId.key]: ['', this.appConfig.formDetails.residenceId.validation],
          [this.appConfig.formDetails.mobileNo.key]: ['',   this.appConfig.formDetails.mobileNo.validation],
          [this.appConfig.formDetails.consentReceived.key]: ['', this.appConfig.formDetails.consentReceived.validation],
          [this.appConfig.formDetails.studentRemarks.key]: ['', this.appConfig.formDetails.studentRemarks.validation],
        });
  }

  /**
   * Get all the form control
   */
  get formControl() {
    return this.contactUsForm.controls;
  }

  /**
   * Get form control of contact field
   */
  get contactFormControl() {
    return this.contactUsForm.controls[this.appConfig.formDetails.mobileNo.key];
  }

  /**
    * get user data
  */
  getuserData() {
    this.studentDashboardService.getDetails(EndpointConstant.USER_DETAILS(this.commonService.getUId().toString()))
          .pipe(takeUntil(this.destroySubscription))
          .subscribe(data => {
            this.userData = data;
            this.contactUsForm.patchValue(data);
            if (data[this.appConfig.formDetails.mobileNo.countryISO]) {
              AppUtil.updateFlag(this.phoneNumberConfig,
                data[this.appConfig.formDetails.mobileNo.countryISO]);
            }
          },error => {
            console.error('error', error);
          });
  }

  /**
   * get conutry list
   */
  getCountryList() {
    this.studentDashboardService.getDetails(EndpointConstant.COUNTRY)
        .pipe(takeUntil(this.destroySubscription))
        .subscribe(data => {
          this.countryList = data;
        },error => {
          console.error('error', error);
        });
  }

  /**
   *
   * Update student mobile no country flag.
   */
  updateCountryFlag() {
      const countryId = this.contactUsForm.controls[this.appConfig.formDetails.residenceId.key].value;
      if (countryId && this.countryList && this.countryList.length > 0) {
        this.selectedCountry = this.countryList.find(matchCountry => matchCountry.country_id === countryId);
      }

      if (this.selectedCountry) {
        AppUtil.updateFlag(this.phoneNumberConfig, this.selectedCountry.isq.toLowerCase());
        this.contactUsForm.controls[this.appConfig.formDetails.mobileNo.key].setValue(null);
      }
  }

  /**
   * Trim white space in the contact number
   */
  trimPhoneNumber() {
    if (this.contactFormControl.value) {
      const trimmedContact = this.commonService.trimEmptySpaces(this.contactFormControl.value.number);
      this.contactFormControl.setValue(trimmedContact);
    }
  }

  checkAndUpdatePhoneNumber() {
    if (this.contactUsForm.controls[this.appConfig.formDetails.mobileNo.key].errors?.validatePhoneNumber &&
        this.contactUsForm.status === 'INVALID') {
              this.isPhoneValid = true;
      }else {
        this.isPhoneValid = false;
      }
    }

  /**
   * save form details.
   */
  saveDetails(contactData: any){
    this.contactUsForm.markAllAsTouched();
    if (this.contactUsForm.valid) {
        if (this.authenticateService.isTokenAvailable()) {
          this.updateUserProfile(contactData);
        }
        const formData = Object.assign({}, contactData);
        formData[ this.applicationConstant.formMetaFields.uId]
          = this.commonService.getUId();
        this.studentDashboardService.saveDetails(EndpointConstant.CONTACT_US_DETAILS_SAVE, formData)
          .pipe(takeUntil(this.destroySubscription))
          .subscribe( data => {
            if(data.status === 200){
              this.applicationNotifierService.getNotifier(this.applicationNotifierConstant.contact_form_saved);
              this.contactUsForm.get(this.appConfig.formDetails.studentRemarks.key).reset();
            }
            }, error => {
              console.error('error', error);
              this.applicationNotifierService.getNotifier(this.applicationNotifierConstant.error_message);
            });
      }
  }

  /**
   * upddate user profile.
   */
  updateUserProfile(formData: any) {
    const payload = {
      [this.appConfig.formDetails.mobileNo.key] : this.commonService.trimEmptySpaces(formData[this.appConfig.formDetails.mobileNo.key].number),
      [this.appConfig.formDetails.mobileNo.countryCode]: formData[this.appConfig.formDetails.mobileNo.key].dialCode,
      [this.appConfig.formDetails.mobileNo.countryISO]: formData[this.appConfig.formDetails.mobileNo.key].countryCode.toLowerCase(),
      [this.appConfig.formDetails.residenceId.key]: formData[this.appConfig.formDetails.residenceId.key],
      [ this.applicationConstant.formMetaFields.uId]: this.commonService.getUId()
    }
    this.studentDashboardService.saveDetails(EndpointConstant.USER_DETAILS_SAVE(this.commonService.getUId().toString()), payload)
        .pipe(takeUntil(this.destroySubscription))
        .subscribe( data =>{
          this.headerService.dispatchGetUserDetails();
        }, error => {
          console.error('error', error);
          this.applicationNotifierService.getNotifier(this.applicationNotifierConstant.error_message);
        });
  }

  /**
   * get all active form list
   */
  private getFormDetails() {
    this.studentDashboardService.getDetails(EndpointConstant.FEEDBACK_FORMS_GET)
      .pipe(takeUntil(this.destroySubscription))
      .subscribe(resp => {
        const activeFromDetails = resp?.data?.find(item => item?.template_id === FORM_PARTNERSHIP_TEMPLATE_ID && item?.is_active);
        this.genericDynamicFormUrlSlug = activeFromDetails?.url_slug;
      }, error => {
        console.error('error', error);
    });
  }

  /**
   * navigate to partnership form page
   */
  navigateToPartnerShipForm() {
    this.router.navigateByUrl(RoutingConstant.GENERIC_FORM_WITH_SLUG(this.genericDynamicFormUrlSlug))
  }

  ngOnDestroy(): void {
    this.destroySubscription.next();
    this.destroySubscription.complete();
  }

}
