import { Component, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CommonService } from './services/common.service';
import { Meta } from '@angular/platform-browser';
import { ApplicationConstant } from './shared/constant/app.constant';
import { RoutingConstant } from './shared/constant/routing.constant';
import { HeaderService } from './header/header.service';
import { FormService } from './services/form.service';
import { APP_URL } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'athena-frontend';
  currentURL: string;
  isFooterEnabled = true;
  isCoachMarkAvailable = false;
  isHeaderDisabled = false;
  isMediumSizeDevice = window.screen.width < ApplicationConstant.DEVICE_SIZE.maxIpad;

  constructor(private commonService: CommonService,
              private headerService: HeaderService,
              private meta: Meta,
              private router: Router,
              private renderer: Renderer2,
              private formService: FormService) {
  }

  ngOnInit() {
    this.getCountryCode();
    this.setFooterVisibility();
    this.updateWrapSection();
  }

  /**
   * Footer enable and disable
   */
  private setFooterVisibility() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentURL = event.url;
        this.setMetaTags(event.url);
        this.commonService.currentUrl = event.url;
        if(event.url.indexOf(RoutingConstant.VIEW_AS_LEARNER_PAGE) > -1 || 
          event.url.indexOf(RoutingConstant.QUIZ_ASSESSMENT_QUESTION) > -1 ||
          event.url.indexOf(RoutingConstant.COURSE_RECOMMENDATION) > -1) {
          this.isFooterEnabled = false;
        } else {
          this.isFooterEnabled = true;
        }

        this.isHeaderDisabled = this.isMediumSizeDevice && event.url.indexOf(RoutingConstant.VIEW_AS_LEARNER_PAGE) > -1;
        let loader = this.renderer.selectRootElement('#loader');
        this.renderer.setStyle(loader, 'display', 'none');
      }
    });
  }

  /**
   * Set the meta tags for the pages.
   * @param url
   */
  setMetaTags(url: string) {
    const metaInfo: any = ApplicationConstant.urlMapping.find(value => value.url.indexOf(url) > -1 );
    if (metaInfo && metaInfo.metaTagKey) {
      this.meta.updateTag( {
        name: 'title',
        content: ApplicationConstant.metaTags[metaInfo.metaTagKey].title
      });
      this.meta.updateTag(  {
        name: 'description',
        content: ApplicationConstant.metaTags[metaInfo.metaTagKey].description
      });
    } else {
      this.meta.removeTag('name=title');
      this.meta.removeTag('name=description');
    }
  }

  /**
   * update styles dynamically for wrap content
   */
  private updateWrapSection() {
    this.headerService.toggleHeaderPosition
        .subscribe(res => {
          this.isCoachMarkAvailable = res;
        },error => {
          console.error('error', error);
        });
  }

  /**
   * get country details.
   */
  private getCountryCode() {
      this.formService.getDetailsFromThirdParty(`${APP_URL.IP_GEOLOCATION}`, '')
        .then((data: any) => {
          this.commonService.setCountryCode(data?.countryCode);
      });
  }

}
