import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticateService } from '../services/authenticate.service';
import { PushNotificationService } from '../services/push-notification.service';
import { ApplicationConstant } from '../shared/constant/app.constant';
import { RoutingConstant } from '../shared/constant/routing.constant';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authenticateService: AuthenticateService,
              private router: Router,
              private pushNotificationService: PushNotificationService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (ApplicationConstant.studentAutoLoginUrls.includes(next.routeConfig.path)
      && next.queryParams && Object.keys(next.queryParams).length !== 0
      && (next.queryParams.uid || next.queryParams.token || next.queryParams.user_id)) {

      let url = state.url.split('?')[0];
      url = this.addRequiredParams(next.queryParams, url);
      const isTokenValid = this.authenticateService.getTokenAndCheckUserExist(next.queryParams, url,
        next.routeConfig.path, next.params);
      if (isTokenValid) {
        this.pushNotificationService.requestPushNotificationPermission();
      }
      return isTokenValid;
    } else if (this.authenticateService.isTokenAvailable()) {
      this.pushNotificationService.requestPushNotificationPermission();
      return this.authenticateService.checkAdminUserAndRoutePermission(state.url);
    } else if (ApplicationConstant.studentLoginPopupUrls.includes(next.routeConfig.path)) {
      return true;
    } else {
        return this.router.navigate([RoutingConstant.SHORT_COURSE_LOGIN_URL],
          { queryParams: { returnUrl: state.url } });
    }
  }

  /**
   * Adding required query params into URL
   */
  private addRequiredParams(params: Params, url: string) {
    if (params.remind_me_later) {
      url += '?remind_me_later=true';
    }
    if (params.user_from_email) {
      url += '?user_from_email=true';
    }
    if (params.push_notification) {
      url += '?push_notification=1';
    }
    if (params.email_verification_token) {
      url += `?email_verification_token=${params.email_verification_token}`;
    }
    return url;
  }

}
