<div class="alarm-main">
    <div class="alarm-header">
            <img src="assets/webinar/close-btn.png" (click)="closeButton()" class="close-btn pointer">
        <span>I want to resume my course after</span>
    </div>
    <div class="within-next-section">
        <span class="content">Within Next</span>
        <mat-select matInput class="drop-down-type-values mr-3" [(ngModel)]="durationSpan"
            (selectionChange)="calculateAlarmTime($event.value)">
            <mat-option value="none" selected disabled> Select </mat-option>
            <mat-option *ngFor="let option of alarmDetails" [value]="option">
                {{option}}
            </mat-option>
        </mat-select>
        <mat-select matInput class="drop-down-types" [(ngModel)]="durationType"
            (selectionChange)="getAlarmValueOnChange($event.value)">
            <mat-option *ngFor="let option of applicationConstant.alarmValuesTypes; let i = index"
                [value]="option.value">
                {{option.name}}
            </mat-option>
        </mat-select><br/>
        <mat-error class="error-msg" *ngIf="!isValueSelected && durationSpan === 'none'">
            Please select the number of {{durationType | lowercase}}
        </mat-error>
    </div>
    <div class="alram-btns">
        <button (click)="saveAlarm()" class="setup-now-btn">Save</button>
    </div>
    <span (click)="mayBeLater()" class="maybe-later-btn">Maybe Later</span>
</div>