import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EndpointConstant } from 'src/app/shared/constant/endpoint.constant';
import { StudentDashboardService } from 'src/app/student-dashboard/student-dashboard.service';
import { RoutingConstant } from '../../constant/routing.constant';

@Component({
    selector: 'app-birthday-offer',
    templateUrl: './birthday-offer.component.html',
    styleUrls: ['./birthday-offer.component.scss']
})
export class BirthdayOfferComponent {

    routingConstant = RoutingConstant;

    constructor(@Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
                private studentDashboardService: StudentDashboardService) {
    }

    /**
     * Trigger Birthday Offer Email
     */
    sendBirthdayOfferEmail() {
        this.studentDashboardService.getDetails(EndpointConstant.BIRTHDAY_OFFER_SEND_EMAIL).subscribe(() => {
        }, error => {
            console.error('error', error);
        });
    }
}
