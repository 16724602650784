import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { ApplicationConstant } from '../../constant/app.constant';
import { EndpointConstant } from '../../constant/endpoint.constant';
import { FormService } from '../../../services/form.service';
import { AlarmConfirmationComponent } from '../alarm-confirmation/alarm-confirmation.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RoutingConstant } from '../../constant/routing.constant';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from '../../../services/common.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EventLogsEnum } from 'src/app/student-dashboard/enum/event-logs-enum';

@Component({
  selector: 'app-alarm-setup-dialog',
  templateUrl: './alarm-setup-dialog.component.html',
  styleUrls: ['./alarm-setup-dialog.component.scss']
})
export class AlarmSetupDialogComponent implements OnInit, OnDestroy {

  applicationConstant = ApplicationConstant;
  alarmDetails: any;
  dateAndTimeValue: string;
  destroySubscription: Subject<void> = new Subject<void>();
  formatedDateAndTime: string;
  durationType = '';
  durationSpan = 'none';
  isValueSelected = true;
  eventLogsEnum = EventLogsEnum;
  
  constructor(private formService: FormService,
              public matDialog: MatDialog,
              @Optional() private matDialogRef: MatDialogRef<AlarmSetupDialogComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
              public commonService: CommonService,
              private cookieService: CookieService,
              private router: Router) { }

  ngOnInit(): void {
    this.getAlarmValueOnChange(this.applicationConstant.alarmValuesTypes[1].value);
  }

  /**
   * Alarm confirmation popup
   */
  showAlarmConfirmPopup() {
    this.matDialog.open(AlarmConfirmationComponent, {
      width: '460px',
      height: '111px',
      panelClass: 'ath-panel-alarm-confirmation',
      disableClose: true,
      data: {
        courseId: this.dialogData.courseId,
        lessonId: this.dialogData.lessonId,
        stayInLesson: this.dialogData?.stayInLesson,
        navigateEnd: this.dialogData?.navigateEnd,
        slug: this.dialogData?.slug,
        durationType: this.durationType,
        durationSpan: this.durationSpan
      }
     });
  }

  /**
   *
   * @param event
   */
  getAlarmValueOnChange(item: any) {
    this.durationType = item;
    this.durationSpan = 'none';
    let endpoint = EndpointConstant.ALARM_CONFIGURATION(this.durationType);
    this.formService.getDetails(endpoint)
    .pipe(takeUntil(this.destroySubscription))
    .subscribe(resp => {
      this.alarmDetails = resp?.data;
    }, error => {
      console.error('error', error);
    });
  }

  /**
   * calculate alarm time
   * @param event
   */
  calculateAlarmTime(event: any) {
    this.durationSpan = event;
  }

  /**
   * calculate duration
   * @param durationSpan
   */
  calculateDuration(durationSpan: any) {
    if(this.durationType === this.applicationConstant.alarmValuesTypes[0].value) {
      const currentDate = new Date();
      const utcDate = new Date(currentDate.setUTCHours(currentDate.getUTCHours() + Number(durationSpan)));
      this.dateAndTimeValue =  utcDate.toLocaleString('en-GB', { timeZone: 'UTC' });
    } else if(this.durationType === this.applicationConstant.alarmValuesTypes[1].value) {
      const currentDate = new Date();
      const utcDate = new Date(currentDate.setUTCHours(currentDate.getUTCHours() + Number(durationSpan*24)));
      this.dateAndTimeValue =  utcDate.toLocaleString('en-GB', { timeZone: 'UTC' });
    } else if(this.durationType === this.applicationConstant.alarmValuesTypes[2].value) {
      const currentDate = new Date();
      const utcDate = new Date(currentDate.setUTCHours(currentDate.getUTCHours() + Number(durationSpan*7*24)));
      this.dateAndTimeValue =  utcDate.toLocaleString('en-GB', { timeZone: 'UTC' });
    }
    return this.formatedDateAndTime = this.dateAndTimeValue.replace(/,/g, '');
  }

  /**
   * Save Alarm
   */
  saveAlarm() {
    if(this.durationSpan !== 'none') {
      this.isValueSelected = true;
      let payload = {
        uid: this.commonService.getUId(),
        cid: this.dialogData.courseId,
        lesson_id: this.dialogData.lessonId,
        alarm_time: this.calculateDuration(this.durationSpan)
      }
      let endpoint = EndpointConstant.ALARM_SET_SAVE;
      this.formService.saveDetails(endpoint, payload)
      .pipe(takeUntil(this.destroySubscription))
      .subscribe(resp => {
        this.matDialogRef.close(true);
        this.showAlarmConfirmPopup();
      }, error => {
        console.error('error', error);
      });
    }
    else {
      this.isValueSelected = false;
    }
    this.commonService.saveStudentClickEventLog(this.eventLogsEnum.alarmSave, this.dialogData?.courseId, this.dialogData?.lessonId);
  }

  /**
   * maybe later
   */
  mayBeLater() {
    this.navigateToCourseHomeOrDashboard();
    this.matDialogRef.close(true);
    this.cookieService.set(ApplicationConstant.storageKeys.ALARM_KEY + '_' + this.dialogData?.courseId + this.commonService.getUId(), 'alarmShow', 1);
    this.commonService.saveStudentClickEventLog(this.eventLogsEnum.alarmMaybeLater, this.dialogData?.courseId, this.dialogData?.lessonId);
  }

  /**
   * close popup
   */
  closeButton() {
    this.navigateToCourseHomeOrDashboard();
    this.matDialogRef.close(false);
  }

 /**
  * Navigate to course details page or dashboard
  */
  navigateToCourseHomeOrDashboard() { 
    if(this.dialogData && !this.dialogData?.stayInLesson) {
      const url = this.dialogData?.navigateEnd === 'courseDetails' ? RoutingConstant.COURSE_DETAILS_PAGE(this.dialogData.courseId, this.dialogData.slug) : RoutingConstant.STUDENT_DASHBOARD;
      this.router.navigateByUrl(url);
    }
  }

  /**
   * Destroy the component.
   */
   ngOnDestroy(): void {
    this.destroySubscription.next();
    this.destroySubscription.complete();
  }

}
