import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApplicationConstant } from 'src/app/shared/constant/app.constant';
import { FormService } from '../../services/form.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationNotifierService } from 'src/app/shared/component/application-notifier/application-notifier.service';
import { ApplicationNotifierConstant } from 'src/app/shared/constant/notifier.constant';
import { CommonService } from '../../services/common.service';
import { AppUtil } from 'src/app/shared/utils/app.util';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RoutingConstant } from '../../shared/constant/routing.constant';
import {EndpointConstant} from "../../shared/constant/endpoint.constant";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  applicationConstant = ApplicationConstant;
  autoFetchedEmail: any;
  applicationNotifierConstant = ApplicationNotifierConstant;
  errorMessage: string;
  isResetFormValid = true;
  keyToken: any;
  responseResetForm: FormGroup;
  successMessage: string;
  destroySubscription: Subject<void> = new Subject<void>();

  constructor(private activatedRoute: ActivatedRoute,
              private applicationNotifierService: ApplicationNotifierService,
              public commonService: CommonService,
              private formBuilder: FormBuilder,
              private formService: FormService,
              private router: Router) {
    this.keyToken = this.router.url.split('/').pop();
  }

  ngOnInit(): void {
    this.getAutoFetchEmail();
    this.responseResetForm = this.formBuilder.group({
      key: [this.keyToken],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
      email: ['', [Validators.required]],
      termAndCondition: [false, Validators.requiredTrue],
    });
  }

  /**
   * Reset form control
   */
  get resetFormControl() {
    return this.responseResetForm.controls;
  }

  /**
   * Validate confirm password
   *
   * @param passwordFormGroup
   */
  validate(passwordFormGroup: FormGroup) {
    const password = passwordFormGroup.controls.password.value;
    const confirmPassword = passwordFormGroup.controls.confirmPassword.value;

    if (confirmPassword.length <= 0) {
      return null;
    }

    if (confirmPassword !== password) {
      return {
        doesNotMatch: true,
      };
    }

    return null;
  }

  /**
   * Reset form data submit
   *
   * @param form
   */
  resetPassword(form) {
    this.responseResetForm.markAllAsTouched();
    if (form.valid) {
      this.isResetFormValid = true;
      if (form.get('confirmPassword').value === form.get('password').value) {
        this.formService.saveDetails(EndpointConstant.RESET_PASSWORD, this.responseResetForm.value)
            .pipe(takeUntil(this.destroySubscription))
            .subscribe(data => {
              this.successMessage = data.message;
              this.applicationNotifierService.getNotifier(this.applicationNotifierConstant.password_updated);
              this.router.navigateByUrl(`${RoutingConstant.SHORT_COURSE_LOGIN_URL}?email=${this.responseResetForm.value.email}`)
            },
            (err) => {
              if (err.error.message) {
                this.errorMessage = err.error.message;
                this.isResetFormValid = false;
                this.applicationNotifierService.getNotifier(
                  this.applicationNotifierConstant.invalid_email
                );
              }
            }
          );
      } else {
        this.applicationNotifierService.getNotifier(
          this.applicationNotifierConstant.password_not_matched
        );
      }
    } else {
      this.isResetFormValid = false;
    }
  }

  /* Auto fetch email id */
  getAutoFetchEmail() {
    this.formService.getDetails(EndpointConstant.RESET_PASSWORD_EMAIL_AUTO_FETCH(this.keyToken))
        .pipe(takeUntil(this.destroySubscription))
        .subscribe((data) => {
          this.autoFetchedEmail = data;
          this.responseResetForm.patchValue({
            email: data?.data
          });
        });
  }

  /**
   * Navigate to External link.
   *
   */
  navigateWebsiteLink() {
   return AppUtil.createWebsiteURL(this.applicationConstant.externalPages.TERMS);
  }

  ngOnDestroy(): void {
    this.destroySubscription.next();
    this.destroySubscription.complete();
  }

}
