import { Validators } from '@angular/forms';
import { AppUtil } from '../shared/utils/app.util';
import { CheckMediaLink } from '../shared/validator/confirmed.validator';

export const StudentProfileConstant = {

    personalInformationForm : {
        firstName: {
            key: 'first_name',
            label: 'First Name',
            placeholder: 'First Name',
            validation: [
              Validators.required,
              Validators.pattern('^[ a-zA-ZÀ-ÖØ-öø-ÿ\'.-]*$'),
              Validators.maxLength(100),
              Validators.minLength(3)
            ],
            errorMessage: {required: 'First name is required', invalid: 'First name is invalid'}
        },
        lastName: {
            key: 'last_name',
            label: 'Last Name',
            placeholder: 'Last Name',
            validation: [
              Validators.required,
              Validators.pattern('^[ a-zA-ZÀ-ÖØ-öø-ÿ\'.-]*$'),
              Validators.maxLength(100)
            ],
            errorMessage: {required: 'Last name is required', invalid: 'Last name is invalid'}
        },
        DOB: {
            key: 'dob',
            labelKey: 'DOB',
            label: 'Date of Birth',
            placeholder: 'MM/DD/YYYY',
            validation: [
              Validators.required
            ],
            errorMessage: {required: 'DOB is required'}
        },
        emailId: {
            key: 'mail',
            label: 'Email ID',
            placeholder: 'Enter Email ID',
            validation: [
              Validators.required,
              Validators.email
            ],
            errorMessage: {required: 'Email ID is required', invalid: 'Invalid Email Id'}
        },
        mobileNo: {
            key: 'contact_number',
            label: 'Mobile Number',
            country_code: 'country_contact_code',
            country_iso: 'phone_iso',
            placeholder: 'Enter mobile number',
            validation: [Validators.required],
            errorMessage: {
              required: 'Mobile No is required',
              invalid: 'Invalid Mobile No',
              isExist: 'Mobile No already been taken'
            }
        },
        proofType: {
            key: 'proof_type_id',
            label: 'Upload Your Photo ID',
            placeholder: '',
            validation: [],
            errorMessage :  {required: 'Proof type required'}
        },
        photo: {
            key: 'photo',
            imageKey: 'image_id',
            label: 'Upload Photo',
            placeholder: '',
            validation: [],
            errorMessage :  {required: 'Photo is Required'}
        },
        profilePic: {
            key: 'is_profile_pic',
            label: 'Set as profile picture',
        },
        facebookProfile: {
            key: 'facebook_profile',
            label: 'Facebook Profile',
            placeholder: 'Paste your facebook link',
            validation: [
              Validators.pattern(AppUtil.getPatternLink),
              CheckMediaLink('facebook')
            ],
            errorMessage :  {
              required: 'Facebook link required',
              invalid: 'Invalid link',
              mediaLinkInvalid: 'Invalid facebook link'
            }
        },
        linkedinProfile: {
            key: 'linkedin_profile',
            label: 'LinkedIn Profile',
            placeholder: 'Paste your linkedin link',
            validation: [
              Validators.pattern(AppUtil.getPatternLink),
              CheckMediaLink('linkedin')
            ],
            errorMessage :  {
              required: 'Linkedin link required',
              invalid: 'Invalid link',
              mediaLinkInvalid: 'Invalid linkedin link'
            }
        },
        twitterProfile: {
            key: 'twitter_profile',
            label: 'Twitter Profile',
            placeholder: 'Paste your twitter link',
            validation: [
              Validators.pattern(AppUtil.getPatternLink),
              CheckMediaLink('twitter')
            ],
            errorMessage :  {
              required: 'Twitter link required',
              invalid: 'Invalid link',
              mediaLinkInvalid: 'Invalid twitter link'
            }
        },
        gender: {
          key: 'gender',
          label: 'Gender',
          placeholder: 'Select',
          validation: [
            Validators.required
          ],
          errorMessage: {required: 'Gender is required'}
        },
        userProfileLockLabelKeys: ['firstName', 'lastName']
    },

    contactInformationForm: {
        AddressLineOne: {
            key: 'address_line1',
            label: 'address line1',
            placeholder: 'Enter your Address',
            validation: [],
            errorMessage: {required: 'Residential Address is required'}
        },
        AddressLineTwo: {
            key: 'address_line2',
            label: 'address line2',
            placeholder: 'Enter your Address',
            validation:  [],
            errorMessage: {required: 'Residential Address is required'}
        },
        postCode: {
            key: 'postcode',
            label: 'Post Code',
            placeholder: 'Enter your Post code',
            validation: [
              Validators.pattern('^[a-zA-Z0-9]*$'),
              Validators.maxLength(10)
            ],
            errorMessage: {
              invalid: 'Invalid Post Code',
              maxLength: 'Maximum length is upto 10'
            }
        },
        city: {
            key: 'city',
            label: 'City',
            placeholder: 'Enter your city',
            validation: [ Validators.pattern('^[a-zA-Z ]+$')],
            errorMessage: {
            required: 'City is required',
            invalid: 'Invalid City Name'}
        },
        state: {
            key: 'state_id',
            name: 'state_name',
            label: 'State',
            placeholder: 'Select your State',
            validation: [],
            errorMessage: {required: 'State is required'}
        },
        country: {
            key: 'country_id',
            name: 'country_name',
            label: 'Country',
            placeholder: 'Select your Country',
            validation: [],
            errorMessage: {required: 'Country is required', invalid: 'Invalid Country',}
        },
    },

    educationDetailsForm: {
        qualificationLevel: {
          key: 'high_qal_id',
          label: 'Level of Qualification',
          placeholder: 'Select the Level',
          validation: [
            Validators.required,
          ],
          errorMessage: {required: 'Qualification level is required'}
        },
        qualificationName: {
          key: 'qual_name',
          label: 'Qualification Name',
          placeholder: 'BSc Maths, BA History, Higher-Diploma in Business',
          validation: [],
          errorMessage: {required: 'Qualification name is required'}
        },
        universityName: {
          key: 'univ_award_body',
          label: 'University Name',
          placeholder: 'University Name',
          validation: [
            Validators.required,
          ],
          errorMessage: {required: 'University name is required'}
        },
        grade: {
          key: 'grade_name',
          label: 'Final Grade',
          placeholder: 'Final Grade',
          validation: [],
          errorMessage: {required: 'Industry is required'}
        },
        passoutYear: {
          key: 'year_of_passing',
          label: 'Year of Passing',
          placeholder: 'Passout Year',
          validation: [
            Validators.required,
          ],
          errorMessage: {required: 'Passout year is required'}
        },
        certificateUploadName: {
          key: 'certificate_upload_text',
          label: 'Copy of  Certificate',
          placeholder: ' Name of Certificate',
          validation: [],
          errorMessage: {required: 'Document Name is required'}
        },
        certificateUpload: {
          key: 'certificate_upload',
          label: 'Upload Certificate',
          placeholder: 'Choose file',
          validation: [],
          errorMessage: {required: 'Certificate is required'}
        },
        additionalDocsUpload: {
          key: 'additional_doc_upload',
          label: 'Additional Documents(Optional)',
          placeholder: 'Choose file',
          validation: [],
          errorMessage: {required: 'Certificate is required'}
        },
        additionalDocName: {
          key: 'additional_doc_upload_text',
          label: 'Copy of  Certificate',
          placeholder: ' Name of Certificate',
          validation: [],
          errorMessage: {required: 'Document Name is required'}
        }
    },

    workDetailsForm: {
      companyName: {
        key: 'company_name',
        label: 'Company Name',
        placeholder: 'Name of the company',
        validation: [
          Validators.required
        ],
        errorMessage: {required: 'Company Name is required'}
      },
      industry: {
        key: 'industry_id',
        label: 'Industry',
        placeholder: 'Select your industry',
        validation: [
          Validators.required
        ],
        errorMessage: {required: 'Industry is required'}
      },
      curWorking: {
        key: 'current_company',
        label: 'Are you currently working here?',
        placeholder: '',
        validation: [],
        errorMessage: {required: 'Current working status is required'}
      },
      startDateYear: {
        key: 'work_start_year',
        label: 'Start Date',
        placeholder: '1970',
        validation: [
          Validators.required
        ],
        errorMessage: {required: 'Start year is required'}
      },
      startDateMonth: {
        key: 'work_start_month',
        label: '',
        placeholder: 'January',
        validation: [],
        errorMessage: {required: 'Start month is required'}
      },
      endDateYear: {
        key: 'work_end_year',
        label: 'End Date',
        placeholder: '1980',
        validation: [
          Validators.required
        ],
        errorMessage: {required: 'End year is required', yearValidate: 'End Year is not valid'}
      },
      endDateMonth: {
        key: 'work_end_month',
        label: '',
        placeholder: 'January',
        validation: [],
        errorMessage: {required: 'End month is required'}
      },
      designation: {
        key: 'current_designation',
        label: 'Designation title',
        placeholder: 'Designation',
        validation: [
          Validators.required
        ],
        errorMessage: {required: 'Designation is required'}
      },
      employmentLevel: {
        key: 'grade_id',
        label: 'What\'s your level of employment?',
        placeholder: 'Employment Level',
        validation: [],
        errorMessage: {required: 'Employment level is required'}
      },
      workDocumentUploadName: {
        key: 'work_upload_text',
        label: 'Upload any Other Documents (experience,Reference etc)',
        placeholder: 'Other Documents name',
        validation: [],
        errorMessage: {required: 'Other Document name is required'}
      },
      workDocumentUpload: {
        key: 'work_upload',
        label: '',
        placeholder: '',
        validation: [],
        errorMessage: {required: 'Other Document is required'}
      }
    },

    documentDetailsForm: {
      photoUpload: {
        key: 'user_photo',
        label: '',
        placeholder: '',
        validation: [],
        errorMessage: {required: 'Grade is required'}
      },
      passportUpload: {
        key: 'user_passport',
        label: '',
        placeholder: '',
        validation: [],
        errorMessage: {required: 'Grade is required'}
      }
    },

    myProfileLabel: 'My Profile',
    contactUsLabel: 'Contact Us',
    faqLabel: 'FAQ',
    genericFormLabel: 'Partner with us',
    certificateIssuedLabel:'Certificates Issued',
    claimYourCertificateLabel: 'Claim your Certificate',
    myRewardsLabel: 'My Rewards',
    logoutLabel: 'Logout',
    eLibrary: 'E-Library',
    mySubscriptions: 'My Subscriptions',
    PASSPORT_TYPE_ID: 1,
    PHOTO_TYPE_ID: 2,
    OTHERS_TYPE_ID: 7,
    documentProofTypeIds : [1, 7],
    passportNewLabel: 'Passport',

 }