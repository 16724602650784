import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberShort'
})
export class NumberShortPipe implements PipeTransform {

  transform(value: number, format: string): string {
    return this.convertNumber(value, format);
  }

  /**
   * Convert number to specified format (international or indian)
   * @param value - number input
   * @param format - 'international' or 'indian'
   * @returns formatted string
   */
  private convertNumber(value: number, format: string): string {
    let convertedStr = '';
    if (value < 1000) {
      return value.toString();
    }
    if (format === 'international') {
      if (value >= 1000 && value <= 999999) {
        convertedStr = (Math.sign(value) * (Math.abs(value) / 1000)).toFixed(1) + 'k';
      } else if (value >= 1000000 && value < 1000000000) {
        convertedStr = (Math.sign(value) * (Math.abs(value) / 1000000)).toFixed(1) + ' Million';
      } else {
        convertedStr = (Math.sign(value) * (Math.abs(value) / 1000000000)).toFixed(1) + ' Billion';
      }
    } else if (format === 'indian') {
      if (value >= 1000 && value <= 99999) {
        convertedStr = (Math.sign(value) * (Math.abs(value) / 1000)).toFixed(1) + 'k';
      } else {
        convertedStr = (Math.sign(value) * (Math.abs(value) / 100000)).toFixed(1) + 'Lakh';
      }
    }
    return convertedStr;
  }

}