import {Component, Inject, Optional} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import {NotifierData} from './notifier-data.interface';

@Component({
  selector: 'app-application-notifier',
  templateUrl: './application-notifier.component.html',
  styleUrls: ['./application-notifier.component.scss']
})
export class ApplicationNotifierComponent {

  constructor(@Optional() @Inject(MAT_SNACK_BAR_DATA) public data: NotifierData) { }

}
