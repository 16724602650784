import { Component, Inject, Optional } from '@angular/core';
import { DialogData } from './dialog-data.interface';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-application-dialog',
  templateUrl: './application-dialog.component.html',
  styleUrls: ['./application-dialog.component.scss']
})
export class ApplicationDialogComponent {

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

}
