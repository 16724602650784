import { Component, Inject, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { EventLogsEnum } from 'src/app/student-dashboard/enum/event-logs-enum';
import { CommonService } from '../../../services/common.service';
import { ApplicationConstant } from '../../constant/app.constant';
import { RoutingConstant } from '../../constant/routing.constant';
import { AlarmSetupDialogComponent } from '../alarm-setup-dialog/alarm-setup-dialog.component';
import { EndpointConstant } from '../../constant/endpoint.constant';
import { FormService } from 'src/app/services/form.service';
import { Subject } from 'rxjs';
import { DontShowMeAgainComponent } from '../dont-show-me-again/dont-show-me-again.component';

@Component({
  selector: 'app-alarm-setup-confirm-dialog',
  templateUrl: './alarm-setup-confirm-dialog.component.html',
  styleUrls: ['./alarm-setup-confirm-dialog.component.scss']
})
export class AlarmSetupConfirmDialogComponent {

  applicationConstant = ApplicationConstant;
  eventLogsEnum = EventLogsEnum;
  destroySubscription: Subject<void> = new Subject<void>();
  enableDoNotShowMeCheckBox = false;

  constructor(@Optional() private matDialogRef: MatDialogRef<AlarmSetupConfirmDialogComponent>,
             @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
             public matDialog: MatDialog,
             public commonService: CommonService,
             private router: Router,
             private cookieService: CookieService,
             private formService: FormService,) { }

  /**
   * Setup now Alarm
   */
  setUpNowAlarm() {
    this.matDialogRef.close();
    this.matDialog.open(AlarmSetupDialogComponent, {
      width: '556px',
      height: '336px',
      panelClass: 'ath-panel-alarm-confirm',
      disableClose: true,
      data: {
        courseId: this.dialogData?.courseId,
        lessonId: this.dialogData?.lessonId,
        stayInLesson: this.dialogData?.stayInLesson,
        navigateEnd: this.dialogData?.navigateEnd,
        slug: this.dialogData?.slug
      }
    });
    this.commonService.saveStudentClickEventLog(this.eventLogsEnum.alarmSetupNow, this.dialogData?.courseId, this.dialogData?.lessonId);
  }

  /**
   * Set alarm status
   */
  setAlarmStatus() {
      let endpoint = EndpointConstant.ALARM_STATUS_SET;
      const payload = {
        uid: this.commonService.getUId(),
        cid: this.dialogData?.courseId,
        lesson_id: this.dialogData?.lessonId,
        popup_status: true
      }
      this.formService.saveDetails(endpoint, payload)
      .subscribe(() => {
        this.navigateToCourseHomeOrDashboard();
      }, 
       err => {
        console.error('err', err);
      });
  }

  /**
   * Open don't show me popup
   */
  openDoNotShowMeAgainPopup() {
    this.commonService.saveStudentClickEventLog(EventLogsEnum.doNotShowAgainAlarm, this.dialogData?.courseId, this.dialogData?.lessonId);
    const dialogRef = this.matDialog.open(DontShowMeAgainComponent, {
       width: '381px',
       panelClass: 'ath-panel-do-not-show-me-again',
       disableClose: true
     });
     dialogRef.afterClosed().subscribe(resultStatus => {
       if(resultStatus === true) {
         this.enableDoNotShowMeCheckBox = true;
         this.matDialogRef.close();
         this.setAlarmStatus();
       } else {
         this.enableDoNotShowMeCheckBox = false;
       }
     });
  }

  /**
   * Maybe later button
   */
  mayBeLater() {
    this.navigateToCourseHomeOrDashboard();
    this.matDialogRef.close();
    this.cookieService.set(ApplicationConstant.storageKeys.ALARM_KEY + '_' + this.dialogData?.courseId + this.commonService.getUId(), 'alarmShown', 1);
    this.commonService.saveStudentClickEventLog(this.eventLogsEnum.alarmMaybeLater, this.dialogData?.courseId, this.dialogData?.lessonId);
  }

  /**
   * Close popup
   */
  closeButton() {
    this.navigateToCourseHomeOrDashboard();
    this.matDialogRef.close();
  }

  /**
   * Navigate to course details page or dashboard
   */
  navigateToCourseHomeOrDashboard() {
    if(this.dialogData && !this.dialogData?.stayInLesson) {
      const url = this.dialogData?.navigateEnd === 'courseDetails' ? RoutingConstant.COURSE_DETAILS_PAGE(this.dialogData.courseId, this.dialogData.slug) : RoutingConstant.STUDENT_DASHBOARD;
      this.router.navigateByUrl(url);
    }
  }

   /**
   * Destroy the component.
   */
  ngOnDestroy(): void {
    this.destroySubscription.next();
    this.destroySubscription.complete();
  }

}
