import { Component, OnInit } from '@angular/core';
import { ApplicationNotifierService } from 'src/app/shared/component/application-notifier/application-notifier.service';

@Component({
  selector: 'app-academic-header-notifier',
  templateUrl: './academic-header-notifier.component.html',
  styleUrls: ['./academic-header-notifier.component.scss']
})
export class AcademicHeaderNotifierComponent implements OnInit {

  manualData;

  constructor(private applicationNotifierService: ApplicationNotifierService) { }

  ngOnInit(): void {
    this.showNotifyMessage();
  }

  showNotifyMessage() {
    this.applicationNotifierService.applicationNotifierManualSub
      .subscribe(response => {
          this.manualData = {...response?.data};
          setTimeout(() => {
            this.manualData = null;
          }, 4000);
        }, error => {
          console.error('error', error);
     });
  }

}
