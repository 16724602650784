<app-claim-certificate-float></app-claim-certificate-float>
<div class="fix-div">
  <div class="athena-header col-md-12">
    <div class="logo col-4 col-md-2 col-lg-2 pointer" (click)="redirectToHome()">
      <img src="assets/uniathena-logo-without-txt.svg" />
    </div>
    <div class="navigation col-8 col-md-10 col-lg-10 pr-0">
      <ul class="mob-parent-list">
        <li *ngIf="eligibleCertCount > 0" class="mob-list mob-cart">
          <ng-template #popContent>
            <app-certificate-cart-popup [eligibleCourseInfo]="eligibleCourseInfo"></app-certificate-cart-popup>
          </ng-template>
          <div (click)="getEligibleForClaimCertificates(false)" class="cart pointer" [ngbPopover]="popContent"
            [placement]="'bottom-right'" #popOverMob="ngbPopover" [autoClose]="'outside'" triggers="manual" popoverClass="popover-cart">
            <img class="dropdown d-md-block" src="assets/cart-gif.gif" />
            <p class="cart-count badge">{{eligibleCertCount}}</p>
          </div>
        </li>
        <li *ngIf="showNotificationBell" class="mob-list mob-notification-bell-section">
          <ng-template #popContentPushNotification>
            <app-push-notification-popup></app-push-notification-popup>
          </ng-template>
          <div (click)="togglePushNotificationPopup(false)" class="bell pointer" [ngbPopover]="popContentPushNotification"
            [autoClose]="'outside'" [placement]="'bottom-right'" #popOverMobPushNotification="ngbPopover" triggers="manual"
            popoverClass="popover-mob-bell">
            <img class="bell-mob" src="assets/notifications/notify-bell.svg" />
            <div *ngIf="headerService.pushNotificationBadgeValue | async as pushNotificationBadgeValue">
              <p class="bell-count">{{ pushNotificationBadgeValue > 10 ? '10+' : pushNotificationBadgeValue }}</p>
            </div>
          </div>
        </li>
        <li *ngIf="!showNavigation" class="mob-list mobile-menu-icon float-right" (click)="toggleMenu(true)">
          <span></span>
        </li>
      </ul>
      <!--This Menu shows for desktop and tablet-->
      <div *ngIf="!mobileViewMenu && !showNavigation" class="menuOnMobile">
        <div class="nav-links col-md-12">
          <div class="col-md-12 user-name-cart">
            <ul class="list-parent">
              <li class="list cart-section">
                <ng-container *ngIf="eligibleCertCount > 0">
                  <ng-template #popContent>
                    <app-certificate-cart-popup [eligibleCourseInfo]="eligibleCourseInfo"></app-certificate-cart-popup>
                  </ng-template>
                  <div (click)="getEligibleForClaimCertificates()" class="cart pointer" [ngbPopover]="popContent"
                    [autoClose]="'outside'" [placement]="'bottom-right'" #popOver="ngbPopover" triggers="manual" popoverClass="popover-cart">
                    <img class="dropdown cart-desk" src="assets/cart-gif.gif" />
                    <p class="cart-count badge cart-desk">{{eligibleCertCount}}</p>
                  </div>
                </ng-container>
              </li>
              <li *ngIf="showNotificationBell" class="list notification-bell-section">
                <ng-template #popContentPushNotification>
                  <app-push-notification-popup></app-push-notification-popup>
                </ng-template>
                <div (click)="togglePushNotificationPopup()" class="bell pointer" [ngbPopover]="popContentPushNotification"
                  [autoClose]="'outside'" [placement]="'bottom-right'" #popOverPushNotification="ngbPopover" triggers="manual"
                  popoverClass="popover-bell">
                  <img class="dropdown bell-desk" src="assets/notifications/notify-bell.svg" />
                  <div *ngIf="headerService.pushNotificationBadgeValue | async as pushNotificationBadgeValue">
                    <p class="bell-count">{{ pushNotificationBadgeValue > 10 ? '10+' : pushNotificationBadgeValue }}</p>
                  </div>
                </div>
              </li>
              <li class="list profile-img dropdown-icon" [ngClass]="{ 'dropdown-login': isLoggenIn }">
                <img [src]="getProfilePic(userProfileImage)" class="propic-desk" />
                <span class="user-desk">{{ firstName }}</span>
                <img *ngIf="showProfileBadge || userDetails?.profile_verified"  src="assets/profile-badge.svg" alt="profile-badge" class="profile-verify-badge">
                <img class="dropdown user-desk" src="assets/discussion-forum/dropdown.svg" />
                <div class="dropdown-content" 
                    [ngStyle]="{'height': dropdownContentHeight ? dropdownContentHeight : 'auto'}">
                  <p *ngFor="let link of profileDropDownLinks; let i=index" [ngClass]="{ 'active': link?.url && router.url == link.url,
                'disable': (link?.url === routingConstant.STUDENT_DASHBOARD_CERTIFICATE_ISSUED ||
                link?.url === routingConstant.ELIGIBLE_FOR_CLAIM_CERTIFICATE) &&
                (!link?.isCertIssuedCount || !link?.isClaimCertCount) }" (click)="profileNavigationLinks(link)">
                    <img [src]="link.imageUrl" class="menu-icons" />
                    {{ link.label }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
  
      <!--This Menu shows for mobile-->
      <div *ngIf="mobileViewMenu && showNavigation">
        <div class="nav-links col-md-12">
          <div class="d-block d-md-block d-lg-none">
            <div class="row pb-3 pl-4 pr-4">
  
              <div class="logo col-4" (click)="redirectToHome()">
                <img src="assets/uniathena-logo-without-txt.svg" />
              </div>
              <div class="col-8 text-right pt-2" (click)="toggleMenu(false)">
                <img src="assets/close.svg" class="link-close"/>
              </div>
            </div>
          </div>
  
          <div class="profile-mob col-md-12 col-lg-2 dropdown-icon d-block d-md-block d-lg-none mobile"
              [ngClass]="{ 'dropdown-login': isLoggenIn }">
            <img [src]="getProfilePic(userProfileImage)" class="d-block d-md-block d-lg-none propic-mob" />
            <span class="d-block d-md-block d-lg-none fname-mob">{{ firstName }}</span>
            <img *ngIf="showProfileBadge || userDetails?.profile_verified"  src="assets/profile-badge.svg" alt="profile-badge" class="profile-verify-badge">
          </div>
          <div class="profile-img col-md-12 col-lg-2 dropdown-icon" 
               [ngClass]="{ 'dropdown-login': isLoggenIn }">
            <div class="dropdown-content"
                [ngStyle]="{'height': dropdownContentHeight}">
              <p class="drop-items" *ngFor="let link of profileDropDownLinks; let i=index"
                [ngClass]="{ 'active': link?.url && router.url == link.url }" (click)="profileNavigationLinks(link)"
                [class.enable]="link && link?.isCertIssuedCount" [class.enable]="link && link?.isClaimCertCount">
                <img [src]="link.imageUrl" class="menu-icons" />
                {{ link.label }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-academic-header-poll></app-academic-header-poll>