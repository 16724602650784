import { FormGroup } from '@angular/forms';
import { FileUpload } from '../../interface/file-upload.interface';
import { NotifierData } from '../component/application-notifier/notifier-data.interface';
import { ApplicationNotifierConstant } from '../constant/notifier.constant';
import { APP_URL } from '../../../environments/environment';

/**
 * Utility class for the Application.
 */
export class AppUtil {

  /**
   * Check for file size less than 5MB.
   *
   * @param file to upload
   * @param maxFileSize file size
   */
  static checkFileSize(file, maxFileSize) {
    if (file) {
      const fileSize = file.size;
      const kbFileSize = Math.round(fileSize / 1024);
      return kbFileSize < (1024 * maxFileSize);
    }
    return false;
  }

  /**
   * Check if the file is a valid extension.
   *
   * @param fileName name of the file.
   * @param validFileExtensions supported file extensions
   */
  static checkFileExtension(fileName, validFileExtensions) {
    let isValidExtension = false;
    for (const extension of validFileExtensions) {
      if (fileName.substr(fileName.length - extension.length,
        extension.length).toLowerCase() === extension.toLowerCase()) {
        isValidExtension = true;
        break;
      }
    }
    return isValidExtension;
  }

  /**
   * Create the file name for the file upload
   *
   * @param uid usrId for the user
   * @param uploadFileText upload file text
   */
  static createFileName(uid: number, uploadFileText: string) {
    return `${uid}_${uploadFileText}_${Date.now()}`;
  }

  /**
   * To enable or disable the form fields
   *
   * @param form on which fields needs to enable or disable
   * @param fieldArray fields which needs to be enable or disabled.
   * @param isEnabled boolean value true for enable and false for disable.
   */
  static enableOrDisableFormFields(form: FormGroup, fieldArray: Array<any>, isEnabled: boolean) {
    
    for (const field of fieldArray) {
      if (form.get(field.key) && isEnabled) {
        form.get(field.key).enable();
      } else if(form.get(field.key)) {
        form.get(field.key).disable();
        // reset the form value on disable
        form.get(field.key).reset();
      }
    }
  }

  /**
   * Create the new date by subtracting the given no. of years
   */
  static getNewDateByReduceYears(date: Date, years: number) {
    date.setFullYear(date.getFullYear() - years);
  }

  /**
   * Update the flag on the form
   *
   * @param config form
   * @param countryCode country iso
   */
  static updateFlag(config, countryCode) {
    if (countryCode !== 'null') {
      // country heard and mcdonald island not available in ngx intl input, so assigning to norfolk which has same phone code
      const countryIsq = countryCode === 'hm' ? 'nf' : countryCode;
      config.selectedCountryISO = countryIsq;
    }
  }
  /**
   * Calculate year difference
   *
   * @param dobYr Student dob year
   */
   static calculateYear(dobYr: number) {
    if (dobYr !== null) {
      const date = new Date();
      const year = date.getFullYear();
      const yrDiff =   year - dobYr;
      return yrDiff;
    }
  }
  /**
   * Scroll to the first element with error in the form.
   */
  static scrollFirstElementWithError() {
    const target = document.querySelector('.ng-invalid:not(form)');

    if (target) {
      target.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  /**
   * Reset the phone number
   *
   * @param form Form
   * @param fieldNameConfig name of the field
   */
  static resetPhoneNumber(form: FormGroup, fieldNameConfig: string) {
    form.controls[fieldNameConfig].valueChanges.subscribe((phoneNumber) => {
        if (form.value[fieldNameConfig] && form.value[fieldNameConfig].countryCode
          && phoneNumber && phoneNumber.countryCode && phoneNumber.countryCode !== form.value[fieldNameConfig].countryCode) {
          form.controls[fieldNameConfig].setValue('');
        }
      }
    );
  }

  /**
   * File upload set the attributes
   *
   * @param fileData data of file upload
   * @param form FormGroup
   * @param fileTextKey text key of the file
   * @param fileNameKey uploaded file
   * @param upload array of file to upload
   * @param notifierData message to be shown.
   */
  static multipleFileUpload(fileData: any, form: FormGroup, fileTextKey: string, fileNameKey: string, upload: Array<FileUpload>,
                            notifierData: NotifierData) {
    const fileUpload = {} as FileUpload;
    fileUpload.url = fileData.url;
    fileUpload.id = fileData.image_id;
    fileUpload.name = form.value[fileTextKey];
    fileUpload.notifier = notifierData;
    upload.push(fileUpload);
    const gradeUploadList = upload.map(({notifier, ...gradeUploads}) => gradeUploads);
    form.controls[fileNameKey].setValue(gradeUploadList);
    form.controls[fileTextKey].setValue('');
  }

  /**
   * File upload set the attributes
   *
   * @param fileData data of file upload
   * @param fileName name of the file
   * @param upload array of file to upload
   */
  static singleFileUpload(fileData: any, fileName: string, upload: FileUpload) {
    upload.url = fileData.url;
    upload.id = fileData.image_id;
    upload.notifier = ApplicationNotifierConstant.file_upload;
    upload.name = fileName;
  }

  /**
   * Group the array according to the key
   *
   * @param items array details
   * @param key on which grouping to be done.
   */
  static groupArrayByGivenKey(items, key) {
    return items.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: [...(result[item[key]] || []), item, ],
      }), {}, );
  }

  /**
   * Get the filtered list.
   *
   * @param list full list of elements.
   * @param selectedList selected ones.
   * @param idKeyForList primary key for the full list.
   * @param idKeyForSelectedList primary key for the selected list.
   */
  static getFilterList(list: Array<any>, selectedList: Array<any>, idKeyForList: string, idKeyForSelectedList: string) {
    return list.filter(({ [idKeyForList]: id1 }) => !selectedList.some(({ [idKeyForSelectedList]: id2 }) => id2 === id1));
  }

  /**
   * Get the path from the URL.
   *
   * @param url get the pathname.
   */
  static getPathFromURL(url: string) {
    if (url) {
      return new URL(url).pathname.slice(1);
    }
    return null;
  }

  /**
   * Get the array of string from the array of list.
   *
   * @param list of object.
   * @param filterKey from the list.
   */
  static getArrayOfStringFromArrayOfObject(list: Array<any>, filterKey: string): Array<string> {
    return list && list.length && filterKey ? list.map(field => field[filterKey]) : [''];
  }

  /**
   * All object value is null or empty or undefined or false then return true.
   *
   * @param field is the object for checking the value.
   */
  static allObjectAttributeIsNull(field: object) {
    return Object.values(field).every(value => (value === null || value === '' || value === undefined || value === false));
  }

  /**
   * Create the website url.
   *
   * @param endpoint of the page.
   */
  static createWebsiteURL(endpoint) {
    return `${APP_URL.WEBSITE}${endpoint}`;
  }

  /**
   * Detect the browser name.
   */
  static detectBrowserName(): string {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  /**
   * Get pattern for link validation
   */
   static get getPatternLink() {
    return '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
  }

  /**
   * Get random number
   */
  static getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
  }

}
