<div class="container-fluid p-0 shortcourse-login-bg">
  <div class="container login-wrapper">
    <form [formGroup]="signUpForm"
          (ngSubmit)="registerUser()">
      <div class="row">
        <div class="col-md-12 col-lg-6 p-0">
          <h1  class="register-now">Register
            <span>Now</span>
          </h1>
          <h3 class="start-learning">Start  Learning in 60 seconds - no need for any payments Details!
          If you are an existing user Please 
            <span>
                <a class="text-pink"
                   [routerLink]="routingConstant.SHORT_COURSE_LOGIN_URL">
                   Sign In
                </a>
              </span>
            </h3>
          <div class="register-wrapper">
            <ul class="nav nav-pills">
              <li class="active">
                <a class="nav-link show active"
                   (click)="commonService.navigateWebsiteLink(applicationConstant.externalPages.REGISTRATION)"
                   data-toggle="pill">Academic Courses</a>
              </li>
              <li>
                <a class="nav-link"
                   data-toggle="pill">
                  Short Courses
                </a>
              </li>
            </ul>
           <div class="mob-bg">
            <div class="social-login">
              <p class="log-in-parent">
                <a class="login-with">Login/Sign Up with</a>
              </p>
              <a (click)="formService.signInWithGoogle(locationDetails, queryParamData, null, returnUrl)" class="social-icon">
                <img src="assets/google-login.svg"
                     width="30"/>
              </a>
              <!-- <a (click)="formService.loginWithFacebook(locationDetails, queryParamData, null, null, returnUrl)" class="social-icon">
                <img src="assets/facebook-login.svg"
                     width="16"/>
              </a> -->
              <!--<a (click)="formService.loginWithMicrosoft(locationDetails, queryParamData, null, null, returnUrl)">
                <img src="assets/microsoft-login.svg"
                     width="30"/>
              </a>
              <a (click)="formService.loginWithMicrosoft(locationDetails, null)">
                <img src="assets/linkedin-login.svg"
                     width="38"/>
              </a>-->
            </div>
          </div>
            <div class="continue-with">
              <span class="continue-text"><span class="mob">or </span> <span class="desk">create a new account</span></span>
            </div>
            <div class="continue-with-mob">
              <span>Create a New Account</span>
            </div>
            <div class="tab-content">
              <div class="tab-pane fade in active show">
                <div class="login-details ac-form">
                  <div class="login-item names">
                    <div class="name-item mr-3">
                      <p>{{ appConfig.formDetails.firstName.label }}</p>
                      <input type="text"
                            id="reg_first_name"
                            [formControlName]="appConfig.formDetails.firstName.key"
                            [placeholder]="appConfig.formDetails.firstName.placeholder" autofocus /><br/>
                      <mat-error *ngIf="formControl[appConfig.formDetails.firstName.key].invalid
                      && formControl[appConfig.formDetails.firstName.key].touched">
                        <mat-error *ngIf="formControl[appConfig.formDetails.firstName.key].errors.required">
                          {{ appConfig.formDetails.firstName.errorMessage.required }}
                        </mat-error>
                        <mat-error *ngIf="!formControl[appConfig.formDetails.firstName.key].errors.required">
                          {{ appConfig.formDetails.firstName.errorMessage.invalid }}
                        </mat-error>
                      </mat-error>
                    </div>
                    <div class="name-item">
                      <p>{{ appConfig.formDetails.lastName.label }}</p>
                      <input type="text"
                            id="reg_last_name"
                            [formControlName]="appConfig.formDetails.lastName.key"
                            [placeholder]="appConfig.formDetails.lastName.placeholder" /><br/>
                      <mat-error *ngIf="formControl[appConfig.formDetails.lastName.key].invalid
                      && formControl[appConfig.formDetails.lastName.key].touched">
                        <mat-error *ngIf="formControl[appConfig.formDetails.lastName.key].errors.required">
                          {{ appConfig.formDetails.lastName.errorMessage.required }}
                        </mat-error>
                        <mat-error *ngIf="!formControl[appConfig.formDetails.lastName.key].errors.required">
                          {{ appConfig.formDetails.lastName.errorMessage.invalid }}
                        </mat-error>
                      </mat-error>
                    </div>
                  </div>
                  <div class="login-item">
                    <p>{{ appConfig.formDetails.emailId.label }}</p>
                    <input type="email"
                          id="reg_email"
                          [formControlName]="appConfig.formDetails.emailId.key"
                          [placeholder]="appConfig.formDetails.emailId.placeholder"
                          (input)="updatingEmail()"/>
                          <br/>
                    <mat-error *ngIf="formControl[appConfig.formDetails.emailId.key].invalid
                      && formControl[appConfig.formDetails.emailId.key].touched">
                      <mat-error *ngIf="formControl[appConfig.formDetails.emailId.key].errors.required">
                        {{ appConfig.formDetails.emailId.errorMessage.required }}
                      </mat-error>
                      <mat-error *ngIf="!formControl[appConfig.formDetails.emailId.key].errors.required">
                        {{ appConfig.formDetails.emailId.errorMessage.invalid }}
                      </mat-error>
                    </mat-error>
                  </div>
                  <div class="login-item">
                    <p>{{ appConfig.formDetails.password.label }}</p>
                    <input type="password"
                          id="password"
                          [formControlName]="appConfig.formDetails.password.key"
                          [placeholder]="appConfig.formDetails.password.placeholder" />
                          <br/>
                    <mat-error *ngIf="formControl[appConfig.formDetails.password.key].invalid
                      && formControl[appConfig.formDetails.password.key].touched">
                      <mat-error *ngIf="formControl[appConfig.formDetails.password.key].errors.required">
                        {{ appConfig.formDetails.password.errorMessage.required }}
                      </mat-error>
                      <mat-error *ngIf="!formControl[appConfig.formDetails.password.key].errors.required">
                        {{ appConfig.formDetails.password.errorMessage.invalid }}
                      </mat-error>
                    </mat-error>
                  </div>
                  <div class="login-item phone-number">
                    <p>{{ appConfig.formDetails.contactNo.label }}</p>
                    <ngx-intl-tel-input
                        [cssClass]="'tel-dial'"
                        [inputId]="appConfig.formDetails.contactNo.key"
                        [preferredCountries]="phoneNumberConfig.preferredCountries"
                        [enableAutoCountrySelect]="phoneNumberConfig.enableAutoCountrySelect"
                        [enablePlaceholder]=phoneNumberConfig.enablePlaceholder
                        [searchCountryFlag]="phoneNumberConfig.searchCountryFlag"
                        [searchCountryField]="phoneNumberConfig.searchCountryField"
                        [selectFirstCountry]="phoneNumberConfig.selectFirstCountry"
                        [selectedCountryISO]="phoneNumberConfig.selectedCountryISO"
                        [maxLength]="phoneNumberConfig.maxlength"
                        [tooltipField]="phoneNumberConfig.tooltipField"
                        [phoneValidation]="phoneNumberConfig.phoneValidation"
                        [separateDialCode]="phoneNumberConfig.separateDialCode"
                        [formControlName]="appConfig.formDetails.contactNo.key"
                        (input)="checkAndUpdatePhoneNumber()"
                        [customPlaceholder]="appConfig.formDetails.contactNo.placeholder"
                        ngDefaultControl>
                    </ngx-intl-tel-input>
                  <mat-error *ngIf="formControl[appConfig.formDetails.contactNo.key].invalid &&
                  formControl[appConfig.formDetails.contactNo.key].touched">
                    <mat-error *ngIf="!formControl[appConfig.formDetails.contactNo.key].errors.required">
                      {{ appConfig.formDetails.contactNo.errorMessage.invalid }}
                    </mat-error>
                  </mat-error>
                  </div>
                  <div class="button">
                    <label for="reg_terms" class="pointer">
                      <input type="checkbox"
                            id="reg_terms"
                            [formControlName]="appConfig.formDetails.acceptTerms.key"
                            class="checkbox">
                      <span class="custom-check"> I accept the</span>

                      <a  class="TandC" (click)="consentDialog($event)"
                        target="_blank">Terms and Conditions</a>
                    </label>
                    <mat-error class="row" *ngIf="formControl[appConfig.formDetails.acceptTerms.key].invalid
                      && formControl[appConfig.formDetails.acceptTerms.key].touched">
                        <div class="col-12 text-center pt-2">
                            <mat-error *ngIf="formControl[appConfig.formDetails.acceptTerms.key].errors.required">
                              {{ appConfig.formDetails.acceptTerms.errorMessage.required }}
                            </mat-error>
                        </div>
                    </mat-error>
                    <br/>
                    <br/>
                    <button id="registration_form"
                            [disabled]="isDisabledButton">
                      Sign Up
                    </button>
                    <mat-error *ngIf="serverError" class="row" >
                      <div class="col-12 text-center pt-3">
                        <span *ngIf="serverError?.error && 
                        serverError?.error?.data
                        && serverError?.error?.data?.mail && 
                        serverError?.error?.data?.mail?.length else contactError">
                        {{serverError.error.data.mail[0]}}
                          <a [routerLink]="routingConstant.SHORT_COURSE_LOGIN_URL" class="text-pink">Please login</a>
                        </span>
                        <ng-template #contactError>
                          <span *ngIf="serverError?.error && 
                          serverError?.error?.data
                          && serverError?.error?.data?.contact_number && 
                          serverError?.error?.data?.contact_number?.length else errorBlock">
                          {{serverError.error.data.contact_number[0]}}
                          </span>
                        </ng-template>
                        <ng-template #errorBlock>
                          Something went wrong. Please try again..
                        </ng-template>
                      </div>
                    </mat-error>
                    </div>
                    <div class="button p-3">
                      <p class="text-center">
                        Already Have An Account?
                        <a class="text-pink"
                        [routerLink]="routingConstant.SHORT_COURSE_LOGIN_URL">
                        Login
                        </a>
                      </p>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 register-images">
          <img src="assets/shortcourses-login-img.png"
               style="max-width:100%;" />
        </div>
      </div>
    </form>
  </div>
</div>
