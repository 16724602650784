<div class="main">
    <div class="top-background-image">
       <img class="top-banner-curve" src="assets/contact/curv-home.png">
    </div>
    <div class="bottom-background-image">
       <img class="bottom-banner-curve" src="assets/contact/curv-home.png">
    </div>
    <div class=" contact-page py-4">
       <div class="container my-4">
          <h1 class="contact-heading"><span class="contact-clr">Contact </span>Us</h1>
       </div>
    </div>
    <div class="container">
       <div class="row">
          <div class="col-md-5  cont-left mb-4">
             <div class="contact-head">
                <h1>Speak to an Advisor</h1>
                <span></span>
             </div>
             <p>Not sure if this course  is right for you? Fill in your details below and one of our Course Advisors will be in contact to provide you with the information you need to make a decision.</p>
             <form [formGroup]="contactUsForm" (ngSubmit)="saveDetails(contactUsForm.value)" >
                <div class="form-group name-group">
                   <label for="fname">First Name</label>
                   <input type="text"
                     class="form-control inpfields fn-inp"
                     id="fname"
                     [formControlName]="appConfig.formDetails.firstName.key"
                     placeholder="First Name"
                     [readonly]="isLoggedIn">
                     <div *ngIf="formControl[appConfig.formDetails.firstName.key].invalid
                        && formControl[appConfig.formDetails.firstName.key].touched"
                        class="contact-error">
                           <mat-error *ngIf="formControl[appConfig.formDetails.firstName.key].errors.required">
                              {{ appConfig.formDetails.firstName.errorMessage.required }}
                           </mat-error>
                           <mat-error *ngIf="!formControl[appConfig.formDetails.firstName.key].errors.required">
                              {{ appConfig.formDetails.firstName.errorMessage.invalid }}
                           </mat-error>
                     </div>
                </div>
                <div class="form-group name-group">
                   <label for="lname">Last Name</label>
                   <input type="text"
                     class="form-control inpfields"
                     id="lname"
                     [formControlName]="appConfig.formDetails.lastName.key"
                     placeholder="Last Name"
                     [readonly]="isLoggedIn">
                     <div *ngIf="formControl[appConfig.formDetails.lastName.key].invalid
                        && formControl[appConfig.formDetails.lastName.key].touched"
                        class="contact-error">
                        <mat-error *ngIf="formControl[appConfig.formDetails.lastName.key].errors.required">
                        {{ appConfig.formDetails.lastName.errorMessage.required }}
                        </mat-error>
                        <mat-error *ngIf="!formControl[appConfig.formDetails.lastName.key].errors.required">
                        {{ appConfig.formDetails.lastName.errorMessage.invalid }}
                        </mat-error>
                     </div>
                </div>
                <div class="form-group">
                   <label for="email">Email Address</label>
                   <input type="email"
                     class="form-control inpfields"
                     id="email"
                     [formControlName]="appConfig.formDetails.emailId.key"
                     placeholder="Email Address"
                     [readonly]="isLoggedIn">
                     <mat-error *ngIf="formControl[appConfig.formDetails.emailId.key].invalid
                      && formControl[appConfig.formDetails.emailId.key].touched">
                      <mat-error *ngIf="formControl[appConfig.formDetails.emailId.key].errors.required">
                        {{ appConfig.formDetails.emailId.errorMessage.required }}
                      </mat-error>
                      <mat-error *ngIf="!formControl[appConfig.formDetails.emailId.key].errors.required">
                        {{ appConfig.formDetails.emailId.errorMessage.invalid }}
                      </mat-error>
                    </mat-error>
                </div>
                <div class="form-group">
                   <label for="pwd">Country</label>
                   <mat-select matInput
                     id="pwd"
                     [formControlName]="appConfig.formDetails.residenceId.key"
                     (selectionChange) ="updateCountryFlag()"
                     class="country inpfields"
                     ngDefaultControl>
                     <mat-option *ngFor="let countries of countryList" [value]="countries.country_id">{{
                        countries["country_name"] }}
                      </mat-option>
                   </mat-select>
                   <mat-error
                        *ngIf="formControl[appConfig.formDetails.residenceId.key].invalid
                        && formControl[appConfig.formDetails.residenceId.key].touched">
                        <mat-error *ngIf="formControl[appConfig.formDetails.residenceId.key].errors.required" >
                          {{appConfig.formDetails.residenceId.errorMessage.required}}
                        </mat-error>
                   </mat-error>
                </div>
                <div class="form-group" >
                   <label for="quantity">Phone Number</label>
                        <ngx-intl-tel-input
                        [inputId]="appConfig.formDetails.mobileNo.key"
                        [preferredCountries]="phoneNumberConfig.preferredCountries"
                        [enableAutoCountrySelect]="phoneNumberConfig.enableAutoCountrySelect"
                        [enablePlaceholder]=phoneNumberConfig.enablePlaceholder
                        [searchCountryFlag]="phoneNumberConfig.searchCountryFlag"
                        [searchCountryField]="phoneNumberConfig.searchCountryField"
                        [selectFirstCountry]="phoneNumberConfig.selectFirstCountry"
                        [selectedCountryISO]="phoneNumberConfig.selectedCountryISO"
                        [maxLength]="phoneNumberConfig.maxlength"
                        [tooltipField]="phoneNumberConfig.tooltipField"
                        [phoneValidation]="phoneNumberConfig.phoneValidation"
                        [separateDialCode]="phoneNumberConfig.separateDialCode"
                        (change)="checkAndUpdatePhoneNumber()"
                        (input)="trimPhoneNumber()"
                        [formControlName]="appConfig.formDetails.mobileNo.key"
                        ngDefaultControl
                        >
                      </ngx-intl-tel-input>
                      <mat-error
                          *ngIf="formControl[appConfig.formDetails.mobileNo.key].invalid &&
                                 formControl[appConfig.formDetails.mobileNo.key].touched">
                          <mat-error *ngIf="formControl[appConfig.formDetails.mobileNo.key].errors.required" >
                            {{appConfig.formDetails.mobileNo.errorMessage.required}}
                          </mat-error>
                          <mat-error *ngIf="isPhoneValid && contactUsForm.invalid" >
                            <mat-error *ngIf="formControl[appConfig.formDetails.mobileNo.key].errors.validatePhoneNumber">
                              {{appConfig.formDetails.mobileNo.errorMessage.invalid}}
                            </mat-error>
                          </mat-error>
                          <mat-error *ngIf="formControl[appConfig.formDetails.mobileNo.key].errors.isExist" >
                            {{appConfig.formDetails.mobileNo.errorMessage.isExist}}
                          </mat-error>
                        </mat-error>
                </div>
                <label for="remarks">Remarks</label>
                <textarea class="remarks"
                  [formControlName]="appConfig.formDetails.studentRemarks.key">
               </textarea>
               <mat-error class="row" *ngIf="formControl[appConfig.formDetails.studentRemarks.key].invalid
                      && formControl[appConfig.formDetails.studentRemarks.key].touched">
                            <mat-error *ngIf="formControl[appConfig.formDetails.studentRemarks.key].errors.required">
                              {{ appConfig.formDetails.studentRemarks.errorMessage.required }}
                            </mat-error>
                    </mat-error>
                    <div class="agree-stm mt-2">
                     <input type="checkbox"
                       class="agree-tick pointer"
                       [formControlName]="appConfig.formDetails.consentReceived.key"
                       >
                     <p>I agree with <a href="{{ websiteURL }}terms" target="_blank"> Terms & Conditions </a></p>
                      <mat-error class="row" *ngIf="formControl[appConfig.formDetails.consentReceived.key].invalid
                        && formControl[appConfig.formDetails.consentReceived.key].touched">
                              <mat-error *ngIf="formControl[appConfig.formDetails.consentReceived.key].errors.required">
                                {{ appConfig.formDetails.consentReceived.errorMessage.required }}
                              </mat-error>
                      </mat-error>
                  </div>
                <button type="submit" class="submit-btn mt-3">Submit</button>
             </form>
          </div>
          <div class="col-md-7  cont-right">
             <div class="bord-box">
                <h1>For General Enquiries</h1>
                <p>We understand you may have questions that are not answered in our FAQ section.
                   If you cannot find the asnwers to your questions, please feel free to contact support.</p>
             </div>
             <div class="flex-container">
                <div class="flex-items">
                   <p>Student Support</p>
                   <a href="mailto:support@uniathena.com">support@uniathena.com</a>
                </div>
                <div class="flex-items">
                   <p>Payments</p>
                   <a href="mailto:payments@uniathena.com">payments@uniathena.com</a>
                </div>
                <div class="flex-items">
                   <p>IT Support</p>
                   <a href="mailto:itsupport@uniathena.com">itsupport@uniathena.com</a>
                </div>
                <div class="flex-items">
                  <p>Information</p>
                  <a href="mailto:information@uniathena.com">information@uniathena.com</a>
               </div>
                <div class="flex-items">
                   <p>Partnership</p>
                   <a href="mailto:partnership@uniathena.com">partnership@uniathena.com</a>
                   <button (click)="navigateToPartnerShipForm()" class="partner-knowmore">Know more<span class="material-icons partner-next-arrow">east</span></button>
                </div>
                <div class="flex-items">
                   <p>Faculty</p>
                   <a href="mailto:faculty@uniathena.com">faculty@uniathena.com</a>
                </div>
             </div>
          </div>
       </div>
      
    </div>
    <div>
       <div class="about-popular-content outerContainer py-5">
          <div class="container">
             <h2 class="text-center py-5">Our Locations</h2>
             <div class="card-group row">
                <div class="col-md-4">
                   <div class="card mr-2">
                      <img src="assets/contact/uk-one.png" />
                      <div class="card-body">
                         <h5 class="short-title card-title text-center">United Kingdom</h5>
                         <div class="ml-3 mb-3">
                            <img src="assets/contact/location.png" class="location-img"/>
                            <p class="card-text card-address">Athena Global Education<br>
                              Magdalen Centre<br>
                              Robert Robinson Avenue<br>
                              Oxford, OX4 4GA, UK<br>
                              <br>
                            </p>
                            <p class="card-text "><span class="mr-2 ml-2">
                               <img src="assets/contact/call-img.png"/></span>
                               <span class="card-number">01865 784299</span>
                            </p>
                         </div>
                      </div>
                   </div>
                </div>
                <div class="col-md-4">
                   <div class="card mr-2">
                      <img src="assets/contact/uk-two.jpg" class="mid-add-img">
                      <div class="card-body">
                         <h5 class="short-title card-title text-center">Middle East</h5>
                         <div class="ml-3 mb-3">
                            <img src="assets/contact/location.png" class="location-img"/>
                            <p class="card-text card-address">Athena Global Education FZE<br>
                               Block L-03, First Floor,<br>
                               P O Box 519265,<br>
                               Sharjah Publishing City,<br>
                               Free Zone, Sharjah, UAE
                            </p>
                            <p class="card-text"><span class="mr-2 ml-2">
                               <img src="assets/contact/call-img.png"/></span>
                               <span class="card-number">+971 65 31 2511</span>
                            </p>
                         </div>
                      </div>
                   </div>
                </div>
                <div class="col-md-4">
                  <div class="card mr-2">
                     <img src="assets/contact/india.jpg" class="mid-add-img">
                     <div class="card-body">
                        <h5 class="short-title card-title text-center">India</h5>
                        <div class="ml-3 mb-3">
                           <img src="assets/contact/location.png" class="location-img"/>
                           <p class="card-text card-address">Uniathena Private Limited<br>
                              9A,Midas Tower <br>
                              Phase 1<br>
                              Hinjewadi Rajiv Gandhi Infotech Park <br>
                              Pune-411057
                           </p>
                           <p class="card-text"><span class="mr-2 ml-2">
                              <img src="assets/contact/call-img.png"/></span>
                              <span class="card-number">+91 9145665544</span>
                           </p>
                        </div>
                     </div>
                  </div>
               </div>
             </div>
          </div>
       </div>
    </div>
 </div>
