import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { APP_URL } from 'src/environments/environment';

@Component({
  selector: 'app-download-pdf-page',
  templateUrl: './download-pdf-page.component.html',
  styleUrls: ['./download-pdf-page.component.scss']
})
export class DownloadPdfPageComponent implements OnInit {

  quizId: string;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: Params) => {
      this.quizId = params.get('quizId');
      if(this.quizId) {
        window.open(`${APP_URL.API}quiz-results-pdf/${this.quizId}`, '_blank');
      }
    });
  }

}
