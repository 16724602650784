import { Validators } from '@angular/forms';
import { ApplicationConstant } from '../constant/app.constant';

export const AppConfig = {

  formDetails: {
    acceptTerms: {
      key: 'consent_terms',
      label: 'I accept the Terms and conditions of Athena Global Education.',
      validation: [
        Validators.requiredTrue,
      ],
      errorMessage: {required: 'Terms and condition is required'}
    },
    confirmPassword:  {
      key: 'confirmPassword',
      label: 'Confirm Password',
      placeholder: 'Confirm Password',
      validation: [
        Validators.required
      ],
      errorMessage: { required: 'Confirm password is required', invalid: 'Confirm password does not match with password' }
    },
    consentReceived: {
      key: 'consent_received',
      label: 'I accept the Terms and conditions of Athena Global Education.',
      validation: [
        Validators.requiredTrue,
      ],
      errorMessage: {required: 'Terms and condition is required'}
    },
    certificateIssuedCount: {
      key: 'certificate_issued_count'
    },
    emailUnsubscribeReason: {
      key: 'reason',
      label: 'Before you go, please let us know why you wish to unsubscribe:',
      validation: [
        Validators.required,
      ],
      errorMessage: {required: 'Reason for the email unsubscribe is required'}
    },
    eligibleCertificateCount: {
      key: 'eligible_certificate_count'
    },
    maxOfferPercentage: {
      key: 'max_offer_percentage'
    },
    rewardPointsCount: {
      key: 'reward_points_total'
    },
    studentCompletedWeek: {
      key: 'student_completed_week'
    },
    courseId: {
      key: 'course_id'
    },
    countryCode: {
      key: 'country_code'
    },
    countryContactCode: {
      key: 'calling_code'
    },
    dob: {
      key: 'dob',
      label: 'Date of Birth',
      placeholder: 'MM/DD/YYYY',
      customValidation: {maxAge: 18},
      validation: [
        Validators.required
      ],
      errorMessage: {required: 'DOB is required'}
    },
    emailId: {
      key: 'mail',
      label: 'Email address',
      placeholder: 'Enter email address',
      validation: [
        Validators.required,
        Validators.email,
        Validators.pattern(ApplicationConstant.validatorPatterns.email),
      ],
      errorMessage: {required: 'Email ID is required', invalid: 'Invalid Email Id'}
    },
    employmentLevel: {
      key: 'grade_id',
      label: 'Employment Level',
      name: 'grade',
      placeholder: 'Select your employment level',
      validation: [
        Validators.required
      ],
      type: 'experience',
      errorMessage: {required: 'Level of employment is required'}
    },
    firstName: {
      key: 'first_name',
      label: 'First Name',
      placeholder: 'First Name',
      validation: [
        Validators.required,
        Validators.pattern('^[ a-zA-ZÀ-ÖØ-öø-ÿ\'.-]*$'),
        Validators.maxLength(100),
        Validators.minLength(3)
      ],
      errorMessage: {required: 'First Name is required', invalid: 'First Name is invalid'}
    },
    highestQualification: {
      key: 'high_qal_id',
      label: 'Highest Qualification',
      name: 'qualification_name',
      placeholder: 'Select highest qualification',
      validation: [
        Validators.required
      ],
      errorMessage: {required: 'Highest qualification is required'}
    },
    lastName: {
      key: 'last_name',
      label: 'Last Name',
      placeholder: 'Last Name',
      validation: [
        Validators.required,
        Validators.pattern('^[ a-zA-ZÀ-ÖØ-öø-ÿ\'.-]*$'),
        Validators.maxLength(100)
      ],
      errorMessage: {required: 'Last name is required', invalid: 'Last Name is invalid'}
    },
    nationality: {
      key: 'country_id',
      name: 'country_name',
      label: 'Nationality',
      placeholder: 'Select nationality',
      validation: [
        Validators.required
      ],
      errorMessage: {required: 'Nationality is required'}
    },
    newPassword: {
      key: 'newPassword',
      label: 'New Password',
      placeholder: "New Password",
      validation: [
        Validators.required,
      ],
      errorMessage: {
        required: 'New password is required',
        isExist: 'New password can’t be same as old password'
      }
    },
    oldPassword: {
      key: 'oldPassword',
      label: 'Current Password',
      placeholder: 'Current Password',
      validation: [
        Validators.required
      ],
      errorMessage: { 
        required: 'Current password is required'
        }
    },
    password: {
      key: 'password',
      label: 'Password',
      placeholder: 'Enter Password',
      validation: [
        Validators.required
      ],
      errorMessage: {required: 'Password is required', invalid: 'Invalid Password'}
    },
    recoveryEmailId: {
      key: 'recovery_email',
      label: 'Email ID',
      placeholder: 'Enter Email ID',
      validation: [
        Validators.required,
        Validators.email,
        Validators.pattern(ApplicationConstant.validatorPatterns.email),
      ],
      errorMessage: {required: 'Email ID is required', invalid: 'Invalid Email Id'}
    },
    work_experience: {
      key: 'years_exp',
      label: '',
      placeholder: 'YY',
      validation: [Validators.required],
      type: 'experience',
      errorMessage: {required: 'Year is required'}
    },
    employment_level: {
      key: 'grade_id',
      label: 'What\'s your level of employment?',
      placeholder: 'Select current employment level',
      validation: [],
      type: 'experience',
      errorMessage: {required: 'Employment level is required'}
    },
    qualificationLevel: {
      key: 'high_qal_id',
      label: 'Select your Level of your Qualification',
      placeholder: 'Select qualification level',
      validation: [Validators.required],
      errorMessage: {required: 'Qualification level is required'}
    },
    yearExperience: {
      key: 'years_exp',
      label: 'Total Years / Months of Experience',
      placeholder: 'Year',
      validation: [Validators.required],
      type: 'experience',
      errorMessage: {required: 'Year is required'}
    },
    industry: {
      key: 'industry_id',
      label: 'Industry',
      placeholder: 'Select industry',
      validation: [Validators.required],
      type: 'experience',
      errorMessage: {required: 'Industry is required'}
    },
    monthsExperience: {
      key: 'months_exp',
      label: 'Month',
      placeholder: 'MM',
      validation: [Validators.required],
      type: 'experience',
      errorMessage: {required: 'Month is required'}
    },
    mobileNo: {
      key: 'contact_number',
      label: 'Mobile Number',
      countryCode: 'country_contact_code',
      countryISO: 'phone_iso',
      placeholder: 'Enter Mobile Number',
      validation: [Validators.required],
      errorMessage: {
        required: 'Mobile No is required',
        invalid: 'Invalid Mobile No',
        isExist: 'Mobile No already been taken'
      }
    },
    contactNo: {
      key: 'contact_number',
      label: 'Mobile Number',
      countryCode: 'country_contact_code',
      countryISO: 'phone_iso',
      placeholder: 'Enter Mobile Number',
      validation: [],
      errorMessage: {
        invalid: 'Invalid Mobile No',
        isExist: 'Mobile No already been taken'
      }
    },
    fullName: {
      key: 'displayName',
      label: 'full Name',
      placeholder: 'full Name',
      validation: [
        Validators.required,
        Validators.pattern('^[ a-zA-ZÀ-ÖØ-öø-ÿ\'.-]*$')
      ],
      errorMessage: {required: 'Full Name is required', invalid: 'Full Name is invalid'}
    },
    certificationType: {
      key: 'certificationType',
      validation: [
        Validators.required
      ],
    },
    courseName: {
      key: 'course_name',
      validation: [],
    },
    courseFee: {
      key: 'amount',
      validation: [],
    },
    country: {
      key: 'country_id',
      name: 'country_name',
      label: 'Country',
      placeholder: 'Select your Country',
      validation: [],
      errorMessage: {required: 'Country is required'}
    },
    residenceId: {
      key: 'residence_id',
      placeholder: 'Residence name',
      validation: [Validators.required],
      errorMessage: {required: 'Residential Country is required'}
    },
    countryName: {
      key: 'country_name',
      validation: [],
      errorMessage: {required: 'Country Name required'}
    },
    studentRemarks: {
      key: 'student_remarks',
      label: 'Remarks',
      placeholder: 'Remarks',
      validation: [Validators.required],
      errorMessage: {required: 'Remarks is required'}
    },
    workExp: {
      key: 'work_experience',
      label: 'Do you have work experience ?',
      placeholder: 'Do you have work experience ?',
      validation: [],
      type: 'experience',
      errorMessage: {required: 'Work experience is required'}
    },
    qualificationName: {
      key: 'qual_name',
      label: 'Name of the Qualification',
      placeholder: 'BSc Maths, BA History, Higher-Diploma in Business',
      validation: [Validators.required],
      errorMessage: {required: 'Qualification name is required'}
    },
    couponCode: {
      key: 'couponCode',
      placeholder: 'Available Offers',
      validation: []
    },
    designation: {
      key: 'current_designation',
      label: 'Your Designation',
      placeholder: 'Designation',
      validation: [Validators.required],
      type: 'experience',
      errorMessage: {required: 'Designation is required'}
    },
    starRating: {
      key: 'star_rating',
      label: 'Star Rating',
      placeholder: 'Start Rating',
      validation: [Validators.required],
      errorMessage: {required: 'Please rate the course'}
    },
    userComment: {
      key: 'comment',
      label: 'User Comment',
      placeholder: 'How has this course helped you?',
      validation: [],
      errorMessage: {}
    },
    professionalProfile: {
      key: 'professional_profile',
      label: 'Professional Profile',
      placeholder: 'Professional Profile',
      validation: [Validators.required],
      errorMessage: {required: 'This field is required'}
    },
    recommendedCourse: {
      key: 'recommended_course',
      label: 'Recommended Course',
      placeholder: 'Recommended Course',
      validation: [Validators.required],
      errorMessage: {required: 'This field is required'}
    },
    userId: {
      key: 'user_id'
    },
    courseFeeTypeId: {
      key:'course_fee_type_id',
      validation: [],
    },
    utmDetailsId: {
      key: 'utm_details_id'
    },
    dropReasonId: {
      key: 'reason_id',
      validation: [Validators.required],
      errorMessage: {required: 'Reason is required'}
    },
    dropReason: {
      key: 'reason',
      placeholder: 'Tell us what did not work and how we can improve the learning experience for you.'
    },
    hardCopy: {
      key: 'with_hard_copy',
      label: 'Hard Copy'
    },
    courseCategory: {
      key: 'certification_type_id',
      label: 'Course Category',
      placeholder: 'Course Category',
      validation: [Validators.required],
      errorMessage: {required: 'Course category is required'}
    },
    courseNames: {
      key: 'course_name',
      label: 'Choose Your Course',
      placeholder: 'Select your course',
      validation: [Validators.required],
      errorMessage: {required: 'Course name is required'}
    },
    gender: {
      key: 'gender',
      label: 'Gender',
      placeholder: 'Select',
      validation: [
        Validators.required
      ],
      errorMessage: {required: 'Gender is required'}
    },
    certificationTypeId: {
      key: 'course_fee_type_id'
    },
    actualRewardAmount: {
      key: 'is_actual_reward_amount_applied'
    },
    subscriptionId: {
      key: 'subscription_id'
    },
    isEarnCredit: {
      key: 'is_earn_credit_applied'
    },
    proofType: {
      key: 'proof_type_id',
      label: 'Upload Your Photo ID',
      placeholder: 'Upload your Photo ID',
      validation: [
        Validators.required
      ],
      errorMessage :  {required: 'Proof type required'}
    },
    mySubscriptionCount: {
      key: 'my_subscription_count'
    },
    degreePrograms: {
      key: 'interested_in_degree',
      label: 'Degree Programs',
      placeholder: 'Degree Programs',
      validation: [Validators.required],
      errorMessage: {required: 'This field is required'}
    },
    isOnlyCMICoursesAvailable: {
      key: 'is_only_cmi_courses_available'
    }
  }
}
