import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { EventLogsEnum } from '../../../student-dashboard/enum/event-logs-enum';
import { RoutingConstant } from '../../../shared/constant/routing.constant';
import { CommonService } from '../../../services/common.service';
import { CourseFeeTypes } from 'src/app/student-dashboard/enum/course-fee-types'

@Component({
  selector: 'app-certificate-cart-popup',
  templateUrl: './certificate-cart-popup.component.html',
  styleUrls: ['./certificate-cart-popup.component.scss']
})
export class CertificateCartPopupComponent {

  @Input() eligibleCourseInfo: any;
  eventLogsEnum = EventLogsEnum;
  routingConstant = RoutingConstant;
  
  constructor(private commonService: CommonService, private router: Router) {}

  /**
   * Navigate to payment page or certificate generate page
   * @param courseData 
   */
  navigateToCertGenOrPaymentPage(courseData: any) {
    this.commonService.saveStudentClickEventLog(this.eventLogsEnum.cartClaimNowButton, courseData?.cid);
      if (courseData?.orderId) {
        this.router.navigateByUrl(RoutingConstant.PAYMENT_STATUS(courseData?.orderId));
      } else {
        const feeType = courseData?.isCMICourse ? CourseFeeTypes.CMI_CERTIFICATION_FEE : CourseFeeTypes.DIGITAL_CERT_FEE;
        this.router.navigateByUrl(RoutingConstant.PAYMENT_DETAILS(courseData?.cid, feeType));
      }
  }

}
