import { Injectable } from '@angular/core';
import { BaseService } from '../services/base.service';
import { EndpointConstant } from '../shared/constant/endpoint.constant';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { Store } from '@ngxs/store';
import { catchError, filter, pairwise } from 'rxjs/operators';
import { GetStudentProgress, GetSubscriptionPlans, GetUserDetails } from '../actions/student-dashboard.action';
import { EventLogsEnum } from '../student-dashboard/enum/event-logs-enum';
import { Router, RoutesRecognized } from '@angular/router';
import { StudentProgress } from '../model/student-progress.model';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  toggleHeaderPosition: Subject<boolean> = new Subject<boolean>();
  quotesList = [];
  previousUrl: string;
  profileBadge = new BehaviorSubject<boolean>(false);
  pushNotificationBadge =  new BehaviorSubject<number>(null);
  pushNotificationBadgeValue = this.pushNotificationBadge.asObservable();
  pushNotificationGetAllTab: Subject<boolean> = new Subject<boolean>();
  pushNotificationUnreadGlobalValue = 0;
  removeGenericFormObject: Subject<boolean> = new Subject<boolean>();
  togglePollSection = false;
  studentProgressDetails: StudentProgress;
  triggerOnCourseLoginRegister: Subject<boolean> = new Subject<boolean>();
  triggerOnClickOfRegisterLater: Subject<boolean> = new Subject<boolean>();

  constructor(private baseService: BaseService,
    private store: Store,
    private router : Router) {
      this.getRouterEvents();
  }

  /**
   * Get the Router Events
  */
  private getRouterEvents() {
    this.router.events
    .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
    .subscribe((events: RoutesRecognized[]) => {
      this.previousUrl = events[0].urlAfterRedirects;
    });
  }

  /**
   * Get the Previous Route URL
  */
  public getPreviousUrl(){
    return this.previousUrl;
  } 

    
  /**
   * Get the user profile image.
   * @param id of the user.
   */
  getProfileImage(id: number) {
    return this.baseService.get(EndpointConstant.USER_PROFILE_IMAGE(id.toString()))
      .toPromise()
      .then(response => response)
      .catch();
  }

  /**
   * Dispatch student progress action
   */
  dispatchProgress() {
    this.store.dispatch(new GetStudentProgress())
        .pipe(catchError(error => of(console.error(error))))
        .subscribe();
  }

  /**
   * Dispatch Subscription plan details
   */
  dispatchSubscriptionPlans() {
    this.store.dispatch(new GetSubscriptionPlans())
        .pipe(catchError(error => of(console.error(error))))
        .subscribe();
  }

  /**
   * Dispatch Subscription plan details
   */
  dispatchGetUserDetails() {
    this.store.dispatch(new GetUserDetails())
        .pipe(catchError(error => of(console.error(error))))
        .subscribe();
  }

  /**
   * Checking for the user has premium course
   */
  checkPremiumUser() {
    return this.baseService.get(EndpointConstant.PREMIUM_CHECK);
  }

  /*
   * Get quotes loader
   */
  getLoaderQuotes() {
    return this.baseService.get(EndpointConstant.QUOTES_LIST);
  }

  /*
   * Toggle header position (fixed and static)
   * @param - value (boolean) (true for fixed and fals for static)
   */
  updateHeaderPosition(value: boolean) {
    this.toggleHeaderPosition.next(value);
  }

  /*
   * Remove Generic Form Object From Menu
   * @param - value (boolean) (true for remove)
   */
  removeGenericFormObjectFromMenu(value: boolean) {
    this.removeGenericFormObject.next(value);
  }

  /*
   * Toggle Default Tab
   * @param - value (boolean) (true for GetAll and false for other options and not by routing)
   */
  updatePushNotificationGetAllTab(value: boolean) {
    this.pushNotificationGetAllTab.next(value);
  }

  /*
   * Update push notification log
   */
  savePushNotifyLog() {
    const payload = {
      student_activity_type: EventLogsEnum.pushNotification
    };
    return this.baseService.post(EndpointConstant.STUDENT_LOG_SAVE, payload);
  }

  /*
   * Update push notification badge value
   * @param - value (number) that shows count
   */
  updatePushNotificationBadgeValue(value: number) {
    this.pushNotificationUnreadGlobalValue = value;
    this.pushNotificationBadge.next(value);
  }

  /*
   * Trigger after login or register from course related pages
   */
  updatePageAfterLoggedIn() {
    this.triggerOnCourseLoginRegister.next(true);
  }

  /**
   * trigger landing page on click of register later button
   */
  updatePageAfterRegisterLaterClick() {
    this.triggerOnClickOfRegisterLater.next(true);
  }
}
