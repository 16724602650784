import { Component, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dont-show-me-again',
  templateUrl: './dont-show-me-again.component.html',
  styleUrls: ['./dont-show-me-again.component.scss']
})
export class DontShowMeAgainComponent {

  constructor(@Optional() private matDialogRef: MatDialogRef<DontShowMeAgainComponent>) { }

  /**
   * Popup status
   * @param status 
   */
  closePopup(status: boolean) {
    this.matDialogRef.close(status);
  }

}
