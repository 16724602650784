/**
 * Model class for the quiz introduction.
 */
export class QuizIntroduction {
  assessmentName: string;
  courseId: string;
  courseName: string;
  courseSlugName: string;
  moduleName: string;
  modulePosition: number;
  chapterName: string;
  chapterPosition: number;
  unitName: string;
  unitPosition: number;
  lessonName: string;
  lessonPosition: number;
  config: QuizConfig;
  isResumable: number;
  remainingTime: number;
  offerDetails: OfferInfo;
  isPremium: number;
  isLastQuiz: boolean;
}

export class QuizConfig {
  totalQuestions: number;
  isRetake: boolean;
  passMarks: number;
  timeLimit: number;
  marksPerQuestion: number;
  instructions: Array<string>;
}
export interface OfferInfo {
  id: number;
  discount_percentage: number;
  name: string;
  personal_offer: number;
  offer_period_days: number;
  is_personal_offer_active: number;
}
