import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CustomMaterialModule } from '../custom-material/custom-material.module';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';

import { ApplicationConstant } from '../../constant/app.constant';
import { ApplicationDialogComponent } from '../../component/application-dialog/application-dialog.component';
import { ApplicationNotifierComponent } from '../../component/application-notifier/application-notifier.component';
import { ChangePasswordDialogComponent } from '../../component/change-password-dialog/change-password-dialog.component';
import { CkEditorComponent } from '../../component/ck-editor/ck-editor.component';
import { CkEditorModalComponent } from '../../component/ck-editor/ck-editor-modal/ck-editor-modal.component';
import { CountDownTimerComponent } from '../../component/count-down-timer/count-down-timer.component';
import { DragDropUploadFileDirective } from '../../directive/drag-drop-upload-file.directive';
import { NetworkInterceptor } from '../../../interceptor/network.interceptor';
import { PaginatorComponent } from '../../component/paginator/paginator.component';
import { ProgressbarComponent } from '../../component/progressbar/progressbar.component';
import * as Sentry from '@sentry/angular';
import { YearMonthPipe } from '../../pipe/year-month.pipe';
import { ExploreMoreCoursesComponent } from '../../component/explore-more-courses/explore-more-courses.component';
import { AthenaFormComponent } from '../../component/athena-form/athena-form.component';
import { AthenaFormDialogComponent } from '../../component/athena-form-dialog/athena-form-dialog.component';
import { CertificateComponent } from '../../component/certificate/certificate.component';
import { NotFoundDialogComponent } from '../../component/not-found-dialog/not-found-dialog.component';
import { CookieService } from 'ngx-cookie-service';
import { BirthdayOfferComponent } from '../../component/birthday-offer/birthday-offer.component';
import { UserReviewComponent } from '../../component/user-review/user-review.component';
import { TimeFormatPipe } from '../../pipe/time-format.pipe';
import { NgxPermissionsModule } from 'ngx-permissions';
import { HtmlTrimPipe } from '../../pipe/html-trim.pipe';
import { JoyrideModule } from 'ngx-joyride';
import { ClaimCertificateFloatComponent } from '../../component/claim-certificate-float/claim-certificate-float.component';
import { DiscussionComponent } from 'src/app/student-dashboard/discussion/discussion.component';
import { CourseInfoComponent } from 'src/app/student-dashboard/course-info/course-info.component';
import { AlarmSetupDialogComponent } from '../../component/alarm-setup-dialog/alarm-setup-dialog.component';
import { AlarmSetupConfirmDialogComponent } from '../../component/alarm-setup-confirm-dialog/alarm-setup-confirm-dialog.component';
import { AlarmConfirmationComponent } from '../../component/alarm-confirmation/alarm-confirmation.component';
import { CertificateCartPopupComponent } from '../../component/certificate-cart-popup/certificate-cart-popup.component';
import { AlarmReminderPopupComponent } from '../../component/alarm-reminder-popup/alarm-reminder-popup.component';
import { DropCoursePopupComponent } from '../../component/drop-course-popup/drop-course-popup.component';
import { AutoFocus } from 'src/app/shared/directive/auto-focus.directive';
import { TestimonialDetailsComponent } from '../../component/testimonial-details/testimonial-details.component';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { APP_DATE_FORMATS, MatDateAdapter } from '../../utils/mat-data-adapter';
import { EducationDetailsInDownloadComponent } from '../../component/education-details-in-download/education-details-in-download.component';
import { AcademicProgramsComponent } from '../../component/academic-programs/academic-programs.component';
import { NegativeToZeroPipe } from '../../pipe/negative-to.zero.pipe';
import { PreventDoubleClick } from '../../directive/prevent-double-click.directive';
import { CountDownDialogComponent } from '../../component/count-down-dialog/count-down-dialog.component';
import { DontShowMeAgainComponent } from '../../component/dont-show-me-again/dont-show-me-again.component';
import { NumberShortPipe } from '../../pipe/number-short.pipe';
import { PersonalizedOfferPopupComponent } from '../../component/personalized-offer-popup/personalized-offer-popup.component';
import { NgxWheelModule } from 'ngx-wheel';
import { CourseCompletionPopupComponent } from '../../component/course-completion-popup/course-completion-popup.component';
import { PushNotificationPopupComponent } from '../../component/push-notification-popup/push-notification-popup.component';
import { PollDomainComponent } from '../../component/poll-domain/poll-domain.component';
import { CourseLoginRegisterComponent } from '../../component/course-login-register/course-login-register.component';
import { RegisterChildComponent } from '../../component/course-login-register/register-child/register-child.component';

@NgModule({
  declarations: [
    AcademicProgramsComponent,
    ApplicationDialogComponent,
    ApplicationNotifierComponent,
    AthenaFormComponent,
    AthenaFormDialogComponent,
    AlarmConfirmationComponent,
    AlarmReminderPopupComponent,
    AlarmSetupConfirmDialogComponent,
    AlarmSetupDialogComponent,
    AutoFocus,
    BirthdayOfferComponent,
    ChangePasswordDialogComponent,
    CkEditorComponent,
    CkEditorModalComponent,
    ClaimCertificateFloatComponent,
    CourseInfoComponent,
    CountDownTimerComponent,
    DiscussionComponent,
    DontShowMeAgainComponent,
    DragDropUploadFileDirective,
    DropCoursePopupComponent,
    EducationDetailsInDownloadComponent,
    ExploreMoreCoursesComponent,
    CertificateComponent,
    CertificateCartPopupComponent,
    NegativeToZeroPipe,
    NotFoundDialogComponent,
    NumberShortPipe,
    PaginatorComponent,
    PreventDoubleClick,
    PersonalizedOfferPopupComponent,
    ProgressbarComponent,
    PushNotificationPopupComponent,
    UserReviewComponent,
    HtmlTrimPipe,
    YearMonthPipe,
    TimeFormatPipe,
    TestimonialDetailsComponent,
    CountDownDialogComponent,
    CourseCompletionPopupComponent,
    PollDomainComponent,
    CourseLoginRegisterComponent,
    RegisterChildComponent
  ],
  entryComponents: [
    ApplicationDialogComponent,
    ApplicationNotifierComponent,
    AthenaFormDialogComponent,
    AlarmConfirmationComponent,
    AlarmReminderPopupComponent,
    AlarmSetupConfirmDialogComponent,
    AlarmSetupDialogComponent,
    BirthdayOfferComponent,
    ChangePasswordDialogComponent,
    CkEditorModalComponent,
    CertificateComponent,
    CertificateCartPopupComponent,
    ClaimCertificateFloatComponent,
    DontShowMeAgainComponent,
    DropCoursePopupComponent,
    EducationDetailsInDownloadComponent,
    NotFoundDialogComponent,
    UserReviewComponent,
    TestimonialDetailsComponent,
    CountDownDialogComponent,
    PersonalizedOfferPopupComponent,
    CourseCompletionPopupComponent,
    PushNotificationPopupComponent,
    PollDomainComponent,
    CourseLoginRegisterComponent,
    RegisterChildComponent
  ],
  exports: [
    AcademicProgramsComponent,
    AccordionModule,
    AlarmSetupConfirmDialogComponent,
    AlarmConfirmationComponent,
    AlarmReminderPopupComponent,
    AlarmSetupDialogComponent,
    BirthdayOfferComponent,
    BsDropdownModule,
    ChangePasswordDialogComponent,
    CkEditorComponent,
    CkEditorModalComponent,
    CKEditorModule,
    ClaimCertificateFloatComponent,
    CommonModule,
    CourseInfoComponent,
    CountDownTimerComponent,
    CustomMaterialModule,
    DiscussionComponent,
    DontShowMeAgainComponent,
    DragDropUploadFileDirective,
    DropCoursePopupComponent,
    EducationDetailsInDownloadComponent,
    ExploreMoreCoursesComponent,
    FormsModule,
    CertificateComponent,
    CertificateCartPopupComponent,
    JoyrideModule,
    NegativeToZeroPipe,
    NgCircleProgressModule,
    NgxIntlTelInputModule,
    NgxPermissionsModule,
    NgxQRCodeModule,
    NotFoundDialogComponent,
    NumberShortPipe,
    PaginatorComponent,
    PreventDoubleClick,
    PersonalizedOfferPopupComponent,
    ProgressbarComponent,
    ReactiveFormsModule,
    RouterModule,
    UserReviewComponent,
    HtmlTrimPipe,
    YearMonthPipe,
    TimeFormatPipe,
    TestimonialDetailsComponent,
    CountDownDialogComponent,
    NgxWheelModule,
    CourseCompletionPopupComponent,
    PushNotificationPopupComponent,
    PollDomainComponent,
    CourseLoginRegisterComponent,
    RegisterChildComponent
  ],
  imports: [
    AccordionModule.forRoot(),
    BsDropdownModule.forRoot(),
    CKEditorModule,
    CommonModule,
    CustomMaterialModule,
    FormsModule,
    JoyrideModule.forRoot(),
    NgCircleProgressModule.forRoot(ApplicationConstant.progressCircleConfig),
    NgxIntlTelInputModule,
    NgxPermissionsModule.forChild(),
    NgxQRCodeModule,
    ReactiveFormsModule,
    RouterModule,
    NgxWheelModule
  ],
  providers: [
    CookieService,
    DatePipe,
    HtmlTrimPipe,
    NegativeToZeroPipe,
    NumberShortPipe,
    { 
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: DateAdapter, useClass: MatDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class SharedModule { }
