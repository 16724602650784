import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { APP_URL } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(private httpClient: HttpClient) { }

  /**
   * This is a get method for api call.
   * @param endpoint which will be used for getting the data.
   */
  get(endpoint: string): Observable<any> {
    return this.httpClient.get(`${APP_URL.API}${endpoint}`)
      .pipe(catchError((error: any) =>throwError(error)));
  }

  /**
   * This is a get method for api call to acads courses.
   * @param endpoint which will be used for getting the data.
   * @returns 
   */
  getOldLmsData(endpoint: string): Observable<any> {
    return this.httpClient.get(`${APP_URL.OLD_LMS_COURSE_DETAILS_API}${endpoint}`)
      .pipe(catchError((error: any) =>throwError(error)));
  }

  /**
   * This is the post method for api call.
   * @param endpoint which will be used for posting the data.
   * @param data to post
   */
  post(endpoint: string, data: any): Observable<any> {
    return this.httpClient.post(`${APP_URL.API}${endpoint}`, data)
      .pipe(catchError((error: any) => throwError(error)));
  }

  /**
   * This is the put method for api call.
   * @param endpoint which will be used for posting the data.
   * @param data to post
   */
  put(endpoint: string, data: any): Observable<any> {
    return this.httpClient.put(`${APP_URL.API}${endpoint}`, data)
      .pipe(catchError((error: any) => throwError(error)));
  }

  /**
   * This is a delete method for api call.
   * @param endpoint which will be used for deleting the data.
   * @param params additional information
   */
  delete(endpoint: string, params?: HttpParams): Observable<any> {
    return this.httpClient.delete(`${APP_URL.API}${endpoint}`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getThirdParty(endpoint: string): Observable<any> {
    return this.httpClient.get(`${endpoint}`)
      .pipe(tap(result => {
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  postThirdParty(endpoint: string, data: any, token: any= ''): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    if (token !== '') {
      headers  = headers.append('Authorization',  `Bearer ${token}`);
    }
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('accept', 'application/json');

    return this.httpClient.post(`${endpoint}`, data, {headers})
      .pipe(catchError((error: any) => throwError(error)));
  }

  getDetailsFromThirdParty(url: string, token: string) {
    if (token !== '') {
      return fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });
    } else {
      return fetch(url, {
        method: 'GET',
      });
    }
  }

  /**
   * This is a get method for api call.
   * @param endpoint which will be used for getting the data.
   * @param url - api base url
   */
  getMethod(url: string, endpoint: string): Observable<any> {
    return this.httpClient.get(`${url}${endpoint}`)
      .pipe(catchError((error: any) =>throwError(error)));
  }

  /**
   * This is the post method for api call.
   * @param endpoint which will be used for posting the data.
   * @param data to post
   * @param url - api base url
   */
  postMethod(url: string, endpoint: string, data: any): Observable<any> {
    return this.httpClient.post(`${url}${endpoint}`, data)
      .pipe(catchError((error: any) => throwError(error)));
  }

  /**
   * Get encryted details from website endpoint
   * @param endpoint 
   * @returns 
  */
  getEncryptData(endpoint: string): Observable<any> {
    const requestOptions: Object = {
      responseType: 'text'
    };
    return this.httpClient.get(`${APP_URL.WEBSITE_ENCRYPT_API}${endpoint}`, requestOptions)
      .pipe(catchError((error: any) => {
        return throwError(error);
    }));
  }

}
