import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { APP_URL } from '../../environments/environment';
import { FormService } from '../services/form.service';
import { ApplicationConstant } from '../shared/constant/app.constant';
import { AppConfig } from '../shared/config/app.config';
import { CommonService } from '../services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationDialogService } from '../shared/component/application-dialog/application-dialog.service';
import { ApplicationDialogConstant } from '../shared/constant/dialog.constant';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppUtil } from '../shared/utils/app.util';
import { RoutingConstant } from '../shared/constant/routing.constant';
import { EndpointConstant } from "../shared/constant/endpoint.constant";
import { FormUtils } from '../shared/utils/form.utils';

@Component({
  selector: 'app-short-course-sign-up',
  templateUrl: './short-course-sign-up.component.html',
  styleUrls: ['./short-course-sign-up.component.scss']
})
export class ShortCourseSignUpComponent implements OnInit, AfterViewInit, OnDestroy {

  appConfig = AppConfig;
  applicationConstant = ApplicationConstant;
  destroySubscription: Subject<void> = new Subject<void>();
  isDisabledButton = false;
  locationDetails = ApplicationConstant.DEFAULT_COUNTRY;
  mobileViewMenu = false;
  phoneNumberConfig = Object.assign({}, ApplicationConstant.phoneNumberConfig);
  queryParamData: any;
  routingConstant = RoutingConstant;
  signUpForm: FormGroup = this.formBuilder.group({});
  serverError: any;
  showNavigation = true;
  returnUrl = RoutingConstant.STUDENT_DASHBOARD;

  constructor(private activatedRoute: ActivatedRoute,
              private applicationDialogService: ApplicationDialogService,
              public commonService: CommonService,
              private formBuilder: FormBuilder,
              public formService: FormService,
              private router: Router) { }
            
  ngOnInit(): void {
    this.getUTMParameters();
    this.initializeForm();
    this.getCountryCode();
    this.commonService.clearLocalStorageOnLogout();
    this.transformInputFields();

    setTimeout(() => {
      if (window.innerWidth <= 768) {
        this.mobileViewMenu = true;
        this.showNavigation = false;
      }
    });
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  /**
   * Get the UTM parameters for the registration page.
   */
  getUTMParameters() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.queryParamData = params;
    });
  }

  /**
   * Toggle the menu for show/hide.
   *
   * @param toggleState true/false.
   */
  toggleMenu(toggleState: boolean) {
    this.showNavigation = toggleState;
  }

  /**
   * Initialize the form data.
   */
  initializeForm() {
    this.signUpForm = this.formBuilder.group({
      [this.appConfig.formDetails.firstName.key]: ['', this.appConfig.formDetails.firstName.validation],
      [this.appConfig.formDetails.lastName.key]: ['', this.appConfig.formDetails.lastName.validation],
      [this.appConfig.formDetails.emailId.key]: ['', this.appConfig.formDetails.emailId.validation],
      [this.appConfig.formDetails.password.key]: ['', this.appConfig.formDetails.password.validation],
      [this.appConfig.formDetails.acceptTerms.key]: ['', this.appConfig.formDetails.acceptTerms.validation],
      [this.appConfig.formDetails.contactNo.key]: ['', this.appConfig.formDetails.contactNo.validation]
    });
  }

  /**
   * Get the country code.
   */
  getCountryCode() {
    if (this.commonService.countryCode) {
      this.getCountryInfo(this.commonService.countryCode);
    } else {
      this.getThirdPartyApiDetails();
    }
  }

  /**
   * get third party api details
   */
  getThirdPartyApiDetails() {
    this.formService.getDetailsFromThirdParty(`${APP_URL.IP_GEOLOCATION}`, '').then((data: any) => {
      this.getCountryInfo(data.countryCode);
      this.commonService.setCountryCode(data.countryCode);
    }, error => {
       console.error(error);
   });
  }

  /**
   *
   * @param countryCode
   */
  getCountryInfo(countryCode: string) {
    if (this.commonService.locationDetails) {
      this.locationDetails = this.commonService.locationDetails;
      AppUtil.updateFlag(this.phoneNumberConfig, this.locationDetails.countryIsq);
    } else {
      this.formService.getDetails(EndpointConstant.COUNTRY_INFO(countryCode))
      .pipe(takeUntil(this.destroySubscription))
        .subscribe(res => {
          if (res) {
              this.locationDetails = {
                  countryId : res?.country_id,
                  countryCode : res?.iso3,
                  countryContactCode : res?.phonecode ? '+' + res?.phonecode : '',
                  countryIsq : res?.isq
                };
                if (this.locationDetails && this.locationDetails?.countryIsq) {
                  AppUtil.updateFlag(this.phoneNumberConfig, this.locationDetails.countryIsq);
                }
                this.commonService.setLocationDetails(this.locationDetails);
              }
          }, error => {
         console.error(error);
      });
    }
  }

  /**
   * Get all the form control
   */
  get formControl() {
    return this.signUpForm.controls;
  }

  /**
   * Navigate to login page.
   */
  navigateToLoginPage() {
    this.router.navigateByUrl(RoutingConstant.SHORT_COURSE_LOGIN_URL);
  }

  /**
   * Consent dialog for terms and condition.
   *
   * @param event click event for the checkbox
   */
  consentDialog(event) {
    event.preventDefault();
    this.applicationDialogService.showDialogMessage(ApplicationDialogConstant.terms_condition, false);
  }

  /**
   * Register user.
   */
  registerUser() {
    this.isDisabledButton = true;
    const contactControl = this.signUpForm.controls[this.appConfig.formDetails.contactNo.key];
    if (contactControl?.value?.number) {
      contactControl.value.number = this.commonService.trimEmptySpaces(contactControl.value.number);
    }
    this.formService.registerUser(this.signUpForm, this.locationDetails, null, this.queryParamData)
      .then(() => {
        this.isDisabledButton = false;
        this.router.navigateByUrl(RoutingConstant.COURSE_RECOMMENDATION_SECTORS);
      }).catch(reason => {
        this.isDisabledButton = false;
        this.serverError = reason;
      });
  }

  /**
   * Removed unwanted white spaces
   */
  checkAndUpdatePhoneNumber() {
    const contactControl = this.signUpForm.controls[this.appConfig.formDetails.contactNo.key];
    if (contactControl.value) {
      const trimmedContact = this.commonService.trimEmptySpaces(contactControl?.value?.number);
      contactControl.setValue(trimmedContact);
    }
    if (this.serverError?.error?.data?.contact_number) {
      this.serverError.error.data.contact_number = null;
      this.resetErrorMessage();
    }
  }

  /**
   * Reset mail error message while updating
   */
  updatingEmail() {
    if (this.serverError?.error?.data?.mail) {
      this.serverError.error.data.mail = null;
      this.resetErrorMessage();
    }
  }

  /**
   * Reset something went wrong error block
   */
  resetErrorMessage() {
    this.serverError = this.serverError?.error?.data?.contact_number && this.serverError?.error?.data?.mail;
  }

  /**
   * Transform first name and last name if only white spaces
   */
  private transformInputFields() {
    this.signUpForm.valueChanges.subscribe(val => {
      if (FormUtils.resetStringWhiteSpaces(val[this.appConfig.formDetails.firstName.key])) {
        this.signUpForm.get(this.appConfig.formDetails.firstName.key).setValue('');
      }
      if (FormUtils.resetStringWhiteSpaces(val[this.appConfig.formDetails.lastName.key])) {
        this.signUpForm.get(this.appConfig.formDetails.lastName.key).setValue('');
      }
    });
  }

  ngOnDestroy(): void {
    this.destroySubscription.next();
    this.destroySubscription.complete();
  }
}
