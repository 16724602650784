import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ApplicationConstant } from '../shared/constant/app.constant';
import { CommonService } from './common.service';
import { Router } from '@angular/router';
import { APP_URL } from '../../environments/environment';
import { EndpointConstant } from '../shared/constant/endpoint.constant';
import { NgxPermissionsService } from 'ngx-permissions';
import { RoutingConstant } from '../shared/constant/routing.constant';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateService {

  constructor(private baseService: BaseService,
              private commonService: CommonService,
              private ngxPermissionsService: NgxPermissionsService,
              private router: Router) {
  }

  /**
   * Get the token.
   */
  getToken(): string {
    const token = localStorage.getItem(ApplicationConstant.storageKeys.AUTH_TOKEN);
    return token ? `Bearer ${token}` : '';
  }

  /**
   * Set the token.
   * @param token for api calls
   */
  setToken(token: string) {
    localStorage.setItem(ApplicationConstant.storageKeys.AUTH_TOKEN, token);
  }

  /**
   * Check if the token is available.
   */
  isTokenAvailable(): boolean {
    return localStorage.getItem(ApplicationConstant.storageKeys.AUTH_TOKEN) != null;
  }

  /**
   * Check the user is admin.
   */
  checkAdminUserAndRoutePermission(url: string): Promise<boolean> {
    return new Promise(resp => {
      this.baseService.get(EndpointConstant.USER_DETAILS(this.commonService.getUId().toString()))
        .subscribe(() => {
          resp(this.getPermissions(url));
        }, error => {
          console.error('error', error);
          this.commonService.clearLocalStorageOnLogout();
          this.delete_cookie('hideOnBoarding');
          window.location.href = `${APP_URL.OLD_LMS}${ApplicationConstant.externalPages.LOGIN}`;
          resp(false);
        });
    });
  }

  /**
   * Get permissions for the admin user.
   */
  getPermissions(url: string): Promise<boolean> {
    return new Promise(resp => {
      this.baseService.get(EndpointConstant.USER_PERMISSIONS).subscribe(value => {
        const permissions = [];
        value?.permission?.permission_details?.forEach(value => {
          permissions.push(value?.module_permission);
        });
        this.ngxPermissionsService.loadPermissions(permissions);
        resp(true);
      }, error => {
        console.error('Error', error);
        this.routeRedirectionOnTokenExpiry(url);
        resp(false);
      })
    });
  }

  /**
   * Route redirection after token expiry
   * @param url - redirect url
   */
  routeRedirectionOnTokenExpiry(url: string) {
    this.commonService.clearLocalStorageOnLogout();
    if (url.indexOf(RoutingConstant.COURSE_DETAILS) > -1 || url.indexOf(RoutingConstant.VIEW_AS_LEARNER_PAGE) > -1) {
      this.router.navigateByUrl(url);
    } else {
      this.router.navigateByUrl(RoutingConstant.SHORT_COURSE_LOGIN_URL);
    }
  }

  /**
   * Get the authenticate user.
   *
   * @param user details
   * @param isEmailRequest true/false
   * @param isTokenRequest true/false
   */
  checkSessionAndGetUserToken(user: any, isEmailRequest: boolean, isTokenRequest: boolean) {
    if (isEmailRequest) {
      return this.baseService.post(EndpointConstant.TOKEN_EMAIL, user);
    } else if (isTokenRequest) {
      return this.baseService.post(EndpointConstant.TOKEN_SESSION, user);
    } else {
      return this.baseService.post(EndpointConstant.TOKEN_OLD_LMS, user);
    }
  }

  /**
   * Delete the cookie from old lms.
   *
   * @param name text
   */
  delete_cookie(name: string) {
    document.cookie = name + '=; domain=.athena.edu;Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }


  /**
   * Get the authorized user.
   *
   * @param queryParams uid of the user.
   * @param url current url to navigate
   * @param accessPage temporary page to access
   * @param params for the url.
   */
  getTokenAndCheckUserExist(queryParams: any, url: string, accessPage: string, params: any): Promise<boolean> {

    const userDetails = {};
    userDetails[ApplicationConstant.formMetaFields.isShortCourse] = true;

    if (queryParams.uid) {
      userDetails[ApplicationConstant.formMetaFields.uId] = queryParams.uid;
    }

    if (queryParams.user_id) {
      userDetails[ApplicationConstant.formMetaFields.userId] = queryParams.user_id;
    }

    if (queryParams.token) {
      this.setToken(queryParams.token);
    }

    if (queryParams.mail) {
      userDetails[ApplicationConstant.formMetaFields.email] = queryParams.mail;
      return this.validateUserFromEmail(userDetails, url, accessPage);
    } else if (queryParams.token) {
      return this.validateUserSessionFromToken(userDetails, url, queryParams, params);
    } else {
      return this.validateUserSessionInOldLms(userDetails, url);
    }
  }

  /**
   * Validate the user from the session token.
   *
   * @param userDetails user information.
   * @param url landing page.
   * @param queryParams is the fields on the query string
   * @param params for the url.
   * @return true/false
   */
  validateUserSessionFromToken(userDetails: {}, url: string, queryParams: any, params: any): Promise<boolean> {
    return new Promise(resp => {
      this.checkSessionAndGetUserToken(userDetails, false, true).subscribe(res => {
        this.commonService.setUId(res?.data?.user_details?.uid);
        this.setToken(res?.data?.token);
        this.commonService.setFirstName(res?.data?.user_details?.first_name);
        if (res?.data?.user_details?.cid) {
          this.commonService.course = res.data.user_details.cid;
        }

        //get the courseid from the url.
        const courseId = params?.courseId ? Number(params?.courseId) : null
        this.commonService.getAndSaveUTMDetails(queryParams, courseId);

        if (queryParams?.shared_uid) {
          url += `?${ApplicationConstant.formMetaFields.utmMedium}=${queryParams?.[ApplicationConstant.formMetaFields.utmMedium]}` +
          `&${ApplicationConstant.formMetaFields.utmCampaign}=${queryParams?.[ApplicationConstant.formMetaFields.utmCampaign]}` +
          `&${ApplicationConstant.formMetaFields.utmSource}=${queryParams?.[ApplicationConstant.formMetaFields.utmSource]}` +
          `&${ApplicationConstant.formMetaFields.utmPage}=${queryParams?.[ApplicationConstant.formMetaFields.utmPage]}` +
          `&${ApplicationConstant.formMetaFields.sharedUId}=${queryParams?.[ApplicationConstant.formMetaFields.sharedUId]}`;
          if(queryParams?.[ApplicationConstant.formMetaFields.utmPosition]) {
            url += `&${ApplicationConstant.formMetaFields.utmPosition}=${queryParams?.[ApplicationConstant.formMetaFields.utmPosition]}`
          }
        } else if (queryParams?.utm_medium) {
          url += `?${ApplicationConstant.formMetaFields.utmMedium}=${queryParams?.[ApplicationConstant.formMetaFields.utmMedium]}` +
          `&${ApplicationConstant.formMetaFields.utmCampaign}=${queryParams?.[ApplicationConstant.formMetaFields.utmCampaign]}` +
          `&${ApplicationConstant.formMetaFields.utmSource}=${queryParams?.[ApplicationConstant.formMetaFields.utmSource]}` +
          `&${ApplicationConstant.formMetaFields.utmPage}=${queryParams?.[ApplicationConstant.formMetaFields.utmPage]}`;
          if(queryParams?.[ApplicationConstant.formMetaFields.utmPosition]) {
            url += `&${ApplicationConstant.formMetaFields.utmPosition}=${queryParams?.[ApplicationConstant.formMetaFields.utmPosition]}`
          }
        }
        this.router.navigateByUrl(url);
        resp(true);
      }, error => {
        console.error('error', error);
        this.commonService.clearLocalStorageOnLogout();
        this.router.navigateByUrl(RoutingConstant.SHORT_COURSE_LOGIN_URL);
        resp(false);
      });
    });
  }

  /**
   * Validate the user from the old lms.
   *
   * @param userDetails user information.
   * @param url landing page.
   * @return true/false
   */
  validateUserSessionInOldLms(userDetails: any, url: string): Promise<boolean> {
    return new Promise(resp => {
      this.checkSessionAndGetUserToken(userDetails, false, false).subscribe(res => {
        this.commonService.setUId(res?.data?.user_details?.uid);
        this.setToken(res?.data?.token);
        this.commonService.setFirstName(res?.data?.user_details?.first_name);
        if (res?.data?.user_details.cid) {
          this.commonService.course = res?.data?.user_details?.cid;
        }
        this.router.navigateByUrl(url);
        resp(true);
      }, error => {
        console.error('error', error);
        this.commonService.clearLocalStorageOnLogout();
        this.delete_cookie('hideOnBoarding');
        window.location.href = `${APP_URL.OLD_LMS}${ApplicationConstant.externalPages.LOGIN}`;
        resp(false);
      });
    });
  }

  /**
   * Validate the user from the email.
   *
   * @param userDetails user information.
   * @param url landing page.
   * @param accessPage temporary page to access.
   * @return true/false
   */
  validateUserFromEmail(userDetails: any, url: string, accessPage: string): Promise<boolean> {
    return new Promise(resp => {
      this.checkSessionAndGetUserToken(userDetails, true, false)
        .subscribe(res => {
          this.commonService.setUId(res?.data?.user_details?.uid);
          this.setToken(res?.data?.token);
          this.commonService.setFirstName(res?.data?.user_details?.first_name);
          this.router.navigateByUrl(url);
          resp(true);
        }, error => {
          console.error('error', error);
          this.commonService.clearLocalStorageOnLogout();
          resp(false);
          this.router.navigateByUrl(RoutingConstant.SHORT_COURSE_LOGIN_URL);
        });
    });
  }

}
