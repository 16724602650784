/**
 * event logs
 */
export enum EventLogsEnum {
    videoPlay = 1,
    essentialReadingReadMore,
    additionalReadingDownload,
    referenceMaterialDownload,
    offlineReadingDownload,
    shareCertificateFacebook,
    shareCertificateLinkedin,
    shareCertificateTwitter,
    payNowInitiated,
    courseCardClaimNow,
    dashboardClaimNow,
    lessonViewed,
    shareCertificateWhatsApp,
    shareCertificateMail,
    browserClosed,
    shareCourseFacebook,
    shareCourseLinkedin,
    shareCourseTwitter,
    shareFacebookWestford,
    shareInstagramWestford,
    shareLinkedinWestford,
    shareTwitterWestford,
    reportCardViewed,
    checkedOutOtherCourses,
    paymentInitiatedInEligibleForCertification,
    videoViewed,
    LessonMarkedAsCompleted,
    referenceMaterialsDownload,
    offlineReadingDownloadNow,
    offlineReadingMailNow,
    certificateShareNow,
    facebookShareWithYourFriendsDashboard,
    twitterShareWithYourFriendsDashboard,
    linkedinShareWithYourFriendsDashboard,
    courseCategoryClickInWebsite,
    courseCategoryClickInLms,
    certificationTypeClickInWebsite,
    certificationTypeClickInLms,
    lessonStartTime,
    lessonEndTime,
    studentLogoutTime,
    claimYourCertificate,
    reportCardClaimNow,
    coachMarks,
    floatingStartNow,
    earnRewardsAndCertificationEligibility,
    shareCourseWhatsApp,
    shareCourseMail,
    alarmSetupNow,
    alarmMaybeLater,
    alarmSave,
    recommendedShortCourseKnowMore,
    viewSampleCertificate,
    cartIcon,
    cartClaimNowButton,
    myProfile,
    changePassword,
    contactUs,
    FAQ,
    certificateIssued,
    claimYourCertificates,
    myRewards,
    logout,
    shareAndEarn,
    notificationCertificate,
    notificationCloseButton,
    discussionBoardAutoReply,
    faqCertification,
    faqCoursesEnrollment,
    faqLearning,
    caseStudyDownloads,
    eLibrary,
    practiceQuiz,
    courseLandingMoreInfo,
    pushNotification,
    quizReportMailNow,
    quizReportDownload,
    quizResultCountDown,
    surveyFeedbackButton,
    studentDashboardAcademic,
    studentDashboardShortCourse,
    unlockModule,
    classicPlanSubscribe,
    silverPlanSubscribe,
    goldPlanSubscribe,
    paymentSubscribeType,
    paynow,
    startNowPremiumcourse,
    addToMyCourses,
    planExpiredRenew,
    registerSectorSkip,
    registerSectorNext,
    registerCategorySkip,
    registerCategoryNext,
    registerRecommendCourseListSkip,
    doNotShowAgainAlarm,
    startNowTrending,
    startNowNewlyReleased,
    startNowRecommendation,
    mySubscriptionDashboard,
    mySubscriptionProfile,
    mySubscriptionAddPlan,
    mySubscriptionRenewPlan,
    mySubscriptionAddMoreCourses,
    mySubscriptionCourseCardRenewPlan,
    receiptDownloadButton,
    requestForHardCopyButton,
    requestForHardCopyCheck,
    whyThisCourse,
    pollDomain,
    notificationBellClaimNow,
    courseDetailClaimNow,
    claimWithOfferFromPopup,
    claimWithoutOfferFromPopup,
    backToCourseButtonPopup,
    courseCompletionCloseButton,
    updateProfileButtonPopup,
    spinTheWheelButton,
    personalizedClaimNowButton,
    claimMyOfferButton,
    lessonPageCountDown,
    checkEligibilityDegreeBanner,
    checkEligibilityAcademicProgram,
    degreeBanner,
    recommendedAcademicKnowMore,
    courseAlarmSetTimer,
    courseAlarmNotInterested,
    courseAlarmSubmitFeedback,
    offerDropDownClick,
    offerApplyClick,
    courseDetailsEarnCredit,
    earnCreditsProceedNow,
    earnCreditsAccessAssignments,
    earnCreditsViewCertificate,
    earnCreditsAwardingBodyKnowMore,
    earnCreditsCreditsKnowMore,
    registrationPayNowPopup,
    moduleFeePayNowPopup
}
