import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppConfig } from '../shared/config/app.config';
import { ApplicationConstant } from '../shared/constant/app.constant';
import { ApplicationNotifierConstant } from '../shared/constant/notifier.constant';
import { ApplicationNotifierService } from '../shared/component/application-notifier/application-notifier.service';
import { CommonService } from '../services/common.service';
import { FormService } from '../services/form.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {EndpointConstant} from "../shared/constant/endpoint.constant";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  @Input() data: any;
  @Output() isClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  appConfig = AppConfig;
  applicationConstant = ApplicationConstant;
  applicationNotifierConstant = ApplicationNotifierConstant;
  forgotPasswordForm: FormGroup = this.formBuilder.group({});
  destroySubscription: Subject<void> = new Subject<void>();

  constructor(private applicationNotifierService: ApplicationNotifierService,
              private commonService: CommonService,
              private formBuilder: FormBuilder,
              private formService: FormService,
              private matDialog: MatDialog) {
  }

  ngOnInit(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('modal-open');
    this.initializeForm();
    this.forgotPasswordForm.patchValue({
      [this.appConfig.formDetails.recoveryEmailId.key] :
      this.data && this.data?.mail ? this.data?.mail : ''
    });
  }

  /**
   * Initialize the form
   */
  initializeForm() {
    this.forgotPasswordForm = this.formBuilder.group({
      [this.appConfig.formDetails.recoveryEmailId.key]: ['',
        this.appConfig.formDetails.recoveryEmailId.validation]
    });
  }

  get formControl() {
    return this.forgotPasswordForm.controls;
  }

  /**
   * Save form details
   * @param data
   */
  saveDetails(data: any) {
    this.forgotPasswordForm.markAllAsTouched();
   if (this.forgotPasswordForm.valid) {
    const formData = Object.assign({}, data);
    formData[this.applicationConstant.formMetaFields.uId] = this.commonService.getUId();
    this.formService.saveDetails(EndpointConstant.FORGOT_PASSWORD, formData)
        .pipe(takeUntil(this.destroySubscription))
        .subscribe(result => {
          this.applicationNotifierService.getNotifier(this.applicationNotifierConstant.reset_link);
    }, error => {
      console.error('error', error);
      if(error?.error?.status === 400) {
        this.applicationNotifierService.getNotifier(this.applicationNotifierConstant.invalid_email);
      } else {
        this.applicationNotifierService.getNotifier(this.applicationNotifierConstant.error_message);
      }
    });
  }
}

  /**
   * Back to login
   */
  backToLogin() {
    this.isClose.emit(true);
  }

  /**
   * back to profile
   */
  backToProfile() {
    this.isClose.emit(true);
  }

  /**
   * Destroy the component
   */
  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('modal-open');
    this.destroySubscription.next();
    this.destroySubscription.complete();
  }

}
