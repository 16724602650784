import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormService } from '../services/form.service';
import { EndpointConstant } from '../shared/constant/endpoint.constant';
import { RoutingConstant } from '../shared/constant/routing.constant';
import { NotFoundDialogComponent } from '../shared/component/not-found-dialog/not-found-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from '../services/common.service';
import { Title } from '@angular/platform-browser';
import { ApplicationConstant } from '../shared/constant/app.constant';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('faqTextbox') faqInput;
  routingConstant = RoutingConstant;
  faqCategories = [];
  faqDetails = [];
  searchText = '';
  selectedFAQ = {
    title: '',
    description: ''
  };
  destroySubscription: Subject<void> = new Subject<void>();
  applicationConstant = ApplicationConstant;

  constructor(private commonService: CommonService,
              private formService: FormService,
              private matDialog: MatDialog,
              private title: Title) { }

  ngOnInit(): void {
    this.getFAQCategories();
    this.getFaqDetails();
    this.title.setTitle(this.applicationConstant.META_TITLE);
  }

  ngAfterViewInit(): void {
    this.faqInput.nativeElement.focus();
  }

  /**
   * Get FAQ Categories.
   */
  getFAQCategories(){
    this.formService.getDetails(EndpointConstant.FAQ_CATEGORIES_GET)
        .pipe(takeUntil(this.destroySubscription))
        .subscribe(resp => {
          this.faqCategories = resp?.data;
          this.toggleFAQType('Certification');
        }, error => {
          console.error('Error', error);
        })
  }

  /**
   * Get FAQ details.
   */
  getFaqDetails() {

    const endpoint = this.searchText ? EndpointConstant.FAQ_GET_DETAILS_SEARCH_TEXT(this.searchText)
      : EndpointConstant.FAQ_GET_DETAILS;

    this.formService.getDetails(endpoint)
        .pipe(takeUntil(this.destroySubscription))
        .subscribe(resp => {
          this.faqDetails = resp?.data;

          if (this.searchText) {
            const searchCategories = Object.keys(resp?.data);
            if (searchCategories.length && searchCategories[0]) {
              this.toggleFAQType(searchCategories[0]);
            } else {
              this.showNotFoundDialog();
            }
          }
    }, error => {
      console.error('Error', error);
    })
  }

  /**
   * Toggle FAQ details.
   *
   * @param faq details.
   */
  toggleFAQDetails(faq: any) {
    faq.isShow = !faq?.isShow;
  }

  /**
   * Toggle FQA Type.
   *
   * @param type of FAQ.
   */
  toggleFAQType(type) {
    const faqCategory = this.faqCategories.find(value => value.name === type);
    this.selectedFAQ = { title: type, description: faqCategory?.description };
    this.commonService.captureFaqEventLogs(type);
  }

  /**
   * Search the FAQ question.
   */
  searchFAQQuestion() {
    this.getFaqDetails();
  }
 
  /**
   * Clear the search text
   */
  clearSearch() {
    this.searchText = '';
    this.getFaqDetails();
  }

  /**
   * Show not found dialog.
   */
  private showNotFoundDialog() {
    this.matDialog.open(NotFoundDialogComponent, {
      panelClass: 'ath-not-found-dialog'
    });
  }

  ngOnDestroy(): void {
    this.destroySubscription.next();
    this.destroySubscription.complete();
  }

}