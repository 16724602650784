/**
 * QA Environment properties file.
 */
export const environment = {
  production: false,
  name: 'qa',
  plugins: [],
  firebase: {
    apiKey: "AIzaSyA8p9tw-9Px9uuwEQjV7QIHE-j92NB2Y2o",
    authDomain: "uniathena-dev.firebaseapp.com",
    databaseURL: "https://uniathena-dev-default-rtdb.firebaseio.com",
    projectId: "uniathena-dev",
    storageBucket: "uniathena-dev.appspot.com",
    messagingSenderId: "1001829032507",
    appId: "1:1001829032507:web:3eee4e63826dc924d6b21e",
    measurementId: "G-JQ2JENPBSK" 
  }
};

/**
 * Application URLs.
 */
export const APP_URL = {
  API: 'https://learnqa.uniathena.com/athenaqa/api/',
  API_NEW: 'https://learnqa.uniathena.com/athenaapi/api/',
  CLAIM_CERTIFICATE: 'https://weverifyapi.azurewebsites.net/ClaimCertificate/Claim',  //confirm
  CLAIM_CERTIFICATE_UPDATE:'https://weverifyapi.azurewebsites.net/ClaimCertificate/ClaimFileUpdate',  //confirm
  COUNTRY_FLAG: 'https://flagcdn.com/',
  LOGIN_CERT_CC:'https://www.weverifies.com/users',  //confirm
  CLAIM_CERTIFICATE_CC: 'https://www.weverifies.com/channels/mychannel/chaincodes/cert_cc',  //confirm
  IP_GEOLOCATION: 'https://extreme-ip-lookup.com/json/?key=Q2XTHdI7x9BFavSEj8pj',
  OLD_LMS: 'https://ulearn.schneidestaging.in/', //confirm
  OLD_LMS_COURSE_DETAILS_API: 'https://athenaapi.schneidestaging.in/Course/',
  PAYMENT_GATEWAY: 'https://perfoweb.payments.uniathena.com/payment_form?OrderId=', //confirm
  RAZOR_PAY_KEY_ID: 'rzp_test_4aMp9dIIVhVhcm',
  WEBSITE: 'https://perfoweb.uniathena.com/', //confirm
  VERIFY_URL: 'https://perfoweb.uniathena.com/verify/certificate?certID=',
  WEBSITE_ENCRYPT_API: 'https://athenaapi.uniathena.com/Encript/GetEncriptValue/',
  NEW_LMS_URL: 'https://qa.uniathena.com/lms/',
  ZOHO_DESK_URL: 'https://uniathena.zohodesk.in/'
};

/**
 * Social login client Ids.
 */
export const SOCIAL_LOGIN = {
  GOOGLE_PROVIDER_ID: '276645629606-l0lte3d7aag43kmsm8g339rhhrhaev2o.apps.googleusercontent.com',
  FACEBOOK_PROVIDER_ID: '207887187554142',
  MICROSOFT_CLIENT_ID: '5a8660a7-2f19-4f72-96a5-9e1980b57add'
};

/**
 * Application configurations.
 */
export const APP_CONFIGURATION = {
  CATEGORY_ID: 74
};

/**
 * Application keys.
 */
export const APP_KEYS = {
  IP_GEOLOCATION: '90a52fe906a94d778219bd6d0c76b4e8',
};

