import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import { APP_URL } from 'src/environments/environment';
import { EndpointConstant } from '../../constant/endpoint.constant';
import { ApplicationNotifierService } from '../application-notifier/application-notifier.service';
import { ApplicationNotifierConstant } from '../../constant/notifier.constant';
import { HeaderService } from 'src/app/header/header.service';

@Injectable({
  providedIn: 'root'
})
export class PollDomainService {

  isActivePoll = true;

  constructor(private baseService: BaseService,
              private headerService: HeaderService,
              private applicationNotifierService: ApplicationNotifierService) {}

  /**
   * Get domain list
  */
  getPollList() {
    this.baseService.getMethod(APP_URL.API_NEW, EndpointConstant.POLLS_LIST)
      .subscribe((res) => {
          if (res?.data) {
            const index = res?.data?.questionWithOptions?.findIndex(poll => poll?.is_voted === 0);
            this.isActivePoll = index > -1;
            if (this.isActivePoll) {
              this.headerService.togglePollSection = true;
            } else {
              this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.poll_question_empty);
            }
          }
        }, error => {
          console.error('Error', error);
        });
  }
    
}
