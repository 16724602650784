<div class="main" style="background-image: url('assets/bday-off/bdaybg.png'); background-size:100%;">
    <button mat-dialog-close class="close-btn">X</button>
    <div class="sub">
        <mat-grid-list cols="3" rowHeight="45px">
            <mat-grid-tile colspan="2" class="left">
                <p class="bday-title">Birthday Offer!!!</p>
            </mat-grid-tile>
            <mat-grid-tile rowspan="4"><img src="assets/bday-off/bdayoff.png" /></mat-grid-tile>
            <mat-grid-tile colspan="2">
              <p class="bday-amount" *ngIf="dialogData?.offer_details?.discount_percentage > 0
                else discountAmount" >
                Flat {{dialogData?.offer_details?.discount_percentage}}% Off</p>
              <ng-template #discountAmount>
              <p class="bday-amount">
                Get ${{dialogData?.offer_details?.discount_amount}} Off</p>
              </ng-template>
            </mat-grid-tile>
            <mat-grid-tile colspan="2" rowspan="2">
              <p *ngIf="dialogData?.offer_details?.discount_percentage > 0  else offerAmount"
                  class="bday-detail"  rowspan="2">
                Redeem your certificate with a flat {{dialogData?.offer_details?.discount_percentage}}% off*
                on your favourite course within {{dialogData?.offer_details?.days_left}} days.
              </p>
              <ng-template #offerAmount>
                <p class="bday-detail"  rowspan="2">
                  Redeem your certificate with a flat ${{dialogData?.offer_details?.discount_amount}} off*
                  on your favourite course within {{dialogData?.offer_details?.days_left}} days.
                </p>
              </ng-template>
            </mat-grid-tile>
            <mat-grid-tile colspan="3" rowHeight="10px">
                <div class="hr"></div>
            </mat-grid-tile>
            <mat-grid-tile colspan="3">
              <div class="flex-container">
                <div *ngIf="dialogData?.iseligibleForClaimCertificate === true else noClaimCertificate">
                  <button class="claim-now claim-mob"
                          [routerLink]="routingConstant.ELIGIBLE_FOR_CLAIM_CERTIFICATE"
                          (click)="sendBirthdayOfferEmail()"
                          mat-dialog-close>Claim Now
                  </button>
                  <button
                    (click)="sendBirthdayOfferEmail()"
                    class="claim-later claim-mob"
                    mat-dialog-close>Claim Later
                  </button>
                </div>
                <ng-template #noClaimCertificate>
                  <div *ngIf="dialogData?.isPaymentPage else text">
                    <button class="claim-now claim-mob"
                            [mat-dialog-close]="true">Apply Now
                    </button>
                    <button class="claim-later claim-mob"
                            mat-dialog-close>Cancel
                    </button>
                  </div>
                </ng-template>
                <ng-template #text>
                  <span class="hurry-up">Hurry Up!</span>
                </ng-template>
              </div>
            </mat-grid-tile>

        </mat-grid-list>
    </div>
</div>

<div class="mobile">
  <button mat-dialog-close class="mob-close-btn"><img  src="assets/webinar/close-btn.png"></button>
  <div class="mob-sub">
    <p class="mob-bday-title">
      Birthday Offer!!!
    </p>
    <img src="assets/bday-off/bdayoff.png" width="100"/>
    <ng-container *ngIf="dialogData?.offer_details?.discount_percentage > 0 else amountWithDollar">
      <p class="mob-bday-amount p-2">
        Flat {{ dialogData?.offer_details?.discount_percentage }}% Off
      </p>
      <p class="mob-bday-detail">
        Redeem your certificate with a flat {{ dialogData?.offer_details?.discount_percentage }}%
        off* on your favourite course within {{ dialogData?.offer_details?.days_left }} days.
      </p>
    </ng-container>
    <ng-template #amountWithDollar>
      <p class="mob-bday-amount p-2">
        Get ${{ dialogData?.offer_details?.discount_amount }} Off
      </p>
      <p class="mob-bday-detail">
        Redeem your certificate with a flat ${{ dialogData?.offer_details?.discount_amount }}
        off* on your favourite course within {{ dialogData?.offer_details?.days_left }} days.
      </p>
    </ng-template>
    <div class="hr"></div>
    <div *ngIf="dialogData?.iseligibleForClaimCertificate === true else noClaimCertificateMobile">
      <button [routerLink]="routingConstant.ELIGIBLE_FOR_CLAIM_CERTIFICATE"
              (click)="sendBirthdayOfferEmail()"
              class="mob-claim-now"
              mat-dialog-close>
        Claim Now
      </button>
      <button mat-dialog-close
              (click)="sendBirthdayOfferEmail()"
              class="mob-claim-later">
        Claim Later
      </button>
    </div>
    <ng-template #noClaimCertificateMobile>
      <div *ngIf="dialogData?.isPaymentPage === true else textMobile">
        <button class="mob-claim-now"
                [mat-dialog-close]="true">
          Apply Now
        </button>
        <button class="mob-claim-later"
                mat-dialog-close>
          Cancel
        </button>
      </div>
    </ng-template>
    <ng-template #textMobile>
      <span class="hurry-up">Hurry Up!</span>
    </ng-template>
  </div>
</div>
