<div class="reset-body">
  <div class="header">
    <span class="reset-pswd-text">Reset my password</span>
    <p class="email-passsword-text">Please enter your email address and a new password.</p>
  </div>
  <form [formGroup]="responseResetForm" (ngSubmit)="resetPassword(responseResetForm)">
    <div class="reset-form">
      <div class="email-address">
        <label class="email-lable">Email Address</label>
        <input placeholder="Enter Email Address"
               formControlName="email"
               type="text"
               required
               class="input-filed"
               readonly
        />
      </div>
      <div class="password">
        <label class="email-lable">Password</label>
        <input placeholder="Enter Password"
               formControlName="password"
               type="password"
               required
               class="input-filed"/>
      </div>
      <span *ngIf="resetFormControl['password'].invalid && resetFormControl['password'].touched"
            class="error-block">Please enter password</span>
      <div class="reset-password">
        <label class="email-lable">Confirm Password</label>
        <input placeholder="Enter Confirm Password"
               formControlName="confirmPassword"
               type="password"
               required
               class="input-filed"/>
      </div>
      <span *ngIf="resetFormControl['confirmPassword'].invalid && resetFormControl['confirmPassword'].touched" class="error-block">
              Please enter confirm password
      </span>
      <span *ngIf="responseResetForm.get('confirmPassword').valid
      && (responseResetForm.get('confirmPassword').value != responseResetForm.get('password').value)"
            class="error-block">Confirm Password does not match with password.</span>
      <div class="check-box">
        <mat-checkbox formControlName="termAndCondition" ngDefaultControl required>
          <span>I accept the
            <a [href]="navigateWebsiteLink()"
               target="_blank">
              Terms and Conditions
            </a>
          </span>
        </mat-checkbox>
      </div>
      <span *ngIf="!responseResetForm.get('termAndCondition').valid && !isResetFormValid"
            class="error-block">Please accept the terms and conditions.</span>
      <div class="submit-btn-div">
        <button type="submit" class="submit-btn">Submit</button>
      </div>
    </div>
  </form>
</div>
