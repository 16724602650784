import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApplicationDialogComponent } from './application-dialog.component';
import { DialogData } from './dialog-data.interface';
import { ApplicationDialogConstant } from '../../constant/dialog.constant';
import { RoutingConstant } from '../../constant/routing.constant';
import { QuizAssessmentService } from 'src/app/assessment/quiz-assessment/quiz-assessment.service';
import { LoaderService } from 'src/app/services/loader.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationDialogService {

  applicationDialogConstant = ApplicationDialogConstant;
  isQuizClosed = false;

  constructor(public matDialog: MatDialog,
              private quizAssessmentService: QuizAssessmentService,
              private loaderService: LoaderService,
              private router: Router) { }

  /**
   * Show dialog message.
   *
   * @param dialogData data for dialog
   * @param isRouteAvailable if routing need to be done after click of Okay button
   * @param route which page to be route
   */
  showDialogMessage(dialogData: DialogData, isRouteAvailable: boolean, route?: string) {

    const dialogRef = this.matDialog.open(ApplicationDialogComponent, {
      data: dialogData,
      panelClass:'ath-terms-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (isRouteAvailable) {
        this.router.navigate([route]);
      }
    });
  }

  /**
   * Confirmation Dialog
   */
  confirmDialog(message: string, setting?: any) {
    const settings: any = !setting ? (Object.assign({}, this.applicationDialogConstant.confirmDialog)) : (Object.assign({}, setting)) ;
    settings.content = message;
    const dialogRef = this.matDialog.open(ApplicationDialogComponent, {
        height: 'auto',
        minWidth: '300px',
        maxWidth: '800px',
        panelClass:'ath-confirm-dialog',
        data: settings
      });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.loaderService.isPageRendered = false;
        const url = `${RoutingConstant.QUIZ_ASSESSMENT_INSTRUCTION}?cid=${this.quizAssessmentService?.quizAssessmentDetails?.courseId}`;
        this.router.navigateByUrl(url);

        // To trigger introduction api to get latest response
        this.quizAssessmentService.triggerQuizIntroduction();
        this.isQuizClosed = true;
      }
    });
    return false;
  }

  /**
   * Confirmation Dialog for payment page.
   *
   * @param isRouteAvailable if routing need to be done after click of Okay button
   * @param route which page to be route
   * @param message text.
   */
  paymentConfirmDialog(message: string, isRouteAvailable: boolean, route?: string, popupType?: string) {
    const confirmText = popupType === 'otherFee' || popupType === 'alreadyClaimed' ? 
                        this.applicationDialogConstant.feeConfirmDialog : 
                        this.applicationDialogConstant.paymentConfirmDialog;
    const settings: any = Object.assign({}, confirmText);
    settings.content = message;
    const dialogRef = this.matDialog.open(ApplicationDialogComponent, {
      height: 'auto',
      width: '450px',
      panelClass:'ath-confirm-dialog',
      data: settings,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(resp =>{
      if(isRouteAvailable) {
        this.router.navigate([route]);
      }
    });
  }

  /**
   * Alert Dialog message.
   *
   * @param message text.
   */
  alertDialog(message: string, size = this.applicationDialogConstant.alertPopupSize.SMALL): Promise<boolean> {
    const settings: any = Object.assign({}, this.applicationDialogConstant.alertDialog);
    settings.content = message;
    const dialogRef = this.matDialog.open(ApplicationDialogComponent, {
      height: 'auto',
      width: size,
      panelClass:'ath-confirm-dialog',
      data: settings
    });
    return dialogRef.afterClosed().toPromise();
  }
}
