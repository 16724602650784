import {map, startWith} from 'rxjs/operators';
import {FormGroup} from '@angular/forms';

export class FormUtils {

  /**
 * Watch the field change List.
 *
 * @param fieldConfigName field config for field name.
 * @param form FormGroup
 * @param fieldList is the List
 * @param fieldName name of the field
 */
  static watchFieldChange(fieldConfigName: string, form: FormGroup, fieldList: Array<any>, fieldName: string) {
    return form.controls[fieldConfigName].valueChanges.pipe(startWith(''),
      map(value => value ? FormUtils.filterFieldList(value, fieldList, fieldName) : fieldList.slice())
    );
  }

  /**
   * Filter the field list.
   *
   * @param value field name or field object
   * @param fieldList is the field list.
   * @param fieldName name of the field.
   */
  static filterFieldList(value: any, fieldList: Array<any>, fieldName: string): any[] {
    let filterValue;
    if (typeof value === 'string' || value instanceof String) {
      filterValue = value?.toLowerCase();
    } else {
      filterValue = value[fieldName]?.toLowerCase();
    }
    return fieldList.filter(field => field[fieldName]?.toLowerCase()?.indexOf(filterValue) === 0);
  }
  
  /**
   * Reset white spaces to empty string 
   */
  static resetStringWhiteSpaces(value: string) {
    // Regex to find only empty spaces in a string
    const regexWhiteSpace = new RegExp(/^\s+$/gm);
    return regexWhiteSpace.test(value);
  }

}
