import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Validator for checking two form field are equal.
 *
 * @param controlName
 * @param matchingControlName
 * @constructor
 */
export function ConfirmedValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors?.['confirmedValidator']) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({confirmedValidator: true});
    } else {
      matchingControl.setErrors(null);
    }
  }
}


/**
 * Validator for checking two form field are equal.
 *
 * @param controlName
 * @param matchingControlName
 * @constructor
 */
 export function NewPasswordValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors?.['newPasswordValidator']) {
      return;
    }
    if (control.value === matchingControl.value) {
      matchingControl.setErrors({newPasswordValidator: true});
    } else {
      matchingControl.setErrors(null);
    }
  }
}

/**
 * Check whether url contains media domain name or not
 * @param mediaType - media type like facebook 
 */
export function CheckMediaLink(mediaType: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const regex = new RegExp(mediaType, 'g');
    if (!control.value) {
      return null;
    }
    if (control.value.match(regex) > -1) {
      return { checkMediaLink : true };
    }
    return null;
  }
}