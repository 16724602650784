<div class="main" *ngIf="pushNotificationList?.length > 0">
  <div class="head">
    <h3 class="notifications">Notifications</h3>
    <h3 class="mark-read" (click)="markAllAsRead()">
      <img src="assets/notifications/tick.svg" alt="" class="tick"/>
      Mark all as read
    </h3>
  </div>
  <div class="body" *ngIf="pushNotificationList?.length > 0 else noRecordsFound">
    <div *ngFor="let notification of pushNotificationList" class="flex-div" [class.read]="notification?.is_read === 1">
      <div class="child-one">
        <img [src]="getIconUrl(notification?.notification_type)" alt="" class="notify-img">
      </div>
      <div class="child-two">
        <p class="notify-offer">{{ notification?.message }}</p>
        <p class="offer-date">{{ notification?.created_at | date: 'MMM d\',\' yyyy \'at\' h:mm a' }}</p>
      </div>
      <div class="child-three">
        <span class="dot"></span>
      </div>
    </div>
  </div>
  <ng-template #noRecordsFound>
    <h2 class="mt-3">No Records Found</h2>
  </ng-template>
  <div class="view-all-parent">
    <p class="view-all pointer" 
    (click)="navigateToPushNotificationPage()"
    >View all notifications</p>
  </div>
</div>