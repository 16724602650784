export const ApplicationDialogConstant = {

  terms_condition: {
    isTitle: true,
    title: 'TERMS AND CONDITIONS',
    isHtmlContent: true,
    content: '<div class="row m-3 athena-scroll tandc-scroll">' +
      '<div class="mb-2">These terms and conditions govern the way in which we supply products to you, including any eLearning' +
      'courses.</div>' +
      '<div class="mb-2">Please read these terms carefully before you submit your order to us. These terms tell you who we are, how we ' +
      'will provide products to you, how you and we may change or end the contract, what to do if there is a problem and other' +
      'important information. All rights not expressly granted in these terms are hereby reserved. </div>' +
      '<div class="mb-2">You agree to review this agreement periodically to ensure that you are aware of any amendments to this' +
      'agreement, which may be made at any time.</div>' +
      '<h3>INFORMATION ABOUT US AND HOW TO CONTACT US</h3>' +
      '<div class="mb-2">We are Westford University College FZE, trading as AGE (Athena Global Education FZE),' +
      'a company registered in England and Wales with company number 09919987 and registered office at 225B Island' +
      'Business Centre,18-36 Wellington Street, Woolwich, London SE18 6PF, UK. You can contact us on info@athena.edu</div>' +
      '<ol><li class="mb-2">' +
      '<h4>GRANT OF LICENCE</h4>' +
      '  <div class="mb-2">In consideration of your payment, we hereby grant you a license to use the purchased eLearning courses' +
      '  (“the Products”). This license is limited, revocable, non-exclusive, non-sublicensable and non-transferable,' +
      '  and is subject to the rights and obligations granted under these Terms.</div>' +
      '  <div class="mb-2">This license is personal to you and cannot be shared or exchanged with others.</div>' +
      '  </li>' +
      '<li class="mb-2">' +
      '<h4>GENERAL</h4>' +
      '  <div class="mb-2">We develop, distribute and maintain the Products and will also provide you with log in details. We will also' +
      '  manage your access to the Products and provide support to you, where necessary.</div>' +
      '  <div class="mb-2">You shall not copy, modify, transmit, distribute or in any way exploit the Products or any other copyrighted materials provided other' +
      '  than for your individual training. Any other purpose is expressly prohibited under these terms. You shall' +
      '  also not permit anyone else to copy, use, modify, transmit, distribute or in any way exploit the Products' +
      '    or any other copyrighted materials.</div>' +
      '  <div class="mb-2">We provide the materials ‘as is’ and without any warranties, whether express or implied, except those that cannot be excluded under statute. We also do not warrant that the' +
      '    materials will be error free, including technical inaccuracies.</div>' +
      '  <div class="mb-2">The certification of the purchased course' +
      '  will be subject to the terms and conditions of the respective awarding body and universities, which may' +
      '  subject to changes. Athena responsibility to provide all necessary learning material for the courses' +
      '  enrolled which Athena feel adequate to meet the learning outcomes, however we don’t guarantee you with' +
      '  any certification or awards which is responsibility of the student to satisfy the learning requirement of' +
      '    the respective awarding bodies and universities.</div>' +
      '  <div class="mb-2">' +
      '  Athena do not discriminate on admission in any manner,' +
      '  each course has its stipulated eligibility criteria and its your responsibility to ensure you meet the' +
      '    eligibility requirement prior enrolling to the course and make payments.</div>' +
      '  <div class="mb-2">The admission to the course provided based on the information and documents provided by you, if it is found illegitimate,  or contrary to the facts, the admission may be cancelled and Athena will not be responsible for any damage or refund due to such incidents</div>' +
      '  <div class="mb-2">The universities and awarding body will reserve the rights for the admission to their respective course, which will be final</div>' +
      '</li>' +
      '<li class="mb-2">' +
      '  <h4>ACCESS TO MATERIALS</h4>' +
      '  <div class="mb-2">The starting date of your access to the Products is deemed to be the date that you first have access. We will attempt to contact you where your access period has ended. Where this is the case, we cannot guarantee that certification or completion (as appropriate) will be possible. As such, it is your responsibility to ensure that you complete the content within the allocated time period. If you do not think this will be possible, then extensions of time are available for purchase at an additional cost.</div>' +
      '  <div class="mb-2">We will take all commercially reasonable steps to provide you with uninterrupted access to the Products. However, your access may be restricted from time to time for reasons beyond our control. Such reasons include force majeure events, power outages and actions from computer hackers and others acting outside the law. Your access may also be interrupted due to software issues, server downtime, increased Internet traffic, programming errors, regular maintenance and other related reasons. Where this is the case, we will take commercially reasonable steps to restore your full access within a reasonable period of time. ‘Commercially reasonable’ in these terms shall mean reasonable efforts taken in good faith, without an unduly burdensome use or expenditure of time, resources, personnel or money.</div>' +
      '  <div class="mb-2">Our joint aim is to provide courses and materials of the highest quality. As such, improvements or changes to the Products or any other materials may occur at any time without prior notification in order to ensure that they are up to date and accurate.</div>' +
      '  <div class="mb-2">Where your access to the Products is restricted for any of the above reasons, we may provide you with a free extension of time at our sole discretion.</div>' +
      '</li>' +
      '<li class="mb-2">' +
      '  <h4>PRICING AND PAYMENT</h4>' +
      '  <div class="mb-2">We use third party payment providers, depending on the way in which you make payments. Payment for the Products must be made at the point of purchase.</div>' +
      '  <div class="mb-2">You agree to provide payment for the Products in the stipulated currency and you will be liable to pay any relevant conversion charges, as well as applicable sales tax in your region. Please note that we must receive your payment in full before providing you with access to the Products.</div>' +
      '  <div class="mb-2">Your payment includes the licensing of the Products for a limited period of time that is appropriate for the eLearning content. We are unable to provide a refund if you fail to complete the content within the allocated time, except at our absolute and sole discretion.</div>' +
      '  <div class="mb-2">We reserve our right to review and change the pricing of any of our products. This will not affect products that have already been purchased.</div>' +
      '</li>' +
      '<li class="mb-2">' +
      '  <h4>CANCELLATION AND RESTRICTION POLICY</h4>' +
      '  <div class="mb-2">Where Products are delivered to you immediately, you will not have the right to change your mind. In other cases, you may change your mind within fourteen (14) days of purchase, so long as materials have not been provided to you, downloaded, streamed or otherwise accessed. If you do wish to cancel, please contact us via email.</div>' +
      '  <div class="mb-2">It is your responsibility to ensure that you meet the system requirements, including compatible hardware, software, telecommunications equipment and Internet service, prior to purchasing any content. We are unable to provide refunds where your access to the Products is inhibited due to insufficient system requirements.</div>' +
      '  <div class="mb-2">We may restrict your access to the Products if you breach these terms, including without limitation:</div>' +
      '  <div class="mb-2">a) A failure to make any payment due to us; or</div>' +
      '  <div class="mb-2">b) Failure to provide accurate information that is necessary for us to provide the Products to you.</div>' +
      '<div class="mb-2">In these circumstances, we will inform you in writing with seven (7) days’ notice that your access to the Products will be restricted.</div>' +
      '</li>' +
      '<li class="mb-2">' +
      '  <h4>YOUR RIGHTS</h4>' +
      '  <div class="mb-2">This is a summary of your key legal rights. For more detailed information, you can visit www.adviceguide.org.uk or call 03454 04 05 06, or if based outside the United Kingdom, we recommend you review your local statutory rights.</div>' +
      '  <div class="mb-2">Under the laws of the UAE, the Products must be as described, fit for purpose and of a satisfactory quality. If the Products are faulty, then you are entitled to ask for a repair or a replacement and if the fault cannot be fixed, or it has not been fixed within a reasonable time and without significant inconvenience, then you can get some or all of your money refunded.</div>' +
      '  <div class="mb-2">If you can show that the fault has damaged your device and that we have not used reasonable care and skill, then you may be entitled to compensation.</div>' +
      '  </li>' +
      '<li class="mb-2">' +
      '  <h4>ASSESSMENT CHARGES</h4>' +
      '  <div class="mb-2">Where you have paid Assessment Charges in addition to the Products, it will not guarantee you a pass grade, which is subject meeting the learning outcomes of prescribed course/module. If you fail to meet the pass requirement, you may be given second chance to resubmit, if you fail again you may have to pay the redo charges as applicable</div>' +
      '</li>' +
      '<li class="mb-2">' +
      '  <h4>INTELLECTUAL PROPERTY</h4>' +
      '  <div class="mb-2">All rights, title and interest in intellectual property rights relating to the Products including copyright, patents, trademarks, trade secrets, improvements, developments, proprietary information, know-how, processes, methods, business plans or models (including computer software and preparatory and design materials thereof) and all other intellectual property (whether registered or not) developed or created from time to time shall exclusively be owned by Athena Global Education FZE. While you may utilise the intellectual property, you understand that there shall be no transfer of ownership of the same.</div>' +
      '  <div class="mb-2">Nothing that you see or read in the Products may be copied, reproduced, modified, distributed, transmitted, republished, displayed or performed for commercial use.</div>' +
      '  <div class="mb-2">All other trademarks, service marks and trade names in this material are the marks of the respective owners and any unauthorized use is prohibited.</div>' +
      '</li>' +
      '<li class="mb-2">' +
      '  <h4>OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU</h4>' +
      '  <div class="mb-2">We are responsible to you for foreseeable loss and damage caused by us. If we fail to comply with these terms, we are responsible for loss or damage you suffer that is a foreseeable result of our breaking this contract or our failing to use reasonable care and skill. Loss or damage is foreseeable if it is either obvious that it will happen or if we are both aware it might happen, such as where you have discussed the possibility with us during the sales process.</div>' +
      '  <div class="mb-2">We are not liable to you in any way for any indirect, special, incidental, punitive or consequential damages of any character, including without limitation damages for loss of goodwill, work stoppage, computer failure or malfunction, loss of data, loss of productivity or contract or any and all other commercial damages or losses.</div>' +
      '  <div class="mb-2">We do not exclude or limit our liability to you in any case where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors; for fraud or fraudulent misrepresentation; for breach of your legal rights in relation to the products (summarised above at 6).</div>' +
      '  <div class="mb-2">If the Products damage your device or digital content belonging to you and this is caused by our failure to use reasonable care and skill, we will either repair the damage or pay you compensation. However, we will not be liable for damage which you could have avoided by following our advice, following installation/download instructions, or having in place the minimum system requirements as advised by us.</div>' +
      '  <div class="mb-2">Our liability to you for any damage to your computer system or loss of data resulting from the downloading of content is limited to the amount you have paid for the Products. In no event shall we be liable for damages in excess of this sum.</div>' +
      '</li>' +
      '<li class="mb-2">' +
      '  <h4>OTHER IMPORTANT TERMS</h4>' +
      '  <div class="mb-2">You need our consent to transfer your rights under these terms to someone else. We may not agree to this as these terms grant a licence for your benefit only.</div>' +
      '  <div class="mb-2">Nobody else has any rights under these terms – they are between you and us. No other person shall have any rights to enforce any of its terms.</div>' +
      '  <div class="mb-2">If a court finds part of these terms illegal, then rest will continue in force.</div>' +
      '  <div class="mb-2">Even if we delay in enforcing this contract, we can still enforce it later. If we do not insist immediately that you do anything you are required to do under these terms, or if we delay in taking steps against you in respect of your breaking this contract, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date.</div>' +
      '</li>' +
      '<li class="mb-2">' +
      '  <h4>CERTIFICATION</h4>' +
      '  <div class="mb-2">Once you have satisfactorily completed the requirements of certification from respective awarding bodies, you may request the certification by paying certification charges. The awarding bodies and universities will take 1 to 3 months period to issue the certificate and we will not be responsible for unforeseen delay in issuance of certificate. The certificate will be couriered to you to the provided address and we shall not be liable for any damage during the shipment. If the students wish to attend the convocation events, Athena will coordinate to get a permit from the respective university, the university may charge a nominal fee for such event decided by the university and the student will be liable to pay such charges if need to attend such events</div>' +
      '</li>' +
      '<li class="mb-2">' +
      '  <h4>ACCREDITATION</h4>' +
      '  <div>Accreditation status has been clearly mentioned in every course page, there are credit rated and non-credit rated programmes. The accredited program means that particular course has been approved by the respective country of issuance of the certificate not necessary to every country. So it’s the student responsibility to check the accreditation status in their respective countries and get satisfied prior enrolling to the course. We will not be responsible for any country withdraw the accreditation status to any of our partner universities/awarding bodies in future. If any university withdraw the partnership with Athena in future, we will provide best possible alternative solution to complete your study or students can opt to withdraw from the course and Athena will not be responsible for any such events or damage caused by such events.</div>' +
      '</li>' +
      '<li class="mb-2">' +
      '  <h4>GOVERNING LAW</h4>' +
      '  <div>These terms are governed by and construed in accordance with the laws of United Arab Emirates and you can bring legal proceedings in the UAE courts.</div>' +
      '</li>' +
      '<li class="mb-2">' +
      '  <h4>USE OF PERSONAL DATA</h4>' +
      '  <div class="mb-2">By using our products and services, you agree to the collection, processing, and use of your personal data as outlined in our Privacy Policy. We may collect and process personal information such as your name, contact details, usage data, demographic information, and any other data inputted by the user  for purposes including but not limited to:</div>' + 
      '  <div class="d-flex">' + '<span class="mr-2">1.</span>' + '<span>Providing access to our eLearning courses and managing your account. </span>' + '</div>' +
      '  <div class="d-flex">' + '<span class="mr-2">2.</span>' + '<span>Communicating with you regarding updates, promotions, and important notifications related to our products and services.</span>' + '</div>' +
      '  <div class="d-flex">' + '<span class="mr-2">3.</span>' + '<span>Improving our products and services, including analysing user behaviour and preferences. </span>' + '</div>' +
      '  <div class="mb-2 d-flex">' + '<span class="mr-2">4.</span>' + '<span>Complying with legal obligations and regulations.</span>' +'</div>' +
      '  <div class="mb-2">We are committed to protecting your privacy and will not disclose your personal information to third parties without your consent, except where required by law. You have the right to withdraw your consent or update your preferences at any time by contacting us.      </div>' +
      '  <div class="mb-2">By continuing to use our products and services, you acknowledge that you have read and understood our Privacy Policy and agree to the collection, processing, and use of your personal data as described herein.</div>' +
      '</li></ol></div>',
    isCancelButton: false,
    isOkButton: false,
    okText: '',
    isCloseButton: true
  },

  confirmDialog: {
    isTitle: false,
    title: '',
    isHtmlContent: false,
    content: '',
    isCancelButton: true,
    isOkButton: true,
    okText: 'Yes',
    isCloseButton: false
  },

  paymentConfirmDialog: {
    isTitle: false,
    title: '',
    isHtmlContent: false,
    content: '',
    isCancelButton: false,
    isClaimButton: true,
    claimText: 'Download Certificate',
    isCloseButton: false
  },

  feeConfirmDialog: {
    isTitle: false,
    title: '',
    isHtmlContent: false,
    content: '',
    isCancelButton: false,
    isClaimButton: true,
    claimText: 'Ok',
    isCloseButton: false
  },
  alertDialog: {
    isTitle: false,
    title: '',
    isHtmlContent: false,
    content: '',
    isCancelButton: false,
    isOkButton: true,
    okText: 'Close',
    isCloseButton: false
  },

  alertPopupSize: {
    SMALL: '300px',
    MEDIUM: '450px'
  },

  modalMessage: {
    alertDialogDiscussion: 'You will be notified once your comment is published by the admin. Thanks for participating!',
    birthdayOfferExpiredDailog: 'Birthday Offer Expired!!',
    birthdayOfferAlreadyClaimedDailog: 'Birthday Offer Already Claimed!!',
    closeQuiz: 'Are you sure you want to close the quiz ?',
    deleteConfirmation: 'Are you sure you want to delete ?',
    inActiveConfirmation: 'Are you sure you want to Inactive the module ?',
    lessonCompleted: 'This lesson will be unmarked !',
    publishConfirmation: 'Are you sure you want to Publish the module ?',
    publishErrorMessage: 'Module cannot be published since the breakdown naming is incomplete, Are you want to navigate Breakdown Structure ?',
    quickApplicationNotApproved: 'Your quick application for this course not approved',
    quizTimeUp: 'Your time is up for the quiz',
    resourceSelectAlert: 'Please select at least one resource!',
    sameModuleMultipleTabOpenErrorMessage: 'Please close this tab, else the unsaved changes done in other tab will be lost',
    unPublishConfirmation: 'Are you sure you want to Unpublish the module ?',
    unEnrollCourseConfirmation: 'Are you sure you want to drop this course?',
    alreadyPaidForThisCourse: 'Payment for this course has been completed',
    alreadyClaimedForThisCourse: 'Already claimed for this course',
    hardCopyFeePaid: 'Already hard copy fee paid for this course',
    notEligibleForClaim: 'You are not eligible to claim this course',
    alreadyRegFeePaid: 'Already registration fee paid for this course',
    alreadyModuleFeePaid: 'Already module fee paid for this course',
    alreadyAssessmentFeePaid: 'Already Assessment Fee paid for this course'
  }
};
