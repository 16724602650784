import { Injectable, Optional } from '@angular/core';
import { ApplicationConstant } from '../shared/constant/app.constant';
import { CommonService } from './common.service';
import { ApplicationNotifierConstant } from '../shared/constant/notifier.constant';
import { ApplicationNotifierService } from '../shared/component/application-notifier/application-notifier.service';
import { FormGroup } from '@angular/forms';
import { AppUtil } from '../shared/utils/app.util';
import { NotifierData } from '../shared/component/application-notifier/notifier-data.interface';
import { FileUpload } from '../interface/file-upload.interface';
import { BaseService } from './base.service';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { MsalService } from '@azure/msal-angular';
import { AppConfig } from '../shared/config/app.config';
import { AuthenticateService } from './authenticate.service';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import {EndpointConstant} from "../shared/constant/endpoint.constant";
import { HeaderService } from '../header/header.service';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  isDisabledButton = false;

  constructor(private authenticateService: AuthenticateService,
              private applicationNotifierService: ApplicationNotifierService,
              private baseService: BaseService,
              private commonService: CommonService,
              @Optional() private msalService: MsalService,
              private router: Router,
              @Optional() private socialAuthService: SocialAuthService,
              private headerService: HeaderService) { }

  /**
   * Get the details for the form
   *
   * @param endpoint endpoint
   */
  getDetails(endpoint: string) {
    return this.baseService.get(endpoint);
  }

  /**
   * Get acads course list
   * @param endpoint endpoint
   */
  getOldLmsDetails(endpoint: string) {
    return this.baseService.getOldLmsData(endpoint);
  }

  /**
   * Delete the form details
   *
   * @param endpoint endpoint
   */
  deleteDetails(endpoint: string) {
    return this.baseService.delete(endpoint);
  }

  /**
   * Remove the form details
   *
   * @param endpoint endpoint
   * @param data form data
   */
  removeDetails(endpoint: string, data: any) {
    return this.baseService.post(endpoint, data);
  }

  /**
   * Update the form details
   *
   * @param endpoint endpoint
   * @param data form data
   */
  updateDetails(endpoint: string, data: any) {
    return this.baseService.post(endpoint, data);
  }

  /**
   * Save the form details
   *
   * @param endpoint endpoint
   * @param data form data
   */
  saveDetails(endpoint: string, data: any) {
    return this.baseService.post(endpoint, data);
  }

  /**
   * Get the details from the third party APIs.
   *
   * @param url request URL.
   * @param token authorization token.
   */
  getDetailsFromThirdParty(url: string, token: string) {
    return new Promise( (resp, err) => {
      this.baseService.getDetailsFromThirdParty(url, token).then(value => resp(value.json()))
        .catch(reason => err(reason));
    });
  }

  saveToThirdParty(endpoint: string, data: any, token: any = '') {
    return this.baseService.postThirdParty(endpoint, data, token);
  }
  /**
   * Validate the form details
   *
   * @param endpoint endpoint
   * @param data form data
   */
  validateDetails(endpoint: string, data: any) {
    return this.baseService.post(endpoint, data);
  }

  /**
   * Multiple file Upload.
   *
   * @param event for file uploaded
   * @param fileConfigKey  file config key
   * @param fileUploadKey  file key
   * @param fileUploadNameKey  filename key
   * @param form FormGroup
   * @param fileUpload file to be uploaded
   * @param parentId id of the details
   * @param source source id of the application
   * @param courseId id for the user applied
   */
  multipleUploadFile(event, fileConfigKey: any, fileUploadKey: any, fileUploadNameKey: any, form: FormGroup, fileUpload: Array<FileUpload>,
                     parentId: number, source: string, courseId: number) {

    // check for no. of files
    if (fileUpload.length < ApplicationConstant.maxFile) {
      // check for the file size less than 5MB.
      if (AppUtil.checkFileSize(event.target.files.item(0), ApplicationConstant.maxFileSize)) {

        // check for file extension.
        if (AppUtil.checkFileExtension(event.target.files.item(0).name, fileConfigKey.extension)) {

          const formData: FormData = new FormData();
          if (form.value[fileUploadNameKey.key] === null || form.value[fileUploadNameKey.key] === '') {
            form.controls[fileUploadNameKey.key].setValue(`${fileConfigKey.defaultTextValue} ${fileUpload.length + 1}`);
          }
          if (parentId) {
            formData.append(ApplicationConstant.formMetaFields.parentId, parentId.toString());
          }
          formData.append(fileConfigKey.text, form.value[fileUploadNameKey.key]);
          formData.append(fileConfigKey.key, event.target.files.item(0));
          formData.append('type', fileConfigKey.type);
          formData.append(ApplicationConstant.formMetaFields.uId, this.commonService.getUId().toString());
          formData.append('filename', AppUtil.createFileName(this.commonService.getUId(), fileConfigKey.name));
          formData.append(ApplicationConstant.formMetaFields.courseId, courseId.toString());
          formData.append(ApplicationConstant.formMetaFields.applicationSource, source);
          formData.append(ApplicationConstant.formMetaFields.temp_file, 'Y');

          this.saveDetails(EndpointConstant.UPLOAD_USER_FILE, formData).subscribe(value => {
            event.target.value = null;
            AppUtil.multipleFileUpload(value?.data, form, fileUploadNameKey.key, fileUploadKey.key, fileUpload,
              ApplicationNotifierConstant.file_upload);
          }, error => {
            console.error('error', error);
            event.target.value = null;
            this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.error_message);
          });
        } else {
          event.target.value = null;
          this.applicationNotifierService.getNotifier(this.applicationNotifierService.createNotifierMessage(
            ApplicationNotifierConstant.file_extension_error, fileConfigKey.extension));
        }
      } else {
        event.target.value = null;
        this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.file_size_error);
      }
    } else {
      event.target.value = null;
      const errorMessage: NotifierData = Object.assign({}, ApplicationNotifierConstant.file_limit_error);
      errorMessage.message = `${errorMessage.message} ${ApplicationConstant.maxFile}`;
      this.applicationNotifierService.getNotifier(errorMessage);
    }
  }

  /**
   * Remove the file.
   *
   * @param fileUploadKey configuration
   * @param uploadFile file upload details
   * @param uploadFileList remove file from the file array.
   * @param index removing element position in the array.
   */
  removeFile(fileUploadKey: any, uploadFile: any, uploadFileList: Array<FileUpload>, index: number) {
    const formData = {
      [ApplicationConstant.formMetaFields.uId]: this.commonService.getUId(),
      [ApplicationConstant.formMetaFields.type]: fileUploadKey.type,
      [fileUploadKey.deleteKey]: uploadFile.id
    };
    this.removeDetails(EndpointConstant.UPLOAD_FILE_REMOVE, formData).subscribe(value => {
        uploadFileList.splice(index, 1);
    }, error => {
      console.error('error', error);
    });
  }

  /**
   * Check if the phone number is exist.
   *
   * @param contactNumber phone number
   * @param countryCode country code for the phone number
   */
  checkPhoneNumberExist(contactNumber: string, countryCode: string): Promise<boolean> {

    const formData = {
      [ApplicationConstant.formMetaFields.uId]: this.commonService.getUId(),
      [ApplicationConstant.formMetaFields.contactNumber]: contactNumber,
      [ApplicationConstant.formMetaFields.contactCountryCode]: countryCode,
    };

    return new Promise(resp => {
      this.validateDetails(EndpointConstant.MOBILE_NO_VALIDATE, formData).subscribe(value => {
          resp(true);
        }, error => {
          console.error('error', error);
          resp(false);
        });
    });
  }

  /**
   * Single Upload file.
   *
   * @param event for file uploaded
   * @param fileConfigKey  file config key
   * @param url file to be uploaded
   * @param tag for the module name
   * @return promise url of the upload file.
   */
  singleFileUpload(event, fileConfigKey: any, url: string, tag: string): Promise<string> {

    return new Promise( (resp, err) => {
      // check for no. of files
      if (url === null || url === undefined || url === '') {
        // check for the file size less than 5MB.
        if (AppUtil.checkFileSize(event.target.files.item(0), ApplicationConstant.maxFileSize)) {

          // check for file extension.
          if (AppUtil.checkFileExtension(event.target.files.item(0).name, fileConfigKey.extension)) {

            const formData: FormData = new FormData();
            formData.append(fileConfigKey.key, event.target.files.item(0));
            formData.append('type', fileConfigKey.type);
            formData.append('tag', tag);
            formData.append(ApplicationConstant.formMetaFields.uId, this.commonService.getUId().toString());

            this.saveDetails(EndpointConstant.UPLOAD_FILE, formData)
              .subscribe(value => {
                event.target.value = null;
                this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.file_upload);
                resp(value?.data);
              }, error => {
                event.target.value = null;
                this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.error_message);
                err(error);
              });
          } else {
            event.target.value = null;
            this.applicationNotifierService.getNotifier(this.applicationNotifierService.createNotifierMessage(
              ApplicationNotifierConstant.file_extension_error, fileConfigKey.extension));
            err(null);
          }
        } else {
          event.target.value = null;
          this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.file_size_error);
          err(null);
        }
      } else {
        event.target.value = null;
        this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.single_file_limit_error);
        err(null);
      }
    });
  }

  /**
   * drag and drop Upload file.
   *
   * @param files for file uploaded
   * @param fileConfigKey  file config key
   * @param url file to be uploaded
   * @param tag for the module name
   * @return promise url of the upload file.
   */
   dragAndDropFileUpload(files, fileConfigKey: any, url: string, tag: string): Promise<any> {

    return new Promise( (resp, err) => {
      // check for no. of files
      if (url === null || url === undefined) {
        // check for the file size less than 5MB.
        if (AppUtil.checkFileSize(files.item(0), ApplicationConstant.maxFileSize)) {

          // check for file extension.
          if (AppUtil.checkFileExtension(files.item(0).name, fileConfigKey.extension)) {

            const formData: FormData = new FormData();
            formData.append(fileConfigKey.key, files.item(0));
            formData.append('type', fileConfigKey.type);
            formData.append('tag', tag);
            formData.append(ApplicationConstant.formMetaFields.uId, this.commonService.getUId().toString());

            this.saveDetails(EndpointConstant.UPLOAD_FILE, formData)
              .subscribe(value => {
                this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.file_upload);
                resp(value?.data);
              }, error => {
                this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.error_message);
                err(error);
              });
          } else {
            this.applicationNotifierService.getNotifier(this.applicationNotifierService.createNotifierMessage(
              ApplicationNotifierConstant.file_extension_error, fileConfigKey.extension));
            err(null);
          }
        } else {
          this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.file_size_error);
          err(null);
        }
      } else {
        this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.single_file_limit_error);
        err(null);
      }
    });
  }

  /**
   * File Upload.
   *
   * @param event for file uploaded
   * @param fileConfigKey  file config key
   * @param url file to be uploaded
   * @param tag for the module name
   * @return promise url of the upload file.
   */
  fileUpload(event, fileConfigKey: any, url: string, tag: string): Promise<string> {

    return new Promise( (resp, err) => {
      // check for no. of files
      if (url === null || url === undefined) {
        // check for the file size less than 5MB.
        if (AppUtil.checkFileSize(event.target.files.item(0), ApplicationConstant.maxFileSize)) {

          // check for file extension.
          if (AppUtil.checkFileExtension(event.target.files.item(0).name, fileConfigKey.extension)) {

            const formData: FormData = new FormData();
            formData.append(fileConfigKey.key, event.target.files.item(0));
            formData.append('type', fileConfigKey.type);
            formData.append('tag', tag);
            formData.append(ApplicationConstant.formMetaFields.uId, this.commonService.getUId().toString());

            this.saveDetails(EndpointConstant.UPLOAD_FILE, formData)
              .subscribe(value => {
                event.target.value = null;
                this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.file_upload);
                resp(value?.data);
              }, error => {
                event.target.value = null;
                this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.error_message);
                err(error);
              });
          } else {
            event.target.value = null;
            this.applicationNotifierService.getNotifier(this.applicationNotifierService.createNotifierMessage(
              ApplicationNotifierConstant.file_extension_error, fileConfigKey.extension));
            err(null);
          }
        } else {
          event.target.value = null;
          this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.file_size_error);
          err(null);
        }
      } else {
        event.target.value = null;
        this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.single_file_limit_error);
        err(null);
      }
    });
  }

  /**
   * Sign in with Google.
   *
   * @param locationDetails is the location of the user.
   * @param queryParamData fetching the utm parameters.
   * @param courseId id of the course.
   * @param dialogRef popup reference.
   */
  signInWithGoogle(locationDetails: any, queryParamData: any, courseId: number, returnUrl?: string): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
    this.getLoginInformation(locationDetails, queryParamData, courseId, ApplicationConstant.LOGIN_PROVIDERS.GOOGLE,
      returnUrl);
  }

  /**
   * Login with Facebook.
   *
   * @param locationDetails is the location of the user.
   * @param queryParamData fetching the utm parameters.
   * @param courseId id of the course.
   * @param dialogRef popup reference.
   */
  loginWithFacebook(locationDetails: any, queryParamData: any, courseId: number, returnUrl?: string): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
    this.getLoginInformation(locationDetails, queryParamData, courseId, ApplicationConstant.LOGIN_PROVIDERS.FACEBOOK, 
      returnUrl);
  }

  /**
   * Get Google and Facebook login Information.
   *
   * @param locationDetails is the location of the user.
   * @param queryParamData fetching the utm parameters.
   * @param courseId id of the course.
   * @param loginProvider by the user.
   * @param dialogRef popup reference.
   */
  private getLoginInformation(locationDetails: any, queryParamData: any, courseId: number, loginProvider: string,
                              returnUrl?: string) {
    this.socialAuthService.authState.subscribe((user) => {
      if (user && user?.provider.toLowerCase() === loginProvider) {
        this.registerViaSocialLogin(user.firstName, user.lastName, user.email, locationDetails, queryParamData,
          courseId, loginProvider, returnUrl);
      }
    });
  }

  /**
   * Login with Microsoft.
   *
   * @param locationDetails is the location of the user.
   * @param queryParamData fetching the utm parameters.
   * @param courseId id of the course.
   * @param dialogRef popup reference.
   */
  loginWithMicrosoft(locationDetails: any, queryParamData: any, courseId: number, dialogRef: MatDialogRef<any>, returnUrl?: string): void {
    this.msalService.loginPopup().subscribe({
      next: (result) => {
        this.getDetailsFromThirdParty('https://graph.microsoft.com/v1.0/me', result.accessToken)
          .then( (user: any) => {
            this.registerViaSocialLogin(user.givenName, user.surname, user.userPrincipalName, locationDetails,
              queryParamData, courseId, ApplicationConstant.LOGIN_PROVIDERS.MICROSOFT, returnUrl);
          }).catch((err) => {
          console.error('Error', err);
        });
      },
      error: (error) => console.error(error)
    });
  }

  /**
   * Register via Social Login.
   *
   * @param firstName of the user
   * @param lastName of the user
   * @param email of the user
   * @param locationDetails is the location of the user.
   * @param queryParamData fetching the utm parameters.
   * @param courseId id of the course.
   * @param loginProvider by the user.
   * @param dialogRef popup reference.
   */
  registerViaSocialLogin(firstName: string, lastName: string, email: string, locationDetails: any, queryParamData: any,
                         courseId: number, loginProvider: string, returnUrl: string) {

    if (!this.isDisabledButton) {

      this.isDisabledButton = true;
      const postData = {
        [AppConfig.formDetails.firstName.key]: firstName,
        [AppConfig.formDetails.lastName.key]: lastName,
        [AppConfig.formDetails.emailId.key]: email,
        [AppConfig.formDetails.countryCode.key]: locationDetails.countryCode,
        [AppConfig.formDetails.countryContactCode.key]: locationDetails.countryContactCode,
        [AppConfig.formDetails.courseId.key] : courseId
      }

      this.saveDetails(EndpointConstant.REGISTER_VIA_SOCIAL_LOGIN, postData)
        .subscribe(resp => {
          this.isDisabledButton = false;
          this.authenticateService.setToken(resp?.data?.token);
          this.commonService.getAndSaveUTMDetails(queryParamData, courseId);
          this.commonService.setUId(resp?.data?.user_details?.uid);
          this.commonService.setFirstName(resp?.data?.user_details?.first_name);
          this.commonService.setLoggedUser(resp?.data?.user_details);
          this.commonService.loginProvider = loginProvider;
          this.headerService.dispatchProgress();
          this.headerService.dispatchGetUserDetails();
          this.headerService.dispatchSubscriptionPlans();
          
          if(returnUrl) {
            this.router.navigateByUrl(returnUrl);
          } else {
            this.headerService.updatePageAfterLoggedIn();
          }

        },error => {
          console.error('Error', error);
          this.isDisabledButton = false;
          this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.error_message);
        })

    }
  }

  /**
   * Login user.
   *
   * @param signInForm is the form reference.
   */
  loginUser(signInForm: FormGroup): Promise<string> {
    return new Promise((resolve, reject) => {
      if (signInForm.valid) {
        this.saveDetails(EndpointConstant.LOGIN, signInForm.value).subscribe(resp => {
          if (resp.success) {
            this.authenticateService.setToken(resp?.data?.token);
            this.commonService.setUId(resp?.data?.user_details?.uid);
            this.commonService.setFirstName(resp?.data?.user_details?.first_name);
            this.commonService.setLoggedUser(resp?.data?.user_details);
            this.commonService.loginProvider = ApplicationConstant.LOGIN_PROVIDERS.PASSWORD;
            resolve(resp.success);
          }
        }, error => {
          console.error('Error', error);
          if (error && error?.error && error?.error?.data) {
            if (error.error.data.password && error.error.data.password[0] === 'The password is invalid.') {
              reject('Please enter the correct password!');
            } else if (error.error.data.mail && error.error.data.mail[0] === 'The selected mail is invalid.') {
              reject('Ohh!! Looks like you have not registered with us. Please register.');
            } else {
              reject('Invalid User Name or Password, Retry');
            }
          } else {
            reject('Invalid User Name or Password, Retry');
          }
        })
      } else {
        signInForm.markAllAsTouched();
        reject('');
      }
    });
  }

  /**
   * Register user.
   *
   * @param signUpForm is the form reference.
   * @param locationDetails is the location details
   * @param courseId is the course Id.
   * @param queryParamData is the information which is passed in query string.
   */
  registerUser(signUpForm: FormGroup, locationDetails: any, courseId: number, queryParamData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (signUpForm.valid) {
        if(signUpForm.value[AppConfig.formDetails.contactNo.key]) {
          var formData = {
            ...signUpForm.value,
            [AppConfig.formDetails.countryCode.key]: locationDetails.countryCode,
            [AppConfig.formDetails.countryContactCode.key] : locationDetails.countryContactCode,
            [AppConfig.formDetails.courseId.key] : courseId,
            [AppConfig.formDetails.contactNo.key]: signUpForm.value[AppConfig.formDetails.contactNo.key].number,
            [AppConfig.formDetails.contactNo.countryCode]: signUpForm.value[AppConfig.formDetails.contactNo.key].dialCode,
            [AppConfig.formDetails.contactNo.countryISO]: signUpForm.value[AppConfig.formDetails.contactNo.key].countryCode.toLowerCase(),
          }
        } else {
          var formData = {
            ...signUpForm.value,
            [AppConfig.formDetails.countryCode.key]: locationDetails.countryCode,
            [AppConfig.formDetails.countryContactCode.key] : locationDetails.countryContactCode,
            [AppConfig.formDetails.courseId.key] : courseId,
          }
        }

        this.saveDetails(EndpointConstant.REGISTER, formData).subscribe(resp => {
          this.authenticateService.setToken(resp?.data?.token);
          this.commonService.getAndSaveUTMDetails(queryParamData, courseId);
          this.commonService.setUId(resp?.data?.user_details?.uid);
          this.commonService.setFirstName(resp?.data?.user_details?.first_name);
          this.commonService.setLoggedUser(resp?.data?.user_details);
          this.commonService.loginProvider = ApplicationConstant.LOGIN_PROVIDERS.PASSWORD;
          resolve(resp?.data);
        }, error => {
          console.error('Error', error);
          reject(error);
        })
      } else {
        signUpForm.markAllAsTouched();
        reject('');
      }
    });
  }
  
  /**
   * Upload the file.
   * @param event for file uploaded
   * @param fileUploadKey  file key
   */
  uploadFiles(event, fileUploadKey: any, documentProofTypes: any, proofTypeId: number): Promise<string> {

    if (proofTypeId) {
      fileUploadKey.type = documentProofTypes.find(p => p?.proof_type_id === proofTypeId)?.upload_doc_name;
    } else {
      fileUploadKey.type = 'others';
    }
    return new Promise((resp, err) => {
      // check for the file size less than 5MB.
      if (proofTypeId) {
        if (AppUtil.checkFileSize(event.target.files.item(0), ApplicationConstant.maxFileSize)) {
          // check for file extension.
          if (AppUtil.checkFileExtension(event.target.files.item(0).name, fileUploadKey.extension)) {
            const formData: FormData = new FormData();
            formData.append(fileUploadKey.key, event.target.files.item(0));
            formData.append('type', fileUploadKey.type);
            formData.append(ApplicationConstant.formMetaFields.uId, this.commonService.getUId().toString());
            formData.append('fileName', AppUtil.createFileName(this.commonService.getUId(), fileUploadKey.name));

            this.saveDetails(EndpointConstant.UPLOAD_USER_FILE, formData)
              .subscribe((value) => {
                event.target.value = null;
                if (fileUploadKey === ApplicationConstant.uploadFileKeys.photoVerificationUpload) {
                  resp(value?.data);
                } else {
                  resp(value?.data?.url);
                }
                this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.file_upload);
              }, (error) => {
                console.error('error', error);
                err(error);
                event.target.files = null;
                this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.error_message);
              });
          } else {
            event.target.value = null;;
            this.applicationNotifierService.getNotifier(this.applicationNotifierService.createNotifierMessage(
              ApplicationNotifierConstant.file_extension_error, fileUploadKey.extension));
          }
        } else {
          event.target.value = null;
          this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.file_size_error);
        }
      } else {
        this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.proof_type);
      }
    });
  }

}
