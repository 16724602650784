import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  isLoading = new Subject<boolean>();
  isNavigationComplete = new Subject<boolean>();
  isPageRendered = true;

  /**
   * Show loader
   */
  show() {
    this.isLoading.next(true);
  }

  /**
   * Hide loader
   */
  hide() {
    this.isLoading.next(false);
  }

  /**
  * Hiding and showing footer after page loads (eligible certification only)
  */
  toggleFooterVisibility(value: boolean) {
    this.isNavigationComplete.next(value);
  }

}