import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RoutingConstant } from '../../constant/routing.constant';
import { Router } from '@angular/router';
import { CommonService } from '../../../services/common.service';
import { EventLogsEnum } from '../../../student-dashboard/enum/event-logs-enum';
import { CourseFeeTypes } from 'src/app/student-dashboard/enum/course-fee-types';

@Component({
  selector: 'app-count-down-dialog',
  templateUrl: './count-down-dialog.component.html',
  styleUrls: ['./count-down-dialog.component.scss']
})
export class CountDownDialogComponent implements OnInit {

  courseId: any;
  countdown: number = 5;
  countdownInterval: any;

  constructor(public commonService: CommonService,
              @Optional() private dialogRef: MatDialogRef<CountDownDialogComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
              private router: Router) {}

  ngOnInit() {
    this.startCountdown();
  }

  /**
   * Stop Quiz Result/Lesson page Countdown and Stay in the same page
   */
  stopCountdown() {
    const eventTypeId = this.dialogData?.lessonId ?
      EventLogsEnum.lessonPageCountDown :
      EventLogsEnum.quizResultCountDown;
    this.commonService.saveStudentClickEventLog(eventTypeId, this.dialogData?.courseId);
    clearInterval(this.countdownInterval);
    this.dialogRef.close(true);
  }

  /**
   * Start Quiz Result Countdown and redirect to payment page/lesson page.
   */
  private startCountdown() {
    this.countdownInterval = setInterval(() => {
      this.countdown--;
      if (this.countdown < 0) {
        clearInterval(this.countdownInterval);
        this.dialogRef.close();
        this.navigateToLessonOrPaymentPage();
      }
    }, 1000);
  }

  /**
   * navigate to lesson or payment page.
   */
  private navigateToLessonOrPaymentPage() {
    const feeType = this.dialogData?.isCMICourse ? CourseFeeTypes.CMI_CERTIFICATION_FEE : CourseFeeTypes.DIGITAL_CERT_FEE
    const url = this.dialogData?.lessonId ?
      RoutingConstant.STUDENT_DASHBOARD_VIEW_AS_LEARNER(this.dialogData?.courseId, this.dialogData?.lessonId) :
      RoutingConstant.PAYMENT_DETAILS(this.dialogData?.courseId, feeType);
    this.router.navigateByUrl(url);
  }
  
}
