/**
 * Endpoint details for the application.
 */
 export const RoutingConstant = {

  CONTACT_US:'/contact-us',
  
  COURSE_DETAILS: '/student-dashboard/course',
  COURSE_DETAILS_PAGE: (courseId: string, slugName: string) => `/student-dashboard/course/${courseId}/${slugName}`,
  COURSE_LANDING_DEFAULT: `/student-dashboard/course-landing`,
  COURSE_LANDING_PAGE: (courseId: string, slugName: string) => `/student-dashboard/course-landing/${courseId}/${slugName}`,
  COURSE_RECOMMENDATION: '/course-recommendation',
  COURSE_RECOMMENDATION_CATEGORIES: '/course-recommendation/categories',
  COURSE_RECOMMENDATION_LIST: '/course-recommendation/list',
  COURSE_RECOMMENDATION_SECTORS: '/course-recommendation/sectors',
  COURSE_RECOMMENDATION_THANKS: '/course-recommendation/thank-you',

  DEFAULT: '/',
  DOWNLOAD_PDF: '/download-pdf-page',
  
  EARN_CREDITS: `/earn-credits`,
  EARN_CREDITS_COURSE: (courseId: string) => `/earn-credits/${courseId}`,
  EARN_CREDITS_THANK_YOU: (courseId: string) =>  `/earn-credits/${courseId}/thank-you`,

  ELIGIBLE_FOR_CLAIM_CERTIFICATE: `/student-dashboard/eligible-for-certification`,
  EMAIL_SUBSCRIPTION: (userId: string) => `/email-preference/${userId}/subscribe`,
  EMAIL_UNSUBSCRIPTION: (userId: string) => `/email-preference/${userId}/unsubscribe`,

  FAQ: '/faq',
  FEEDBACK_FORM: '/feedback',
  FEEDBACK_FORM_WITH_SLUG: (slugName: string) => `/feedback/${slugName}`,
  
  GENERIC_FORM: '/form',
  GENERIC_FORM_WITH_SLUG: (slugName: string) => `/form/${slugName}`,

  PAYMENT: '/payment',
  PAYMENT_DETAILS: (courseId: string, feeType?: string) => feeType ? 
  `/payment/${courseId}?feeType=${feeType}` : `/payment/${courseId}`,
  PAYMENT_DETAILS_EARN_CREDITS: (courseId: string, feeType: string, acadsCourseId: string) => `/payment/${courseId}?feeType=${feeType}&acadsCourseId=${acadsCourseId}`,
  PAYMENT_DETAILS_WITH_UTM_DETAILS: (courseId: string, utmParams: string, feeType?: string) => feeType ? `/payment/${courseId}?${utmParams}&feeType=${feeType}` :
   `/payment/${courseId}?${utmParams}`,
  PAYMENT_STATUS: (orderId: string) => `/payment/payment-status/${orderId}`,
  PAYMENT_STATUS_DEFAULT: '/payment/payment-status',
  PERSONALIZED_OFFERS: '/personalized-offers',
  PUSH_NOTIFICATION: `/push-notification`,

  QUIZ_ASSESSMENT: '/quiz-assessment',
  QUIZ_ASSESSMENT_RESULT: '/quiz-assessment/result',
  QUIZ_ASSESSMENT_INSTRUCTION: '/quiz-assessment/instruction',
  QUIZ_ASSESSMENT_QUESTION: '/quiz-assessment/questions',

  REWARD_POINTS: '/reward-points',
  REWARD_POINTS_CURRENT: '/reward-points/current',
  REWARD_POINTS_EARN: '/reward-points/earn',
  REWARD_POINTS_SHARED: '/reward-points/shared-courses',
  REWARD_POINTS_STATEMENT: '/reward-points/statement',

  SHORT_COURSE_LOGIN_URL: '/short-course-sign-in',
  SHORT_COURSE_REGISTER_URL: '/short-course-sign-up',

  STUDENT_DASHBOARD: '/student-dashboard',
  STUDENT_DASHBOARD_CERTIFICATE_ISSUED: '/student-dashboard/certification-issued',
  STUDENT_DASHBOARD_CERTIFICATION_LEVEL: '/student-dashboard/certification-level',
  STUDENT_DASHBOARD_REPORT_CARD: (courseId: string) => `/student-dashboard/report-card/${courseId}`,
  STUDENT_DASHBOARD_VIEW_AS_LEARNER: (courseId: string, lessonId: string, discussionId?: string) => discussionId ?
    `/student-dashboard/view-as-learner/${courseId}/${lessonId}?discussionId=${discussionId}`
    : `/student-dashboard/view-as-learner/${courseId}/${lessonId}`,
  STUDENT_PROFILE: '/student-profile',
  STUDENT_PROFILE_EDIT: '/student-profile/edit',
  SUBSCRIPTIONS: '/subscriptions',

  UN_PUBLISHED_COURSE_PAGE: '/student-dashboard/error-page',
  
  VIEW_AS_LEARNER_PAGE: 'view-as-learner'
};
