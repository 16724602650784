import { State, Action, StateContext, Selector } from '@ngxs/store';
import { StudentProgress } from '../model/student-progress.model';
import { GetStudentProgress, GetSubscriptionPlans } from '../actions/student-dashboard.action';
import { StudentDashboardService } from '../student-dashboard/student-dashboard.service';
import { EndpointConstant } from '../shared/constant/endpoint.constant';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SubscriptionPlan } from '../model/subscription-plan.model';
import { CommonService } from '../services/common.service';
export class StudentProgressModel {
    data: StudentProgress;
}

@State<StudentProgressModel>({
    name: 'progress',
    defaults: {
        data: <StudentProgress>{}
    }
})

@Injectable()
export class StudentProgressState {
    constructor(private studentDashboardService: StudentDashboardService) {}
    
    @Selector()
    static getProgressInfo(state: StudentProgress) {
        return state;
    }
    
    @Action(GetStudentProgress)
    getProgress({getState, setState}: StateContext<StudentProgressModel>) {
        return this.studentDashboardService.getDetails(EndpointConstant.STUDENT_PROGRESS)
                   .pipe(tap((result) => {
                        const state = getState();
                        setState({
                            ...state, ...result?.data
                        });
        }));
    }
}

  
export class SubscriptionPlansModel {
    data: Array<SubscriptionPlan>;
}

@State<SubscriptionPlansModel>({
    name: 'plans',
    defaults: {
        data: []
    }
})

@Injectable()
export class SubscriptionPlansState {
    constructor(private studentDashboardService: StudentDashboardService,
                private commonService: CommonService) {}
    
    @Selector()
    static getSubsciptionInfo(state: SubscriptionPlan) {
        return state;
    }
    
    @Action(GetSubscriptionPlans)
    getSubscriptionPlans({getState, setState}: StateContext<SubscriptionPlansModel>) {
        const endpoint = `${EndpointConstant.SUBSCRIBE_PLANS}?country_code=${this.commonService.countryCode}`;
        return this.studentDashboardService.getDetails(endpoint)
                   .pipe(tap((result) => {
                        const state = getState();
                        setState({
                            ...state, ...result
                        });
        }));
    }
}

